import ModalActionTypes from './modal.types';

const INITIAL_STATE = {
  view: 'Incomes',
  current: 'Todos',
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalActionTypes.TOGGLE_MODAL_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case ModalActionTypes.TOGGLE_MODAL_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
};
export default modalReducer;
