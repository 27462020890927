import { createContext, useEffect, useContext, useState } from 'react';

import { Auth, Hub } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { emailSignInStart } from '../../../../redux/user/user.actions';
import { toast } from 'react-toastify';
import AmplifyErrors from '../../../../utils/AmplifyErrors';

const UserContext = createContext({
  signIn: () => {},
  completeNewPassword: () => {},
  verification: () => {},
  resentCode: () => {},
  userData: null,
});

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          dispatch(emailSignInStart(data.attributes.sub || data.username));
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
          AmplifyErrors(data.code);
          if (data.code === 'UserNotConfirmedException') {
            navigate('/confirm-email');
          }
          break;
        default:
          break;
      }
    });

    return unsubscribe;
  }, [dispatch, navigate]);

  const signIn = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUserData({
          user,
        });
        return navigate('/change-password');
      }
      //
    } catch (error) {
      setUserData({
        email,
        password,
      });
      console.log('P');
      console.error(error);
    }
  };

  const verification = async (code) => {
    try {
      await Auth.confirmSignUp(userData.email, code);
      await Auth.signIn(userData.email, userData.password);
    } catch (error) {
      console.error(error);
    }
  };

  const changePassword = async (email, password, code) => {};

  const completeNewPassword = async (newPassword) => {
    try {
      await Auth.completeNewPassword(userData.user, newPassword);
    } catch (error) {
      console.error(error);
    }
  };
  const resentCode = async () => {
    try {
      await Auth.resendSignUp(userData.email);
      toast.info('Código reenviado');
    } catch (error) {
      console.error(error);
      toast.error('Error al reenviar el código');
    }
  };

  return (
    <UserContext.Provider
      value={{
        signIn,
        completeNewPassword,
        verification,
        resentCode,
        userData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useAuth() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useAuth };
