import React, { useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ModalPos from './ModalPos';
import { Dropdown } from '../../../../shared/components/form/Dropdown';
import InitialCash from './InitialCash';

const TurnOnModal = ({
  modal,
  handleOpenTurn,
  handleCancelTurn,
  totalCashBefore,
  setTotalCashBefore,
  cash,
  setCash,
  handleChangeInput,
  posDataOptions,
  terminalOptions,
  selectPos,
  selectTerminal,
  handleChangeSelectPos,
  handleChangeTerminal,
  setObservationTurn,
}) => {
  const calculateTotal = (cash) => {
    const keys = Object.keys(cash);

    let total = 0;
    keys?.map((item) => {
      let num = item * cash[item];
      return (total = total + num);
    });

    return setTotalCashBefore(total);
  };

  const clearData = () => {
    setCash(null);
    setTotalCashBefore(null);
  };

  useEffect(() => {
    clearData();
  }, [modal]);

  useEffect(() => {
    if (cash) {
      calculateTotal(cash);
    }
  }, [cash]);

  return (
    <ModalPos open={modal} title="Abrir turno" toggle={handleCancelTurn}>
      <div>
        <Col>
          <Row>
            <Col className="mt-3" md={1} xl={1} lg={1} />
            <Col xl={5} lg={5}>
              <p className="bold-text">Seleccione punto de venta</p>
              <Dropdown
                placeholder="Seleccione tipo de documento*"
                options={posDataOptions}
                onChange={(e) => handleChangeSelectPos(e)}
              />
            </Col>
            {selectPos && (
              <Col xl={5} lg={5}>
                <p className="bold-text">Seleccione terminal</p>
                <Dropdown
                  placeholder="Seleccione tipo de documento*"
                  options={terminalOptions}
                  onChange={(e) => handleChangeTerminal(e)}
                />
              </Col>
            )}
          </Row>
          {selectTerminal && selectPos && (
            <InitialCash
              title="Efectivo inicial"
              {...{ totalCashBefore, setObservationTurn, handleChangeInput }}
            />
          )}
        </Col>

        <Button
          className="mt-3"
          outline
          onClick={handleOpenTurn}
          disabled={!selectPos || !selectTerminal}
        >
          <p>Iniciar</p>
        </Button>
      </div>
    </ModalPos>
  );
};

export default TurnOnModal;
