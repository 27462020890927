import UserActionTypes from './user.types';

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});
export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const signInFailure = (error, email) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: { error, email },
});
export const userNotVerified = () => ({
  type: UserActionTypes.USER_NOT_VERIFIED,
});

export const userVerified = () => ({
  type: UserActionTypes.USER_VERIFIED_SUCCESS,
});
export const createUserCompany = (userCredentials) => ({
  type: UserActionTypes.CREATE_COMPANY,
  payload: userCredentials,
});

export const userVerifiedStart = (emailAndcode) => ({
  type: UserActionTypes.USER_VERIFIED_START,
  payload: emailAndcode,
});

export const userVerifiedAfterSignUp = (userData) => ({
  type: UserActionTypes.USER_VERIFIED_SIGN_UP,
  payload: userData,
});

export const emailSignInStart = (userId) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: userId,
});

export const checkUserSession = (userId) => ({
  type: UserActionTypes.CHECK_USER_SESSION,
  payload: userId,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
});

export const signUpSuccess = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: userCredentials,
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});
