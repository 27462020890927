import { createSelector } from 'reselect';

const selectWrapper = (state) => state.wrapper;

export const selectWrapperView = createSelector(
  [selectWrapper],
  (wrapper) => wrapper.View
);

export const selectWrapperStateView = createSelector(
  [selectWrapper],
  (wrapper) => wrapper.active
);
export const selectWrapperViewProps = createSelector(
  [selectWrapper],
  (wrapper) => wrapper.props
);
