/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      TAXID
      verificationDigit
      personType
      constitutionDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      isKeyPartner
      tags {
        name
        value
        __typename
      }
      companyMainRole
      signatures {
        fullName
        prefix
        title
        __typename
      }
      defaultSignature
      bankingEnabled
      restrictiveLists
      PEPS
      PEPSCategory
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      referrals {
        name
        email
        date
        __typename
      }
      lastDateOfReferral
      readers
      editors
      createdAt
      updatedAt
      owner
      configuration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      plan {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      documents {
        nextToken
        __typename
      }
      industries {
        nextToken
        __typename
      }
      forms {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      badges {
        nextToken
        __typename
      }
      supportTickets {
        nextToken
        __typename
      }
      groupCategories {
        nextToken
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      taxes {
        nextToken
        __typename
      }
      logging {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      terminals {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      cashiers {
        nextToken
        __typename
      }
      salesmen {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      epayroll {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      bags {
        nextToken
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      productServiceTypes {
        nextToken
        __typename
      }
      supplierProductsServices {
        nextToken
        __typename
      }
      keyPartnersProductsServices {
        nextToken
        __typename
      }
      customers {
        nextToken
        __typename
      }
      suppliers {
        nextToken
        __typename
      }
      patients {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      commissionsAndTips {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      TAXID
      verificationDigit
      personType
      constitutionDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      isKeyPartner
      tags {
        name
        value
        __typename
      }
      companyMainRole
      signatures {
        fullName
        prefix
        title
        __typename
      }
      defaultSignature
      bankingEnabled
      restrictiveLists
      PEPS
      PEPSCategory
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      referrals {
        name
        email
        date
        __typename
      }
      lastDateOfReferral
      readers
      editors
      createdAt
      updatedAt
      owner
      configuration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      plan {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      documents {
        nextToken
        __typename
      }
      industries {
        nextToken
        __typename
      }
      forms {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      badges {
        nextToken
        __typename
      }
      supportTickets {
        nextToken
        __typename
      }
      groupCategories {
        nextToken
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      taxes {
        nextToken
        __typename
      }
      logging {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      terminals {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      cashiers {
        nextToken
        __typename
      }
      salesmen {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      epayroll {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      bags {
        nextToken
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      productServiceTypes {
        nextToken
        __typename
      }
      supplierProductsServices {
        nextToken
        __typename
      }
      keyPartnersProductsServices {
        nextToken
        __typename
      }
      customers {
        nextToken
        __typename
      }
      suppliers {
        nextToken
        __typename
      }
      patients {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      commissionsAndTips {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      TAXID
      verificationDigit
      personType
      constitutionDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      isKeyPartner
      tags {
        name
        value
        __typename
      }
      companyMainRole
      signatures {
        fullName
        prefix
        title
        __typename
      }
      defaultSignature
      bankingEnabled
      restrictiveLists
      PEPS
      PEPSCategory
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      referrals {
        name
        email
        date
        __typename
      }
      lastDateOfReferral
      readers
      editors
      createdAt
      updatedAt
      owner
      configuration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      plan {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      documents {
        nextToken
        __typename
      }
      industries {
        nextToken
        __typename
      }
      forms {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      badges {
        nextToken
        __typename
      }
      supportTickets {
        nextToken
        __typename
      }
      groupCategories {
        nextToken
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      taxes {
        nextToken
        __typename
      }
      logging {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      terminals {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      cashiers {
        nextToken
        __typename
      }
      salesmen {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      epayroll {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      bags {
        nextToken
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      productServiceTypes {
        nextToken
        __typename
      }
      supplierProductsServices {
        nextToken
        __typename
      }
      keyPartnersProductsServices {
        nextToken
        __typename
      }
      customers {
        nextToken
        __typename
      }
      suppliers {
        nextToken
        __typename
      }
      patients {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      commissionsAndTips {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPublicCompany = /* GraphQL */ `
  mutation CreatePublicCompany(
    $input: CreatePublicCompanyInput!
    $condition: ModelPublicCompanyConditionInput
  ) {
    createPublicCompany(input: $input, condition: $condition) {
      id
      name
      url
      description
      lemma
      industries
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      defaultContact
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      bannerPhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      productServices
      readers
      editors
      createdAt
      updatedAt
      owner
      posts {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updatePublicCompany = /* GraphQL */ `
  mutation UpdatePublicCompany(
    $input: UpdatePublicCompanyInput!
    $condition: ModelPublicCompanyConditionInput
  ) {
    updatePublicCompany(input: $input, condition: $condition) {
      id
      name
      url
      description
      lemma
      industries
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      defaultContact
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      bannerPhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      productServices
      readers
      editors
      createdAt
      updatedAt
      owner
      posts {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deletePublicCompany = /* GraphQL */ `
  mutation DeletePublicCompany(
    $input: DeletePublicCompanyInput!
    $condition: ModelPublicCompanyConditionInput
  ) {
    deletePublicCompany(input: $input, condition: $condition) {
      id
      name
      url
      description
      lemma
      industries
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      defaultContact
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      bannerPhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      productServices
      readers
      editors
      createdAt
      updatedAt
      owner
      posts {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyConfiguration = /* GraphQL */ `
  mutation CreateCompanyConfiguration(
    $input: CreateCompanyConfigurationInput!
    $condition: ModelCompanyConfigurationConditionInput
  ) {
    createCompanyConfiguration(input: $input, condition: $condition) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        __typename
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        __typename
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
        __typename
      }
      branches {
        name
        code
        description
        currentBillNumber
        __typename
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      adjustmentNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      decimals
      decimalsQuantity
      emailForInvoicing
      eInvoiceHeaderExtraFields {
        name
        value
        __typename
      }
      eInvoiceFooterExtraFields {
        name
        value
        __typename
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      currentEventsNumber
      billsReceiverData
      sendSuppliersEmail
      quotationsPrefixAndNumbering
      cashReceiptsPrefixAndNumbering
      dischargeReceiptsPrefixAndNumbering
      signInvoices
      signSupportDocuments
      signCashReceipts
      signDischargeReceipt
      adjustTaxBase
      healthProvider
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      productServicesConfiguration
      showBatchAndExpirationDate
      warehousesConfiguration
      pointOfSalesConfiguration
      terminalsConfiguration
      POSEnabled
      POSResolutions
      cashiersConfiguration
      salesmenConfiguration
      POSDocumentConfiguration
      defaultToInvoiceIfHigherValue
      storagePath
      loggingVerbosityLevel
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsabilities {
        nextToken
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyConfiguration = /* GraphQL */ `
  mutation UpdateCompanyConfiguration(
    $input: UpdateCompanyConfigurationInput!
    $condition: ModelCompanyConfigurationConditionInput
  ) {
    updateCompanyConfiguration(input: $input, condition: $condition) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        __typename
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        __typename
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
        __typename
      }
      branches {
        name
        code
        description
        currentBillNumber
        __typename
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      adjustmentNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      decimals
      decimalsQuantity
      emailForInvoicing
      eInvoiceHeaderExtraFields {
        name
        value
        __typename
      }
      eInvoiceFooterExtraFields {
        name
        value
        __typename
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      currentEventsNumber
      billsReceiverData
      sendSuppliersEmail
      quotationsPrefixAndNumbering
      cashReceiptsPrefixAndNumbering
      dischargeReceiptsPrefixAndNumbering
      signInvoices
      signSupportDocuments
      signCashReceipts
      signDischargeReceipt
      adjustTaxBase
      healthProvider
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      productServicesConfiguration
      showBatchAndExpirationDate
      warehousesConfiguration
      pointOfSalesConfiguration
      terminalsConfiguration
      POSEnabled
      POSResolutions
      cashiersConfiguration
      salesmenConfiguration
      POSDocumentConfiguration
      defaultToInvoiceIfHigherValue
      storagePath
      loggingVerbosityLevel
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsabilities {
        nextToken
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyConfiguration = /* GraphQL */ `
  mutation DeleteCompanyConfiguration(
    $input: DeleteCompanyConfigurationInput!
    $condition: ModelCompanyConfigurationConditionInput
  ) {
    deleteCompanyConfiguration(input: $input, condition: $condition) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        __typename
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        __typename
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
        __typename
      }
      branches {
        name
        code
        description
        currentBillNumber
        __typename
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      adjustmentNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      decimals
      decimalsQuantity
      emailForInvoicing
      eInvoiceHeaderExtraFields {
        name
        value
        __typename
      }
      eInvoiceFooterExtraFields {
        name
        value
        __typename
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      currentEventsNumber
      billsReceiverData
      sendSuppliersEmail
      quotationsPrefixAndNumbering
      cashReceiptsPrefixAndNumbering
      dischargeReceiptsPrefixAndNumbering
      signInvoices
      signSupportDocuments
      signCashReceipts
      signDischargeReceipt
      adjustTaxBase
      healthProvider
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      productServicesConfiguration
      showBatchAndExpirationDate
      warehousesConfiguration
      pointOfSalesConfiguration
      terminalsConfiguration
      POSEnabled
      POSResolutions
      cashiersConfiguration
      salesmenConfiguration
      POSDocumentConfiguration
      defaultToInvoiceIfHigherValue
      storagePath
      loggingVerbosityLevel
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsabilities {
        nextToken
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyConfigurationFiscalResponsability = /* GraphQL */ `
  mutation CreateCompanyConfigurationFiscalResponsability(
    $input: CreateCompanyConfigurationFiscalResponsabilityInput!
    $condition: ModelCompanyConfigurationFiscalResponsabilityConditionInput
  ) {
    createCompanyConfigurationFiscalResponsability(
      input: $input
      condition: $condition
    ) {
      id
      companyConfigurationID
      fiscalResponsabilityID
      readers
      editors
      createdAt
      updatedAt
      companyConfiguration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyConfigurationFiscalResponsability = /* GraphQL */ `
  mutation UpdateCompanyConfigurationFiscalResponsability(
    $input: UpdateCompanyConfigurationFiscalResponsabilityInput!
    $condition: ModelCompanyConfigurationFiscalResponsabilityConditionInput
  ) {
    updateCompanyConfigurationFiscalResponsability(
      input: $input
      condition: $condition
    ) {
      id
      companyConfigurationID
      fiscalResponsabilityID
      readers
      editors
      createdAt
      updatedAt
      companyConfiguration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyConfigurationFiscalResponsability = /* GraphQL */ `
  mutation DeleteCompanyConfigurationFiscalResponsability(
    $input: DeleteCompanyConfigurationFiscalResponsabilityInput!
    $condition: ModelCompanyConfigurationFiscalResponsabilityConditionInput
  ) {
    deleteCompanyConfigurationFiscalResponsability(
      input: $input
      condition: $condition
    ) {
      id
      companyConfigurationID
      fiscalResponsabilityID
      readers
      editors
      createdAt
      updatedAt
      companyConfiguration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createMyPlan = /* GraphQL */ `
  mutation CreateMyPlan(
    $input: CreateMyPlanInput!
    $condition: ModelMyPlanConditionInput
  ) {
    createMyPlan(input: $input, condition: $condition) {
      id
      initDate
      endDate
      active
      data
      log
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateMyPlan = /* GraphQL */ `
  mutation UpdateMyPlan(
    $input: UpdateMyPlanInput!
    $condition: ModelMyPlanConditionInput
  ) {
    updateMyPlan(input: $input, condition: $condition) {
      id
      initDate
      endDate
      active
      data
      log
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteMyPlan = /* GraphQL */ `
  mutation DeleteMyPlan(
    $input: DeleteMyPlanInput!
    $condition: ModelMyPlanConditionInput
  ) {
    deleteMyPlan(input: $input, condition: $condition) {
      id
      initDate
      endDate
      active
      data
      log
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyDocument = /* GraphQL */ `
  mutation CreateCompanyDocument(
    $input: CreateCompanyDocumentInput!
    $condition: ModelCompanyDocumentConditionInput
  ) {
    createCompanyDocument(input: $input, condition: $condition) {
      id
      location {
        bucket
        region
        key
        __typename
      }
      date
      expires
      validUntil
      public
      metadata
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyDocument = /* GraphQL */ `
  mutation UpdateCompanyDocument(
    $input: UpdateCompanyDocumentInput!
    $condition: ModelCompanyDocumentConditionInput
  ) {
    updateCompanyDocument(input: $input, condition: $condition) {
      id
      location {
        bucket
        region
        key
        __typename
      }
      date
      expires
      validUntil
      public
      metadata
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyDocument = /* GraphQL */ `
  mutation DeleteCompanyDocument(
    $input: DeleteCompanyDocumentInput!
    $condition: ModelCompanyDocumentConditionInput
  ) {
    deleteCompanyDocument(input: $input, condition: $condition) {
      id
      location {
        bucket
        region
        key
        __typename
      }
      date
      expires
      validUntil
      public
      metadata
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyIndustry = /* GraphQL */ `
  mutation CreateCompanyIndustry(
    $input: CreateCompanyIndustryInput!
    $condition: ModelCompanyIndustryConditionInput
  ) {
    createCompanyIndustry(input: $input, condition: $condition) {
      id
      companyID
      industryID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      industry {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyIndustry = /* GraphQL */ `
  mutation UpdateCompanyIndustry(
    $input: UpdateCompanyIndustryInput!
    $condition: ModelCompanyIndustryConditionInput
  ) {
    updateCompanyIndustry(input: $input, condition: $condition) {
      id
      companyID
      industryID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      industry {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyIndustry = /* GraphQL */ `
  mutation DeleteCompanyIndustry(
    $input: DeleteCompanyIndustryInput!
    $condition: ModelCompanyIndustryConditionInput
  ) {
    deleteCompanyIndustry(input: $input, condition: $condition) {
      id
      companyID
      industryID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      industry {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyForm = /* GraphQL */ `
  mutation CreateCompanyForm(
    $input: CreateCompanyFormInput!
    $condition: ModelCompanyFormConditionInput
  ) {
    createCompanyForm(input: $input, condition: $condition) {
      id
      companyID
      formID
      answers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      form {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyForm = /* GraphQL */ `
  mutation UpdateCompanyForm(
    $input: UpdateCompanyFormInput!
    $condition: ModelCompanyFormConditionInput
  ) {
    updateCompanyForm(input: $input, condition: $condition) {
      id
      companyID
      formID
      answers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      form {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyForm = /* GraphQL */ `
  mutation DeleteCompanyForm(
    $input: DeleteCompanyFormInput!
    $condition: ModelCompanyFormConditionInput
  ) {
    deleteCompanyForm(input: $input, condition: $condition) {
      id
      companyID
      formID
      answers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      form {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      timestamp
      owner
      type
      title
      message
      data
      url
      callback
      callbackParams
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      timestamp
      owner
      type
      title
      message
      data
      url
      callback
      callbackParams
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      timestamp
      owner
      type
      title
      message
      data
      url
      callback
      callbackParams
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyBadge = /* GraphQL */ `
  mutation CreateCompanyBadge(
    $input: CreateCompanyBadgeInput!
    $condition: ModelCompanyBadgeConditionInput
  ) {
    createCompanyBadge(input: $input, condition: $condition) {
      id
      companyID
      badgeID
      assigned
      active
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      badge {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyBadge = /* GraphQL */ `
  mutation UpdateCompanyBadge(
    $input: UpdateCompanyBadgeInput!
    $condition: ModelCompanyBadgeConditionInput
  ) {
    updateCompanyBadge(input: $input, condition: $condition) {
      id
      companyID
      badgeID
      assigned
      active
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      badge {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyBadge = /* GraphQL */ `
  mutation DeleteCompanyBadge(
    $input: DeleteCompanyBadgeInput!
    $condition: ModelCompanyBadgeConditionInput
  ) {
    deleteCompanyBadge(input: $input, condition: $condition) {
      id
      companyID
      badgeID
      assigned
      active
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      badge {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSupportTicket = /* GraphQL */ `
  mutation CreateSupportTicket(
    $input: CreateSupportTicketInput!
    $condition: ModelSupportTicketConditionInput
  ) {
    createSupportTicket(input: $input, condition: $condition) {
      id
      subject
      description
      assignedTo
      messages {
        timestamp
        authorName
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSupportTicket = /* GraphQL */ `
  mutation UpdateSupportTicket(
    $input: UpdateSupportTicketInput!
    $condition: ModelSupportTicketConditionInput
  ) {
    updateSupportTicket(input: $input, condition: $condition) {
      id
      subject
      description
      assignedTo
      messages {
        timestamp
        authorName
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSupportTicket = /* GraphQL */ `
  mutation DeleteSupportTicket(
    $input: DeleteSupportTicketInput!
    $condition: ModelSupportTicketConditionInput
  ) {
    deleteSupportTicket(input: $input, condition: $condition) {
      id
      subject
      description
      assignedTo
      messages {
        timestamp
        authorName
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createMyGroupCategory = /* GraphQL */ `
  mutation CreateMyGroupCategory(
    $input: CreateMyGroupCategoryInput!
    $condition: ModelMyGroupCategoryConditionInput
  ) {
    createMyGroupCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      accountCategories {
        nextToken
        __typename
      }
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateMyGroupCategory = /* GraphQL */ `
  mutation UpdateMyGroupCategory(
    $input: UpdateMyGroupCategoryInput!
    $condition: ModelMyGroupCategoryConditionInput
  ) {
    updateMyGroupCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      accountCategories {
        nextToken
        __typename
      }
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteMyGroupCategory = /* GraphQL */ `
  mutation DeleteMyGroupCategory(
    $input: DeleteMyGroupCategoryInput!
    $condition: ModelMyGroupCategoryConditionInput
  ) {
    deleteMyGroupCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      accountCategories {
        nextToken
        __typename
      }
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createMyAccountCategory = /* GraphQL */ `
  mutation CreateMyAccountCategory(
    $input: CreateMyAccountCategoryInput!
    $condition: ModelMyAccountCategoryConditionInput
  ) {
    createMyAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      groupCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateMyAccountCategory = /* GraphQL */ `
  mutation UpdateMyAccountCategory(
    $input: UpdateMyAccountCategoryInput!
    $condition: ModelMyAccountCategoryConditionInput
  ) {
    updateMyAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      groupCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteMyAccountCategory = /* GraphQL */ `
  mutation DeleteMyAccountCategory(
    $input: DeleteMyAccountCategoryInput!
    $condition: ModelMyAccountCategoryConditionInput
  ) {
    deleteMyAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      groupCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createMySubAccountCategory = /* GraphQL */ `
  mutation CreateMySubAccountCategory(
    $input: CreateMySubAccountCategoryInput!
    $condition: ModelMySubAccountCategoryConditionInput
  ) {
    createMySubAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      auxiliarCategories {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateMySubAccountCategory = /* GraphQL */ `
  mutation UpdateMySubAccountCategory(
    $input: UpdateMySubAccountCategoryInput!
    $condition: ModelMySubAccountCategoryConditionInput
  ) {
    updateMySubAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      auxiliarCategories {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteMySubAccountCategory = /* GraphQL */ `
  mutation DeleteMySubAccountCategory(
    $input: DeleteMySubAccountCategoryInput!
    $condition: ModelMySubAccountCategoryConditionInput
  ) {
    deleteMySubAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      auxiliarCategories {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createAuxiliarCategory = /* GraphQL */ `
  mutation CreateAuxiliarCategory(
    $input: CreateAuxiliarCategoryInput!
    $condition: ModelAuxiliarCategoryConditionInput
  ) {
    createAuxiliarCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAuxiliarCategory = /* GraphQL */ `
  mutation UpdateAuxiliarCategory(
    $input: UpdateAuxiliarCategoryInput!
    $condition: ModelAuxiliarCategoryConditionInput
  ) {
    updateAuxiliarCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAuxiliarCategory = /* GraphQL */ `
  mutation DeleteAuxiliarCategory(
    $input: DeleteAuxiliarCategoryInput!
    $condition: ModelAuxiliarCategoryConditionInput
  ) {
    deleteAuxiliarCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTax = /* GraphQL */ `
  mutation CreateTax(
    $input: CreateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    createTax(input: $input, condition: $condition) {
      id
      name
      type
      percentage
      municipality
      value
      country
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateTax = /* GraphQL */ `
  mutation UpdateTax(
    $input: UpdateTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    updateTax(input: $input, condition: $condition) {
      id
      name
      type
      percentage
      municipality
      value
      country
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteTax = /* GraphQL */ `
  mutation DeleteTax(
    $input: DeleteTaxInput!
    $condition: ModelTaxConditionInput
  ) {
    deleteTax(input: $input, condition: $condition) {
      id
      name
      type
      percentage
      municipality
      value
      country
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      timestamp
      operationType
      subOperationType
      description
      total
      url
      extraData
      user
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      readers
      editors
      __typename
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      timestamp
      operationType
      subOperationType
      description
      total
      url
      extraData
      user
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      readers
      editors
      __typename
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      timestamp
      operationType
      subOperationType
      description
      total
      url
      extraData
      user
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      readers
      editors
      __typename
    }
  }
`;
export const createWarehouse = /* GraphQL */ `
  mutation CreateWarehouse(
    $input: CreateWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    createWarehouse(input: $input, condition: $condition) {
      id
      code
      description
      location {
        name
        headquarters
        branchCode
        __typename
      }
      remarks
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateWarehouse = /* GraphQL */ `
  mutation UpdateWarehouse(
    $input: UpdateWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    updateWarehouse(input: $input, condition: $condition) {
      id
      code
      description
      location {
        name
        headquarters
        branchCode
        __typename
      }
      remarks
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteWarehouse = /* GraphQL */ `
  mutation DeleteWarehouse(
    $input: DeleteWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    deleteWarehouse(input: $input, condition: $condition) {
      id
      code
      description
      location {
        name
        headquarters
        branchCode
        __typename
      }
      remarks
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPOS = /* GraphQL */ `
  mutation CreatePOS(
    $input: CreatePOSInput!
    $condition: ModelPOSConditionInput
  ) {
    createPOS(input: $input, condition: $condition) {
      id
      code
      location {
        name
        headquarters
        branchCode
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updatePOS = /* GraphQL */ `
  mutation UpdatePOS(
    $input: UpdatePOSInput!
    $condition: ModelPOSConditionInput
  ) {
    updatePOS(input: $input, condition: $condition) {
      id
      code
      location {
        name
        headquarters
        branchCode
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deletePOS = /* GraphQL */ `
  mutation DeletePOS(
    $input: DeletePOSInput!
    $condition: ModelPOSConditionInput
  ) {
    deletePOS(input: $input, condition: $condition) {
      id
      code
      location {
        name
        headquarters
        branchCode
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTerminal = /* GraphQL */ `
  mutation CreateTerminal(
    $input: CreateTerminalInput!
    $condition: ModelTerminalConditionInput
  ) {
    createTerminal(input: $input, condition: $condition) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      nameType
      location
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      cashiers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTerminal = /* GraphQL */ `
  mutation UpdateTerminal(
    $input: UpdateTerminalInput!
    $condition: ModelTerminalConditionInput
  ) {
    updateTerminal(input: $input, condition: $condition) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      nameType
      location
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      cashiers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTerminal = /* GraphQL */ `
  mutation DeleteTerminal(
    $input: DeleteTerminalInput!
    $condition: ModelTerminalConditionInput
  ) {
    deleteTerminal(input: $input, condition: $condition) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      nameType
      location
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      cashiers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      companyRole
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      status
      employeeID
      supplierID
      cashierID
      salesmanID
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      companyRole
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      status
      employeeID
      supplierID
      cashierID
      salesmanID
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      companyRole
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      status
      employeeID
      supplierID
      cashierID
      salesmanID
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyRole = /* GraphQL */ `
  mutation CreateCompanyRole(
    $input: CreateCompanyRoleInput!
    $condition: ModelCompanyRoleConditionInput
  ) {
    createCompanyRole(input: $input, condition: $condition) {
      id
      name
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      users {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateCompanyRole = /* GraphQL */ `
  mutation UpdateCompanyRole(
    $input: UpdateCompanyRoleInput!
    $condition: ModelCompanyRoleConditionInput
  ) {
    updateCompanyRole(input: $input, condition: $condition) {
      id
      name
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      users {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteCompanyRole = /* GraphQL */ `
  mutation DeleteCompanyRole(
    $input: DeleteCompanyRoleInput!
    $condition: ModelCompanyRoleConditionInput
  ) {
    deleteCompanyRole(input: $input, condition: $condition) {
      id
      name
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      users {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createCashier = /* GraphQL */ `
  mutation CreateCashier(
    $input: CreateCashierInput!
    $condition: ModelCashierConditionInput
  ) {
    createCashier(input: $input, condition: $condition) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCashier = /* GraphQL */ `
  mutation UpdateCashier(
    $input: UpdateCashierInput!
    $condition: ModelCashierConditionInput
  ) {
    updateCashier(input: $input, condition: $condition) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCashier = /* GraphQL */ `
  mutation DeleteCashier(
    $input: DeleteCashierInput!
    $condition: ModelCashierConditionInput
  ) {
    deleteCashier(input: $input, condition: $condition) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSalesman = /* GraphQL */ `
  mutation CreateSalesman(
    $input: CreateSalesmanInput!
    $condition: ModelSalesmanConditionInput
  ) {
    createSalesman(input: $input, condition: $condition) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comtips {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSalesman = /* GraphQL */ `
  mutation UpdateSalesman(
    $input: UpdateSalesmanInput!
    $condition: ModelSalesmanConditionInput
  ) {
    updateSalesman(input: $input, condition: $condition) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comtips {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSalesman = /* GraphQL */ `
  mutation DeleteSalesman(
    $input: DeleteSalesmanInput!
    $condition: ModelSalesmanConditionInput
  ) {
    deleteSalesman(input: $input, condition: $condition) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comtips {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      code
      highPensionRisk
      nationalID
      name
      lastname
      position
      positionType
      defaultBankName
      defaultBankAccountType
      defaultBankAccountNumber
      workCountryCode
      workStateCode
      workCityCode
      address
      integralSalary
      baseSalary
      startDate
      endDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      phoneNumbers {
        countryCode
        number
        extension
        __typename
      }
      tags {
        name
        value
        __typename
      }
      active
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      defaultPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeSubType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      code
      highPensionRisk
      nationalID
      name
      lastname
      position
      positionType
      defaultBankName
      defaultBankAccountType
      defaultBankAccountNumber
      workCountryCode
      workStateCode
      workCityCode
      address
      integralSalary
      baseSalary
      startDate
      endDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      phoneNumbers {
        countryCode
        number
        extension
        __typename
      }
      tags {
        name
        value
        __typename
      }
      active
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      defaultPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeSubType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      code
      highPensionRisk
      nationalID
      name
      lastname
      position
      positionType
      defaultBankName
      defaultBankAccountType
      defaultBankAccountNumber
      workCountryCode
      workStateCode
      workCityCode
      address
      integralSalary
      baseSalary
      startDate
      endDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      phoneNumbers {
        countryCode
        number
        extension
        __typename
      }
      tags {
        name
        value
        __typename
      }
      active
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      defaultPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeSubType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createEmployeeProfile = /* GraphQL */ `
  mutation CreateEmployeeProfile(
    $input: CreateEmployeeProfileInput!
    $condition: ModelEmployeeProfileConditionInput
  ) {
    createEmployeeProfile(input: $input, condition: $condition) {
      id
      studiesLevel
      experienceYears
      rating
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateEmployeeProfile = /* GraphQL */ `
  mutation UpdateEmployeeProfile(
    $input: UpdateEmployeeProfileInput!
    $condition: ModelEmployeeProfileConditionInput
  ) {
    updateEmployeeProfile(input: $input, condition: $condition) {
      id
      studiesLevel
      experienceYears
      rating
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteEmployeeProfile = /* GraphQL */ `
  mutation DeleteEmployeeProfile(
    $input: DeleteEmployeeProfileInput!
    $condition: ModelEmployeeProfileConditionInput
  ) {
    deleteEmployeeProfile(input: $input, condition: $condition) {
      id
      studiesLevel
      experienceYears
      rating
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createEmployeePayment = /* GraphQL */ `
  mutation CreateEmployeePayment(
    $input: CreateEmployeePaymentInput!
    $condition: ModelEmployeePaymentConditionInput
  ) {
    createEmployeePayment(input: $input, condition: $condition) {
      id
      paymentType
      month
      year
      paymentDates
      period {
        startDate
        endDate
        workingTime
        generationDate
        paymentIntervalCode
        __typename
      }
      extras
      deductions
      total
      paymentForm
      bankName
      bankAccountType
      bankAccountNumber
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      ePayroll {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateEmployeePayment = /* GraphQL */ `
  mutation UpdateEmployeePayment(
    $input: UpdateEmployeePaymentInput!
    $condition: ModelEmployeePaymentConditionInput
  ) {
    updateEmployeePayment(input: $input, condition: $condition) {
      id
      paymentType
      month
      year
      paymentDates
      period {
        startDate
        endDate
        workingTime
        generationDate
        paymentIntervalCode
        __typename
      }
      extras
      deductions
      total
      paymentForm
      bankName
      bankAccountType
      bankAccountNumber
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      ePayroll {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteEmployeePayment = /* GraphQL */ `
  mutation DeleteEmployeePayment(
    $input: DeleteEmployeePaymentInput!
    $condition: ModelEmployeePaymentConditionInput
  ) {
    deleteEmployeePayment(input: $input, condition: $condition) {
      id
      paymentType
      month
      year
      paymentDates
      period {
        startDate
        endDate
        workingTime
        generationDate
        paymentIntervalCode
        __typename
      }
      extras
      deductions
      total
      paymentForm
      bankName
      bankAccountType
      bankAccountNumber
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      ePayroll {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createEPayroll = /* GraphQL */ `
  mutation CreateEPayroll(
    $input: CreateEPayrollInput!
    $condition: ModelEPayrollConditionInput
  ) {
    createEPayroll(input: $input, condition: $condition) {
      id
      number
      month
      year
      CUNE
      status
      jsonFile {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateEPayroll = /* GraphQL */ `
  mutation UpdateEPayroll(
    $input: UpdateEPayrollInput!
    $condition: ModelEPayrollConditionInput
  ) {
    updateEPayroll(input: $input, condition: $condition) {
      id
      number
      month
      year
      CUNE
      status
      jsonFile {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteEPayroll = /* GraphQL */ `
  mutation DeleteEPayroll(
    $input: DeleteEPayrollInput!
    $condition: ModelEPayrollConditionInput
  ) {
    deleteEPayroll(input: $input, condition: $condition) {
      id
      number
      month
      year
      CUNE
      status
      jsonFile {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      accountID
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      branchCode
      balance
      userData {
        username
        password
        __typename
      }
      active
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      debitCards {
        nextToken
        __typename
      }
      eCards {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      accountID
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      branchCode
      balance
      userData {
        username
        password
        __typename
      }
      active
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      debitCards {
        nextToken
        __typename
      }
      eCards {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      accountID
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      branchCode
      balance
      userData {
        username
        password
        __typename
      }
      active
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      debitCards {
        nextToken
        __typename
      }
      eCards {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createThirdPartyAccount = /* GraphQL */ `
  mutation CreateThirdPartyAccount(
    $input: CreateThirdPartyAccountInput!
    $condition: ModelThirdPartyAccountConditionInput
  ) {
    createThirdPartyAccount(input: $input, condition: $condition) {
      id
      accountID
      thirdPartyType
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateThirdPartyAccount = /* GraphQL */ `
  mutation UpdateThirdPartyAccount(
    $input: UpdateThirdPartyAccountInput!
    $condition: ModelThirdPartyAccountConditionInput
  ) {
    updateThirdPartyAccount(input: $input, condition: $condition) {
      id
      accountID
      thirdPartyType
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteThirdPartyAccount = /* GraphQL */ `
  mutation DeleteThirdPartyAccount(
    $input: DeleteThirdPartyAccountInput!
    $condition: ModelThirdPartyAccountConditionInput
  ) {
    deleteThirdPartyAccount(input: $input, condition: $condition) {
      id
      accountID
      thirdPartyType
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      bankTransactionID
      reference
      timestamp
      type
      data {
        total
        description
        extra
        __typename
      }
      user
      fee {
        total
        description
        extra
        __typename
      }
      xml {
        __typename
      }
      status
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      debitCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      eCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      bankTransactionID
      reference
      timestamp
      type
      data {
        total
        description
        extra
        __typename
      }
      user
      fee {
        total
        description
        extra
        __typename
      }
      xml {
        __typename
      }
      status
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      debitCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      eCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      bankTransactionID
      reference
      timestamp
      type
      data {
        total
        description
        extra
        __typename
      }
      user
      fee {
        total
        description
        extra
        __typename
      }
      xml {
        __typename
      }
      status
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      debitCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      eCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createDebitCard = /* GraphQL */ `
  mutation CreateDebitCard(
    $input: CreateDebitCardInput!
    $condition: ModelDebitCardConditionInput
  ) {
    createDebitCard(input: $input, condition: $condition) {
      id
      data {
        number
        expirationDate
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateDebitCard = /* GraphQL */ `
  mutation UpdateDebitCard(
    $input: UpdateDebitCardInput!
    $condition: ModelDebitCardConditionInput
  ) {
    updateDebitCard(input: $input, condition: $condition) {
      id
      data {
        number
        expirationDate
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteDebitCard = /* GraphQL */ `
  mutation DeleteDebitCard(
    $input: DeleteDebitCardInput!
    $condition: ModelDebitCardConditionInput
  ) {
    deleteDebitCard(input: $input, condition: $condition) {
      id
      data {
        number
        expirationDate
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createECard = /* GraphQL */ `
  mutation CreateECard(
    $input: CreateECardInput!
    $condition: ModelECardConditionInput
  ) {
    createECard(input: $input, condition: $condition) {
      id
      data {
        number
        expirationDate
        cvv
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateECard = /* GraphQL */ `
  mutation UpdateECard(
    $input: UpdateECardInput!
    $condition: ModelECardConditionInput
  ) {
    updateECard(input: $input, condition: $condition) {
      id
      data {
        number
        expirationDate
        cvv
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteECard = /* GraphQL */ `
  mutation DeleteECard(
    $input: DeleteECardInput!
    $condition: ModelECardConditionInput
  ) {
    deleteECard(input: $input, condition: $condition) {
      id
      data {
        number
        expirationDate
        cvv
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createBag = /* GraphQL */ `
  mutation CreateBag(
    $input: CreateBagInput!
    $condition: ModelBagConditionInput
  ) {
    createBag(input: $input, condition: $condition) {
      id
      name
      balance
      blocked
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateBag = /* GraphQL */ `
  mutation UpdateBag(
    $input: UpdateBagInput!
    $condition: ModelBagConditionInput
  ) {
    updateBag(input: $input, condition: $condition) {
      id
      name
      balance
      blocked
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteBag = /* GraphQL */ `
  mutation DeleteBag(
    $input: DeleteBagInput!
    $condition: ModelBagConditionInput
  ) {
    deleteBag(input: $input, condition: $condition) {
      id
      name
      balance
      blocked
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createProductService = /* GraphQL */ `
  mutation CreateProductService(
    $input: CreateProductServiceInput!
    $condition: ModelProductServiceConditionInput
  ) {
    createProductService(input: $input, condition: $condition) {
      id
      code
      type
      productServiceTypeID
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      limitless
      quantity
      averagePrice
      acceptableLimit
      dontSaleOnScarcity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      configuration
      favorite
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      active
      public
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      psTypes {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateProductService = /* GraphQL */ `
  mutation UpdateProductService(
    $input: UpdateProductServiceInput!
    $condition: ModelProductServiceConditionInput
  ) {
    updateProductService(input: $input, condition: $condition) {
      id
      code
      type
      productServiceTypeID
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      limitless
      quantity
      averagePrice
      acceptableLimit
      dontSaleOnScarcity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      configuration
      favorite
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      active
      public
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      psTypes {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteProductService = /* GraphQL */ `
  mutation DeleteProductService(
    $input: DeleteProductServiceInput!
    $condition: ModelProductServiceConditionInput
  ) {
    deleteProductService(input: $input, condition: $condition) {
      id
      code
      type
      productServiceTypeID
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      limitless
      quantity
      averagePrice
      acceptableLimit
      dontSaleOnScarcity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      configuration
      favorite
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      active
      public
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      psTypes {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createProductServicePSType = /* GraphQL */ `
  mutation CreateProductServicePSType(
    $input: CreateProductServicePSTypeInput!
    $condition: ModelProductServicePSTypeConditionInput
  ) {
    createProductServicePSType(input: $input, condition: $condition) {
      id
      productServiceID
      psTypeID
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      pstype {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateProductServicePSType = /* GraphQL */ `
  mutation UpdateProductServicePSType(
    $input: UpdateProductServicePSTypeInput!
    $condition: ModelProductServicePSTypeConditionInput
  ) {
    updateProductServicePSType(input: $input, condition: $condition) {
      id
      productServiceID
      psTypeID
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      pstype {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteProductServicePSType = /* GraphQL */ `
  mutation DeleteProductServicePSType(
    $input: DeleteProductServicePSTypeInput!
    $condition: ModelProductServicePSTypeConditionInput
  ) {
    deleteProductServicePSType(input: $input, condition: $condition) {
      id
      productServiceID
      psTypeID
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      pstype {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createPSType = /* GraphQL */ `
  mutation CreatePSType(
    $input: CreatePSTypeInput!
    $condition: ModelPSTypeConditionInput
  ) {
    createPSType(input: $input, condition: $condition) {
      id
      code
      name
      description
      image {
        bucket
        region
        key
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePSType = /* GraphQL */ `
  mutation UpdatePSType(
    $input: UpdatePSTypeInput!
    $condition: ModelPSTypeConditionInput
  ) {
    updatePSType(input: $input, condition: $condition) {
      id
      code
      name
      description
      image {
        bucket
        region
        key
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePSType = /* GraphQL */ `
  mutation DeletePSType(
    $input: DeletePSTypeInput!
    $condition: ModelPSTypeConditionInput
  ) {
    deletePSType(input: $input, condition: $condition) {
      id
      code
      name
      description
      image {
        bucket
        region
        key
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createWPTransfer = /* GraphQL */ `
  mutation CreateWPTransfer(
    $input: CreateWPTransferInput!
    $condition: ModelWPTransferConditionInput
  ) {
    createWPTransfer(input: $input, condition: $condition) {
      id
      warehouseID
      posID
      transferType
      date
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateWPTransfer = /* GraphQL */ `
  mutation UpdateWPTransfer(
    $input: UpdateWPTransferInput!
    $condition: ModelWPTransferConditionInput
  ) {
    updateWPTransfer(input: $input, condition: $condition) {
      id
      warehouseID
      posID
      transferType
      date
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteWPTransfer = /* GraphQL */ `
  mutation DeleteWPTransfer(
    $input: DeleteWPTransferInput!
    $condition: ModelWPTransferConditionInput
  ) {
    deleteWPTransfer(input: $input, condition: $condition) {
      id
      warehouseID
      posID
      transferType
      date
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createWarehouseProductService = /* GraphQL */ `
  mutation CreateWarehouseProductService(
    $input: CreateWarehouseProductServiceInput!
    $condition: ModelWarehouseProductServiceConditionInput
  ) {
    createWarehouseProductService(input: $input, condition: $condition) {
      id
      warehouseID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateWarehouseProductService = /* GraphQL */ `
  mutation UpdateWarehouseProductService(
    $input: UpdateWarehouseProductServiceInput!
    $condition: ModelWarehouseProductServiceConditionInput
  ) {
    updateWarehouseProductService(input: $input, condition: $condition) {
      id
      warehouseID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteWarehouseProductService = /* GraphQL */ `
  mutation DeleteWarehouseProductService(
    $input: DeleteWarehouseProductServiceInput!
    $condition: ModelWarehouseProductServiceConditionInput
  ) {
    deleteWarehouseProductService(input: $input, condition: $condition) {
      id
      warehouseID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createWPTransferProductService = /* GraphQL */ `
  mutation CreateWPTransferProductService(
    $input: CreateWPTransferProductServiceInput!
    $condition: ModelWPTransferProductServiceConditionInput
  ) {
    createWPTransferProductService(input: $input, condition: $condition) {
      id
      wpTransferID
      productServiceID
      quantity
      price
      reason
      variations
      remarks
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      wpTransfer {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateWPTransferProductService = /* GraphQL */ `
  mutation UpdateWPTransferProductService(
    $input: UpdateWPTransferProductServiceInput!
    $condition: ModelWPTransferProductServiceConditionInput
  ) {
    updateWPTransferProductService(input: $input, condition: $condition) {
      id
      wpTransferID
      productServiceID
      quantity
      price
      reason
      variations
      remarks
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      wpTransfer {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteWPTransferProductService = /* GraphQL */ `
  mutation DeleteWPTransferProductService(
    $input: DeleteWPTransferProductServiceInput!
    $condition: ModelWPTransferProductServiceConditionInput
  ) {
    deleteWPTransferProductService(input: $input, condition: $condition) {
      id
      wpTransferID
      productServiceID
      quantity
      price
      reason
      variations
      remarks
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      wpTransfer {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createPOSProductService = /* GraphQL */ `
  mutation CreatePOSProductService(
    $input: CreatePOSProductServiceInput!
    $condition: ModelPOSProductServiceConditionInput
  ) {
    createPOSProductService(input: $input, condition: $condition) {
      id
      posID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePOSProductService = /* GraphQL */ `
  mutation UpdatePOSProductService(
    $input: UpdatePOSProductServiceInput!
    $condition: ModelPOSProductServiceConditionInput
  ) {
    updatePOSProductService(input: $input, condition: $condition) {
      id
      posID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePOSProductService = /* GraphQL */ `
  mutation DeletePOSProductService(
    $input: DeletePOSProductServiceInput!
    $condition: ModelPOSProductServiceConditionInput
  ) {
    deletePOSProductService(input: $input, condition: $condition) {
      id
      posID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createPublicProductService = /* GraphQL */ `
  mutation CreatePublicProductService(
    $input: CreatePublicProductServiceInput!
    $condition: ModelPublicProductServiceConditionInput
  ) {
    createPublicProductService(input: $input, condition: $condition) {
      id
      name
      productServiceTypeID
      url
      description
      lemma
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      prices {
        id
        name
        description
        value
        conditions
        __typename
      }
      defaultPrice
      taxed
      vatPercentage
      vat
      active
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePublicProductService = /* GraphQL */ `
  mutation UpdatePublicProductService(
    $input: UpdatePublicProductServiceInput!
    $condition: ModelPublicProductServiceConditionInput
  ) {
    updatePublicProductService(input: $input, condition: $condition) {
      id
      name
      productServiceTypeID
      url
      description
      lemma
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      prices {
        id
        name
        description
        value
        conditions
        __typename
      }
      defaultPrice
      taxed
      vatPercentage
      vat
      active
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePublicProductService = /* GraphQL */ `
  mutation DeletePublicProductService(
    $input: DeletePublicProductServiceInput!
    $condition: ModelPublicProductServiceConditionInput
  ) {
    deletePublicProductService(input: $input, condition: $condition) {
      id
      name
      productServiceTypeID
      url
      description
      lemma
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      prices {
        id
        name
        description
        value
        conditions
        __typename
      }
      defaultPrice
      taxed
      vatPercentage
      vat
      active
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSupplierProductService = /* GraphQL */ `
  mutation CreateSupplierProductService(
    $input: CreateSupplierProductServiceInput!
    $condition: ModelSupplierProductServiceConditionInput
  ) {
    createSupplierProductService(input: $input, condition: $condition) {
      id
      code
      equivalentDocumentScheme {
        name
        id
        agencyID
        code
        __typename
      }
      type
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      quantity
      acceptableLimit
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      brandName
      modelName
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSupplierProductService = /* GraphQL */ `
  mutation UpdateSupplierProductService(
    $input: UpdateSupplierProductServiceInput!
    $condition: ModelSupplierProductServiceConditionInput
  ) {
    updateSupplierProductService(input: $input, condition: $condition) {
      id
      code
      equivalentDocumentScheme {
        name
        id
        agencyID
        code
        __typename
      }
      type
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      quantity
      acceptableLimit
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      brandName
      modelName
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSupplierProductService = /* GraphQL */ `
  mutation DeleteSupplierProductService(
    $input: DeleteSupplierProductServiceInput!
    $condition: ModelSupplierProductServiceConditionInput
  ) {
    deleteSupplierProductService(input: $input, condition: $condition) {
      id
      code
      equivalentDocumentScheme {
        name
        id
        agencyID
        code
        __typename
      }
      type
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      quantity
      acceptableLimit
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      brandName
      modelName
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyKeyPartnerProductService = /* GraphQL */ `
  mutation CreateCompanyKeyPartnerProductService(
    $input: CreateCompanyKeyPartnerProductServiceInput!
    $condition: ModelCompanyKeyPartnerProductServiceConditionInput
  ) {
    createCompanyKeyPartnerProductService(
      input: $input
      condition: $condition
    ) {
      id
      companyID
      keyPartnerProductServiceID
      data
      documents
      acquired
      rating
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      keyPartnerproductService {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyKeyPartnerProductService = /* GraphQL */ `
  mutation UpdateCompanyKeyPartnerProductService(
    $input: UpdateCompanyKeyPartnerProductServiceInput!
    $condition: ModelCompanyKeyPartnerProductServiceConditionInput
  ) {
    updateCompanyKeyPartnerProductService(
      input: $input
      condition: $condition
    ) {
      id
      companyID
      keyPartnerProductServiceID
      data
      documents
      acquired
      rating
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      keyPartnerproductService {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyKeyPartnerProductService = /* GraphQL */ `
  mutation DeleteCompanyKeyPartnerProductService(
    $input: DeleteCompanyKeyPartnerProductServiceInput!
    $condition: ModelCompanyKeyPartnerProductServiceConditionInput
  ) {
    deleteCompanyKeyPartnerProductService(
      input: $input
      condition: $condition
    ) {
      id
      companyID
      keyPartnerProductServiceID
      data
      documents
      acquired
      rating
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      keyPartnerproductService {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      timestamp
      topicID
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      timestamp
      topicID
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      timestamp
      topicID
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPostComment = /* GraphQL */ `
  mutation CreatePostComment(
    $input: CreatePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    createPostComment(input: $input, condition: $condition) {
      id
      timestamp
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      post {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePostComment = /* GraphQL */ `
  mutation UpdatePostComment(
    $input: UpdatePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    updatePostComment(input: $input, condition: $condition) {
      id
      timestamp
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      post {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePostComment = /* GraphQL */ `
  mutation DeletePostComment(
    $input: DeletePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    deletePostComment(input: $input, condition: $condition) {
      id
      timestamp
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      post {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      cashReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      cashReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      cashReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      dischargeReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      dischargeReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      dischargeReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      nationalID
      name
      otherNames
      lastname
      otherLastnames
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      invoices {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      patientType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractingAndPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      benefitsPlan {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      nationalID
      name
      otherNames
      lastname
      otherLastnames
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      invoices {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      patientType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractingAndPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      benefitsPlan {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      nationalID
      name
      otherNames
      lastname
      otherLastnames
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      invoices {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      patientType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractingAndPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      benefitsPlan {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createIncome = /* GraphQL */ `
  mutation CreateIncome(
    $input: CreateIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    createIncome(input: $input, condition: $condition) {
      id
      concept
      invoiceID
      posDocumentID
      transactionID
      senderType
      senderID
      senderName
      manual
      date
      time
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateIncome = /* GraphQL */ `
  mutation UpdateIncome(
    $input: UpdateIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    updateIncome(input: $input, condition: $condition) {
      id
      concept
      invoiceID
      posDocumentID
      transactionID
      senderType
      senderID
      senderName
      manual
      date
      time
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteIncome = /* GraphQL */ `
  mutation DeleteIncome(
    $input: DeleteIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    deleteIncome(input: $input, condition: $condition) {
      id
      concept
      invoiceID
      posDocumentID
      transactionID
      senderType
      senderID
      senderName
      manual
      date
      time
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createIncomeSubAccountCategory = /* GraphQL */ `
  mutation CreateIncomeSubAccountCategory(
    $input: CreateIncomeSubAccountCategoryInput!
    $condition: ModelIncomeSubAccountCategoryConditionInput
  ) {
    createIncomeSubAccountCategory(input: $input, condition: $condition) {
      id
      incomeID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateIncomeSubAccountCategory = /* GraphQL */ `
  mutation UpdateIncomeSubAccountCategory(
    $input: UpdateIncomeSubAccountCategoryInput!
    $condition: ModelIncomeSubAccountCategoryConditionInput
  ) {
    updateIncomeSubAccountCategory(input: $input, condition: $condition) {
      id
      incomeID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteIncomeSubAccountCategory = /* GraphQL */ `
  mutation DeleteIncomeSubAccountCategory(
    $input: DeleteIncomeSubAccountCategoryInput!
    $condition: ModelIncomeSubAccountCategoryConditionInput
  ) {
    deleteIncomeSubAccountCategory(input: $input, condition: $condition) {
      id
      incomeID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createIncomeMySubAccountCategory = /* GraphQL */ `
  mutation CreateIncomeMySubAccountCategory(
    $input: CreateIncomeMySubAccountCategoryInput!
    $condition: ModelIncomeMySubAccountCategoryConditionInput
  ) {
    createIncomeMySubAccountCategory(input: $input, condition: $condition) {
      id
      incomeID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateIncomeMySubAccountCategory = /* GraphQL */ `
  mutation UpdateIncomeMySubAccountCategory(
    $input: UpdateIncomeMySubAccountCategoryInput!
    $condition: ModelIncomeMySubAccountCategoryConditionInput
  ) {
    updateIncomeMySubAccountCategory(input: $input, condition: $condition) {
      id
      incomeID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteIncomeMySubAccountCategory = /* GraphQL */ `
  mutation DeleteIncomeMySubAccountCategory(
    $input: DeleteIncomeMySubAccountCategoryInput!
    $condition: ModelIncomeMySubAccountCategoryConditionInput
  ) {
    deleteIncomeMySubAccountCategory(input: $input, condition: $condition) {
      id
      incomeID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createIncomeAuxiliarCategory = /* GraphQL */ `
  mutation CreateIncomeAuxiliarCategory(
    $input: CreateIncomeAuxiliarCategoryInput!
    $condition: ModelIncomeAuxiliarCategoryConditionInput
  ) {
    createIncomeAuxiliarCategory(input: $input, condition: $condition) {
      id
      incomeID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateIncomeAuxiliarCategory = /* GraphQL */ `
  mutation UpdateIncomeAuxiliarCategory(
    $input: UpdateIncomeAuxiliarCategoryInput!
    $condition: ModelIncomeAuxiliarCategoryConditionInput
  ) {
    updateIncomeAuxiliarCategory(input: $input, condition: $condition) {
      id
      incomeID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteIncomeAuxiliarCategory = /* GraphQL */ `
  mutation DeleteIncomeAuxiliarCategory(
    $input: DeleteIncomeAuxiliarCategoryInput!
    $condition: ModelIncomeAuxiliarCategoryConditionInput
  ) {
    deleteIncomeAuxiliarCategory(input: $input, condition: $condition) {
      id
      incomeID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createQuotation = /* GraphQL */ `
  mutation CreateQuotation(
    $input: CreateQuotationInput!
    $condition: ModelQuotationConditionInput
  ) {
    createQuotation(input: $input, condition: $condition) {
      id
      quotationID
      client
      clientID
      productsServices {
        id
        name
        quantity
        taxes
        total
        __typename
      }
      subtotal
      taxes
      total
      date
      expirationDays
      status
      files {
        bucket
        region
        key
        __typename
      }
      videos {
        url
        type
        __typename
      }
      url
      password
      readers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateQuotation = /* GraphQL */ `
  mutation UpdateQuotation(
    $input: UpdateQuotationInput!
    $condition: ModelQuotationConditionInput
  ) {
    updateQuotation(input: $input, condition: $condition) {
      id
      quotationID
      client
      clientID
      productsServices {
        id
        name
        quantity
        taxes
        total
        __typename
      }
      subtotal
      taxes
      total
      date
      expirationDays
      status
      files {
        bucket
        region
        key
        __typename
      }
      videos {
        url
        type
        __typename
      }
      url
      password
      readers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuotation = /* GraphQL */ `
  mutation DeleteQuotation(
    $input: DeleteQuotationInput!
    $condition: ModelQuotationConditionInput
  ) {
    deleteQuotation(input: $input, condition: $condition) {
      id
      quotationID
      client
      clientID
      productsServices {
        id
        name
        quantity
        taxes
        total
        __typename
      }
      subtotal
      taxes
      total
      date
      expirationDays
      status
      files {
        bucket
        region
        key
        __typename
      }
      videos {
        url
        type
        __typename
      }
      url
      password
      readers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      originalData
      terminalCashierID
      resolutionPosition
      invoiceNumber
      seller
      salesman
      salesmanType
      comtipID
      date
      time
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      totalDiscount
      discountPercentage
      includeConditionalDiscount
      total
      paymentLink
      cufe
      xml
      splitPayments
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      copay
      moderatingFee
      recoveryFee
      voluntaryHealthPlansSharedPayments
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      extraData
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      selectedQuotation
      scheduled
      schedule {
        sendOn
        sendOnMultipleDates
        sendUntil
        programmedTimes
        executedTimes
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      healthReferencedDocumentType {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      healthOperationType {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      originalData
      terminalCashierID
      resolutionPosition
      invoiceNumber
      seller
      salesman
      salesmanType
      comtipID
      date
      time
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      totalDiscount
      discountPercentage
      includeConditionalDiscount
      total
      paymentLink
      cufe
      xml
      splitPayments
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      copay
      moderatingFee
      recoveryFee
      voluntaryHealthPlansSharedPayments
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      extraData
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      selectedQuotation
      scheduled
      schedule {
        sendOn
        sendOnMultipleDates
        sendUntil
        programmedTimes
        executedTimes
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      healthReferencedDocumentType {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      healthOperationType {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      originalData
      terminalCashierID
      resolutionPosition
      invoiceNumber
      seller
      salesman
      salesmanType
      comtipID
      date
      time
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      totalDiscount
      discountPercentage
      includeConditionalDiscount
      total
      paymentLink
      cufe
      xml
      splitPayments
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      copay
      moderatingFee
      recoveryFee
      voluntaryHealthPlansSharedPayments
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      extraData
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      selectedQuotation
      scheduled
      schedule {
        sendOn
        sendOnMultipleDates
        sendUntil
        programmedTimes
        executedTimes
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      healthReferencedDocumentType {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      healthOperationType {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createInvoiceProductService = /* GraphQL */ `
  mutation CreateInvoiceProductService(
    $input: CreateInvoiceProductServiceInput!
    $condition: ModelInvoiceProductServiceConditionInput
  ) {
    createInvoiceProductService(input: $input, condition: $condition) {
      id
      invoiceID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      expirationDate
      batch
      configuration
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateInvoiceProductService = /* GraphQL */ `
  mutation UpdateInvoiceProductService(
    $input: UpdateInvoiceProductServiceInput!
    $condition: ModelInvoiceProductServiceConditionInput
  ) {
    updateInvoiceProductService(input: $input, condition: $condition) {
      id
      invoiceID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      expirationDate
      batch
      configuration
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteInvoiceProductService = /* GraphQL */ `
  mutation DeleteInvoiceProductService(
    $input: DeleteInvoiceProductServiceInput!
    $condition: ModelInvoiceProductServiceConditionInput
  ) {
    deleteInvoiceProductService(input: $input, condition: $condition) {
      id
      invoiceID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      expirationDate
      batch
      configuration
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createPurchaseOrder = /* GraphQL */ `
  mutation CreatePurchaseOrder(
    $input: CreatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    createPurchaseOrder(input: $input, condition: $condition) {
      id
      orderID
      date
      documents {
        bucket
        region
        key
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePurchaseOrder = /* GraphQL */ `
  mutation UpdatePurchaseOrder(
    $input: UpdatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    updatePurchaseOrder(input: $input, condition: $condition) {
      id
      orderID
      date
      documents {
        bucket
        region
        key
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePurchaseOrder = /* GraphQL */ `
  mutation DeletePurchaseOrder(
    $input: DeletePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    deletePurchaseOrder(input: $input, condition: $condition) {
      id
      orderID
      date
      documents {
        bucket
        region
        key
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCashReceipt = /* GraphQL */ `
  mutation CreateCashReceipt(
    $input: CreateCashReceiptInput!
    $condition: ModelCashReceiptConditionInput
  ) {
    createCashReceipt(input: $input, condition: $condition) {
      id
      cashReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCashReceipt = /* GraphQL */ `
  mutation UpdateCashReceipt(
    $input: UpdateCashReceiptInput!
    $condition: ModelCashReceiptConditionInput
  ) {
    updateCashReceipt(input: $input, condition: $condition) {
      id
      cashReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCashReceipt = /* GraphQL */ `
  mutation DeleteCashReceipt(
    $input: DeleteCashReceiptInput!
    $condition: ModelCashReceiptConditionInput
  ) {
    deleteCashReceipt(input: $input, condition: $condition) {
      id
      cashReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCashReceiptIncome = /* GraphQL */ `
  mutation CreateCashReceiptIncome(
    $input: CreateCashReceiptIncomeInput!
    $condition: ModelCashReceiptIncomeConditionInput
  ) {
    createCashReceiptIncome(input: $input, condition: $condition) {
      id
      cashReceiptID
      incomeID
      value
      date
      data
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipt {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateCashReceiptIncome = /* GraphQL */ `
  mutation UpdateCashReceiptIncome(
    $input: UpdateCashReceiptIncomeInput!
    $condition: ModelCashReceiptIncomeConditionInput
  ) {
    updateCashReceiptIncome(input: $input, condition: $condition) {
      id
      cashReceiptID
      incomeID
      value
      date
      data
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipt {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteCashReceiptIncome = /* GraphQL */ `
  mutation DeleteCashReceiptIncome(
    $input: DeleteCashReceiptIncomeInput!
    $condition: ModelCashReceiptIncomeConditionInput
  ) {
    deleteCashReceiptIncome(input: $input, condition: $condition) {
      id
      cashReceiptID
      incomeID
      value
      date
      data
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipt {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createTerminalCashier = /* GraphQL */ `
  mutation CreateTerminalCashier(
    $input: CreateTerminalCashierInput!
    $condition: ModelTerminalCashierConditionInput
  ) {
    createTerminalCashier(input: $input, condition: $condition) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashBeforeDetail
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      cashOutputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      observations
      readers
      editors
      createdAt
      updatedAt
      terminal {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashier {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      owner
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTerminalCashier = /* GraphQL */ `
  mutation UpdateTerminalCashier(
    $input: UpdateTerminalCashierInput!
    $condition: ModelTerminalCashierConditionInput
  ) {
    updateTerminalCashier(input: $input, condition: $condition) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashBeforeDetail
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      cashOutputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      observations
      readers
      editors
      createdAt
      updatedAt
      terminal {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashier {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      owner
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTerminalCashier = /* GraphQL */ `
  mutation DeleteTerminalCashier(
    $input: DeleteTerminalCashierInput!
    $condition: ModelTerminalCashierConditionInput
  ) {
    deleteTerminalCashier(input: $input, condition: $condition) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashBeforeDetail
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      cashOutputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      observations
      readers
      editors
      createdAt
      updatedAt
      terminal {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashier {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      owner
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPOSDocument = /* GraphQL */ `
  mutation CreatePOSDocument(
    $input: CreatePOSDocumentInput!
    $condition: ModelPOSDocumentConditionInput
  ) {
    createPOSDocument(input: $input, condition: $condition) {
      id
      type
      POSDocumentID
      code
      date
      total
      taxes
      data
      status
      salesman
      salesmanType
      comtipID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updatePOSDocument = /* GraphQL */ `
  mutation UpdatePOSDocument(
    $input: UpdatePOSDocumentInput!
    $condition: ModelPOSDocumentConditionInput
  ) {
    updatePOSDocument(input: $input, condition: $condition) {
      id
      type
      POSDocumentID
      code
      date
      total
      taxes
      data
      status
      salesman
      salesmanType
      comtipID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deletePOSDocument = /* GraphQL */ `
  mutation DeletePOSDocument(
    $input: DeletePOSDocumentInput!
    $condition: ModelPOSDocumentConditionInput
  ) {
    deletePOSDocument(input: $input, condition: $condition) {
      id
      type
      POSDocumentID
      code
      date
      total
      taxes
      data
      status
      salesman
      salesmanType
      comtipID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPOSDocumentProductService = /* GraphQL */ `
  mutation CreatePOSDocumentProductService(
    $input: CreatePOSDocumentProductServiceInput!
    $condition: ModelPOSDocumentProductServiceConditionInput
  ) {
    createPOSDocumentProductService(input: $input, condition: $condition) {
      id
      posDocumentID
      productServiceID
      quantity
      price
      total
      taxes
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      posDocument {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePOSDocumentProductService = /* GraphQL */ `
  mutation UpdatePOSDocumentProductService(
    $input: UpdatePOSDocumentProductServiceInput!
    $condition: ModelPOSDocumentProductServiceConditionInput
  ) {
    updatePOSDocumentProductService(input: $input, condition: $condition) {
      id
      posDocumentID
      productServiceID
      quantity
      price
      total
      taxes
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      posDocument {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePOSDocumentProductService = /* GraphQL */ `
  mutation DeletePOSDocumentProductService(
    $input: DeletePOSDocumentProductServiceInput!
    $condition: ModelPOSDocumentProductServiceConditionInput
  ) {
    deletePOSDocumentProductService(input: $input, condition: $condition) {
      id
      posDocumentID
      productServiceID
      quantity
      price
      total
      taxes
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      posDocument {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createExpense = /* GraphQL */ `
  mutation CreateExpense(
    $input: CreateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    createExpense(input: $input, condition: $condition) {
      id
      concept
      billID
      transactionID
      equivalentDocumentID
      comtipID
      receiverType
      receiverID
      receiverName
      manual
      date
      time
      transferID
      paid
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
      concept
      billID
      transactionID
      equivalentDocumentID
      comtipID
      receiverType
      receiverID
      receiverName
      manual
      date
      time
      transferID
      paid
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteExpense = /* GraphQL */ `
  mutation DeleteExpense(
    $input: DeleteExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    deleteExpense(input: $input, condition: $condition) {
      id
      concept
      billID
      transactionID
      equivalentDocumentID
      comtipID
      receiverType
      receiverID
      receiverName
      manual
      date
      time
      transferID
      paid
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createExpenseSubAccountCategory = /* GraphQL */ `
  mutation CreateExpenseSubAccountCategory(
    $input: CreateExpenseSubAccountCategoryInput!
    $condition: ModelExpenseSubAccountCategoryConditionInput
  ) {
    createExpenseSubAccountCategory(input: $input, condition: $condition) {
      id
      expenseID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateExpenseSubAccountCategory = /* GraphQL */ `
  mutation UpdateExpenseSubAccountCategory(
    $input: UpdateExpenseSubAccountCategoryInput!
    $condition: ModelExpenseSubAccountCategoryConditionInput
  ) {
    updateExpenseSubAccountCategory(input: $input, condition: $condition) {
      id
      expenseID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteExpenseSubAccountCategory = /* GraphQL */ `
  mutation DeleteExpenseSubAccountCategory(
    $input: DeleteExpenseSubAccountCategoryInput!
    $condition: ModelExpenseSubAccountCategoryConditionInput
  ) {
    deleteExpenseSubAccountCategory(input: $input, condition: $condition) {
      id
      expenseID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createExpenseMySubAccountCategory = /* GraphQL */ `
  mutation CreateExpenseMySubAccountCategory(
    $input: CreateExpenseMySubAccountCategoryInput!
    $condition: ModelExpenseMySubAccountCategoryConditionInput
  ) {
    createExpenseMySubAccountCategory(input: $input, condition: $condition) {
      id
      expenseID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateExpenseMySubAccountCategory = /* GraphQL */ `
  mutation UpdateExpenseMySubAccountCategory(
    $input: UpdateExpenseMySubAccountCategoryInput!
    $condition: ModelExpenseMySubAccountCategoryConditionInput
  ) {
    updateExpenseMySubAccountCategory(input: $input, condition: $condition) {
      id
      expenseID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteExpenseMySubAccountCategory = /* GraphQL */ `
  mutation DeleteExpenseMySubAccountCategory(
    $input: DeleteExpenseMySubAccountCategoryInput!
    $condition: ModelExpenseMySubAccountCategoryConditionInput
  ) {
    deleteExpenseMySubAccountCategory(input: $input, condition: $condition) {
      id
      expenseID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createExpenseAuxiliarCategory = /* GraphQL */ `
  mutation CreateExpenseAuxiliarCategory(
    $input: CreateExpenseAuxiliarCategoryInput!
    $condition: ModelExpenseAuxiliarCategoryConditionInput
  ) {
    createExpenseAuxiliarCategory(input: $input, condition: $condition) {
      id
      expenseID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateExpenseAuxiliarCategory = /* GraphQL */ `
  mutation UpdateExpenseAuxiliarCategory(
    $input: UpdateExpenseAuxiliarCategoryInput!
    $condition: ModelExpenseAuxiliarCategoryConditionInput
  ) {
    updateExpenseAuxiliarCategory(input: $input, condition: $condition) {
      id
      expenseID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteExpenseAuxiliarCategory = /* GraphQL */ `
  mutation DeleteExpenseAuxiliarCategory(
    $input: DeleteExpenseAuxiliarCategoryInput!
    $condition: ModelExpenseAuxiliarCategoryConditionInput
  ) {
    deleteExpenseAuxiliarCategory(input: $input, condition: $condition) {
      id
      expenseID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createTransfer = /* GraphQL */ `
  mutation CreateTransfer(
    $input: CreateTransferInput!
    $condition: ModelTransferConditionInput
  ) {
    createTransfer(input: $input, condition: $condition) {
      id
      concept
      expenseID
      receiverType
      receiverID
      receiverName
      date
      value
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateTransfer = /* GraphQL */ `
  mutation UpdateTransfer(
    $input: UpdateTransferInput!
    $condition: ModelTransferConditionInput
  ) {
    updateTransfer(input: $input, condition: $condition) {
      id
      concept
      expenseID
      receiverType
      receiverID
      receiverName
      date
      value
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteTransfer = /* GraphQL */ `
  mutation DeleteTransfer(
    $input: DeleteTransferInput!
    $condition: ModelTransferConditionInput
  ) {
    deleteTransfer(input: $input, condition: $condition) {
      id
      concept
      expenseID
      receiverType
      receiverID
      receiverName
      date
      value
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createBill = /* GraphQL */ `
  mutation CreateBill(
    $input: CreateBillInput!
    $condition: ModelBillConditionInput
  ) {
    createBill(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      billNumberDIAN
      supplierID
      supplierName
      supplierData
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cufe
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateBill = /* GraphQL */ `
  mutation UpdateBill(
    $input: UpdateBillInput!
    $condition: ModelBillConditionInput
  ) {
    updateBill(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      billNumberDIAN
      supplierID
      supplierName
      supplierData
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cufe
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteBill = /* GraphQL */ `
  mutation DeleteBill(
    $input: DeleteBillInput!
    $condition: ModelBillConditionInput
  ) {
    deleteBill(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      billNumberDIAN
      supplierID
      supplierName
      supplierData
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cufe
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createBillSupplierProductService = /* GraphQL */ `
  mutation CreateBillSupplierProductService(
    $input: CreateBillSupplierProductServiceInput!
    $condition: ModelBillSupplierProductServiceConditionInput
  ) {
    createBillSupplierProductService(input: $input, condition: $condition) {
      id
      billID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      bill {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateBillSupplierProductService = /* GraphQL */ `
  mutation UpdateBillSupplierProductService(
    $input: UpdateBillSupplierProductServiceInput!
    $condition: ModelBillSupplierProductServiceConditionInput
  ) {
    updateBillSupplierProductService(input: $input, condition: $condition) {
      id
      billID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      bill {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteBillSupplierProductService = /* GraphQL */ `
  mutation DeleteBillSupplierProductService(
    $input: DeleteBillSupplierProductServiceInput!
    $condition: ModelBillSupplierProductServiceConditionInput
  ) {
    deleteBillSupplierProductService(input: $input, condition: $condition) {
      id
      billID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      bill {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createEquivalentDocument = /* GraphQL */ `
  mutation CreateEquivalentDocument(
    $input: CreateEquivalentDocumentInput!
    $condition: ModelEquivalentDocumentConditionInput
  ) {
    createEquivalentDocument(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      resolutionPosition
      equivalentDocumentNumber
      equivalentDocumentNumberDIAN
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cuds
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      documents {
        bucket
        region
        key
        __typename
      }
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateEquivalentDocument = /* GraphQL */ `
  mutation UpdateEquivalentDocument(
    $input: UpdateEquivalentDocumentInput!
    $condition: ModelEquivalentDocumentConditionInput
  ) {
    updateEquivalentDocument(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      resolutionPosition
      equivalentDocumentNumber
      equivalentDocumentNumberDIAN
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cuds
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      documents {
        bucket
        region
        key
        __typename
      }
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteEquivalentDocument = /* GraphQL */ `
  mutation DeleteEquivalentDocument(
    $input: DeleteEquivalentDocumentInput!
    $condition: ModelEquivalentDocumentConditionInput
  ) {
    deleteEquivalentDocument(input: $input, condition: $condition) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      resolutionPosition
      equivalentDocumentNumber
      equivalentDocumentNumberDIAN
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cuds
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      documents {
        bucket
        region
        key
        __typename
      }
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createEquivalentDocumentSupplierProductService = /* GraphQL */ `
  mutation CreateEquivalentDocumentSupplierProductService(
    $input: CreateEquivalentDocumentSupplierProductServiceInput!
    $condition: ModelEquivalentDocumentSupplierProductServiceConditionInput
  ) {
    createEquivalentDocumentSupplierProductService(
      input: $input
      condition: $condition
    ) {
      id
      equivalentDocumentID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      startDate
      descriptionCode
      descriptionXML
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      equivalentDocument {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateEquivalentDocumentSupplierProductService = /* GraphQL */ `
  mutation UpdateEquivalentDocumentSupplierProductService(
    $input: UpdateEquivalentDocumentSupplierProductServiceInput!
    $condition: ModelEquivalentDocumentSupplierProductServiceConditionInput
  ) {
    updateEquivalentDocumentSupplierProductService(
      input: $input
      condition: $condition
    ) {
      id
      equivalentDocumentID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      startDate
      descriptionCode
      descriptionXML
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      equivalentDocument {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteEquivalentDocumentSupplierProductService = /* GraphQL */ `
  mutation DeleteEquivalentDocumentSupplierProductService(
    $input: DeleteEquivalentDocumentSupplierProductServiceInput!
    $condition: ModelEquivalentDocumentSupplierProductServiceConditionInput
  ) {
    deleteEquivalentDocumentSupplierProductService(
      input: $input
      condition: $condition
    ) {
      id
      equivalentDocumentID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      startDate
      descriptionCode
      descriptionXML
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      equivalentDocument {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createDischargeReceipt = /* GraphQL */ `
  mutation CreateDischargeReceipt(
    $input: CreateDischargeReceiptInput!
    $condition: ModelDischargeReceiptConditionInput
  ) {
    createDischargeReceipt(input: $input, condition: $condition) {
      id
      dischargeReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateDischargeReceipt = /* GraphQL */ `
  mutation UpdateDischargeReceipt(
    $input: UpdateDischargeReceiptInput!
    $condition: ModelDischargeReceiptConditionInput
  ) {
    updateDischargeReceipt(input: $input, condition: $condition) {
      id
      dischargeReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteDischargeReceipt = /* GraphQL */ `
  mutation DeleteDischargeReceipt(
    $input: DeleteDischargeReceiptInput!
    $condition: ModelDischargeReceiptConditionInput
  ) {
    deleteDischargeReceipt(input: $input, condition: $condition) {
      id
      dischargeReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createDischargeReceiptExpense = /* GraphQL */ `
  mutation CreateDischargeReceiptExpense(
    $input: CreateDischargeReceiptExpenseInput!
    $condition: ModelDischargeReceiptExpenseConditionInput
  ) {
    createDischargeReceiptExpense(input: $input, condition: $condition) {
      id
      dischargeReceiptID
      expenseID
      value
      date
      data
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      dischargeReceipt {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateDischargeReceiptExpense = /* GraphQL */ `
  mutation UpdateDischargeReceiptExpense(
    $input: UpdateDischargeReceiptExpenseInput!
    $condition: ModelDischargeReceiptExpenseConditionInput
  ) {
    updateDischargeReceiptExpense(input: $input, condition: $condition) {
      id
      dischargeReceiptID
      expenseID
      value
      date
      data
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      dischargeReceipt {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteDischargeReceiptExpense = /* GraphQL */ `
  mutation DeleteDischargeReceiptExpense(
    $input: DeleteDischargeReceiptExpenseInput!
    $condition: ModelDischargeReceiptExpenseConditionInput
  ) {
    deleteDischargeReceiptExpense(input: $input, condition: $condition) {
      id
      dischargeReceiptID
      expenseID
      value
      date
      data
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      dischargeReceipt {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createComTip = /* GraphQL */ `
  mutation CreateComTip(
    $input: CreateComTipInput!
    $condition: ModelComTipConditionInput
  ) {
    createComTip(input: $input, condition: $condition) {
      id
      percentage
      value
      data
      posDocumentID
      invoiceID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      salesmen {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateComTip = /* GraphQL */ `
  mutation UpdateComTip(
    $input: UpdateComTipInput!
    $condition: ModelComTipConditionInput
  ) {
    updateComTip(input: $input, condition: $condition) {
      id
      percentage
      value
      data
      posDocumentID
      invoiceID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      salesmen {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteComTip = /* GraphQL */ `
  mutation DeleteComTip(
    $input: DeleteComTipInput!
    $condition: ModelComTipConditionInput
  ) {
    deleteComTip(input: $input, condition: $condition) {
      id
      percentage
      value
      data
      posDocumentID
      invoiceID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      salesmen {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createSalesmanComTip = /* GraphQL */ `
  mutation CreateSalesmanComTip(
    $input: CreateSalesmanComTipInput!
    $condition: ModelSalesmanComTipConditionInput
  ) {
    createSalesmanComTip(input: $input, condition: $condition) {
      id
      salesmanID
      comtipID
      percentage
      value
      comtipType
      data
      readers
      editors
      createdAt
      updatedAt
      salesman {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      comtip {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSalesmanComTip = /* GraphQL */ `
  mutation UpdateSalesmanComTip(
    $input: UpdateSalesmanComTipInput!
    $condition: ModelSalesmanComTipConditionInput
  ) {
    updateSalesmanComTip(input: $input, condition: $condition) {
      id
      salesmanID
      comtipID
      percentage
      value
      comtipType
      data
      readers
      editors
      createdAt
      updatedAt
      salesman {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      comtip {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSalesmanComTip = /* GraphQL */ `
  mutation DeleteSalesmanComTip(
    $input: DeleteSalesmanComTipInput!
    $condition: ModelSalesmanComTipConditionInput
  ) {
    deleteSalesmanComTip(input: $input, condition: $condition) {
      id
      salesmanID
      comtipID
      percentage
      value
      comtipType
      data
      readers
      editors
      createdAt
      updatedAt
      salesman {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      comtip {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createIDType = /* GraphQL */ `
  mutation CreateIDType(
    $input: CreateIDTypeInput!
    $condition: ModelIDTypeConditionInput
  ) {
    createIDType(input: $input, condition: $condition) {
      id
      code
      name
      abbreviation
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIDType = /* GraphQL */ `
  mutation UpdateIDType(
    $input: UpdateIDTypeInput!
    $condition: ModelIDTypeConditionInput
  ) {
    updateIDType(input: $input, condition: $condition) {
      id
      code
      name
      abbreviation
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIDType = /* GraphQL */ `
  mutation DeleteIDType(
    $input: DeleteIDTypeInput!
    $condition: ModelIDTypeConditionInput
  ) {
    deleteIDType(input: $input, condition: $condition) {
      id
      code
      name
      abbreviation
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFiscalResponsability = /* GraphQL */ `
  mutation CreateFiscalResponsability(
    $input: CreateFiscalResponsabilityInput!
    $condition: ModelFiscalResponsabilityConditionInput
  ) {
    createFiscalResponsability(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      companiesConfigurations {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateFiscalResponsability = /* GraphQL */ `
  mutation UpdateFiscalResponsability(
    $input: UpdateFiscalResponsabilityInput!
    $condition: ModelFiscalResponsabilityConditionInput
  ) {
    updateFiscalResponsability(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      companiesConfigurations {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteFiscalResponsability = /* GraphQL */ `
  mutation DeleteFiscalResponsability(
    $input: DeleteFiscalResponsabilityInput!
    $condition: ModelFiscalResponsabilityConditionInput
  ) {
    deleteFiscalResponsability(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      companiesConfigurations {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createRegimenType = /* GraphQL */ `
  mutation CreateRegimenType(
    $input: CreateRegimenTypeInput!
    $condition: ModelRegimenTypeConditionInput
  ) {
    createRegimenType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegimenType = /* GraphQL */ `
  mutation UpdateRegimenType(
    $input: UpdateRegimenTypeInput!
    $condition: ModelRegimenTypeConditionInput
  ) {
    updateRegimenType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegimenType = /* GraphQL */ `
  mutation DeleteRegimenType(
    $input: DeleteRegimenTypeInput!
    $condition: ModelRegimenTypeConditionInput
  ) {
    deleteRegimenType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBank = /* GraphQL */ `
  mutation CreateBank(
    $input: CreateBankInput!
    $condition: ModelBankConditionInput
  ) {
    createBank(input: $input, condition: $condition) {
      id
      name
      description
      extraData
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      finppiAccounts {
        nextToken
        __typename
      }
      thirdPartyAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateBank = /* GraphQL */ `
  mutation UpdateBank(
    $input: UpdateBankInput!
    $condition: ModelBankConditionInput
  ) {
    updateBank(input: $input, condition: $condition) {
      id
      name
      description
      extraData
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      finppiAccounts {
        nextToken
        __typename
      }
      thirdPartyAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteBank = /* GraphQL */ `
  mutation DeleteBank(
    $input: DeleteBankInput!
    $condition: ModelBankConditionInput
  ) {
    deleteBank(input: $input, condition: $condition) {
      id
      name
      description
      extraData
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      finppiAccounts {
        nextToken
        __typename
      }
      thirdPartyAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createAccountType = /* GraphQL */ `
  mutation CreateAccountType(
    $input: CreateAccountTypeInput!
    $condition: ModelAccountTypeConditionInput
  ) {
    createAccountType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccountType = /* GraphQL */ `
  mutation UpdateAccountType(
    $input: UpdateAccountTypeInput!
    $condition: ModelAccountTypeConditionInput
  ) {
    updateAccountType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccountType = /* GraphQL */ `
  mutation DeleteAccountType(
    $input: DeleteAccountTypeInput!
    $condition: ModelAccountTypeConditionInput
  ) {
    deleteAccountType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductServiceType = /* GraphQL */ `
  mutation CreateProductServiceType(
    $input: CreateProductServiceTypeInput!
    $condition: ModelProductServiceTypeConditionInput
  ) {
    createProductServiceType(input: $input, condition: $condition) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductServiceType = /* GraphQL */ `
  mutation UpdateProductServiceType(
    $input: UpdateProductServiceTypeInput!
    $condition: ModelProductServiceTypeConditionInput
  ) {
    updateProductServiceType(input: $input, condition: $condition) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductServiceType = /* GraphQL */ `
  mutation DeleteProductServiceType(
    $input: DeleteProductServiceTypeInput!
    $condition: ModelProductServiceTypeConditionInput
  ) {
    deleteProductServiceType(input: $input, condition: $condition) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEInvoiceProvider = /* GraphQL */ `
  mutation CreateEInvoiceProvider(
    $input: CreateEInvoiceProviderInput!
    $condition: ModelEInvoiceProviderConditionInput
  ) {
    createEInvoiceProvider(input: $input, condition: $condition) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEInvoiceProvider = /* GraphQL */ `
  mutation UpdateEInvoiceProvider(
    $input: UpdateEInvoiceProviderInput!
    $condition: ModelEInvoiceProviderConditionInput
  ) {
    updateEInvoiceProvider(input: $input, condition: $condition) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEInvoiceProvider = /* GraphQL */ `
  mutation DeleteEInvoiceProvider(
    $input: DeleteEInvoiceProviderInput!
    $condition: ModelEInvoiceProviderConditionInput
  ) {
    deleteEInvoiceProvider(input: $input, condition: $condition) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEPayrollProvider = /* GraphQL */ `
  mutation CreateEPayrollProvider(
    $input: CreateEPayrollProviderInput!
    $condition: ModelEPayrollProviderConditionInput
  ) {
    createEPayrollProvider(input: $input, condition: $condition) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEPayrollProvider = /* GraphQL */ `
  mutation UpdateEPayrollProvider(
    $input: UpdateEPayrollProviderInput!
    $condition: ModelEPayrollProviderConditionInput
  ) {
    updateEPayrollProvider(input: $input, condition: $condition) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEPayrollProvider = /* GraphQL */ `
  mutation DeleteEPayrollProvider(
    $input: DeleteEPayrollProviderInput!
    $condition: ModelEPayrollProviderConditionInput
  ) {
    deleteEPayrollProvider(input: $input, condition: $condition) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIndustry = /* GraphQL */ `
  mutation CreateIndustry(
    $input: CreateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    createIndustry(input: $input, condition: $condition) {
      id
      CIIU
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateIndustry = /* GraphQL */ `
  mutation UpdateIndustry(
    $input: UpdateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    updateIndustry(input: $input, condition: $condition) {
      id
      CIIU
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteIndustry = /* GraphQL */ `
  mutation DeleteIndustry(
    $input: DeleteIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    deleteIndustry(input: $input, condition: $condition) {
      id
      CIIU
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyDocumentType = /* GraphQL */ `
  mutation CreateCompanyDocumentType(
    $input: CreateCompanyDocumentTypeInput!
    $condition: ModelCompanyDocumentTypeConditionInput
  ) {
    createCompanyDocumentType(input: $input, condition: $condition) {
      id
      type
      allowedFileTypes
      maxSize
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyDocumentType = /* GraphQL */ `
  mutation UpdateCompanyDocumentType(
    $input: UpdateCompanyDocumentTypeInput!
    $condition: ModelCompanyDocumentTypeConditionInput
  ) {
    updateCompanyDocumentType(input: $input, condition: $condition) {
      id
      type
      allowedFileTypes
      maxSize
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyDocumentType = /* GraphQL */ `
  mutation DeleteCompanyDocumentType(
    $input: DeleteCompanyDocumentTypeInput!
    $condition: ModelCompanyDocumentTypeConditionInput
  ) {
    deleteCompanyDocumentType(input: $input, condition: $condition) {
      id
      type
      allowedFileTypes
      maxSize
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBadge = /* GraphQL */ `
  mutation CreateBadge(
    $input: CreateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    createBadge(input: $input, condition: $condition) {
      id
      code
      name
      image {
        bucket
        region
        key
        __typename
      }
      text
      validFor
      requiredPoints
      callback
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateBadge = /* GraphQL */ `
  mutation UpdateBadge(
    $input: UpdateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    updateBadge(input: $input, condition: $condition) {
      id
      code
      name
      image {
        bucket
        region
        key
        __typename
      }
      text
      validFor
      requiredPoints
      callback
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteBadge = /* GraphQL */ `
  mutation DeleteBadge(
    $input: DeleteBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    deleteBadge(input: $input, condition: $condition) {
      id
      code
      name
      image {
        bucket
        region
        key
        __typename
      }
      text
      validFor
      requiredPoints
      callback
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod(
    $input: CreatePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    createPaymentMethod(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod(
    $input: UpdatePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    updatePaymentMethod(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePaymentMethod = /* GraphQL */ `
  mutation DeletePaymentMethod(
    $input: DeletePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    deletePaymentMethod(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDefaultCompanyRole = /* GraphQL */ `
  mutation CreateDefaultCompanyRole(
    $input: CreateDefaultCompanyRoleInput!
    $condition: ModelDefaultCompanyRoleConditionInput
  ) {
    createDefaultCompanyRole(input: $input, condition: $condition) {
      id
      name
      description
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDefaultCompanyRole = /* GraphQL */ `
  mutation UpdateDefaultCompanyRole(
    $input: UpdateDefaultCompanyRoleInput!
    $condition: ModelDefaultCompanyRoleConditionInput
  ) {
    updateDefaultCompanyRole(input: $input, condition: $condition) {
      id
      name
      description
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDefaultCompanyRole = /* GraphQL */ `
  mutation DeleteDefaultCompanyRole(
    $input: DeleteDefaultCompanyRoleInput!
    $condition: ModelDefaultCompanyRoleConditionInput
  ) {
    deleteDefaultCompanyRole(input: $input, condition: $condition) {
      id
      name
      description
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      name
      description
      data
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      name
      description
      data
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      name
      description
      data
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createKeyPartnerProductService = /* GraphQL */ `
  mutation CreateKeyPartnerProductService(
    $input: CreateKeyPartnerProductServiceInput!
    $condition: ModelKeyPartnerProductServiceConditionInput
  ) {
    createKeyPartnerProductService(input: $input, condition: $condition) {
      id
      code
      name
      description
      notes
      productServiceTypeID
      contactEmail
      url
      clicks
      clickedBy
      acquired
      rating
      callback
      dataKeys {
        name
        mandatory
        possibleValues
        defaultValue
        description
        comments
        __typename
      }
      requirements {
        type
        value
        condition
        fullText
        __typename
      }
      requiredDocuments {
        type
        name
        mandatory
        comment
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      owner
      keyPartner {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateKeyPartnerProductService = /* GraphQL */ `
  mutation UpdateKeyPartnerProductService(
    $input: UpdateKeyPartnerProductServiceInput!
    $condition: ModelKeyPartnerProductServiceConditionInput
  ) {
    updateKeyPartnerProductService(input: $input, condition: $condition) {
      id
      code
      name
      description
      notes
      productServiceTypeID
      contactEmail
      url
      clicks
      clickedBy
      acquired
      rating
      callback
      dataKeys {
        name
        mandatory
        possibleValues
        defaultValue
        description
        comments
        __typename
      }
      requirements {
        type
        value
        condition
        fullText
        __typename
      }
      requiredDocuments {
        type
        name
        mandatory
        comment
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      owner
      keyPartner {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteKeyPartnerProductService = /* GraphQL */ `
  mutation DeleteKeyPartnerProductService(
    $input: DeleteKeyPartnerProductServiceInput!
    $condition: ModelKeyPartnerProductServiceConditionInput
  ) {
    deleteKeyPartnerProductService(input: $input, condition: $condition) {
      id
      code
      name
      description
      notes
      productServiceTypeID
      contactEmail
      url
      clicks
      clickedBy
      acquired
      rating
      callback
      dataKeys {
        name
        mandatory
        possibleValues
        defaultValue
        description
        comments
        __typename
      }
      requirements {
        type
        value
        condition
        fullText
        __typename
      }
      requiredDocuments {
        type
        name
        mandatory
        comment
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      owner
      keyPartner {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createNoteConcept = /* GraphQL */ `
  mutation CreateNoteConcept(
    $input: CreateNoteConceptInput!
    $condition: ModelNoteConceptConditionInput
  ) {
    createNoteConcept(input: $input, condition: $condition) {
      id
      code
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNoteConcept = /* GraphQL */ `
  mutation UpdateNoteConcept(
    $input: UpdateNoteConceptInput!
    $condition: ModelNoteConceptConditionInput
  ) {
    updateNoteConcept(input: $input, condition: $condition) {
      id
      code
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNoteConcept = /* GraphQL */ `
  mutation DeleteNoteConcept(
    $input: DeleteNoteConceptInput!
    $condition: ModelNoteConceptConditionInput
  ) {
    deleteNoteConcept(input: $input, condition: $condition) {
      id
      code
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvoiceOperationType = /* GraphQL */ `
  mutation CreateInvoiceOperationType(
    $input: CreateInvoiceOperationTypeInput!
    $condition: ModelInvoiceOperationTypeConditionInput
  ) {
    createInvoiceOperationType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvoiceOperationType = /* GraphQL */ `
  mutation UpdateInvoiceOperationType(
    $input: UpdateInvoiceOperationTypeInput!
    $condition: ModelInvoiceOperationTypeConditionInput
  ) {
    updateInvoiceOperationType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvoiceOperationType = /* GraphQL */ `
  mutation DeleteInvoiceOperationType(
    $input: DeleteInvoiceOperationTypeInput!
    $condition: ModelInvoiceOperationTypeConditionInput
  ) {
    deleteInvoiceOperationType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTariffItem = /* GraphQL */ `
  mutation CreateTariffItem(
    $input: CreateTariffItemInput!
    $condition: ModelTariffItemConditionInput
  ) {
    createTariffItem(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTariffItem = /* GraphQL */ `
  mutation UpdateTariffItem(
    $input: UpdateTariffItemInput!
    $condition: ModelTariffItemConditionInput
  ) {
    updateTariffItem(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTariffItem = /* GraphQL */ `
  mutation DeleteTariffItem(
    $input: DeleteTariffItemInput!
    $condition: ModelTariffItemConditionInput
  ) {
    deleteTariffItem(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUNSPSC = /* GraphQL */ `
  mutation CreateUNSPSC(
    $input: CreateUNSPSCInput!
    $condition: ModelUNSPSCConditionInput
  ) {
    createUNSPSC(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUNSPSC = /* GraphQL */ `
  mutation UpdateUNSPSC(
    $input: UpdateUNSPSCInput!
    $condition: ModelUNSPSCConditionInput
  ) {
    updateUNSPSC(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUNSPSC = /* GraphQL */ `
  mutation DeleteUNSPSC(
    $input: DeleteUNSPSCInput!
    $condition: ModelUNSPSCConditionInput
  ) {
    deleteUNSPSC(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      planID
      name
      description
      data
      createdAt
      updatedAt
      features {
        nextToken
        __typename
      }
      terms {
        nextToken
        __typename
      }
      price {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      planID
      name
      description
      data
      createdAt
      updatedAt
      features {
        nextToken
        __typename
      }
      terms {
        nextToken
        __typename
      }
      price {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      planID
      name
      description
      data
      createdAt
      updatedAt
      features {
        nextToken
        __typename
      }
      terms {
        nextToken
        __typename
      }
      price {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createPlanFeature = /* GraphQL */ `
  mutation CreatePlanFeature(
    $input: CreatePlanFeatureInput!
    $condition: ModelPlanFeatureConditionInput
  ) {
    createPlanFeature(input: $input, condition: $condition) {
      id
      planID
      featureID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      feature {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updatePlanFeature = /* GraphQL */ `
  mutation UpdatePlanFeature(
    $input: UpdatePlanFeatureInput!
    $condition: ModelPlanFeatureConditionInput
  ) {
    updatePlanFeature(input: $input, condition: $condition) {
      id
      planID
      featureID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      feature {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deletePlanFeature = /* GraphQL */ `
  mutation DeletePlanFeature(
    $input: DeletePlanFeatureInput!
    $condition: ModelPlanFeatureConditionInput
  ) {
    deletePlanFeature(input: $input, condition: $condition) {
      id
      planID
      featureID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      feature {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createFeature = /* GraphQL */ `
  mutation CreateFeature(
    $input: CreateFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    createFeature(input: $input, condition: $condition) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateFeature = /* GraphQL */ `
  mutation UpdateFeature(
    $input: UpdateFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    updateFeature(input: $input, condition: $condition) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteFeature = /* GraphQL */ `
  mutation DeleteFeature(
    $input: DeleteFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    deleteFeature(input: $input, condition: $condition) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPlanTerm = /* GraphQL */ `
  mutation CreatePlanTerm(
    $input: CreatePlanTermInput!
    $condition: ModelPlanTermConditionInput
  ) {
    createPlanTerm(input: $input, condition: $condition) {
      id
      planID
      termID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      term {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updatePlanTerm = /* GraphQL */ `
  mutation UpdatePlanTerm(
    $input: UpdatePlanTermInput!
    $condition: ModelPlanTermConditionInput
  ) {
    updatePlanTerm(input: $input, condition: $condition) {
      id
      planID
      termID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      term {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deletePlanTerm = /* GraphQL */ `
  mutation DeletePlanTerm(
    $input: DeletePlanTermInput!
    $condition: ModelPlanTermConditionInput
  ) {
    deletePlanTerm(input: $input, condition: $condition) {
      id
      planID
      termID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      term {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTerm = /* GraphQL */ `
  mutation CreateTerm(
    $input: CreateTermInput!
    $condition: ModelTermConditionInput
  ) {
    createTerm(input: $input, condition: $condition) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTerm = /* GraphQL */ `
  mutation UpdateTerm(
    $input: UpdateTermInput!
    $condition: ModelTermConditionInput
  ) {
    updateTerm(input: $input, condition: $condition) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTerm = /* GraphQL */ `
  mutation DeleteTerm(
    $input: DeleteTermInput!
    $condition: ModelTermConditionInput
  ) {
    deleteTerm(input: $input, condition: $condition) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPlanPrice = /* GraphQL */ `
  mutation CreatePlanPrice(
    $input: CreatePlanPriceInput!
    $condition: ModelPlanPriceConditionInput
  ) {
    createPlanPrice(input: $input, condition: $condition) {
      id
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlanPrice = /* GraphQL */ `
  mutation UpdatePlanPrice(
    $input: UpdatePlanPriceInput!
    $condition: ModelPlanPriceConditionInput
  ) {
    updatePlanPrice(input: $input, condition: $condition) {
      id
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlanPrice = /* GraphQL */ `
  mutation DeletePlanPrice(
    $input: DeletePlanPriceInput!
    $condition: ModelPlanPriceConditionInput
  ) {
    deletePlanPrice(input: $input, condition: $condition) {
      id
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClassCategory = /* GraphQL */ `
  mutation CreateClassCategory(
    $input: CreateClassCategoryInput!
    $condition: ModelClassCategoryConditionInput
  ) {
    createClassCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateClassCategory = /* GraphQL */ `
  mutation UpdateClassCategory(
    $input: UpdateClassCategoryInput!
    $condition: ModelClassCategoryConditionInput
  ) {
    updateClassCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteClassCategory = /* GraphQL */ `
  mutation DeleteClassCategory(
    $input: DeleteClassCategoryInput!
    $condition: ModelClassCategoryConditionInput
  ) {
    deleteClassCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createGroupCategory = /* GraphQL */ `
  mutation CreateGroupCategory(
    $input: CreateGroupCategoryInput!
    $condition: ModelGroupCategoryConditionInput
  ) {
    createGroupCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateGroupCategory = /* GraphQL */ `
  mutation UpdateGroupCategory(
    $input: UpdateGroupCategoryInput!
    $condition: ModelGroupCategoryConditionInput
  ) {
    updateGroupCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteGroupCategory = /* GraphQL */ `
  mutation DeleteGroupCategory(
    $input: DeleteGroupCategoryInput!
    $condition: ModelGroupCategoryConditionInput
  ) {
    deleteGroupCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createAccountCategory = /* GraphQL */ `
  mutation CreateAccountCategory(
    $input: CreateAccountCategoryInput!
    $condition: ModelAccountCategoryConditionInput
  ) {
    createAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateAccountCategory = /* GraphQL */ `
  mutation UpdateAccountCategory(
    $input: UpdateAccountCategoryInput!
    $condition: ModelAccountCategoryConditionInput
  ) {
    updateAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteAccountCategory = /* GraphQL */ `
  mutation DeleteAccountCategory(
    $input: DeleteAccountCategoryInput!
    $condition: ModelAccountCategoryConditionInput
  ) {
    deleteAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createSubAccountCategory = /* GraphQL */ `
  mutation CreateSubAccountCategory(
    $input: CreateSubAccountCategoryInput!
    $condition: ModelSubAccountCategoryConditionInput
  ) {
    createSubAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSubAccountCategory = /* GraphQL */ `
  mutation UpdateSubAccountCategory(
    $input: UpdateSubAccountCategoryInput!
    $condition: ModelSubAccountCategoryConditionInput
  ) {
    updateSubAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSubAccountCategory = /* GraphQL */ `
  mutation DeleteSubAccountCategory(
    $input: DeleteSubAccountCategoryInput!
    $condition: ModelSubAccountCategoryConditionInput
  ) {
    deleteSubAccountCategory(input: $input, condition: $condition) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createPatientType = /* GraphQL */ `
  mutation CreatePatientType(
    $input: CreatePatientTypeInput!
    $condition: ModelPatientTypeConditionInput
  ) {
    createPatientType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePatientType = /* GraphQL */ `
  mutation UpdatePatientType(
    $input: UpdatePatientTypeInput!
    $condition: ModelPatientTypeConditionInput
  ) {
    updatePatientType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePatientType = /* GraphQL */ `
  mutation DeletePatientType(
    $input: DeletePatientTypeInput!
    $condition: ModelPatientTypeConditionInput
  ) {
    deletePatientType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContractingAndPaymentMethod = /* GraphQL */ `
  mutation CreateContractingAndPaymentMethod(
    $input: CreateContractingAndPaymentMethodInput!
    $condition: ModelContractingAndPaymentMethodConditionInput
  ) {
    createContractingAndPaymentMethod(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContractingAndPaymentMethod = /* GraphQL */ `
  mutation UpdateContractingAndPaymentMethod(
    $input: UpdateContractingAndPaymentMethodInput!
    $condition: ModelContractingAndPaymentMethodConditionInput
  ) {
    updateContractingAndPaymentMethod(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContractingAndPaymentMethod = /* GraphQL */ `
  mutation DeleteContractingAndPaymentMethod(
    $input: DeleteContractingAndPaymentMethodInput!
    $condition: ModelContractingAndPaymentMethodConditionInput
  ) {
    deleteContractingAndPaymentMethod(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBenefitsPlan = /* GraphQL */ `
  mutation CreateBenefitsPlan(
    $input: CreateBenefitsPlanInput!
    $condition: ModelBenefitsPlanConditionInput
  ) {
    createBenefitsPlan(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBenefitsPlan = /* GraphQL */ `
  mutation UpdateBenefitsPlan(
    $input: UpdateBenefitsPlanInput!
    $condition: ModelBenefitsPlanConditionInput
  ) {
    updateBenefitsPlan(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBenefitsPlan = /* GraphQL */ `
  mutation DeleteBenefitsPlan(
    $input: DeleteBenefitsPlanInput!
    $condition: ModelBenefitsPlanConditionInput
  ) {
    deleteBenefitsPlan(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHealthReferencedDocumentType = /* GraphQL */ `
  mutation CreateHealthReferencedDocumentType(
    $input: CreateHealthReferencedDocumentTypeInput!
    $condition: ModelHealthReferencedDocumentTypeConditionInput
  ) {
    createHealthReferencedDocumentType(input: $input, condition: $condition) {
      id
      code
      name
      comment
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHealthReferencedDocumentType = /* GraphQL */ `
  mutation UpdateHealthReferencedDocumentType(
    $input: UpdateHealthReferencedDocumentTypeInput!
    $condition: ModelHealthReferencedDocumentTypeConditionInput
  ) {
    updateHealthReferencedDocumentType(input: $input, condition: $condition) {
      id
      code
      name
      comment
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHealthReferencedDocumentType = /* GraphQL */ `
  mutation DeleteHealthReferencedDocumentType(
    $input: DeleteHealthReferencedDocumentTypeInput!
    $condition: ModelHealthReferencedDocumentTypeConditionInput
  ) {
    deleteHealthReferencedDocumentType(input: $input, condition: $condition) {
      id
      code
      name
      comment
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHealthOperationType = /* GraphQL */ `
  mutation CreateHealthOperationType(
    $input: CreateHealthOperationTypeInput!
    $condition: ModelHealthOperationTypeConditionInput
  ) {
    createHealthOperationType(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHealthOperationType = /* GraphQL */ `
  mutation UpdateHealthOperationType(
    $input: UpdateHealthOperationTypeInput!
    $condition: ModelHealthOperationTypeConditionInput
  ) {
    updateHealthOperationType(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHealthOperationType = /* GraphQL */ `
  mutation DeleteHealthOperationType(
    $input: DeleteHealthOperationTypeInput!
    $condition: ModelHealthOperationTypeConditionInput
  ) {
    deleteHealthOperationType(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKeyPartner = /* GraphQL */ `
  mutation CreateKeyPartner(
    $input: CreateKeyPartnerInput!
    $condition: ModelKeyPartnerConditionInput
  ) {
    createKeyPartner(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      description
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      tags {
        name
        value
        __typename
      }
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      rating
      comments {
        author
        text
        __typename
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateKeyPartner = /* GraphQL */ `
  mutation UpdateKeyPartner(
    $input: UpdateKeyPartnerInput!
    $condition: ModelKeyPartnerConditionInput
  ) {
    updateKeyPartner(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      description
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      tags {
        name
        value
        __typename
      }
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      rating
      comments {
        author
        text
        __typename
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteKeyPartner = /* GraphQL */ `
  mutation DeleteKeyPartner(
    $input: DeleteKeyPartnerInput!
    $condition: ModelKeyPartnerConditionInput
  ) {
    deleteKeyPartner(input: $input, condition: $condition) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      description
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      tags {
        name
        value
        __typename
      }
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      rating
      comments {
        author
        text
        __typename
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createSuppressionList = /* GraphQL */ `
  mutation CreateSuppressionList(
    $input: CreateSuppressionListInput!
    $condition: ModelSuppressionListConditionInput
  ) {
    createSuppressionList(input: $input, condition: $condition) {
      email
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSuppressionList = /* GraphQL */ `
  mutation UpdateSuppressionList(
    $input: UpdateSuppressionListInput!
    $condition: ModelSuppressionListConditionInput
  ) {
    updateSuppressionList(input: $input, condition: $condition) {
      email
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSuppressionList = /* GraphQL */ `
  mutation DeleteSuppressionList(
    $input: DeleteSuppressionListInput!
    $condition: ModelSuppressionListConditionInput
  ) {
    deleteSuppressionList(input: $input, condition: $condition) {
      email
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeType = /* GraphQL */ `
  mutation CreateEmployeeType(
    $input: CreateEmployeeTypeInput!
    $condition: ModelEmployeeTypeConditionInput
  ) {
    createEmployeeType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeType = /* GraphQL */ `
  mutation UpdateEmployeeType(
    $input: UpdateEmployeeTypeInput!
    $condition: ModelEmployeeTypeConditionInput
  ) {
    updateEmployeeType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeType = /* GraphQL */ `
  mutation DeleteEmployeeType(
    $input: DeleteEmployeeTypeInput!
    $condition: ModelEmployeeTypeConditionInput
  ) {
    deleteEmployeeType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeSubType = /* GraphQL */ `
  mutation CreateEmployeeSubType(
    $input: CreateEmployeeSubTypeInput!
    $condition: ModelEmployeeSubTypeConditionInput
  ) {
    createEmployeeSubType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployeeSubType = /* GraphQL */ `
  mutation UpdateEmployeeSubType(
    $input: UpdateEmployeeSubTypeInput!
    $condition: ModelEmployeeSubTypeConditionInput
  ) {
    updateEmployeeSubType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployeeSubType = /* GraphQL */ `
  mutation DeleteEmployeeSubType(
    $input: DeleteEmployeeSubTypeInput!
    $condition: ModelEmployeeSubTypeConditionInput
  ) {
    deleteEmployeeSubType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContractType = /* GraphQL */ `
  mutation CreateContractType(
    $input: CreateContractTypeInput!
    $condition: ModelContractTypeConditionInput
  ) {
    createContractType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContractType = /* GraphQL */ `
  mutation UpdateContractType(
    $input: UpdateContractTypeInput!
    $condition: ModelContractTypeConditionInput
  ) {
    updateContractType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContractType = /* GraphQL */ `
  mutation DeleteContractType(
    $input: DeleteContractTypeInput!
    $condition: ModelContractTypeConditionInput
  ) {
    deleteContractType(input: $input, condition: $condition) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
