import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import CalculatorIcon from 'mdi-react/CalculatorIcon';
import CurrencyFormat from 'react-currency-format';

const InitialCash = ({
  title,
  totalCashBefore,
  setObservationTurn,
  handleChangeInput,
}) => {
  return (
    <>
      <Col>
        <p className="bold-text mt-3">{title}</p>
        <Row>
          <Col className="mt-3" md={1} xl={1} lg={1} />

          <Col md={9} xl={9} lg={9} xs={9}>
            <CurrencyFormat
              customInput={Input}
              placeholder="$"
              thousandSeparator
              prefix="$"
              disabled
              value={totalCashBefore ?? 0}
            />
          </Col>
          <Col md={1} xl={1} lg={1} xs={1}>
            <CalculatorIcon size="2.5rem" />
          </Col>
        </Row>
      </Col>

      <Col className="text-align-left mt-2">
        <p className="text-align-center bold-text">Monedas y billetes</p>
        <Row>
          <Col className="mt-3" md={1} xl={1} lg={1} />
          <Col md={6} xl={3} lg={3} xs={6}>
            <Col>
              <p>$50</p>
              <Input
                type="number"
                min="0"
                name="50"
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <p>$100</p>
              <Input
                type="number"
                min="0"
                name="100"
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <p>$200</p>
              <Input
                type="number"
                min="0"
                name="200"
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <p>$500</p>
              <Input
                type="number"
                min="0"
                name="500"
                onChange={handleChangeInput}
              />
            </Col>
          </Col>
          <Col md={6} xl={3} lg={3} xs={6}>
            <Col>
              <p>$1.000</p>
              <Input
                type="number"
                min="0"
                name="1000"
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <p>$2.000</p>
              <Input
                type="number"
                min="0"
                name="2000"
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <p>$5.000</p>
              <Input
                type="number"
                min="0"
                name="5000"
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <p>$10.000</p>
              <Input
                type="number"
                min="0"
                name="10000"
                onChange={handleChangeInput}
              />
            </Col>
          </Col>
          <Col md={6} xl={3} lg={3} xs={6}>
            <Col>
              <p>$20.000</p>
              <Input
                type="number"
                min="0"
                name="20000"
                onChange={handleChangeInput}
              />
            </Col>

            <Col>
              <p>$50.000</p>
              <Input
                type="number"
                min="0"
                name="50000"
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <p>$100.000</p>
              <Input
                type="number"
                min="0"
                name="100000"
                onChange={handleChangeInput}
              />
            </Col>
          </Col>
        </Row>
      </Col>
      <Row>
        <Col className="mt-3" md={1} xl={1} lg={1} />
        <Col className="mt-3 " xl={10} lg={10}>
          <p>Observaciones</p>
          <textarea
            rows={3}
            className="textarea-description"
            onChange={(e) => setObservationTurn(e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
};

export default InitialCash;
