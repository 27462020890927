import { useEffect } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { getProductsByCompany } from './queries';
import { useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';

import {
  onCreateProductService,
  onDeleteProductService,
  onUpdateProductService,
} from '../../../graphql/subscriptions';
import { getProductService, listUnits } from '../../../graphql/queries';

import queryKeys from '../queryKeys';
import { useParams } from 'react-router-dom';
import { productMapper } from '../../../containers/Items/components/form-props';

const fetchProducts = async (id, filterOption) => {
  try {
    let prods = [];
    let nextToken = null;
    const options = {};
    if (filterOption !== 'ALL') {
      options.filter = {
        or: [{ active: { eq: true } }, { active: { attributeExists: false } }],
      };
    }
    do {
      const products = await API.graphql(
        graphqlOperation(getProductsByCompany, {
          id: id,
          nextToken,
          ...options,
        })
      );
      prods.push(...products.data.getCompany.productsServices.items);
      nextToken = products.data.getCompany.productsServices.nextToken;
    } while (nextToken);

    return prods;
  } catch (error) {
    console.error('fetchProducts', error);
  }
};

export function useProducts(filter = 'ALL') {
  const currentUser = useSelector((state) => state.user.currentUser);
  const queryClient = useQueryClient();

  const { data: products = [], isLoading } = useQuery(
    [queryKeys.products, filter],
    () => fetchProducts(currentUser.company.id, filter)
  );

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateProductService, {
        owner: currentUser.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: (eventData) => {
        const tax = eventData.value.data.onCreateProductService;
        queryClient.setQueryData(queryKeys.products, (prevData) => [
          ...prevData,
          tax,
        ]);
      },
    });
    const subscription3 = API.graphql(
      graphqlOperation(onUpdateProductService, {
        owner: currentUser.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: (eventData) => {
        const product = eventData.value.data.onUpdateProductService;

        const updatedProduct = {
          id: product.id,
          name: product.name,
          code: product.code,
          type: product.type,
          taxed: product.taxed ? 'Si' : 'No',
          active: typeof product.active === 'boolean' ? product.active : true,
          description: product.description,
          vatPercentage:
            product.vatPercentage > 0 ? product.vatPercentage : 'Ninguno',
          price: product.prices[product.defaultPrice].value,
          path: '/products/edit/' + product.id,
        };

        queryClient.setQueryData(queryKeys.products, (prevData) =>
          prevData.map((tax) =>
            tax.id === updatedProduct.id
              ? {
                  ...updatedProduct,
                  idx: prevData.length + 1,
                }
              : tax
          )
        );
      },
    });

    const subscription2 = API.graphql(
      graphqlOperation(onDeleteProductService, {
        owner: currentUser.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: (eventData) => {
        const prodRemoved = eventData.value.data.onDeleteTax;
        queryClient.setQueryData(queryKeys.products, (prevData) =>
          prevData.filter((prod) => prod.id !== prodRemoved.id)
        );
      },
    });
    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    };
  }, [currentUser.id, queryClient]);

  return {
    products: products.map((item, idx) => ({
      id: item.id,
      idx: idx + 1,
      name: item.name,
      code: item.code,
      type: item.type,
      unit: item.unit,
      active: typeof item.active === 'boolean' ? item.active : true,
      description: item.description,
      taxed: item.taxed ? 'Si' : 'No',
      vatPercentage: item.vatPercentage > 0 ? item.vatPercentage : 'Ninguno',
      vatPercentageValue: item.vatPercentage,
      incPercentageValue: item.incPercentage,
      price: item.prices[item.defaultPrice].value,
      quantity: 1,
      total: item.prices[item.defaultPrice].value,
      totalINC:
        item.incPercentage > 0
          ? (item.prices[item.defaultPrice].value * item.incPercentage) / 100
          : 0,
      totalIVA:
        item.vatPercentage > 0
          ? (item.prices[item.defaultPrice].value * item.vatPercentage) / 100
          : 0,
    })),
    isLoading,
  };
}

const fetchUnits = async () => {
  const { data } = await API.graphql(
    graphqlOperation(listUnits, { limit: 1100 })
  );

  return data.listUnits.items.map((item) => ({
    value: item.id,
    key: item.id,
    label: _.capitalize(item.name),
  }));
};

export function useUnits() {
  const { data: units = [], isLoading } = useQuery(queryKeys.units, () =>
    fetchUnits()
  );
  return {
    units,
    isLoading,
  };
}

export function usePrefetchUnits() {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(queryKeys.units, fetchUnits);
}

const fetchProduct = async (id) => {
  try {
    const product = await API.graphql(
      graphqlOperation(getProductService, { id })
    );
    return await productMapper(product.data.getProductService);
  } catch (error) {
    console.error('fetchProduct', error);
  }
};

export function useGetProduct() {
  const params = useParams();
  const queryClient = useQueryClient();

  const { data: product = [], isLoading } = useQuery(
    [queryKeys.products, params.id],
    () => fetchProduct(params.id),
    {
      initialData: async () => {
        const data = queryClient
          .getQueryData(queryKeys.products)
          ?.find((d) => d.id === params.id);
        if (data) {
          return await productMapper(data);
        }
      },
      enabled: !!params.id,
    }
  );
  return {
    product,
    isLoading,
  };
}
