import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { signOutStart } from '../../../redux/user/user.actions';

import DownIcon from 'mdi-react/ChevronDownIcon';
import { Button, Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../assets/ava.png';
import { closeTurn } from '../../../redux/pos/pos.actions';

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const currentUser = useSelector((state) => state.user.currentUser);

  const logout = () => {
    dispatch(closeTurn());
    dispatch(signOutStart());
    navigate('/');
  };
  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={handleToggleCollapse}
      >
        <img
          className="topbar__avatar-img"
          src={currentUser.company.ImgUrl || Avatar}
          alt="avatar"
        />
        <p className="topbar__avatar-name">{currentUser.name}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Mi Perfil" icon="user" path="/account" />
          {!currentUser.isCashier && (
            <>
              <TopbarMenuLink
                title="Configuraciones"
                icon="cog"
                path="/configurations"
              />
              <TopbarMenuLink title="Trazabilidad" icon="cart" path="/logs" />
            </>
          )}

          <div className="topbar__menu-divider" />
          <Button onClick={logout} outline className="topbar__link">
            <span className={`topbar__link-icon lnr lnr-exit`} />
            <p className="topbar__link-title">Salir</p>
          </Button>
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
