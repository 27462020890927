import React from 'react';

function useSafeDispatch(dispatch) {
  const mountedRef = React.useRef(false);

  React.useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return React.useCallback(
    (...args) => (mountedRef.current ? dispatch(...args) : void 0),
    [dispatch]
  );
}

export const listActionTypes = {
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
  FINISHED: 'FINISHED',
};

const customReducer = (extraReducer = (state, action) => state) => {
  return function (state, action) {
    switch (action.type) {
      case listActionTypes.PENDING: {
        return { ...state, loading: true };
      }
      case listActionTypes.RESOLVED: {
        return { ...state, loading: false, data: action.data };
      }
      case listActionTypes.REJECTED: {
        return { ...state, loading: false, error: action.error };
      }
      case listActionTypes.FINISHED: {
        return { ...state, loading: false };
      }
      default: {
        return extraReducer(state, action);
      }
    }
  };
};

export function useList(initialState = {}, reducer = (state, action) => state) {
  const [state, unsafeDispatch] = React.useReducer(customReducer(reducer), {
    loading: true,
    data: null,
    error: null,
    ...initialState,
  });
  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, loading } = state;
  const run = React.useCallback(
    async (promise) => {
      try {
        const result = await promise();
        console.log(result);

        dispatch({
          type: listActionTypes.RESOLVED,
          data: result,
        });
      } catch (error) {
        console.error(error);
        dispatch({ type: listActionTypes.REJECTED, error });
      }
    },
    [dispatch]
  );
  return {
    error,
    loading,
    data,
    run,
    dispatch,
  };
}
