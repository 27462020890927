import React, { useEffect } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import HeaderPayment from '../../components/HeaderPayment';
import { BankNameOptions } from '../../../../../utils/OthersData';
import Dropdown from '../../../../../shared/components/form/Dropdown';
import CurrencyFormat from 'react-currency-format';
import PercentIcon from 'mdi-react/PercentIcon';

const Combined = ({
  total,
  createPosDocument,
  handleChangePayment,
  paymentInfo,
  setTotalWithDiscount,
  formatNumber,
  isDiscountPercentage,
  handleChangeDiscount,
}) => {
  const cash = paymentInfo.cashReceived ?? 0;
  const debit = paymentInfo.debit ?? 0;
  const credit = paymentInfo.credit ?? 0;
  const transfer = paymentInfo.transfer ?? 0;
  const discount = paymentInfo?.discount ?? 0;
  const calculeDiscount = isDiscountPercentage
    ? (total * discount) / 100
    : discount;
  const payable = total - calculeDiscount - cash - debit - credit - transfer;
  useEffect(() => {
    setTotalWithDiscount(total - calculeDiscount);
  }, [total, calculeDiscount]);

  return (
    <div>
      <HeaderPayment total={formatNumber(total - calculeDiscount)} />
      <Col>
        <Row>
          <Col>
            <p className="bold-text">Por pagar {'->'} </p>
          </Col>
          <Col>
            <p className="bold-text">{formatNumber(payable)}</p>
          </Col>
        </Row>
      </Col>
      <Row className="mt-3">
        <Col md={6} xl={6} lg={6} xs={6}>
          <Row>
            <Col>
              <p>Efectivo</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix="$"
                name="cashReceived"
                value={paymentInfo?.cashReceived}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'cashReceived',
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p>Debito</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix="$"
                name="debit"
                value={paymentInfo?.debit}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'debit',
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Dropdown
                placeholder="Seleccione banco"
                options={BankNameOptions}
                value={paymentInfo.bankNameDebit}
                onChange={(value) =>
                  handleChangePayment({
                    target: { value, name: 'bankNameDebit' },
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p>Credito</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix="$"
                name="credit"
                value={paymentInfo?.credit}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'credit',
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Dropdown
                placeholder="Seleccione banco"
                options={BankNameOptions}
                value={paymentInfo.bankNameCredit}
                onChange={(value) =>
                  handleChangePayment({
                    target: { value, name: 'bankNameCredit' },
                  })
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={6} xl={6} lg={6} xs={6}>
          <Row>
            <Col>
              <p>Transferencia</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix="$"
                name="transfer"
                value={paymentInfo?.transfer}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'transfer',
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Dropdown
                placeholder="Seleccione banco"
                options={BankNameOptions}
                value={paymentInfo.bankNameTransfer}
                onChange={(value) =>
                  handleChangePayment({
                    target: { value, name: 'bankNameTransfer' },
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p>{`Descuento en ${isDiscountPercentage ? '%' : 'pesos'}`}</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix={isDiscountPercentage ? '%' : '$'}
                name="discount"
                value={discount}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'discount',
                    },
                  });
                }}
              />
            </Col>
            <Col xl={3}>
              <Button
                className="mt-4"
                outline
                size="sm"
                onClick={() => handleChangeDiscount()}
                active={isDiscountPercentage}
              >
                <p>
                  <PercentIcon />
                </p>
              </Button>
            </Col>
          </Row>
          <p>Observaciones</p>
          <textarea
            rows={3}
            className="textarea-description"
            name="observation"
            onChange={(e) => handleChangePayment(e)}
            value={paymentInfo.observation}
          />
        </Col>
      </Row>

      <Button
        className="mt-3"
        size="sm"
        outline
        onClick={() => createPosDocument()}
      >
        <p>Pagar</p>
      </Button>
    </div>
  );
};

export default Combined;
