import React from 'react';
import classNames from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWrapperStateView,
  selectWrapperView,
  selectWrapperViewProps,
} from '../../../redux/wrapper/selectors';
import { setDesactiveWrapperView } from '../../../redux/wrapper/actions';

const Wrapper = () => {
  const isOpen = useSelector(selectWrapperStateView);
  const View = useSelector(selectWrapperView);
  const viewProps = useSelector(selectWrapperViewProps);
  const dispatch = useDispatch();
  const wrapperClass = classNames({
    customizer__wrap: true,
    'customizer__wrap--open': isOpen,
  });

  const handleOpen = () => {
    dispatch(setDesactiveWrapperView());
  };

  return (
    <div className="customizer">
      <div
        className={wrapperClass}
        hidden={!isOpen}
        style={{
          overflowY: 'scroll',
        }}
      >
        <div className="customizer__title-wrap">
          {viewProps?.title && <h5>{viewProps.title}</h5>}
          <button
            className="customizer__close-btn"
            type="button"
            onClick={handleOpen}
          >
            <CloseIcon />
          </button>
        </div>
        {typeof View === 'object' ? View : <View />}
      </div>
    </div>
  );
};

export default Wrapper;
