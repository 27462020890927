export const getTaxByCompany = `
query GetCompany($id: ID!, $nextToken: String) {
  getCompany(id: $id) {
    taxes(nextToken: $nextToken) {
        items {
            id
            name
            type
            percentage
            municipality
            value
            country
        }
        nextToken
    }
  }
}
`;
export const getTax = `
  query getTax($id: ID!) {
    getTax(id: $id) {
      id
      name
      type
      percentage
      municipality
      value
      country
    }
  }
`;
