import { API, graphqlOperation } from 'aws-amplify';
import { createLog } from '../graphql/mutations';
import { cleanObject } from './formulas';
export const TrackerEvent = async ({
  logCompanyId,
  description,
  timestamp,
  operationType,
  subOperationType,
  total,
  url,
  user,
}) => {
  const input = cleanObject({
    logCompanyId,
    description,
    timestamp,
    operationType,
    subOperationType,
    total,
    url,
    user,
  });

  try {
    await API.graphql(
      graphqlOperation(createLog, {
        input,
      })
    );

    return console.log('log- -->', input);
  } catch (error) {
    console.log(error);
  }
};
