export const getUserQuery = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        tags {
          name
          value
        }
      }
      companyRole

      idType {
        id
        code
        name
        abbreviation
        country
      }
      cashierID
      salesmanID
      owner
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        lastDateOfReferral
        companyMainRole
        editors
        readers
        contacts {
          name
          lastname
          title
          email
          main
        }
        locations {
          name
          headquarters
          address {
            country
            city
            state
            address_line1
            address_line2
          }
          phoneNumbers {
            countryCode
            number
            extension
          }
        }
        profilePhoto {
          bucket
          region
          key
        }
        owner
        configuration {
          id
          decimals
          decimalsQuantity
          ePayrollExtras
          ePayrollDeductions
          currentEventsNumber
          healthProvider
          storagePath
          discountRetentions
          healthServicesLenderCode
          eInvoicingDIANEnabled
          ePayrollDIANEnabled
          showBatchAndExpirationDate
          defaultTermsAndConditionsForInvoice
          sendSuppliersEmail
          billsReceiverData
          emailForInvoicing
          editors
          resolutions {
            name
            branchCode
            invoicePrefix
            initDate
            endDate
            rangeInit
            rangeEnd
            technicalKey
            currentInvoiceNumber
            invoicesResolution
          }
          fiscalResponsabilities {
            items {
              fiscalResponsability {
                name
                code
              }
            }
          }
          regimenType {
            name
            code
          }
        }
        plan {
          id
          initDate
          endDate
          active
          data
          editors
          type {
            id
            planID
            name
            description
            data
            price {
              id
              value
              description
            }
          }
        }
        roles {
          items {
            name
            id
            users {
              items {
                name
                nationalID
              }
            }
          }
        }
        users {
          items {
            email
            id
            lastname
            name
            companyRole
            nationalID
            profile {
              name
            }
          }
        }
      }
    }
  }
`;
