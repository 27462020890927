export const getCustomerByCompany = `
query GetCompany($id: ID!,$filter: ModelCustomerFilterInput, $nextToken: String ) {
  getCompany(id: $id) {
    customers(limit: 1000,filter: $filter, nextToken: $nextToken) {
      items  {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      active
      locations {
        name
        address {
          country
          cityCode
          city
          stateCode
          state
          address_line1
          address_line2
          address_zip_code
        }
        headquarters
        branchCode
        phoneNumbers {
          countryCode
          number
          extension
        }
        tags {
          name
          value
        }
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        tags {
          name
          value
        }
      }
      contacts {
        name
        lastname
        title
        email
        location {
          name
          headquarters
          branchCode
        }
        phoneNumber {
          countryCode
          number
          extension
        }
        main
        tags {
          name
          value
        }
      }
      tags {
        name
        value
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
      }
      owner
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        companiesConfigurations {
          nextToken
        }
      }
    }
      nextToken
    }
  }
}
`;

export const getCustomer = `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      active
      locations {
        name
        address {
          country
          cityCode
          city
          stateCode
          state
          address_line1
          address_line2
          address_zip_code
        }
        headquarters
        branchCode
        phoneNumbers {
          countryCode
          number
          extension
        }
        tags {
          name
          value
        }
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        tags {
          name
          value
        }
      }
      contacts {
        name
        lastname
        title
        email
        location {
          name
          headquarters
          branchCode
        }
        phoneNumber {
          countryCode
          number
          extension
        }
        main
        tags {
          name
          value
        }
      }
      tags {
        name
        value
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
      }
      owner
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        companiesConfigurations {
          nextToken
        }
      }
    }
  }
`;
