import React from 'react';
import { Button } from 'reactstrap';

class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = {
      hasErrored: false,
    };
    this.reloadPage = this.reloadPage.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasErrored: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
  }

  reloadPage() {
    window.location.reload();
  }

  render() {
    if (this.state.hasErrored) {
      return (
        <div className="error__container">
          <div className="error__image">
            <img src="https://i.imgur.com/A040Lxr.png" alt="error" />
          </div>
          <div className="error__text">
            Estamos haciendo cambios en nuestro sitio web.
            <br />
            <br />
            <br />
            <Button outline onClick={this.reloadPage}>
              Regresar&rarr;
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
