import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarContact from './TopbarContacts';
import ChevronDownIcon from 'mdi-react/ChevronLeftIcon';
import Brightness6Icon from 'mdi-react/Brightness6Icon';
import ToggleIconOn from 'mdi-react/ToggleSwitchIcon';
import ToggleIconOff from 'mdi-react/ToggleSwitchOffIcon';
import CashRegisterIcon from 'mdi-react/CashRegisterIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeThemeToLight,
  changeThemeToDark,
} from '../../../redux/theme/theme.actions';
import { changeModal } from '../../../redux/pos/pos.actions';
import MoneyInOutModal from '../../POS/dashboard/components/MoneyInOut';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const currentUser = useSelector((state) => state.user.currentUser);
  const turnPos = useSelector((state) => state.pos.turnActive);

  const [showModal, setShowModal] = useState(false);

  const isCashier = currentUser?.isCashier;
  const handleChangeTurn = (turn) => {
    isCashier && navigation('/');
    if (turn) {
      dispatch(changeModal('closeTurn'));
    } else {
      dispatch(changeModal('openTurn'));
    }
  };

  const handleChangeModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/" />
          <button
            className="topbar__btn"
            type="button"
            onClick={() => navigation(-1)}
          >
            <ChevronDownIcon />
          </button>
        </div>
        <div className="topbar__right">
          <a
            rel="noopener noreferrer"
            href="https://centroayudafinppi.tawk.help/"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <button type="button" className="topbar__avatar">
              <p className="topbar__avatar-name" style={{ margin: 'auto 0' }}>
                Ayuda
              </p>
            </button>
          </a>
          {!isCashier && <TopbarContact />}

          <button
            className="topbar__btn"
            type="button"
            onClick={
              theme.className === 'theme-dark'
                ? () => dispatch(changeThemeToLight())
                : () => dispatch(changeThemeToDark())
            }
          >
            <Brightness6Icon />
          </button>
          {(isCashier || window.location.pathname === '/pos') && (
            <>
              <button
                className="topbar__btn"
                type="button"
                onClick={() => handleChangeTurn(turnPos)}
              >
                {turnPos ? <ToggleIconOn /> : <ToggleIconOff />}
              </button>

              {turnPos && (
                <button
                  className="topbar__btn"
                  type="button"
                  onClick={() => handleChangeModal()}
                >
                  <CashRegisterIcon />
                </button>
              )}
            </>
          )}
          <TopbarProfile />
        </div>
        <MoneyInOutModal modal={showModal} handleClose={handleChangeModal} />
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
