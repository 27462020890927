import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import ModalPos from './ModalPos';
import { updateTerminalCashier } from '../../../../graphql/mutations';

import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';

export const getTerminalCashier = /* GraphQL */ `
  query GetTerminalCashier($id: ID!) {
    getTerminalCashier(id: $id) {
      id
      cashInputs {
        dateTime
        value
        reason
      }
      cashOutputs {
        dateTime
        value
        reason
      }
    }
  }
`;

const MoneyInOutModal = ({ modal, setModalOff, handleClose }) => {
  const pos = useSelector((state) => state.pos);
  const [cash, setCash] = useState(0);
  const [reason, setReason] = useState(null);
  const [operationType, setOperationType] = useState('in');

  useEffect(() => {
    setCash(0);
    setReason(null);
  }, [modal]);

  const handleSubmit = async () => {
    try {
      const id = pos?.idTurn;

      const getDataTerminal = await API.graphql(
        graphqlOperation(getTerminalCashier, {
          id,
        })
      );

      const currentTerminalCash = getDataTerminal.data.getTerminalCashier;

      const inputData = {
        dateTime: moment().format(),
        value: parseFloat(cash),
        reason: reason,
      };
      const input = {
        id,
      };
      if (operationType === 'in') {
        currentTerminalCash.cashInputs?.push(inputData);
        input.cashInputs = currentTerminalCash.cashInputs ?? inputData;
      } else {
        currentTerminalCash.cashOutputs?.push(inputData);
        input.cashOutputs = currentTerminalCash.cashOutputs ?? inputData;
      }

      await API.graphql(
        graphqlOperation(updateTerminalCashier, {
          input,
        })
      );
      handleClose();
      toast.info('Se ha registrado correctamente');
    } catch (error) {
      console.log('error', error);
      toast.error('Ha ocurrido un error, vuelva a intentar');
    }
  };

  const handleChangeOperationType = (e) => {
    setOperationType(e.target.value);
  };

  return (
    <ModalPos
      open={modal}
      title="Entrada / Salida de dinero"
      toggle={handleClose}
    >
      <Row className="mt-3">
        <Col>
          <Label check>
            <Input
              type="radio"
              name="typeOperation"
              value="in"
              checked={operationType === 'in'}
              onChange={(e) => handleChangeOperationType(e)}
            />
            Entrada de dinero
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="radio"
              name="typeOperation"
              value="out"
              checked={operationType === 'out'}
              onChange={(e) => handleChangeOperationType(e)}
            />
            Salida de dinero
          </Label>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3" md={1} xl={1} lg={1} />
        <Col className="mt-3 " xl={10} lg={10}>
          <p>Valor*</p>

          <CurrencyFormat
            customInput={Input}
            placeholder="$"
            thousandSeparator
            prefix={'$'}
            value={cash}
            onValueChange={({ floatValue }) => {
              setCash(isNaN(floatValue) ? 0 : floatValue);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col className="mt-3" md={1} xl={1} lg={1} />
        <Col className="mt-3 " xl={10} lg={10}>
          <p>Observaciones*</p>
          <textarea
            rows={3}
            className="textarea-description"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Col>
      </Row>

      <Button outline onClick={handleSubmit} disabled={cash <= 0 || !reason}>
        Confirmar
      </Button>
    </ModalPos>
  );
};

export default MoneyInOutModal;
