export const onUpdateCompanyConfigurationSubscription = `
  subscription OnUpdateCompanyConfiguration($owner: String) {
    onUpdateCompanyConfiguration(owner: $owner) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        resolutionDocument {
          bucket
          region
          key
        }
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        resolutionDocument {
          bucket
          region
          key
        }
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
      }
      branches {
        name
        code
        description
        location {
          name
          headquarters
          branchCode
        }
        currentBillNumber
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
      }
      decimals
      decimalsQuantity
      eInvoiceHeaderExtraFields {
        name
        value
      }
      eInvoiceFooterExtraFields {
        name
        value
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      healthProvider
      storagePath
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        locations {
          name
          headquarters
          branchCode
        }
        defaultLocation
        contacts {
          name
          lastname
          title
          email
          main
        }
        tags {
          name
          value
        }
        companyMainRole
        profilePhoto {
          bucket
          region
          key
        }
        photos {
          bucket
          region
          key
        }
        videos {
          bucket
          region
          key
        }
        videoURLs
        websites {
          url
          type
        }
        referrals {
          name
          email
          date
        }
        lastDateOfReferral
        createdAt
        updatedAt
        owner
        configuration {
          id
          decimals
          decimalsQuantity
          defaultTermsAndConditionsForInvoice
          ePayrollExtras
          ePayrollDeductions
          healthProvider
          discountRetentions
          healthServicesLenderCode
          eInvoicingDIANEnabled
          ePayrollDIANEnabled
          createdAt
          updatedAt
          owner
        }
        plan {
          id
          initDate
          endDate
          active
          createdAt
          updatedAt
          owner
        }
        documents {
          nextToken
        }
        industries {
          nextToken
        }
        notifications {
          nextToken
        }
        badges {
          nextToken
        }
        supportTickets {
          nextToken
        }
        groupCategories {
          nextToken
        }
        auxiliarCategories {
          nextToken
        }
        taxes {
          nextToken
        }
        users {
          nextToken
        }
        roles {
          nextToken
        }
        employees {
          nextToken
        }
        epayroll {
          nextToken
        }
        bags {
          nextToken
        }
        productsServices {
          nextToken
        }
        supplierProductsServices {
          nextToken
        }
        keyPartnersProductsServices {
          nextToken
        }
        customers {
          nextToken
        }
        suppliers {
          nextToken
        }
        patients {
          nextToken
        }
        incomes {
          nextToken
        }
        invoices {
          nextToken
        }
        expenses {
          nextToken
        }
        transfers {
          nextToken
        }
        bills {
          nextToken
        }
        equivalentDocuments {
          nextToken
        }
      }
      owner
      fiscalResponsabilities {
        items {
          id
          companyConfigurationID
          fiscalResponsabilityID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
      }
    }
  }
`;
