import React, { useEffect } from 'react';

import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { checkUserSession } from '../../redux/user/user.actions';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { ToastContainer } from 'react-toastify';
import Router from './Router';
import MainWrapper from './MainWrapper';

const ThemeComponent = ({ children, themeName }) => {
  const theme = createTheme({
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const ConnectedThemeComponent = connect((state) => ({
  themeName: state.theme.className,
}))(ThemeComponent);

const App = () => {
  const currentUser = useSelector(selectCurrentUser);
  useEffect(() => {
    checkUserSession(currentUser?.id);
  }, [checkUserSession]);

  return (
    <ConnectedThemeComponent>
      <MainWrapper>
        <main>
          <Router />
        </main>
      </MainWrapper>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        puaseOnVisibilityChange
        draggable
        pauseOnHover={false}
      />
    </ConnectedThemeComponent>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
