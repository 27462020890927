import { useEffect } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { getTax, getTaxByCompany } from './queries';
import { useQuery, useQueryClient } from 'react-query';
import { onCreateTax, onDeleteTax } from '../../../graphql/subscriptions';
import queryKeys from '../queryKeys';
import { useParams } from 'react-router-dom';

const fetchTaxes = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getTaxByCompany, { id })
    );
    return data.getCompany.taxes.items.map((tax, idx) => ({
      idx: idx + 1,
      ...tax,
      path: '/taxs/edit/' + tax.id,
    }));
  } catch (error) {
    console.error('fetchTax', error);
  }
};
export function usePrefetchTaxes() {
  const queryClient = useQueryClient();
  const { id } = useSelector((state) => state.user.currentUser.company);
  queryClient.prefetchQuery(queryKeys.taxes, () => fetchTaxes(id));
}
export const useTaxes = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const queryClient = useQueryClient();

  const { data: taxes = [], isLoading } = useQuery(queryKeys.taxes, () =>
    fetchTaxes(currentUser.company.id)
  );

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateTax, {
        owner: currentUser.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: (eventData) => {
        const tax = eventData.value.data.onCreateTax;
        queryClient.setQueryData(queryKeys.taxes, (prevData) => [
          ...prevData,
          tax,
        ]);
      },
    });

    const subscription2 = API.graphql(
      graphqlOperation(onDeleteTax, {
        owner: currentUser.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: (eventData) => {
        const taxRemoved = eventData.value.data.onDeleteTax;
        queryClient.setQueryData(queryKeys.taxes, (prevData) =>
          prevData.filter((tax) => tax.id !== taxRemoved.id)
        );
      },
    });
    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
    };
  }, [currentUser.id, queryClient]);

  return {
    taxes,
    isLoading,
  };
};

const fetchTax = async (id) => {
  try {
    const { data } = await API.graphql(graphqlOperation(getTax, { id }));
    return data.getTax;
  } catch (error) {
    console.error('fetchTax', error);
  }
};

export function useGetTax() {
  const params = useParams();
  const queryClient = useQueryClient();

  const { data: tax, isLoading } = useQuery(
    [queryKeys.taxes, params.id],
    () => (params.id ? fetchTax(params.id) : null),
    {
      initialData: async () => {
        const data = queryClient
          .getQueryData(queryKeys.taxes)
          ?.find((d) => d.id === params.id);
        if (data) {
          return data;
        }
      },
    }
  );

  return {
    tax,
    isLoading,
  };
}
