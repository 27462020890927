import React from 'react';
import PropTypes from 'prop-types';

const ErrorForm = ({ msg }) => {
  return (
    <div className="form__form-group-input-wrap--error-above mb-1 mt-1">
      <span className="error__message text-danger p-1">{msg}</span>
    </div>
  );
};

ErrorForm.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default ErrorForm;
