import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);
export const selectIsUserFetching = createSelector(
  [selectUser],
  (user) => user.isFetching
);
export const selectIsUserVerified = createSelector(
  [selectUser],
  (user) => user.userVerified
);
export const selectUserCredentials = createSelector(
  [selectUser],
  (user) => user.userCredentials
);
export const selectCretedCompany = createSelector(
  [selectUser],
  (user) => user.userCompany
);
