import { toast } from 'react-toastify';

export default function AmplifyErrors(type) {
  switch (type) {
    case 'UsernameExistsException':
      toast.warning('Username already exist');
      break;
    case 'CodeMismatchException':
      toast.error('Incorrect Code. Try again');
      break;
    case 'UserNotFoundException':
      toast.warning('User not found');
      break;
    case 'UserNotConfirmedException':
      toast.warning('You need to verify your account');
      break;
    case 'NotAuthorizedException':
      toast.warning('Incorrect username or password');
      break;
    case 'LimitExceededException':
      toast.warning(
        'Your attempts limit has been exceeded. Please try again later'
      );
      break;
    default:
      toast.warning('Server error, try it again in a couple of minutes');
      break;
  }
}
