import React, { useEffect } from 'react';

import {
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Container,
} from 'reactstrap';
import Loading from '../../../../shared/components/Loading';

import Cities from '../../../../utils/municipios.json';

import { PersonTypeOptions } from '../../../../utils/OthersData';
import { phoneList, countryList } from '../../../../utils/country';
import { verificationDigit } from '../../../../utils/formulas';

import { useDefaultValues, schema } from './form-props';
import { Controller, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

import ErrorForm from '../../../../shared/components/form/ErrorForm';
import { Dropdown } from '../../../../shared/components/form/Dropdown';

const CustomerForm = ({ isLimitDocuments }) => {
  const { isLoading, status, formValues, submit } = useDefaultValues();
  const { defaultValues, ...rest } = formValues;
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const locations = useFieldArray({
    control,
    name: 'locations',
  });
  const fields = useWatch({
    control,
    name: ['active', 'personType'],
  });

  if (isLoading) return <Loading />;

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            {getValues('name') || getValues('businessName')
              ? getValues('personType') === 'natural'
                ? ` ${getValues('name')}  ${getValues('lastname')}`
                : getValues('businessName')
              : 'Cliente'}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} xl={12} lg={12} xs={12}>
          <Card>
            <CardBody>
              <form className={`form`} onSubmit={handleSubmit(submit)}>
                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group"
                >
                  <Col md={1} xl={0} lg={0} xs={1} />
                  <Col md={12} xl={3} lg={3} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Tipo de persona*
                      </span>

                      <div className="form__form-group-field">
                        <Controller
                          name="personType"
                          render={({ field }) => (
                            <Dropdown
                              {...field}
                              placeholder="Tipo de persona*"
                              options={PersonTypeOptions}
                              onChange={(e) => {
                                setValue('personType', e);
                                if (e === 'juridica') {
                                  setValue(
                                    'customerIdTypeId',
                                    '986c0f6f-c2df-4a1c-9443-5fab13cafe10'
                                  );
                                  setValue(
                                    'verificationDigit',
                                    verificationDigit(getValues('nationalID'))
                                  );
                                } else {
                                  setValue(
                                    'customerIdTypeId',
                                    '51f071d8-58d8-4e0f-b6a5-766c0c158683'
                                  );
                                }
                              }}
                            />
                          )}
                          control={control}
                        />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="personType"
                        render={({ message }) => <ErrorForm msg={message} />}
                      />
                    </div>
                  </Col>
                  <Col md={12} xl={4} lg={4} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Tipo de documento*
                      </span>

                      <div className="form__form-group-field">
                        <Controller
                          name="customerIdTypeId"
                          render={({ field }) => (
                            <Dropdown
                              {...field}
                              placeholder="Tipo de documento*"
                              options={rest.idTypeOptions}
                            />
                          )}
                          control={control}
                        />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="customerIdTypeId"
                        render={({ message }) => <ErrorForm msg={message} />}
                      />
                    </div>
                  </Col>
                  <Col md={12} xl={3} lg={3} xs={12}>
                    <span className="form__form-group-label">
                      Número de documento*
                    </span>
                    <div className="form__form-group-field ">
                      <input
                        name="nationalID"
                        placeholder="Número de documento"
                        {...register(`nationalID`)}
                        onChange={(e) => {
                          if (
                            getValues('customerIdTypeId') ===
                            '986c0f6f-c2df-4a1c-9443-5fab13cafe10'
                          ) {
                            setValue(
                              'verificationDigit',
                              verificationDigit(e.target.value)
                            );
                          }
                        }}
                      />
                    </div>

                    <ErrorMessage
                      errors={errors}
                      name="nationalID"
                      render={({ message }) => <ErrorForm msg={message} />}
                    />
                  </Col>
                </Row>
                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group"
                >
                  <Col md={1} xl={0} lg={0} xs={1} />

                  {fields[1] === 'natural' ? (
                    <>
                      <Col md={12} xl={5} lg={5} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Nombres*
                          </span>
                          <div className="form__form-group-field ">
                            <input
                              name="name"
                              type="text"
                              placeholder="Nombres"
                              {...register(`name`)}
                              onChange={(e) => {
                                setValue('name', e.target.value);

                                setValue('contacts[0].name', e.target.value);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => (
                              <ErrorForm msg={message} />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md={12} xl={5} lg={5} xs={12}>
                        <span className="form__form-group-label">
                          Apellidos*
                        </span>
                        <div className="form__form-group-field ">
                          <input
                            name="lastname"
                            type="text"
                            placeholder="Apellidos"
                            {...register(`lastname`)}
                            onChange={(e) => {
                              setValue('lastname', e.target.value);

                              setValue('contacts[0].lastname', e.target.value);
                            }}
                          />
                        </div>

                        <ErrorMessage
                          errors={errors}
                          name="lastname"
                          render={({ message }) => <ErrorForm msg={message} />}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={12} xl={7} lg={7} xs={12}>
                        <span className="form__form-group-label">
                          Razón social*
                        </span>
                        <div className="form__form-group-field ">
                          <input
                            name="businessName"
                            type="text"
                            placeholder="Razón social"
                            {...register(`businessName`)}
                          />
                        </div>

                        <ErrorMessage
                          errors={errors}
                          name="businessName"
                          render={({ message }) => <ErrorForm msg={message} />}
                        />
                      </Col>
                      <Col md={12} xl={3} lg={3} xs={12}>
                        <span className="form__form-group-label">
                          Dígito de verificación
                        </span>
                        <div className="form__form-group-field ">
                          <input
                            type="text"
                            placeholder="dígito de verificación"
                            disabled
                            {...register(`verificationDigit`)}
                          />
                        </div>

                        <ErrorMessage
                          errors={errors}
                          name="verificationDigit"
                          render={({ message }) => <ErrorForm msg={message} />}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group"
                >
                  <Col md={1} xl={0} lg={0} xs={1} />

                  <Col md={12} xl={5} lg={5} xs={12}>
                    <span className="form__form-group-label">
                      Email (Puede escribir varios separados por ";")*
                    </span>
                    <div className="form__form-group-field">
                      <input
                        name={`contacts[0].email`}
                        placeholder={`Email donde llegará la factura`}
                        type="text"
                        {...register(`contacts[0].email`)}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`contacts[0].email`}
                      render={({ message }) => <ErrorForm msg={message} />}
                    />
                  </Col>
                  <Col md={12} xl={2} lg={2} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Identificador
                      </span>
                      <div className="form__form-group-field">
                        <Controller
                          name={`contacts[0].phoneNumber.countryCode`}
                          render={({ field }) => (
                            <Dropdown
                              {...field}
                              placeholder="Identificador"
                              options={phoneList}
                            />
                          )}
                          control={control}
                        />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name={`contacts[0].phoneNumber.countryCode`}
                        render={({ message }) => <ErrorForm msg={message} />}
                      />
                    </div>
                  </Col>
                  <Col md={12} xl={3} lg={3} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Número celular*{' '}
                      </span>
                      <div className="form__form-group-field ">
                        <input
                          name={`contacts[0].phoneNumber.number`}
                          type="text"
                          placeholder="Número celular"
                          {...register(`contacts[0].phoneNumber.number`)}
                        />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name={`contacts[0].phoneNumber.number`}
                        render={() => (
                          <ErrorForm msg={'Número de celular requerido'} />
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group"
                >
                  <Col md={1} xl={0} lg={0} xs={1}></Col>
                  <Col md={12} xl={5} lg={5} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Regimen*</span>
                      <div className="form__form-group-field">
                        <Controller
                          name="customerRegimenTypeId"
                          render={({ field }) => (
                            <Dropdown
                              {...field}
                              placeholder="Seleccione tipo de regimen"
                              options={rest.regimenTypeOptions}
                            />
                          )}
                          control={control}
                        />
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="customerRegimenTypeId"
                        render={({ message }) => <ErrorForm msg={message} />}
                      />
                    </div>
                  </Col>
                  <Col md={12} xl={5} lg={5} xs={12}>
                    <span className="form__form-group-label">
                      Responsabilidad fiscal*
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        name="customerFiscalResponsabilityId"
                        render={({ field }) => (
                          <Dropdown
                            {...field}
                            placeholder="Seleccione Responsabilidad fiscal"
                            options={rest.fiscalOptions}
                          />
                        )}
                        control={control}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="customerFiscalResponsabilityId"
                      render={({ message }) => <ErrorForm msg={message} />}
                    />
                  </Col>
                </Row>
                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group"
                >
                  <Col md={1} xl={0} lg={0} xs={1}></Col>
                  <Col md={12} xl={10} lg={10} xs={12}>
                    <h4 className="bold-text">Información de ubicación </h4>
                  </Col>
                </Row>

                {locations.fields.map((field, idx) => (
                  <>
                    <Row
                      md={12}
                      xl={12}
                      lg={12}
                      xs={12}
                      className="form__form-group"
                      key={`locations__row---${idx}`}
                    >
                      <Col md={1} xl={0} lg={0} xs={1}></Col>
                      <Col md={12} xl={5} lg={5} xs={12}>
                        <span className="form__form-group-label">
                          Nombre ubicación de cliente
                        </span>
                        <div className="form__form-group-field">
                          <input
                            name={`locations.${idx}.name`}
                            placeholder={`Nombre`}
                            type="text"
                            {...register(`locations.${idx}.name`)}
                          />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name={`locations.${idx}.name`}
                          render={({ message }) => <ErrorForm msg={message} />}
                        />
                      </Col>
                      <Col md={12} xl={5} lg={5} xs={12}>
                        <span className="form__form-group-label">País</span>
                        <div className="form__form-group-field">
                          <Controller
                            name={`locations.${idx}.address.country`}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                placeholder="Seleccione pais"
                                options={countryList}
                              />
                            )}
                            control={control}
                          />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name={`locations.${idx}.address.country`}
                          render={({ message }) => <ErrorForm msg={message} />}
                        />
                      </Col>
                    </Row>

                    {getValues(`locations.${idx}.address.country`) ===
                    'Colombia' ? (
                      <Row
                        md={12}
                        xl={12}
                        lg={12}
                        xs={12}
                        className="form__form-group"
                      >
                        <Col md={1} xl={0} lg={0} xs={1}></Col>
                        <Col md={12} xl={10} lg={10} xs={12}>
                          <span className="form__form-group-label">
                            Ciudad*
                          </span>
                          <div className="form__form-group-field">
                            <Controller
                              name={`locations.${idx}.address.cityCode`}
                              render={({ field }) => (
                                <Dropdown
                                  {...field}
                                  placeholder="Seleccione ciudad"
                                  options={[
                                    ...Cities.map(
                                      ({ label, value, name }, idx) => ({
                                        label: `${label} / ${name}`,
                                        value: value.toString(),
                                        key: idx,
                                      })
                                    ),
                                  ]}
                                  onChange={(e) => {
                                    Cities.forEach((city) => {
                                      if (city.value.toString() === e) {
                                        setValue(
                                          `locations.${idx}.address.cityCode`,
                                          city.value.toString()
                                        );
                                        setValue(
                                          `locations.${idx}.address.city`,
                                          city.label
                                        );
                                        setValue(
                                          `locations.${idx}.address.stateCode`,
                                          e.substring(0, 2)
                                        );
                                        setValue(
                                          `locations.${idx}.address.state`,
                                          city.name
                                        );
                                      }
                                    });
                                  }}
                                />
                              )}
                              control={control}
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={`locations.${idx}.address.cityCode`}
                            render={({ message }) => (
                              <ErrorForm msg={message} />
                            )}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row
                        md={12}
                        xl={12}
                        lg={12}
                        xs={12}
                        className="form__form-group"
                      >
                        <Col md={1} xl={0} lg={0} xs={1} />
                        <Col md={12} xl={5} lg={5} xs={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              Ciudad*
                            </span>
                            <div className="form__form-group-field ">
                              <input
                                name={`locations.${idx}.address.city`}
                                type="text"
                                placeholder="Ciudad"
                                {...register(`locations.${idx}.address.city`)}
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={`locations.${idx}.address.city`}
                              render={({ message }) => (
                                <ErrorForm msg={message} />
                              )}
                            />
                          </div>
                        </Col>

                        <Col md={12} xl={4} lg={4} xs={12}>
                          <span className="form__form-group-label">
                            Estado*
                          </span>
                          <div className="form__form-group-field ">
                            <input
                              name={`locations.${idx}.address.state`}
                              type="text"
                              placeholder="Estado"
                              {...register(`locations.${idx}.address.state`)}
                            />
                          </div>

                          <ErrorMessage
                            errors={errors}
                            name={`locations.${idx}.address.state`}
                            render={({ message }) => (
                              <ErrorForm msg={message} />
                            )}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row
                      md={12}
                      xl={12}
                      lg={12}
                      xs={12}
                      className="form__form-group"
                    >
                      <Col md={1} xl={0} lg={0} xs={1}></Col>
                      <Col md={12} xl={10} lg={10} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Dirección principal*
                          </span>
                          <div className="form__form-group-field ">
                            <input
                              name={`locations.${idx}.address.address_line1`}
                              type="text"
                              placeholder="Dirección principal"
                              {...register(
                                `locations.${idx}.address.address_line1`
                              )}
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={`locations.${idx}.address.address_line1`}
                            render={({ message }) => (
                              <ErrorForm msg={message} />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row
                      md={12}
                      xl={12}
                      lg={12}
                      xs={12}
                      className="form__form-group"
                    >
                      <Col md={1} xl={0} lg={0} xs={1} />

                      <Col md={12} xl={4} lg={4} xs={12}>
                        <span className="form__form-group-label">
                          Dirección secundaria
                        </span>
                        <div className="form__form-group-field ">
                          <input
                            name={`locations.${idx}.address.address_line2`}
                            type="text"
                            placeholder="Dirección secundaria"
                            {...register(
                              `locations.${idx}.address.address_line2`
                            )}
                          />
                        </div>

                        <ErrorMessage
                          errors={errors}
                          name={`locations.${idx}.address.address_line2`}
                          render={({ message }) => <ErrorForm msg={message} />}
                        />
                      </Col>
                      <Col md={12} xl={3} lg={3} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Identificador
                          </span>
                          <div className="form__form-group-field">
                            <Controller
                              name={`locations[0].phoneNumbers[0].countryCode`}
                              render={({ field }) => (
                                <Dropdown
                                  {...field}
                                  placeholder="Identificador"
                                  options={phoneList}
                                />
                              )}
                              control={control}
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={`locations[0].phoneNumbers[0].countryCode`}
                            render={({ message }) => (
                              <ErrorForm msg={message} />
                            )}
                          />
                        </div>
                      </Col>

                      <Col md={12} xl={3} lg={3} xs={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Telefono*
                          </span>
                          <div className="form__form-group-field ">
                            <input
                              name={`locations[0].phoneNumbers[0].number`}
                              type="text"
                              placeholder="Número teléfono"
                              {...register(
                                `locations[0].phoneNumbers[0].number`
                              )}
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={`locations[0].phoneNumbers[0].number`}
                            render={() => (
                              <ErrorForm msg={'Número de telefono requerido'} />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ))}

                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group"
                >
                  <Col md={1} xl={0} lg={0} xs={1}></Col>
                  <Col md={12} xl={10} lg={10} xs={12}>
                    <h4 className="bold-text">Información de contacto </h4>
                  </Col>
                </Row>
                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group"
                >
                  <Col md={1} xl={0} lg={0} xs={1}></Col>
                  <Col md={12} xl={3} lg={3} xs={12}>
                    <span className="form__form-group-label">Nombre</span>
                    <div className="form__form-group-field">
                      <input
                        name={`contacts[0].name`}
                        placeholder={`Nombre`}
                        type="text"
                        {...register(`contacts[0].name`)}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`contacts[0].name`}
                      render={({ message }) => <ErrorForm msg={message} />}
                    />
                  </Col>
                  <Col md={12} xl={3} lg={3} xs={12}>
                    <span className="form__form-group-label">Apellido</span>
                    <div className="form__form-group-field">
                      <input
                        name={`contacts[0].lastname`}
                        placeholder={`Apellido `}
                        type="text"
                        {...register(`contacts[0].lastname`)}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`contacts[0].lastname`}
                      render={({ message }) => <ErrorForm msg={message} />}
                    />
                  </Col>
                  <Col md={12} xl={4} lg={4} xs={12}>
                    <span className="form__form-group-label">
                      Cargo del contacto dentro de la empresa
                    </span>
                    <div className="form__form-group-field">
                      <input
                        name={`contacts[0].title`}
                        placeholder={`Cargo`}
                        type="text"
                        {...register(`contacts[0].title`)}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`contacts[0].title`}
                      render={({ message }) => <ErrorForm msg={message} />}
                    />
                  </Col>
                </Row>
                <Row
                  md={12}
                  xl={12}
                  lg={12}
                  xs={12}
                  className="form__form-group form__form-group-field"
                >
                  <Col md={1} xl={1} lg={1} xs={1}></Col>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button outline type="submit" disabled={isLimitDocuments}>
                      <p>{status}</p>
                    </Button>
                    {status === 'Actualizar' && (
                      <Button
                        outline
                        color={
                          getValues('active') !== true ? 'primary' : 'danger'
                        }
                        onClick={() => setValue('active', !fields[0])}
                      >
                        <p>
                          {getValues('active') !== true
                            ? 'Activar'
                            : 'Desactivar'}
                        </p>
                      </Button>
                    )}
                  </ButtonToolbar>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerForm;
