import RtlActionTypes from './rtl.types';

const INITIAL_STATE = {
  direction: 'ltr',
};

const rtlReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RtlActionTypes.CHANGE_DIRECTION_TO_LTR:
      return { direction: 'ltr' };
    case RtlActionTypes.CHANGE_DIRECTION_TO_RTL:
      return { direction: 'rtl' };
    default:
      return state;
  }
};
export default rtlReducer;
