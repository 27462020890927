import React, { useEffect } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import _ from 'lodash';
import HeaderPayment from '../../components/HeaderPayment';
import PercentIcon from 'mdi-react/PercentIcon';
import CurrencyFormat from 'react-currency-format';

const Cash = ({
  total,
  createPosDocument,
  handleChangePayment,
  paymentInfo,
  setTotalWithDiscount,
  formatNumber,
  handleChangeDiscount,
  isDiscountPercentage,
}) => {
  const cashReceived = paymentInfo?.cashReceived ?? 0;
  const discount = paymentInfo?.discount ?? 0;
  const calculeDiscount = isDiscountPercentage
    ? (total * discount) / 100
    : discount;

  useEffect(() => {
    setTotalWithDiscount(total - calculeDiscount);
  }, [total, calculeDiscount]);

  return (
    <div>
      <HeaderPayment total={formatNumber(total - calculeDiscount)} />
      <Row>
        <Col md={6} xl={6} lg={6} xs={12}>
          <p>Efectivo*</p>
          <CurrencyFormat
            customInput={Input}
            placeholder="$"
            thousandSeparator
            prefix={'$'}
            name="cashReceived"
            value={cashReceived}
            onValueChange={({ floatValue }) => {
              handleChangePayment({
                target: {
                  value: isNaN(floatValue) ? 0 : floatValue,
                  name: 'cashReceived',
                },
              });
            }}
          />
        </Col>
        <Col md={6} xl={6} lg={6} xs={12}>
          <p>Opciones rápidas</p>
          <Row>
            <Col md={4} xl={4} lg={4} xs={4}>
              <Button
                outline
                size="sm"
                onClick={() =>
                  handleChangePayment({
                    target: {
                      value: paymentInfo.cashReceived
                        ? Number(paymentInfo.cashReceived) + 2000
                        : 2000,
                      name: 'cashReceived',
                    },
                  })
                }
              >
                <p>2.000</p>
              </Button>
            </Col>
            <Col md={4} xl={4} lg={4} xs={4}>
              <Button
                outline
                size="sm"
                onClick={() =>
                  handleChangePayment({
                    target: {
                      value: paymentInfo.cashReceived
                        ? Number(paymentInfo.cashReceived) + 5000
                        : 5000,
                      name: 'cashReceived',
                    },
                  })
                }
              >
                <p>5.000</p>
              </Button>
            </Col>
            <Col md={4} xl={4} lg={4} xs={4}>
              <Button
                outline
                size="sm"
                onClick={() =>
                  handleChangePayment({
                    target: {
                      value: paymentInfo.cashReceived
                        ? Number(paymentInfo.cashReceived) + 10000
                        : 10000,
                      name: 'cashReceived',
                    },
                  })
                }
              >
                <p>10.000</p>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} xl={4} lg={4} xs={4}>
              <Button
                outline
                size="sm"
                onClick={() =>
                  handleChangePayment({
                    target: {
                      value: paymentInfo.cashReceived
                        ? Number(paymentInfo.cashReceived) + 20000
                        : 20000,
                      name: 'cashReceived',
                    },
                  })
                }
              >
                <p>20.000</p>
              </Button>
            </Col>
            <Col md={4} xl={4} lg={4} xs={4}>
              <Button
                outline
                size="sm"
                onClick={() =>
                  handleChangePayment({
                    target: {
                      value: paymentInfo.cashReceived
                        ? Number(paymentInfo.cashReceived) + 50000
                        : 50000,
                      name: 'cashReceived',
                    },
                  })
                }
              >
                <p>50.000</p>
              </Button>
            </Col>
            <Col md={4} xl={4} lg={4} xs={4}>
              <Button
                outline
                size="sm"
                onClick={() =>
                  handleChangePayment({
                    target: {
                      value: paymentInfo.cashReceived
                        ? Number(paymentInfo.cashReceived) + 100000
                        : 100000,
                      name: 'cashReceived',
                    },
                  })
                }
              >
                <p>100.000</p>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} xl={6} lg={6} xs={6}>
          <Row>
            <Col>
              <p>{`Descuento en ${isDiscountPercentage ? '%' : 'pesos'}`}</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix={isDiscountPercentage ? '%' : '$'}
                name="discount"
                value={discount}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'discount',
                    },
                  });
                }}
              />
            </Col>
            <Col xl={3}>
              <Button
                className="mt-4"
                outline
                size="sm"
                onClick={() => handleChangeDiscount()}
                active={isDiscountPercentage}
              >
                <p>
                  <PercentIcon />
                </p>
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6} xl={6} lg={6} xs={6}>
              <h5>Cambio</h5>
            </Col>
            <Col md={6} xl={6} lg={6} xs={6}>
              <h5 className="bold-text">
                {cashReceived > 0 && cashReceived > total - calculeDiscount
                  ? formatNumber(
                      Math.abs(total - calculeDiscount - cashReceived)
                    )
                  : 0}
              </h5>
            </Col>
          </Row>
        </Col>
        <Col md={6} xl={6} lg={6} xs={6}>
          <p>Observaciones</p>
          <textarea
            rows={5}
            className="textarea-description"
            name="observation"
            onChange={(e) => handleChangePayment(e)}
            value={paymentInfo.observation}
          />
        </Col>
      </Row>

      <Button
        className="mt-3"
        size="sm"
        outline
        onClick={() => createPosDocument()}
        disabled={!cashReceived}
      >
        <p>Pagar</p>
      </Button>
    </div>
  );
};

export default Cash;
