import React from 'react';
import _ from 'lodash';
import {
  Font,
  StyleSheet,
  Page,
  View,
  Text,
  Document,
  Svg,
  Line,
} from '@react-pdf/renderer';
import moment from 'moment';
import { verificationDigit } from '../../../../utils/formulas';

Font.register({
  family: 'Nunito',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf' },
    {
      src: 'https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf',
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: { padding: '30px' },
  headerText: {
    fontSize: '9px',
    fontWeight: 1200,
  },
});

export const getPOSDocumentPDF = /* GraphQL */ `
  query GetPOSDocument($id: ID!) {
    getPOSDocument(id: $id) {
      id
      type
      code
      date
      total
      taxes
      data
      status
      client {
        id
        nationalID
        verificationDigit
        name
        lastname
        businessName
        locations {
          name
          address {
            address_line1
          }
        }
        contacts {
          phoneNumber {
            number
            countryCode
          }
        }
      }
      terminalCashier {
        id
        cashier {
          id
          code
          nationalID
          name
          lastname
          userID
        }
      }
      productServices {
        items {
          id
          posDocumentID
          productService {
            id
            name
          }
          quantity
          price
          total
          taxes
        }
        nextToken
      }
    }
  }
`;
const PosPage = ({ data, currentUser, formatNumber }) => {
  const { company } = currentUser;

  const extraData = JSON.parse(data.data);

  const payments = {
    cash: extraData.payment.cashReceived ?? 0,
    debit: extraData.payment.debit ?? 0,
    credit: extraData.payment.credit ?? 0,
    transfer: extraData.payment.transfer ?? 0,
  };

  const returned =
    payments?.cash +
    payments?.credit +
    payments?.credit +
    payments?.transfer -
    data?.total;
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View>
          <Text style={[styles.headerText]}>{_.upperCase(company.name)}</Text>
          <Text style={[styles.headerText, { marginTop: '5px' }]}>
            {'NIT ' +
              company.TAXID +
              '-' +
              verificationDigit(company.TAXID.toString())}
          </Text>
          <Text style={[styles.headerText, { marginTop: '5px' }]}>
            {'Dir ' + _.upperCase(company.locations[0].address.address_line1)}
          </Text>
          <Text style={[styles.headerText, { marginTop: '5px' }]}>
            {'Tel ' + company.locations[0].phoneNumbers[0] &&
              company.locations[0].phoneNumbers[0].countryCode +
                ' ' +
                company.locations[0].phoneNumbers[0].number}
          </Text>
        </View>

        <View>
          <Text
            style={[
              styles.headerText,
              { marginTop: '5px', fontWeight: 'bold' },
            ]}
          >
            {`FACTURA DE VENTA N°: ${data?.code}`}
          </Text>

          <Text style={[styles.headerText, { marginTop: '5px' }]}>
            {`FECHA: ${moment(data?.date).format('DD/MM/YYYY hh:mm:ss A')}`}
          </Text>
          <Text style={[styles.headerText, { marginTop: '5px' }]}>
            {_.upperCase(currentUser?.company.configuration.regimenType.name)}
          </Text>
        </View>
        <View>
          <View style={{ marginTop: '15px' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.headerText, { width: '15%' }]}>CLIENTE</Text>

              <Text
                style={[
                  styles.headerText,
                  { marginTop: 0, marginBottom: 0, width: '80%' },
                ]}
              >
                {data?.client.businessName !== '' &&
                data?.client.businessName !== null
                  ? data?.client.businessName
                  : data?.client.name + ' ' + data?.client.lastname}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.headerText, { width: '15%' }]}>NIT</Text>

              <Text
                style={[styles.headerText, { marginTop: 0, marginBottom: 0 }]}
              >
                {data?.client.nationalID}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.headerText, { width: '15%' }]}>
                DIRECCIÓN
              </Text>

              <Text
                style={[
                  styles.headerText,
                  { marginTop: 0, marginBottom: 0, width: '80%' },
                ]}
              >
                {data?.client.locations[0].address.address_line1}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.headerText, { width: '15%' }]}>
                TELEFONO
              </Text>

              <Text
                style={[styles.headerText, { marginTop: 0, marginBottom: 0 }]}
              >
                {data?.client.contacts[0].phoneNumber &&
                  data?.client.contacts[0].phoneNumber.countryCode +
                    ' ' +
                    data?.client.contacts[0].phoneNumber.number}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.headerText, { width: '15%' }]}>
                VENDEDOR
              </Text>

              <Text
                style={[styles.headerText, { marginTop: 0, marginBottom: 0 }]}
              >
                {data?.terminalCashier?.cashier.name +
                  data?.terminalCashier?.cashier.lastname}
              </Text>
            </View>
          </View>
        </View>

        <View style={[{ marginTop: '15px' }]}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.headerText, { marginRight: '7px' }]}>
              CANT
            </Text>
            <Text style={[styles.headerText, { marginRight: '7px' }]}>
              PRECIO UNIT.
            </Text>
            <Text style={[styles.headerText]}>PRECIO TOTAL</Text>
          </View>
          <Svg height="10" width="200">
            <Line
              x1="0"
              y1="5"
              x2="280"
              y2="5"
              strokeWidth={2}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          {data.productServices.items?.map((itx, index) => (
            <View style={{ marginBottom: '7px' }} key={index}>
              <Text style={styles.headerText}>{itx.productService.name}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '10px',
                  marginTop: '5px',
                }}
              >
                <Text style={[styles.headerText, { marginRight: '10px' }]}>
                  {itx.quantity}
                </Text>
                <Text style={[styles.headerText, { marginRight: '10px' }]}>
                  {formatNumber(itx.price)}
                </Text>
                <Text style={styles.headerText}>{formatNumber(itx.total)}</Text>
              </View>
            </View>
          ))}
          <Svg height="10" width="200">
            <Line
              x1="0"
              y1="5"
              x2="280"
              y2="5"
              strokeWidth={2}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>SUBTOTAL</Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(
                extraData?.totalWithoutDiscount -
                  extraData?.totalIVA -
                  extraData?.totalINC
              )}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>IVA 19%</Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(extraData?.totalIVA)}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>DESCUENTO</Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(extraData?.totalWithoutDiscount - data?.total)}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Text style={[styles.headerText, { width: '25%' }]}>
            TOTAL FACTURA
          </Text>

          <Text
            style={[
              styles.headerText,
              { marginTop: 0, marginBottom: 0, width: '80%' },
            ]}
          >
            {formatNumber(data?.total)}
          </Text>
        </View>

        <View>
          <Text
            style={[
              styles.headerText,
              { marginBottom: '10px', marginLeft: '30px' },
            ]}
          >
            FORMA DE PAGO
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '3px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>
              TOTAL CANCELADO
            </Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(
                payments.cash + payments.credit,
                payments.credit + payments.transfer
              )}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '3px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>EFECTIVO</Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(payments.cash)}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '3px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>T. DEBITO</Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(payments.debit)}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '3px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>
              T. CREDITO
            </Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(payments.credit)}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '3px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>TRANSFER</Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(payments.transfer)}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '3px',
            }}
          >
            <Text style={[styles.headerText, { width: '25%' }]}>CAMBIO</Text>

            <Text
              style={[
                styles.headerText,
                { marginTop: 0, marginBottom: 0, width: '80%' },
              ]}
            >
              {formatNumber(returned > 0 ? returned : 0)}
            </Text>
          </View>
        </View>

        <View>
          <Text
            style={[
              styles.headerText,
              { marginTop: '10px', fontWeight: 'bold' },
            ]}
          >
            {`Resol Facturación POS ${extraData?.resolution.POSDocumentResolution}`}
          </Text>

          <Text style={[styles.headerText, { marginTop: '5px' }]}>
            {`Prefijo ${extraData.resolution.POSDocumentPrefix} desde ${extraData.resolution.rangeInit} hasta ${extraData.resolution.rangeEnd}`}
          </Text>
          <Text style={[styles.headerText, { marginTop: '5px' }]}>
            {`Fecha ${moment(extraData.resolution.initDate).format(
              'YYYY-DD-MM'
            )} - ${moment(extraData.resolution.endDate).format('YYYY-DD-MM')}`}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PosPage;
