import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { graphqlOperation, API, Storage } from 'aws-amplify';

import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  createProductService,
  updateProductService,
} from '../../../graphql/mutations';
import config from '../../../aws-exports';
import Vars from '../../../utils/deafultvars';
import { useGetProduct, useUnits, useTaxes } from '../../../shared/hooks';
import { cleanObject } from '../../../utils/formulas';
import { useMutation, useQueryClient } from 'react-query';
import queryKeys from '../../../shared/hooks/queryKeys';
import { selectWrapperView } from '../../../redux/wrapper/selectors';
import { TrackerEvent } from '../../../utils/logging';
import moment from 'moment';
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const getProductsByCompany = `
query GetCompany($id: ID!,$filter: ModelProductServiceFilterInput,$nextToken: String) {
  getCompany(id: $id) {
    productsServices(limit: 10000, filter: $filter, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
}
`;

const processPrices = (prices, tax) => {
  return prices.map(({ includedTax, ...price }) => {
    delete price['__typename'];
    return {
      ...price,
      value: (includedTax && tax > 0
        ? price.value / (tax / 100 + 1)
        : price.value
      ).toFixed(2),
    };
  });
};

export const schema = Yup.object({
  name: Yup.string().required('Nombre del producto requerido'),
  description: Yup.string(),
  active: Yup.boolean().oneOf([true, false]),
  prices: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().integer(),
        name: Yup.string().required('Nombre requerido'),
        value: Yup.number().required('Precio requerido').min(-1),
        administration: Yup.number().min(-1),
        unexpected: Yup.number().min(-1),
        utility: Yup.number().min(-1),
        includedTax: Yup.boolean().oneOf([true, false]),
      })
    )
    .required('Debe tener un precio')
    .min(1),
  tags: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Nombre requerido'),
      value: Yup.string(),
    })
  ),
  taxed: Yup.boolean().oneOf([true, false]),
  code: Yup.string().required('Codigo requerido'),
  quantity: Yup.number()
    .min(-1, 'Cantidad positiva')
    .integer('Debe ser un umero'),
  acceptableLimit: Yup.number().min(-1, 'Cantidad positiva'),
  productServiceUnitId: Yup.string().required('Unidad requerida'),
  videoURLs: Yup.array().of(Yup.string()),
  websites: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Campo es requerido'),
      url: Yup.string().required('Campo es requerido'),
    })
  ),
  vatPercentage: Yup.number(),
  defaultPrice: Yup.number().required('Precio por defecto requerido'),
  inced: Yup.boolean().oneOf([true, false]),
  incPercentage: Yup.number(),
  INC: Yup.number(),
});

export async function productMapper(item) {
  const product = {
    ...item,
    active: item.active ?? true,
  };
  Object.keys(product).forEach((key) => {
    if (product[key] === null) {
      product[key] = '';
    }
  });
  product.prices.forEach((key) =>
    Object.keys(key).forEach((val) => {
      if (key[val] === null) {
        key[val] = '';
      }
    })
  );

  const {
    name,
    description,
    prices,
    taxed,
    code,
    quantity,
    unit,
    type,
    tags,
    defaultPrice,
    vatPercentage,
    acceptableLimit,
    videoURLs,
    websites,
    vat,
    videos,
    photos,
    active,
    inced,
    incPercentage,
    INC,
  } = product;

  const videosUrl = [];
  const photosUrl = [];

  for (let photo of photos) {
    const url = await Storage.get(photo.key);
    photosUrl.push({
      url,
      type: 'image',
      key: photo.key,
    });
  }
  for (let video of videos) {
    const url = await Storage.get(video.key);
    videosUrl.push({
      url,
      type: 'video',
      key: video.key,
    });
  }
  return {
    name,
    description,
    prices,
    taxed,
    code,
    type,
    active: Boolean(active),
    quantity: Number(quantity),
    productServiceUnitId: unit ? unit.id : '',
    acceptableLimit: Number(acceptableLimit),
    vatPercentage,
    vat,
    inced: Boolean(inced),
    incPercentage: Number(incPercentage),
    INC: Number(INC),
    defaultPrice,
    websites: websites ? websites : [],
    videoURLs: videoURLs ? videoURLs : [],
    tags: tags ? tags : [],
    files: {
      items: [...photosUrl, ...videosUrl],
    },
  };
}

const initialValues = {
  name: '',
  description: '',
  active: true,
  prices: [
    {
      id: 0,
      name: '',
      value: 0,
      administration: 0,
      unexpected: 0,
      utility: 0,
      includedTax: false,
    },
  ],
  taxed: false,
  type: 'producto',
  code: '',
  quantity: 0,
  productServiceUnitId: Vars.unitProducts,
  acceptableLimit: 0,
  tags: [],
  vatPercentage: 0,
  inced: false,
  incPercentage: 0,
  INC: 0,
  defaultPrice: 0,
  videoURLs: [],
  websites: [],
  files: [],
};

export function useDefaultValues() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const queryClient = useQueryClient();
  const { units, isLoading: isLoadingUnits } = useUnits();
  const { taxes, isLoading: isLoadingTaxes } = useTaxes();
  const { product, isLoading: isLoadingProduct } = useGetProduct();

  const navigate = useNavigate();
  const isOpen = useSelector(selectWrapperView);
  const params = useParams();
  const updateProduct = useCallback(
    async (values) => {
      try {
        const data = { ...values };
        data['prices'] = processPrices(
          values['prices'],
          values['vatPercentage']
        );
        const mappedValues = {
          ...data,
          vat: data.taxed
            ? (data.prices[data.defaultPrice].value * data.vatPercentage) / 100
            : data.prices[data.defaultPrice].value,
        };
        delete mappedValues['files'];

        const input = cleanObject({
          ...mappedValues,
          id: params.id,
        });

        await API.graphql(
          graphqlOperation(updateProductService, {
            input,
          })
        );

        TrackerEvent({
          logCompanyId: currentUser.company.id,
          description: `Actualizaci\u00F3n ${values.type}`,
          timestamp: moment().toISOString(),
          operationType: 'ADMINISTRATIVE',
          subOperationType: 'PRODUCT_UPDATED',
          total: values.prices[values.defaultPrice].value,
          url: `/products/edit/${params.id}`,
          user: currentUser.id,
        });
        toast.info(`${_.capitalize(values.type)} actualizado!`);
      } catch (error) {
        console.error('error', error);
        toast.error('Error al actualizar');
      }
    },
    [currentUser.company.id, currentUser.id, params.id]
  );

  const createProduct = useCallback(
    async (values) => {
      try {
        let next = null;
        let prods = [];
        do {
          const prod = await API.graphql(
            graphqlOperation(getProductsByCompany, {
              id: currentUser.company.id,
              filter: {
                code: { eq: values.code },
              },
              nextToken: next,
            })
          );
          prods.push(...prod.data.getCompany.productsServices.items);
          next = prod.data.getCompany.productsServices.nextToken;
        } while (next);

        if (prods.length > 0) {
          return toast.error('El Codigo ya esta en uso.');
        }

        const mappedValues = Object.keys(values)
          .filter((k) => values[k] !== '')
          .reduce((a, k) => ({ ...a, [k]: values[k] }), {});

        for (let price of mappedValues.prices) {
          Object.keys(price).forEach((k) => price[k] === '' && delete price[k]);
        }
        mappedValues['prices'] = processPrices(
          mappedValues['prices'],
          mappedValues['vatPercentage']
        );

        const input = {
          ...mappedValues,
          taxed: values.vatPercentage > -1,
          inced: values.incPercentage > 0,
          vat:
            values.vatPercentage > -1
              ? (values.prices[values.defaultPrice].value *
                  values.vatPercentage) /
                100
              : values.prices[values.defaultPrice].value,
          INC:
            values.incPercentage > 0
              ? (values.prices[values.defaultPrice].value *
                  values.incPercentage) /
                100
              : values.prices[values.defaultPrice].value,

          productServiceCompanyId: currentUser.company.id,
          readers: currentUser.company?.readers,
        };
        delete input['files'];

        const prod = await API.graphql(
          graphqlOperation(createProductService, {
            input,
          })
        );
        if (values.files.length > 0) {
          const type = values.type === 'producto' ? 'products' : 'services';
          let photos = [];
          let videos = [];
          for (let file of values.files) {
            const { key } = await Storage.put(
              `${currentUser.company.id}/${type}/${
                prod.data.createProductService.id
              }/${uuid()}.${file.type.split('/')[1]}`,
              file,
              { contentType: file.type }
            );
            if (file.name.match(/\.(?:jpe?g|png)$/)) {
              photos.push({
                bucket,
                key,
                region,
              });
            } else if (file.name.match(/\.(?:mov|avi|wmv|flv|3gp|mp4|mpg)$/)) {
              videos.push({
                bucket,
                key,
                region,
              });
            }
          }
          const product = await API.graphql(
            graphqlOperation(updateProductService, {
              input: {
                id: prod.data.createProductService.id,
                photos,
                videos,
              },
            })
          );
        }

        TrackerEvent({
          logCompanyId: currentUser.company.id,
          description: `Creaci\u00F3n ${values.type}`,
          timestamp: moment().toISOString(),
          operationType: 'ADMINISTRATIVE',
          subOperationType: 'PRODUCT_CREATED',
          total: values.prices[values.defaultPrice].value,
          url: `/products/edit/${prod.data.createProductService.id}`,
          user: currentUser.id,
        });

        toast.info(`Item creado!`);
        if (!isOpen)
          navigate(`/products/edit/${prod.data.createProductService.id}`);
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    [currentUser.company.id, currentUser.id, isOpen, navigate]
  );
  const { mutate } = useMutation(params.id ? updateProduct : createProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.products]);
    },
  });
  return {
    isLoading: isLoadingTaxes || isLoadingUnits || isLoadingProduct,
    formValues: {
      units,
      taxs: taxes,
      defaultValues: _.isEmpty(product) ? initialValues : product,
    },
    submit: mutate,
    status: params.id ? 'Actualizar' : 'Crear',
    editing: !!params.id,
  };
}
