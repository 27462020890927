const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  CREATE_COMPANY: 'CREATE_COMPANY',
  SIGN_UP_SUCCESS_VERIFY: 'SIGN_UP_SUCCESS_VERIFY',
  USER_NOT_VERIFIED: 'USER_NOT_VERIFIED',
  USER_VERIFIED_SUCCESS: 'USER_VERIFIED_SUCCESS',
  USER_VERIFIED_START: 'USER_VERIFIED_START',
  USER_VERIFIED_SIGN_UP: 'USER_VERIFIED_SIGN_UP',
};

export default UserActionTypes;
