export const companiesByTAXID = /* GraphQL */ `
  query CompaniesByTAXID(
    $TAXID: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByTAXID(
      TAXID: $TAXID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        TAXID
        users {
          items {
            id
            email
            name
          }
        }
      }
      nextToken
    }
  }
`;
