import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Input, Row } from 'reactstrap';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';

import CardProduct from './components/CardProduct';
import {
  createCashier,
  createPOSDocument,
  createPOSDocumentProductService,
  createTerminalCashier,
  deletePOSDocumentProductService,
  updatePOSDocument,
  updateTerminal,
  updateTerminalCashier,
} from '../../../graphql/mutations';

import { useDispatch, useSelector } from 'react-redux';
import Form from './Form/Form';
import TurnOnModal from './components/turnOnModal';
import { closeTurn, openTurn } from '../../../redux/pos/pos.actions';
import TurnOffModal from './components/turnOfffodal';
import Swal from 'sweetalert2';
import { getPOS } from '../terminals/utils/queries';
import { getCompanyPOS } from '../../Configurations/Pos/utils/queries';
import { useProducts } from '../../../shared/hooks';
import { toast } from 'react-toastify';
import Loading from '../../../shared/components/Loading';
import PosPage, { getPOSDocumentPDF } from './components/PosPDF';
import { pdf } from '@react-pdf/renderer';
import printJS from 'print-js';
import { useParams } from 'react-router-dom';
import { addDocument } from '../../../utils/addDocument';
import { setPlanData } from '../../../redux/myPlan/plan.actions';
import { useCustomers } from '../../../shared/hooks/Customers';
import { verificationDigit } from '../../../utils/formulas';
import axios from 'axios';
import { formatNumber } from '../../../utils/formulas';
import { cashiersByNationalID } from '../../../graphql/queries';

export const getTerminalCashier = /* GraphQL */ `
  query GetTerminalCashier($id: ID!) {
    getTerminalCashier(id: $id) {
      id
      terminalID
      cashierID
      terminal {
        id
        code
        extraData
        resolution {
          name
          branchCode
          POSDocumentPrefix
          initDate
          endDate
          rangeInit
          rangeEnd
          technicalKey
          currentPOSDocumentNumber
          POSDocumentResolution
        }
        notesResolution {
          name
          branchCode
          POSDocumentPrefix
          initDate
          endDate
          rangeInit
          rangeEnd
          technicalKey
          currentPOSDocumentNumber
          POSDocumentResolution
        }
        invoiceResolution {
          name
          branchCode
          POSDocumentPrefix
          initDate
          endDate
          rangeInit
          rangeEnd
          technicalKey
          currentPOSDocumentNumber
          POSDocumentResolution
        }
      }
    }
  }
`;

const FormDashboard = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const currentUser = useSelector((state) => state.user.currentUser);
  const myPlan = useSelector((state) => state.myPlan);
  const turnPos = useSelector((state) => state.pos);
  const { customers } = useCustomers();
  const { products, isLoading } = useProducts();
  const [productsFilter, setProductsFilter] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalOff, setModalOff] = useState(false);
  const [totalCashBefore, setTotalCashBefore] = useState(null);
  const [cash, setCash] = useState(null);
  const [posDataOptions, setPosDataOptions] = useState([]);
  const [terminalOptions, setTerminalOptions] = useState([]);
  const [selectPos, setSelectPos] = useState(null);
  const [selectTerminal, setSelectTerminal] = useState(null);
  const [selectProducts, setSelectProducts] = useState([]);
  const [customerID, setCustomerID] = useState(null);
  const [salesman, setSalesman] = useState(null);
  const [currentPOS, setCurrentPOS] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [observationTurn, setObservationTurn] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoiceType, setInvoiceType] = useState(1);
  const [cashierId, setCashierId] = useState(null);

  useEffect(() => {
    if (turnPos?.modal === 'openTurn') {
      const currentDate = moment().format('HH:mm:ssZ');
      const dateInvalid = moment(turnPos?.hour).format('HH:mm:ssZ');
      const comparation = currentDate <= dateInvalid;
      setModal(comparation);
      setModalOff(false);
    } else if (turnPos?.modal === 'closeTurn') {
      setModalOff(true);
      setModal(false);
    } else {
      setModal(false);
      setModalOff(false);
    }
  }, [turnPos, setModal, dispatch]);

  useEffect(() => {
    fectPosTerminals();
    if (selectPos) getTerminalsOptions(selectPos);
  }, [selectPos]);

  useEffect(() => {
    (async () => {
      if (!currentUser?.cashierID) {
        const cashierUser = await API.graphql(
          graphqlOperation(cashiersByNationalID, {
            nationalID: currentUser.nationalID,
          })
        );

        if (!cashierUser.data.cashiersByNationalID.items.length) {
          const createCashierM = await API.graphql(
            graphqlOperation(createCashier, {
              input: {
                cashierCompanyId: currentUser.company.id,
                cashierIdTypeId: '51f071d8-58d8-4e0f-b6a5-766c0c158683',
                lastname: currentUser.lastname,
                name: currentUser.name,
                userID: currentUser.id,
                nationalID: currentUser.nationalID,
              },
            })
          );
          setCashierId(createCashierM.data.createCashier.id);
        } else {
          setCashierId(cashierUser.data.cashiersByNationalID.items?.[0]?.id);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (params?.id) {
      setDataEdit();
    }
  }, []);

  const setDataEdit = async () => {
    const res = await getDocumentById(params.id);
    setCurrentPOS(res);
    res?.productServices.items.forEach((item) =>
      addProduct({
        id: item.productService.id,
        name: item.productService.name,
        price: item.price,
        quantity: item.quantity,
        incPercentageValue: JSON.parse(item.taxes).incPercentageValue,
        vatPercentageValue: JSON.parse(item.taxes).vatPercentageValue,
        total: item.total,
        totalINC: JSON.parse(item.taxes).totalINC,
        totalIVA: JSON.parse(item.taxes).totalIVA,
      })
    );
    setCustomerID(res.client.id);
    const payInfo = JSON.parse(res.data).payment;
    delete payInfo.type;
    delete payInfo.isDiscountPercentage;
    setPaymentInfo(payInfo);
  };

  const handleOpenTurn = async () => {
    try {
      const createTurn = await API.graphql(
        graphqlOperation(createTerminalCashier, {
          input: {
            cashBefore: totalCashBefore ?? 0,
            cashAfterDetail: JSON.stringify(cash),
            cashierID: currentUser?.cashierID ?? cashierId,
            initDateTime: moment().format(),
            terminalID: selectTerminal,
            observations: observationTurn,
          },
        })
      );

      dispatch(openTurn(createTurn.data.createTerminalCashier.id));
      toast.info(`Turno iniciado a las ${moment().format('HH:mm:ss')}`);
    } catch (error) {
      console.log('error', error);
    } finally {
      setSelectPos(null);
      setSelectTerminal(null);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeTurn());
    setSelectPos(null);
    setSelectTerminal(null);
  };
  const handleCloseTurn = async (values) => {
    Swal.fire({
      title: '¿Estas seguro de terminar tu turno?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, terminar turno',
      cancelButtonText: 'No, Cancelar',
    }).then(async (result) => {
      if (result.value) {
        try {
          await API.graphql(
            graphqlOperation(updateTerminalCashier, {
              input: {
                id: turnPos?.idTurn,
                endDateTime: moment().format(),
                cashAfter: totalCashBefore ?? 0,
                cashAfterDetail: JSON.stringify(cash),
              },
            })
          );
          dispatch(closeTurn());

          Swal.fire('Turno finalizado!', '', 'success');
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const fectPosTerminals = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getCompanyPOS, {
          id: currentUser?.company.id,
        })
      );
      const lisOptions = data.getCompany.pos.items.map((item) => {
        return { value: item.id, label: item.code, key: item.id };
      });
      setPosDataOptions(lisOptions);
    } catch (error) {
      console.log(error);
    }
  };
  const getTerminalsOptions = async (id) => {
    try {
      const getTerminals = await API.graphql(
        graphqlOperation(getPOS, {
          id,
        })
      );

      const listOptions = getTerminals.data.getPOS.terminals.items.map(
        (item) => {
          return { value: item.id, label: item.code, key: item.id };
        }
      );

      setTerminalOptions(listOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSelectPos = (value) => {
    setSelectPos(value);
  };
  const handleChangeTerminal = (value) => {
    setSelectTerminal(value);
  };

  const onCancel = () => {
    dispatch(openTurn(turnPos?.idTurn));
  };

  const addProduct = (item) => {
    if (!turnPos.idTurn) {
      toast.info('Debes iniciar tu turno!');
    } else {
      const existProduct = selectProducts.find((prod) => prod.id === item.id);
      if (existProduct) {
        addQuantity(existProduct);
      } else {
        setSelectProducts((prev) => [...prev, item]);
      }
    }
  };

  const removeProduct = (product) => {
    setSelectProducts((prev) => [
      ...prev.filter((item) => item.id !== product.id),
    ]);
  };
  const addQuantity = (product) => {
    const indexProduct = selectProducts.indexOf(product);
    const currentQuantity = selectProducts[indexProduct]?.quantity;

    const newData = selectProducts.map((item) => {
      if (item.id === product.id) {
        return {
          ...item,
          quantity: currentQuantity + 1,
          total: item.price * (currentQuantity + 1),
          totalINC:
            item.incPercentageValue > 0
              ? (item.price * (currentQuantity + 1) * item.incPercentageValue) /
                100
              : 0,
          totalIVA:
            item.vatPercentageValue > 0
              ? (item.price * (currentQuantity + 1) * item.vatPercentageValue) /
                100
              : 0,
        };
      }
      return item;
    });

    setSelectProducts(newData);
  };
  const removeQuantity = (product) => {
    const indexProduct = selectProducts.indexOf(product);
    const currentQuantity = selectProducts[indexProduct]?.quantity;

    const newData = selectProducts.map((item) => {
      if (item.id === product.id) {
        if (currentQuantity > 1) {
          return {
            ...item,
            quantity: currentQuantity - 1,
            total: item.price * (currentQuantity - 1),
            totalINC:
              item.incPercentageValue > 0
                ? (item.price *
                    (currentQuantity - 1) *
                    item.incPercentageValue) /
                  100
                : 0,
            totalIVA:
              item.vatPercentageValue > 0
                ? (item.price *
                    (currentQuantity - 1) *
                    item.vatPercentageValue) /
                  100
                : 0,
          };
        }
      }
      return item;
    });

    setSelectProducts(newData);
  };

  const handleCreatePosDocument = async (data, isDraft) => {
    try {
      setLoading(true);
      const { total, payment, totalWithoutDiscount, isDiscountPercentage } =
        data;
      const getTerminalCashierQ = await API.graphql(
        graphqlOperation(getTerminalCashier, {
          id: turnPos.idTurn,
        })
      );
      const resultTerminal = getTerminalCashierQ.data.getTerminalCashier;

      const { POSDocumentPrefix, currentPOSDocumentNumber } =
        resultTerminal.terminal.resolution;

      const {
        POSDocumentPrefix: prefixNC,
        currentPOSDocumentNumber: numberNC,
      } = resultTerminal.terminal.notesResolution;

      const currentNumber = POSDocumentPrefix + currentPOSDocumentNumber;
      const currentNumberNC = prefixNC + numberNC;

      const extraData = {
        payment,
        resolution: params?.id
          ? resultTerminal.terminal.notesResolution
          : resultTerminal.terminal.resolution,
        totalWithoutDiscount,
        isDiscountPercentage,
        totalIVA: _.sumBy(selectProducts, 'totalIVA'),
        totalINC: _.sumBy(selectProducts, 'totalINC'),
      };
      if (params?.id) {
        currentPOS.productServices.items.forEach(async (item) => {
          await API.graphql(
            graphqlOperation(deletePOSDocumentProductService, {
              input: {
                id: item.id,
              },
            })
          );
        });

        await API.graphql(
          graphqlOperation(updatePOSDocument, {
            input: {
              id: params.id,
              total,
              code: currentNumberNC,
              pOSDocumentClientId: customerID,
              data: JSON.stringify({ ...extraData, dataBeforeNC: currentPOS }),
              date: moment().format(),
              salesman: salesman
                ? salesman
                : currentUser?.cashierID ?? cashierId,
              salesmanType: salesman ? 'SALESMAN' : 'CASHIER',
              type: 'CreditNote',
              status: 'SENT',
            },
          })
        );

        selectProducts.forEach(async (item) => {
          await API.graphql(
            graphqlOperation(createPOSDocumentProductService, {
              input: {
                posDocumentID: params.id,
                price: item.price,
                productServiceID: item.id,
                quantity: item.quantity,
                total: item.total,
                taxes: JSON.stringify({
                  totalIVA: item.totalIVA,
                  vatPercentageValue: item.vatPercentageValue,
                  totalINC: item.totalINC,
                  incPercentageValue: item.incPercentageValue,
                }),
              },
            })
          );
        });

        await API.graphql(
          graphqlOperation(updateTerminal, {
            input: {
              id: resultTerminal.terminal.id,
              notesResolution: {
                ...resultTerminal.terminal.notesResolution,
                currentPOSDocumentNumber: Number(numberNC) + 1,
              },
            },
          })
        );

        const documentData = await getDocumentById(params.id);

        const blob = await pdf(
          <PosPage data={documentData} {...{ currentUser, formatNumber }} />
        ).toBlob();

        const objectURL = URL.createObjectURL(blob);

        printJS(objectURL);
        toast.success('Documento POS actualizado');
      } else {
        if (invoiceType === 2) {
          if (resultTerminal?.terminal?.invoiceResolution) {
            const resService = await invoiceService({
              resolution: resultTerminal.terminal.invoiceResolution,
              total,
              totalWithoutDiscount,
              payment: payment,
            });

            if (resService?.data?.data?.cadena?.statusCode === '200') {
              await API.graphql(
                graphqlOperation(updateTerminal, {
                  input: {
                    id: resultTerminal.terminal.id,
                    invoiceResolution: {
                      ...resultTerminal.terminal.invoiceResolution,
                      currentPOSDocumentNumber:
                        Number(
                          resultTerminal.terminal.invoiceResolution
                            .currentPOSDocumentNumber
                        ) + 1,
                    },
                  },
                })
              );
              addDocument({
                currentUser,
                typeDocument: 'invoices',
              });

              toast.success('Documento POS enviado');
            } else {
              toast.error('Algo salio mal, intente mas tarde');
            }
          } else {
            return toast.error(
              'La terminal no tiene resolucion para facturación electrónica!'
            );
          }
        } else {
          const createDocument = await API.graphql(
            graphqlOperation(createPOSDocument, {
              input: {
                pOSDocumentTerminalCashierId: turnPos?.idTurn,
                pOSDocumentCompanyId: currentUser.company.id,
                total,
                code: isDraft
                  ? `BORR${_.random(100000, 9999999)}`
                  : currentNumber,
                pOSDocumentClientId: customerID,
                data: JSON.stringify(extraData),
                date: moment().format(),
                salesman: salesman
                  ? salesman
                  : currentUser?.cashierID ?? cashierId,
                salesmanType: salesman ? 'SALESMAN' : 'CASHIER',
                type: 'default',
                status: isDraft ? 'DRAFT' : 'SENT',
              },
            })
          );

          selectProducts.forEach(async (item) => {
            await API.graphql(
              graphqlOperation(createPOSDocumentProductService, {
                input: {
                  posDocumentID: createDocument.data.createPOSDocument.id,
                  price: item.price,
                  productServiceID: item.id,
                  quantity: item.quantity,
                  total: item.total,
                  taxes: JSON.stringify({
                    totalIVA: item.totalIVA,
                    vatPercentageValue: item.vatPercentageValue,
                    totalINC: item.totalINC,
                    incPercentageValue: item.incPercentageValue,
                  }),
                },
              })
            );
          });

          if (!isDraft) {
            await API.graphql(
              graphqlOperation(updateTerminal, {
                input: {
                  id: resultTerminal.terminal.id,
                  resolution: {
                    ...resultTerminal.terminal.resolution,
                    currentPOSDocumentNumber:
                      Number(currentPOSDocumentNumber) + 1,
                  },
                },
              })
            );

            const documentData = await getDocumentById(
              createDocument.data.createPOSDocument.id
            );

            const blob = await pdf(
              <PosPage data={documentData} {...{ currentUser, formatNumber }} />
            ).toBlob();

            const objectURL = URL.createObjectURL(blob);

            printJS(objectURL);
            toast.success('Documento POS creado');
          }
        }
      }

      setSelectProducts([]);
      setCurrentPOS(null);
      setPaymentInfo({});
      setInvoiceType(1);
      const dataPlan = await addDocument({
        myPlan,
        typeDocument: 'documentPOS',
      });
      dataPlan && dispatch(setPlanData(dataPlan));
    } catch (error) {
      console.log('error crear posdocument', error);
    } finally {
      setLoading(false);
    }
  };

  const filterProducts = (name) => {
    if (name !== '') {
      const result = products.filter(
        (el) => el.name.toLowerCase().indexOf(name.toLowerCase()) > -1
      );

      setProductsFilter(result);
    } else {
      setProductsFilter(products);
    }
  };

  const handleChangeInput = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setCash((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const getDocumentById = useCallback(async (id) => {
    try {
      const getDocument = await API.graphql(
        graphqlOperation(getPOSDocumentPDF, {
          id,
        })
      );
      return getDocument.data.getPOSDocument;
    } catch (error) {
      console.log('error get document by id', error);
    }
  }, []);

  const invoiceService = async (data) => {
    const session = await Auth.currentSession();
    const jwtToken = session.idToken.jwtToken;
    const { resolution, total, totalWithoutDiscount, payment } = data;
    const customerInfo = customers?.find((cus) => cus.id === customerID);
    let taxTotalsData = [];
    selectProducts.map((prod) => {
      prod.incPercentageValue !== 0 &&
        taxTotalsData.push({
          tax_code: '04',
          percent: prod.incPercentageValue,
          tax_amount: prod.totalINC,
          taxable_amount: prod.price,
        });

      prod.taxed === 'Si' &&
        taxTotalsData.push({
          tax_code: '01',
          percent: prod.vatPercentageValue,
          tax_amount: prod.totalIVA,
          taxable_amount: prod.price,
        });
    });
    const body = {
      //resolution invoice
      init_date: moment(resolution.initDate).format('YYYY-MM-DD'),
      end_date: moment(resolution.endDate).format('YYYY-MM-DD'),
      range_init: resolution.rangeInit,
      range_end: resolution.rangeEnd,
      number: resolution.currentPOSDocumentNumber,
      prefix: resolution.POSDocumentPrefix,
      technical_key: resolution.technicalKey,
      resolution_number: resolution.POSDocumentResolution,

      // de prueba
      /*       init_date: '2019-01-19',
      end_date: '2030-01-19',
      range_init: 1,
      range_end: 5000000,
      number: 100502,
      prefix: 'SETT',
      technical_key: 'fc8eac422eba16e22ffd8c6f94b3f40a6e38162c',
      resolution_number: '18760000001', */

      document_type_code: '01',
      identification_number: currentUser.company.TAXID,
      operation_type_id: 'e44ac6b6-c10a-4663-9a99-dd463a65f6e7',
      operation_type_code: 10,
      currency_type_code: 'COP',
      terminal_id: turnPos?.idTurn,
      //customer
      customer: {
        customer_id: customerInfo.id,
        identification_type_code: customerInfo.idType?.code,
        identification_number: customerInfo.nationalID,
        dv: verificationDigit(customerInfo.nationalID),
        name: customerInfo.fullName,
        phone: parseInt(customerInfo.contacts.phoneNumber.number),
        email: customerInfo.contacts.email,
        //merchant_registration
        address: customerInfo.location[0].address.address_line1,
        organization_type_code: currentUser.personType === 'natural' ? 2 : 1,
        country_code: 'CO',
        municipality_code: customerInfo.location[0].address.cityCode ?? '',
        regime_type_code: customerInfo.regimenType.code,
        liability_type_code: customerInfo.fiscalResponsability.code,
        tax_code: 'ZZ',
      },
      //payment form
      payment_form: [
        {
          payment_method_id: '981d6c2f-62d9-48c6-afcf-30babdc32b19',
          payment_form_code: 1,
          payment_method_code: 'ZZZ',
          payment_due_date: moment().format('YYYY-MM-DD'),
        },
      ],

      //tax_totals
      tax_totals: taxTotalsData,

      //legal_monetary_totals
      legal_monetary_totals: {
        line_extension_amount: _.sumBy(selectProducts, 'price'),
        tax_exclusive_amount: _.sumBy(selectProducts, 'price'),
        tax_inclusive_amount: total, // total precio con impuesto
        allowance_total_amount: total - totalWithoutDiscount, //descuentos
        charge_total_amount: 0.0,
        payable_amount: total,
      },

      //invoice_lines
      invoice_lines: selectProducts.map((prod) => {
        let prodLine = {
          product_service_id: prod.id,
          unit_measure_code: prod.unit.code,
          invoiced_quantity: prod.quantity,
          line_extension_amount: prod.total,
          free_of_charge_indicator: false,
          code: prod.code,
          item_identification_type_code: 999,
          price_amount: prod.price,
          base_quantity: 1,
          description: prod.description ?? prod.name,
        };

        let tax_totals = [];
        prod.incPercentageValue !== 0 &&
          tax_totals.push({
            tax_code: '04',
            percent: prod.incPercentageValue,
            tax_amount: prod.totalINC,
            taxable_amount: prod.price,
          });

        prod.taxed === 'Si' &&
          tax_totals.push({
            tax_code: '01',
            percent: prod.vatPercentageValue,
            tax_amount: prod.totalIVA,
            taxable_amount: prod.price,
          });

        if (tax_totals.length > 0) prodLine.tax_totals = tax_totals;
        return prodLine;
      }),
    };

    try {
      const req = await axios.post(
        '/invoiceService',
        { invoice: body },
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Origin': '*',
            AuthorizationToken: jwtToken,
          },
        }
      );
      return req;
    } catch (error) {
      return error;
    }
  };

  const validateEnableButton = selectProducts.length > 0 && !!customerID;

  return (
    <Container className="dashboard">
      <TurnOnModal
        modal={modal}
        handleCancelTurn={handleCloseModal}
        {...{
          handleOpenTurn,
          totalCashBefore,
          setTotalCashBefore,
          cash,
          setCash,
          posDataOptions,
          terminalOptions,
          selectPos,
          selectTerminal,
          handleChangeSelectPos,
          handleChangeTerminal,
          setObservationTurn,
          handleChangeInput,
        }}
      />
      <TurnOffModal
        modal={modalOff}
        statePOS={turnPos}
        {...{
          handleCloseTurn,
          onCancel,
          formatNumber,
          totalCashBefore,
          setObservationTurn,
          handleChangeInput,
        }}
      />

      <Row>
        <Col md={12} xl={6} lg={12} xs={12} className="container-products">
          <Row
            className="mb-4 mt-3 container-card-product-form"
            style={{ position: 'sticky', top: 5, zIndex: 1 }}
          >
            <Col md={6} xl={6} lg={6} xs={12}>
              <h4 className="bold-text">
                Productos ({(productsFilter ? productsFilter : products).length}
                )
              </h4>
            </Col>
            <Col md={6} xl={6} lg={6} xs={12}>
              <Input
                size="sm"
                placeholder="Buscar producto"
                onChange={(e) => filterProducts(e.target.value)}
                disabled={isLoading}
              />
            </Col>
          </Row>
          <Row>
            {isLoading && <Loading />}
            {!isLoading &&
              (productsFilter ? productsFilter : products).map((item) => (
                <CardProduct product={item} onClick={() => addProduct(item)} />
              ))}
          </Row>
        </Col>

        <Col md={12} xl={6} lg={12} xs={12}>
          <Form
            products={selectProducts}
            {...{
              removeProduct,
              addQuantity,
              removeQuantity,
              dispatch,
              handleCreatePosDocument,
              setCustomerID,
              customerID,
              validateEnableButton,
              loading,
              formatNumber,
              setSalesman,
              currentPOS,
              paymentInfo,
              setPaymentInfo,
              invoiceType,
              setInvoiceType,
              customers,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FormDashboard;
