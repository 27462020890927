import WrapperActionTypes from './types';

const INITIAL_STATE = {
  active: false,
  View: null,
  props: {
    title: '',
  },
};

const wrapperReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WrapperActionTypes.ACTIVE_VIEW:
      return {
        ...state,
        View: action.payload.View,
        active: true,
        props: action.payload.props,
      };
    case WrapperActionTypes.DESACTIVE_VIEW:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
export default wrapperReducer;
