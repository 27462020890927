import ActionPosTypes from './pos.types';

const INITIAL_STATE = {
  turnActive: false,
  idTurn: null,
  modal: null,
};

const posReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionPosTypes.OPEN_TURN:
      return { turnActive: true, idTurn: action.payload, modal: null };
    case ActionPosTypes.CLOSE_TURN:
      return { idTurn: null, turnActive: false, modal: null };
    case ActionPosTypes.CHANGE_MODAL_OPEN:
      return { ...state, turnActive: true, modal: action.payload };
    case ActionPosTypes.CHANGE_MODAL_CLOSE:
      return { ...state, turnActive: null, modal: action.payload };
    default:
      return state;
  }
};
export default posReducer;
