/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      TAXID
      verificationDigit
      personType
      constitutionDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      isKeyPartner
      tags {
        name
        value
        __typename
      }
      companyMainRole
      signatures {
        fullName
        prefix
        title
        __typename
      }
      defaultSignature
      bankingEnabled
      restrictiveLists
      PEPS
      PEPSCategory
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      referrals {
        name
        email
        date
        __typename
      }
      lastDateOfReferral
      readers
      editors
      createdAt
      updatedAt
      owner
      configuration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      plan {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      documents {
        nextToken
        __typename
      }
      industries {
        nextToken
        __typename
      }
      forms {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      badges {
        nextToken
        __typename
      }
      supportTickets {
        nextToken
        __typename
      }
      groupCategories {
        nextToken
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      taxes {
        nextToken
        __typename
      }
      logging {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      terminals {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      cashiers {
        nextToken
        __typename
      }
      salesmen {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      epayroll {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      bags {
        nextToken
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      productServiceTypes {
        nextToken
        __typename
      }
      supplierProductsServices {
        nextToken
        __typename
      }
      keyPartnersProductsServices {
        nextToken
        __typename
      }
      customers {
        nextToken
        __typename
      }
      suppliers {
        nextToken
        __typename
      }
      patients {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      commissionsAndTips {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesByName = /* GraphQL */ `
  query CompaniesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesByTAXID = /* GraphQL */ `
  query CompaniesByTAXID(
    $TAXID: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByTAXID(
      TAXID: $TAXID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicCompany = /* GraphQL */ `
  query GetPublicCompany($id: ID!) {
    getPublicCompany(id: $id) {
      id
      name
      url
      description
      lemma
      industries
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      defaultContact
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      bannerPhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      productServices
      readers
      editors
      createdAt
      updatedAt
      owner
      posts {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPublicCompanys = /* GraphQL */ `
  query ListPublicCompanys(
    $filter: ModelPublicCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicCompaniesByName = /* GraphQL */ `
  query PublicCompaniesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPublicCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicCompaniesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicCompaniesByURL = /* GraphQL */ `
  query PublicCompaniesByURL(
    $url: String
    $sortDirection: ModelSortDirection
    $filter: ModelPublicCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicCompaniesByURL(
      url: $url
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyConfiguration = /* GraphQL */ `
  query GetCompanyConfiguration($id: ID!) {
    getCompanyConfiguration(id: $id) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        __typename
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        __typename
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
        __typename
      }
      branches {
        name
        code
        description
        currentBillNumber
        __typename
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      adjustmentNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      decimals
      decimalsQuantity
      emailForInvoicing
      eInvoiceHeaderExtraFields {
        name
        value
        __typename
      }
      eInvoiceFooterExtraFields {
        name
        value
        __typename
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      currentEventsNumber
      billsReceiverData
      sendSuppliersEmail
      quotationsPrefixAndNumbering
      cashReceiptsPrefixAndNumbering
      dischargeReceiptsPrefixAndNumbering
      signInvoices
      signSupportDocuments
      signCashReceipts
      signDischargeReceipt
      adjustTaxBase
      healthProvider
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      productServicesConfiguration
      showBatchAndExpirationDate
      warehousesConfiguration
      pointOfSalesConfiguration
      terminalsConfiguration
      POSEnabled
      POSResolutions
      cashiersConfiguration
      salesmenConfiguration
      POSDocumentConfiguration
      defaultToInvoiceIfHigherValue
      storagePath
      loggingVerbosityLevel
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsabilities {
        nextToken
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyConfigurations = /* GraphQL */ `
  query ListCompanyConfigurations(
    $filter: ModelCompanyConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyConfigurationFiscalResponsability = /* GraphQL */ `
  query GetCompanyConfigurationFiscalResponsability($id: ID!) {
    getCompanyConfigurationFiscalResponsability(id: $id) {
      id
      companyConfigurationID
      fiscalResponsabilityID
      readers
      editors
      createdAt
      updatedAt
      companyConfiguration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyConfigurationFiscalResponsabilitys = /* GraphQL */ `
  query ListCompanyConfigurationFiscalResponsabilitys(
    $filter: ModelCompanyConfigurationFiscalResponsabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyConfigurationFiscalResponsabilitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyConfigurationID
        fiscalResponsabilityID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMyPlan = /* GraphQL */ `
  query GetMyPlan($id: ID!) {
    getMyPlan(id: $id) {
      id
      initDate
      endDate
      active
      data
      log
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listMyPlans = /* GraphQL */ `
  query ListMyPlans(
    $filter: ModelMyPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyDocument = /* GraphQL */ `
  query GetCompanyDocument($id: ID!) {
    getCompanyDocument(id: $id) {
      id
      location {
        bucket
        region
        key
        __typename
      }
      date
      expires
      validUntil
      public
      metadata
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyDocuments = /* GraphQL */ `
  query ListCompanyDocuments(
    $filter: ModelCompanyDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        expires
        validUntil
        public
        metadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyIndustry = /* GraphQL */ `
  query GetCompanyIndustry($id: ID!) {
    getCompanyIndustry(id: $id) {
      id
      companyID
      industryID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      industry {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyIndustrys = /* GraphQL */ `
  query ListCompanyIndustrys(
    $filter: ModelCompanyIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyIndustrys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        industryID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyForm = /* GraphQL */ `
  query GetCompanyForm($id: ID!) {
    getCompanyForm(id: $id) {
      id
      companyID
      formID
      answers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      form {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyForms = /* GraphQL */ `
  query ListCompanyForms(
    $filter: ModelCompanyFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        formID
        answers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      timestamp
      owner
      type
      title
      message
      data
      url
      callback
      callbackParams
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        owner
        type
        title
        message
        data
        url
        callback
        callbackParams
        status
        readers
        editors
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByStatusTimestamp = /* GraphQL */ `
  query NotificationsByStatusTimestamp(
    $status: NOTIFICATION_STATUS
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByStatusTimestamp(
      status: $status
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        owner
        type
        title
        message
        data
        url
        callback
        callbackParams
        status
        readers
        editors
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyBadge = /* GraphQL */ `
  query GetCompanyBadge($id: ID!) {
    getCompanyBadge(id: $id) {
      id
      companyID
      badgeID
      assigned
      active
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      badge {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyBadges = /* GraphQL */ `
  query ListCompanyBadges(
    $filter: ModelCompanyBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        badgeID
        assigned
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupportTicket = /* GraphQL */ `
  query GetSupportTicket($id: ID!) {
    getSupportTicket(id: $id) {
      id
      subject
      description
      assignedTo
      messages {
        timestamp
        authorName
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listSupportTickets = /* GraphQL */ `
  query ListSupportTickets(
    $filter: ModelSupportTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        description
        assignedTo
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ticketsByStatus = /* GraphQL */ `
  query TicketsByStatus(
    $status: supportTicketStatus
    $sortDirection: ModelSortDirection
    $filter: ModelSupportTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        description
        assignedTo
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ticketsByAssignedTo = /* GraphQL */ `
  query TicketsByAssignedTo(
    $assignedTo: String
    $sortDirection: ModelSortDirection
    $filter: ModelSupportTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByAssignedTo(
      assignedTo: $assignedTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        description
        assignedTo
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMyGroupCategory = /* GraphQL */ `
  query GetMyGroupCategory($id: ID!) {
    getMyGroupCategory(id: $id) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      accountCategories {
        nextToken
        __typename
      }
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listMyGroupCategorys = /* GraphQL */ `
  query ListMyGroupCategorys(
    $filter: ModelMyGroupCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyGroupCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMyAccountCategory = /* GraphQL */ `
  query GetMyAccountCategory($id: ID!) {
    getMyAccountCategory(id: $id) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      groupCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listMyAccountCategorys = /* GraphQL */ `
  query ListMyAccountCategorys(
    $filter: ModelMyAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMySubAccountCategory = /* GraphQL */ `
  query GetMySubAccountCategory($id: ID!) {
    getMySubAccountCategory(id: $id) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      auxiliarCategories {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listMySubAccountCategorys = /* GraphQL */ `
  query ListMySubAccountCategorys(
    $filter: ModelMySubAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMySubAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuxiliarCategory = /* GraphQL */ `
  query GetAuxiliarCategory($id: ID!) {
    getAuxiliarCategory(id: $id) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listAuxiliarCategorys = /* GraphQL */ `
  query ListAuxiliarCategorys(
    $filter: ModelAuxiliarCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuxiliarCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTax = /* GraphQL */ `
  query GetTax($id: ID!) {
    getTax(id: $id) {
      id
      name
      type
      percentage
      municipality
      value
      country
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listTaxs = /* GraphQL */ `
  query ListTaxs(
    $filter: ModelTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        percentage
        municipality
        value
        country
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      timestamp
      operationType
      subOperationType
      description
      total
      url
      extraData
      user
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      readers
      editors
      __typename
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        operationType
        subOperationType
        description
        total
        url
        extraData
        user
        createdAt
        updatedAt
        owner
        readers
        editors
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logsByTimestamp = /* GraphQL */ `
  query LogsByTimestamp(
    $timestamp: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByTimestamp(
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        operationType
        subOperationType
        description
        total
        url
        extraData
        user
        createdAt
        updatedAt
        owner
        readers
        editors
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logsByOperationType = /* GraphQL */ `
  query LogsByOperationType(
    $operationType: OPERATION_TYPE
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByOperationType(
      operationType: $operationType
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        operationType
        subOperationType
        description
        total
        url
        extraData
        user
        createdAt
        updatedAt
        owner
        readers
        editors
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logsBySubOperationType = /* GraphQL */ `
  query LogsBySubOperationType(
    $subOperationType: SUB_OPERATION_TYPE
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsBySubOperationType(
      subOperationType: $subOperationType
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        operationType
        subOperationType
        description
        total
        url
        extraData
        user
        createdAt
        updatedAt
        owner
        readers
        editors
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logsByUserID = /* GraphQL */ `
  query LogsByUserID(
    $user: ID
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByUserID(
      user: $user
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        operationType
        subOperationType
        description
        total
        url
        extraData
        user
        createdAt
        updatedAt
        owner
        readers
        editors
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($id: ID!) {
    getWarehouse(id: $id) {
      id
      code
      description
      location {
        name
        headquarters
        branchCode
        __typename
      }
      remarks
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listWarehouses = /* GraphQL */ `
  query ListWarehouses(
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarehouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPOS = /* GraphQL */ `
  query GetPOS($id: ID!) {
    getPOS(id: $id) {
      id
      code
      location {
        name
        headquarters
        branchCode
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPOSs = /* GraphQL */ `
  query ListPOSs(
    $filter: ModelPOSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPOSs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTerminal = /* GraphQL */ `
  query GetTerminal($id: ID!) {
    getTerminal(id: $id) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      nameType
      location
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      cashiers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTerminals = /* GraphQL */ `
  query ListTerminals(
    $filter: ModelTerminalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      companyRole
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      status
      employeeID
      supplierID
      cashierID
      salesmanID
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personType
        nationalID
        name
        lastname
        email
        defaultLocation
        companyRole
        status
        employeeID
        supplierID
        cashierID
        salesmanID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByNationalID = /* GraphQL */ `
  query UsersByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personType
        nationalID
        name
        lastname
        email
        defaultLocation
        companyRole
        status
        employeeID
        supplierID
        cashierID
        salesmanID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyRole = /* GraphQL */ `
  query GetCompanyRole($id: ID!) {
    getCompanyRole(id: $id) {
      id
      name
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      users {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listCompanyRoles = /* GraphQL */ `
  query ListCompanyRoles(
    $filter: ModelCompanyRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCashier = /* GraphQL */ `
  query GetCashier($id: ID!) {
    getCashier(id: $id) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCashiers = /* GraphQL */ `
  query ListCashiers(
    $filter: ModelCashierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cashiersByNationalID = /* GraphQL */ `
  query CashiersByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelCashierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashiersByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesman = /* GraphQL */ `
  query GetSalesman($id: ID!) {
    getSalesman(id: $id) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comtips {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listSalesmans = /* GraphQL */ `
  query ListSalesmans(
    $filter: ModelSalesmanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesmans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const salesmenByNationalID = /* GraphQL */ `
  query SalesmenByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelSalesmanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    salesmenByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      code
      highPensionRisk
      nationalID
      name
      lastname
      position
      positionType
      defaultBankName
      defaultBankAccountType
      defaultBankAccountNumber
      workCountryCode
      workStateCode
      workCityCode
      address
      integralSalary
      baseSalary
      startDate
      endDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      phoneNumbers {
        countryCode
        number
        extension
        __typename
      }
      tags {
        name
        value
        __typename
      }
      active
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      defaultPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeSubType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const employeesByNationalID = /* GraphQL */ `
  query EmployeesByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeProfile = /* GraphQL */ `
  query GetEmployeeProfile($id: ID!) {
    getEmployeeProfile(id: $id) {
      id
      studiesLevel
      experienceYears
      rating
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listEmployeeProfiles = /* GraphQL */ `
  query ListEmployeeProfiles(
    $filter: ModelEmployeeProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeePayment = /* GraphQL */ `
  query GetEmployeePayment($id: ID!) {
    getEmployeePayment(id: $id) {
      id
      paymentType
      month
      year
      paymentDates
      period {
        startDate
        endDate
        workingTime
        generationDate
        paymentIntervalCode
        __typename
      }
      extras
      deductions
      total
      paymentForm
      bankName
      bankAccountType
      bankAccountNumber
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      ePayroll {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listEmployeePayments = /* GraphQL */ `
  query ListEmployeePayments(
    $filter: ModelEmployeePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeePayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentType
        month
        year
        paymentDates
        extras
        deductions
        total
        paymentForm
        bankName
        bankAccountType
        bankAccountNumber
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEPayroll = /* GraphQL */ `
  query GetEPayroll($id: ID!) {
    getEPayroll(id: $id) {
      id
      number
      month
      year
      CUNE
      status
      jsonFile {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listEPayrolls = /* GraphQL */ `
  query ListEPayrolls(
    $filter: ModelEPayrollFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEPayrolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      accountID
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      branchCode
      balance
      userData {
        username
        password
        __typename
      }
      active
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      debitCards {
        nextToken
        __typename
      }
      eCards {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThirdPartyAccount = /* GraphQL */ `
  query GetThirdPartyAccount($id: ID!) {
    getThirdPartyAccount(id: $id) {
      id
      accountID
      thirdPartyType
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listThirdPartyAccounts = /* GraphQL */ `
  query ListThirdPartyAccounts(
    $filter: ModelThirdPartyAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThirdPartyAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      bankTransactionID
      reference
      timestamp
      type
      data {
        total
        description
        extra
        __typename
      }
      user
      fee {
        total
        description
        extra
        __typename
      }
      xml {
        __typename
      }
      status
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      debitCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      eCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bankTransactionID
        reference
        timestamp
        type
        user
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByType = /* GraphQL */ `
  query TransactionsByType(
    $type: TRANSACTION_TYPE
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByType(
      type: $type
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bankTransactionID
        reference
        timestamp
        type
        user
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByReference = /* GraphQL */ `
  query TransactionsByReference(
    $reference: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByReference(
      reference: $reference
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bankTransactionID
        reference
        timestamp
        type
        user
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByBankTransactionID = /* GraphQL */ `
  query TransactionsByBankTransactionID(
    $bankTransactionID: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByBankTransactionID(
      bankTransactionID: $bankTransactionID
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bankTransactionID
        reference
        timestamp
        type
        user
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByUserID = /* GraphQL */ `
  query TransactionsByUserID(
    $user: ID
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByUserID(
      user: $user
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bankTransactionID
        reference
        timestamp
        type
        user
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDebitCard = /* GraphQL */ `
  query GetDebitCard($id: ID!) {
    getDebitCard(id: $id) {
      id
      data {
        number
        expirationDate
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listDebitCards = /* GraphQL */ `
  query ListDebitCards(
    $filter: ModelDebitCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDebitCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getECard = /* GraphQL */ `
  query GetECard($id: ID!) {
    getECard(id: $id) {
      id
      data {
        number
        expirationDate
        cvv
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listECards = /* GraphQL */ `
  query ListECards(
    $filter: ModelECardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listECards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBag = /* GraphQL */ `
  query GetBag($id: ID!) {
    getBag(id: $id) {
      id
      name
      balance
      blocked
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listBags = /* GraphQL */ `
  query ListBags(
    $filter: ModelBagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        balance
        blocked
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductService = /* GraphQL */ `
  query GetProductService($id: ID!) {
    getProductService(id: $id) {
      id
      code
      type
      productServiceTypeID
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      limitless
      quantity
      averagePrice
      acceptableLimit
      dontSaleOnScarcity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      configuration
      favorite
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      active
      public
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      psTypes {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listProductServices = /* GraphQL */ `
  query ListProductServices(
    $filter: ModelProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productServiceByType = /* GraphQL */ `
  query ProductServiceByType(
    $productServiceTypeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productServiceByType(
      productServiceTypeID: $productServiceTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productServiceByName = /* GraphQL */ `
  query ProductServiceByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productServiceByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductServicePSType = /* GraphQL */ `
  query GetProductServicePSType($id: ID!) {
    getProductServicePSType(id: $id) {
      id
      productServiceID
      psTypeID
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      pstype {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listProductServicePSTypes = /* GraphQL */ `
  query ListProductServicePSTypes(
    $filter: ModelProductServicePSTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductServicePSTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productServiceID
        psTypeID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPSType = /* GraphQL */ `
  query GetPSType($id: ID!) {
    getPSType(id: $id) {
      id
      code
      name
      description
      image {
        bucket
        region
        key
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPSTypes = /* GraphQL */ `
  query ListPSTypes(
    $filter: ModelPSTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pstypesByName = /* GraphQL */ `
  query PstypesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPSTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pstypesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWPTransfer = /* GraphQL */ `
  query GetWPTransfer($id: ID!) {
    getWPTransfer(id: $id) {
      id
      warehouseID
      posID
      transferType
      date
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listWPTransfers = /* GraphQL */ `
  query ListWPTransfers(
    $filter: ModelWPTransferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWPTransfers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WPTransfersByType = /* GraphQL */ `
  query WPTransfersByType(
    $transferType: PRODUCT_SERVICE_TRANSFER_TYPE
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWPTransferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WPTransfersByType(
      transferType: $transferType
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWarehouseProductService = /* GraphQL */ `
  query GetWarehouseProductService($id: ID!) {
    getWarehouseProductService(id: $id) {
      id
      warehouseID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listWarehouseProductServices = /* GraphQL */ `
  query ListWarehouseProductServices(
    $filter: ModelWarehouseProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarehouseProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        warehouseID
        productServiceID
        quantity
        variations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWPTransferProductService = /* GraphQL */ `
  query GetWPTransferProductService($id: ID!) {
    getWPTransferProductService(id: $id) {
      id
      wpTransferID
      productServiceID
      quantity
      price
      reason
      variations
      remarks
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      wpTransfer {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listWPTransferProductServices = /* GraphQL */ `
  query ListWPTransferProductServices(
    $filter: ModelWPTransferProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWPTransferProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wpTransferID
        productServiceID
        quantity
        price
        reason
        variations
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPOSProductService = /* GraphQL */ `
  query GetPOSProductService($id: ID!) {
    getPOSProductService(id: $id) {
      id
      posID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPOSProductServices = /* GraphQL */ `
  query ListPOSProductServices(
    $filter: ModelPOSProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPOSProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        posID
        productServiceID
        quantity
        variations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicProductService = /* GraphQL */ `
  query GetPublicProductService($id: ID!) {
    getPublicProductService(id: $id) {
      id
      name
      productServiceTypeID
      url
      description
      lemma
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      prices {
        id
        name
        description
        value
        conditions
        __typename
      }
      defaultPrice
      taxed
      vatPercentage
      vat
      active
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPublicProductServices = /* GraphQL */ `
  query ListPublicProductServices(
    $filter: ModelPublicProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        productServiceTypeID
        url
        description
        lemma
        videoURLs
        defaultPrice
        taxed
        vatPercentage
        vat
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicProductServiceByType = /* GraphQL */ `
  query PublicProductServiceByType(
    $productServiceTypeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPublicProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicProductServiceByType(
      productServiceTypeID: $productServiceTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        productServiceTypeID
        url
        description
        lemma
        videoURLs
        defaultPrice
        taxed
        vatPercentage
        vat
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicProductServiceByName = /* GraphQL */ `
  query PublicProductServiceByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPublicProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicProductServiceByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        productServiceTypeID
        url
        description
        lemma
        videoURLs
        defaultPrice
        taxed
        vatPercentage
        vat
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicProductServiceByURL = /* GraphQL */ `
  query PublicProductServiceByURL(
    $url: String
    $sortDirection: ModelSortDirection
    $filter: ModelPublicProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicProductServiceByURL(
      url: $url
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        productServiceTypeID
        url
        description
        lemma
        videoURLs
        defaultPrice
        taxed
        vatPercentage
        vat
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupplierProductService = /* GraphQL */ `
  query GetSupplierProductService($id: ID!) {
    getSupplierProductService(id: $id) {
      id
      code
      equivalentDocumentScheme {
        name
        id
        agencyID
        code
        __typename
      }
      type
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      quantity
      acceptableLimit
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      brandName
      modelName
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listSupplierProductServices = /* GraphQL */ `
  query ListSupplierProductServices(
    $filter: ModelSupplierProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplierProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyKeyPartnerProductService = /* GraphQL */ `
  query GetCompanyKeyPartnerProductService($id: ID!) {
    getCompanyKeyPartnerProductService(id: $id) {
      id
      companyID
      keyPartnerProductServiceID
      data
      documents
      acquired
      rating
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      keyPartnerproductService {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyKeyPartnerProductServices = /* GraphQL */ `
  query ListCompanyKeyPartnerProductServices(
    $filter: ModelCompanyKeyPartnerProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyKeyPartnerProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        keyPartnerProductServiceID
        data
        documents
        acquired
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      timestamp
      topicID
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByTopicIDTimestamp = /* GraphQL */ `
  query PostsByTopicIDTimestamp(
    $topicID: ID
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByTopicIDTimestamp(
      topicID: $topicID
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByCompanyIDTimestamp = /* GraphQL */ `
  query PostsByCompanyIDTimestamp(
    $companyID: ID
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByCompanyIDTimestamp(
      companyID: $companyID
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByTimestamp = /* GraphQL */ `
  query PostsByTimestamp(
    $timestamp: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByTimestamp(
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByPositiveReactionsTimestamp = /* GraphQL */ `
  query PostsByPositiveReactionsTimestamp(
    $totalPositives: Int
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByPositiveReactionsTimestamp(
      totalPositives: $totalPositives
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostComment = /* GraphQL */ `
  query GetPostComment($id: ID!) {
    getPostComment(id: $id) {
      id
      timestamp
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      post {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPostComments = /* GraphQL */ `
  query ListPostComments(
    $filter: ModelPostCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByPositiveReactionsTimestamp = /* GraphQL */ `
  query CommentsByPositiveReactionsTimestamp(
    $totalPositives: Int
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByPositiveReactionsTimestamp(
      totalPositives: $totalPositives
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      cashReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customersByNationalID = /* GraphQL */ `
  query CustomersByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      dischargeReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const suppliersByNationalID = /* GraphQL */ `
  query SuppliersByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suppliersByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      nationalID
      name
      otherNames
      lastname
      otherLastnames
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      invoices {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      patientType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractingAndPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      benefitsPlan {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const patientsByNationalID = /* GraphQL */ `
  query PatientsByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIncome = /* GraphQL */ `
  query GetIncome($id: ID!) {
    getIncome(id: $id) {
      id
      concept
      invoiceID
      posDocumentID
      transactionID
      senderType
      senderID
      senderName
      manual
      date
      time
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listIncomes = /* GraphQL */ `
  query ListIncomes(
    $filter: ModelIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const incomesBySenderID = /* GraphQL */ `
  query IncomesBySenderID(
    $senderID: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    incomesBySenderID(
      senderID: $senderID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIncomeSubAccountCategory = /* GraphQL */ `
  query GetIncomeSubAccountCategory($id: ID!) {
    getIncomeSubAccountCategory(id: $id) {
      id
      incomeID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listIncomeSubAccountCategorys = /* GraphQL */ `
  query ListIncomeSubAccountCategorys(
    $filter: ModelIncomeSubAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomeSubAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        incomeID
        subAccountCategoryID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIncomeMySubAccountCategory = /* GraphQL */ `
  query GetIncomeMySubAccountCategory($id: ID!) {
    getIncomeMySubAccountCategory(id: $id) {
      id
      incomeID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listIncomeMySubAccountCategorys = /* GraphQL */ `
  query ListIncomeMySubAccountCategorys(
    $filter: ModelIncomeMySubAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomeMySubAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        incomeID
        mySubAccountCategoryID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIncomeAuxiliarCategory = /* GraphQL */ `
  query GetIncomeAuxiliarCategory($id: ID!) {
    getIncomeAuxiliarCategory(id: $id) {
      id
      incomeID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listIncomeAuxiliarCategorys = /* GraphQL */ `
  query ListIncomeAuxiliarCategorys(
    $filter: ModelIncomeAuxiliarCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomeAuxiliarCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        incomeID
        auxiliarCategoryID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuotation = /* GraphQL */ `
  query GetQuotation($id: ID!) {
    getQuotation(id: $id) {
      id
      quotationID
      client
      clientID
      productsServices {
        id
        name
        quantity
        taxes
        total
        __typename
      }
      subtotal
      taxes
      total
      date
      expirationDays
      status
      files {
        bucket
        region
        key
        __typename
      }
      videos {
        url
        type
        __typename
      }
      url
      password
      readers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listQuotations = /* GraphQL */ `
  query ListQuotations(
    $filter: ModelQuotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quotationID
        client
        clientID
        subtotal
        taxes
        total
        date
        expirationDays
        status
        url
        password
        readers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const quotationsByClientName = /* GraphQL */ `
  query QuotationsByClientName(
    $client: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quotationsByClientName(
      client: $client
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quotationID
        client
        clientID
        subtotal
        taxes
        total
        date
        expirationDays
        status
        url
        password
        readers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const quotationsByStatus = /* GraphQL */ `
  query QuotationsByStatus(
    $status: QUOTATION_STATUS
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quotationsByStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quotationID
        client
        clientID
        subtotal
        taxes
        total
        date
        expirationDays
        status
        url
        password
        readers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      originalData
      terminalCashierID
      resolutionPosition
      invoiceNumber
      seller
      salesman
      salesmanType
      comtipID
      date
      time
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      totalDiscount
      discountPercentage
      includeConditionalDiscount
      total
      paymentLink
      cufe
      xml
      splitPayments
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      copay
      moderatingFee
      recoveryFee
      voluntaryHealthPlansSharedPayments
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      extraData
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      selectedQuotation
      scheduled
      schedule {
        sendOn
        sendOnMultipleDates
        sendUntil
        programmedTimes
        executedTimes
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      healthReferencedDocumentType {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      healthOperationType {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoiceProductService = /* GraphQL */ `
  query GetInvoiceProductService($id: ID!) {
    getInvoiceProductService(id: $id) {
      id
      invoiceID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      expirationDate
      batch
      configuration
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listInvoiceProductServices = /* GraphQL */ `
  query ListInvoiceProductServices(
    $filter: ModelInvoiceProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceID
        productServiceID
        unitPrice
        retentionPercentage
        retention
        discounted
        discountPercentage
        discount
        quantity
        expirationDate
        batch
        configuration
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        subtotal
        total
        comment
        extra
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchaseOrder = /* GraphQL */ `
  query GetPurchaseOrder($id: ID!) {
    getPurchaseOrder(id: $id) {
      id
      orderID
      date
      documents {
        bucket
        region
        key
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPurchaseOrders = /* GraphQL */ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCashReceipt = /* GraphQL */ `
  query GetCashReceipt($id: ID!) {
    getCashReceipt(id: $id) {
      id
      cashReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listCashReceipts = /* GraphQL */ `
  query ListCashReceipts(
    $filter: ModelCashReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCashReceiptIncome = /* GraphQL */ `
  query GetCashReceiptIncome($id: ID!) {
    getCashReceiptIncome(id: $id) {
      id
      cashReceiptID
      incomeID
      value
      date
      data
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipt {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listCashReceiptIncomes = /* GraphQL */ `
  query ListCashReceiptIncomes(
    $filter: ModelCashReceiptIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashReceiptIncomes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cashReceiptID
        incomeID
        value
        date
        data
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTerminalCashier = /* GraphQL */ `
  query GetTerminalCashier($id: ID!) {
    getTerminalCashier(id: $id) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashBeforeDetail
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      cashOutputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      observations
      readers
      editors
      createdAt
      updatedAt
      terminal {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashier {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      owner
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTerminalCashiers = /* GraphQL */ `
  query ListTerminalCashiers(
    $filter: ModelTerminalCashierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalCashiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPOSDocument = /* GraphQL */ `
  query GetPOSDocument($id: ID!) {
    getPOSDocument(id: $id) {
      id
      type
      POSDocumentID
      code
      date
      total
      taxes
      data
      status
      salesman
      salesmanType
      comtipID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPOSDocuments = /* GraphQL */ `
  query ListPOSDocuments(
    $filter: ModelPOSDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPOSDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const posDocumentsBySalesman = /* GraphQL */ `
  query PosDocumentsBySalesman(
    $salesman: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOSDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    posDocumentsBySalesman(
      salesman: $salesman
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const posDocumentsByDate = /* GraphQL */ `
  query PosDocumentsByDate(
    $date: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelPOSDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    posDocumentsByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPOSDocumentProductService = /* GraphQL */ `
  query GetPOSDocumentProductService($id: ID!) {
    getPOSDocumentProductService(id: $id) {
      id
      posDocumentID
      productServiceID
      quantity
      price
      total
      taxes
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      posDocument {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPOSDocumentProductServices = /* GraphQL */ `
  query ListPOSDocumentProductServices(
    $filter: ModelPOSDocumentProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPOSDocumentProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        posDocumentID
        productServiceID
        quantity
        price
        total
        taxes
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($id: ID!) {
    getExpense(id: $id) {
      id
      concept
      billID
      transactionID
      equivalentDocumentID
      comtipID
      receiverType
      receiverID
      receiverName
      manual
      date
      time
      transferID
      paid
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpenseSubAccountCategory = /* GraphQL */ `
  query GetExpenseSubAccountCategory($id: ID!) {
    getExpenseSubAccountCategory(id: $id) {
      id
      expenseID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listExpenseSubAccountCategorys = /* GraphQL */ `
  query ListExpenseSubAccountCategorys(
    $filter: ModelExpenseSubAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenseSubAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expenseID
        subAccountCategoryID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpenseMySubAccountCategory = /* GraphQL */ `
  query GetExpenseMySubAccountCategory($id: ID!) {
    getExpenseMySubAccountCategory(id: $id) {
      id
      expenseID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listExpenseMySubAccountCategorys = /* GraphQL */ `
  query ListExpenseMySubAccountCategorys(
    $filter: ModelExpenseMySubAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenseMySubAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expenseID
        mySubAccountCategoryID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpenseAuxiliarCategory = /* GraphQL */ `
  query GetExpenseAuxiliarCategory($id: ID!) {
    getExpenseAuxiliarCategory(id: $id) {
      id
      expenseID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listExpenseAuxiliarCategorys = /* GraphQL */ `
  query ListExpenseAuxiliarCategorys(
    $filter: ModelExpenseAuxiliarCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenseAuxiliarCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expenseID
        auxiliarCategoryID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransfer = /* GraphQL */ `
  query GetTransfer($id: ID!) {
    getTransfer(id: $id) {
      id
      concept
      expenseID
      receiverType
      receiverID
      receiverName
      date
      value
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listTransfers = /* GraphQL */ `
  query ListTransfers(
    $filter: ModelTransferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransfers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        concept
        expenseID
        receiverType
        receiverID
        receiverName
        date
        value
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBill = /* GraphQL */ `
  query GetBill($id: ID!) {
    getBill(id: $id) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      billNumberDIAN
      supplierID
      supplierName
      supplierData
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cufe
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listBills = /* GraphQL */ `
  query ListBills(
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const billsByStatus = /* GraphQL */ `
  query BillsByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const billsByCUFE = /* GraphQL */ `
  query BillsByCUFE(
    $cufe: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billsByCUFE(
      cufe: $cufe
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const billsBySupplierID = /* GraphQL */ `
  query BillsBySupplierID(
    $supplierID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billsBySupplierID(
      supplierID: $supplierID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const billsByDIANNumber = /* GraphQL */ `
  query BillsByDIANNumber(
    $billNumberDIAN: String
    $sortDirection: ModelSortDirection
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billsByDIANNumber(
      billNumberDIAN: $billNumberDIAN
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const billsBySupplierName = /* GraphQL */ `
  query BillsBySupplierName(
    $supplierName: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billsBySupplierName(
      supplierName: $supplierName
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBillSupplierProductService = /* GraphQL */ `
  query GetBillSupplierProductService($id: ID!) {
    getBillSupplierProductService(id: $id) {
      id
      billID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      bill {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listBillSupplierProductServices = /* GraphQL */ `
  query ListBillSupplierProductServices(
    $filter: ModelBillSupplierProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillSupplierProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        billID
        productServiceID
        unitPrice
        retentionPercentage
        retention
        discounted
        discountPercentage
        discount
        quantity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        subtotal
        total
        comment
        extra
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEquivalentDocument = /* GraphQL */ `
  query GetEquivalentDocument($id: ID!) {
    getEquivalentDocument(id: $id) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      resolutionPosition
      equivalentDocumentNumber
      equivalentDocumentNumberDIAN
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cuds
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      documents {
        bucket
        region
        key
        __typename
      }
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listEquivalentDocuments = /* GraphQL */ `
  query ListEquivalentDocuments(
    $filter: ModelEquivalentDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEquivalentDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEquivalentDocumentSupplierProductService = /* GraphQL */ `
  query GetEquivalentDocumentSupplierProductService($id: ID!) {
    getEquivalentDocumentSupplierProductService(id: $id) {
      id
      equivalentDocumentID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      startDate
      descriptionCode
      descriptionXML
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      equivalentDocument {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listEquivalentDocumentSupplierProductServices = /* GraphQL */ `
  query ListEquivalentDocumentSupplierProductServices(
    $filter: ModelEquivalentDocumentSupplierProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEquivalentDocumentSupplierProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        equivalentDocumentID
        productServiceID
        unitPrice
        retentionPercentage
        retention
        discounted
        discountPercentage
        discount
        quantity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        subtotal
        total
        comment
        startDate
        descriptionCode
        descriptionXML
        extra
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDischargeReceipt = /* GraphQL */ `
  query GetDischargeReceipt($id: ID!) {
    getDischargeReceipt(id: $id) {
      id
      dischargeReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listDischargeReceipts = /* GraphQL */ `
  query ListDischargeReceipts(
    $filter: ModelDischargeReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDischargeReceipts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDischargeReceiptExpense = /* GraphQL */ `
  query GetDischargeReceiptExpense($id: ID!) {
    getDischargeReceiptExpense(id: $id) {
      id
      dischargeReceiptID
      expenseID
      value
      date
      data
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      dischargeReceipt {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listDischargeReceiptExpenses = /* GraphQL */ `
  query ListDischargeReceiptExpenses(
    $filter: ModelDischargeReceiptExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDischargeReceiptExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dischargeReceiptID
        expenseID
        value
        date
        data
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComTip = /* GraphQL */ `
  query GetComTip($id: ID!) {
    getComTip(id: $id) {
      id
      percentage
      value
      data
      posDocumentID
      invoiceID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      salesmen {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listComTips = /* GraphQL */ `
  query ListComTips(
    $filter: ModelComTipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComTips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesmanComTip = /* GraphQL */ `
  query GetSalesmanComTip($id: ID!) {
    getSalesmanComTip(id: $id) {
      id
      salesmanID
      comtipID
      percentage
      value
      comtipType
      data
      readers
      editors
      createdAt
      updatedAt
      salesman {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      comtip {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listSalesmanComTips = /* GraphQL */ `
  query ListSalesmanComTips(
    $filter: ModelSalesmanComTipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesmanComTips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        salesmanID
        comtipID
        percentage
        value
        comtipType
        data
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIDType = /* GraphQL */ `
  query GetIDType($id: ID!) {
    getIDType(id: $id) {
      id
      code
      name
      abbreviation
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIDTypes = /* GraphQL */ `
  query ListIDTypes(
    $filter: ModelIDTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIDTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFiscalResponsability = /* GraphQL */ `
  query GetFiscalResponsability($id: ID!) {
    getFiscalResponsability(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      companiesConfigurations {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listFiscalResponsabilitys = /* GraphQL */ `
  query ListFiscalResponsabilitys(
    $filter: ModelFiscalResponsabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiscalResponsabilitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegimenType = /* GraphQL */ `
  query GetRegimenType($id: ID!) {
    getRegimenType(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegimenTypes = /* GraphQL */ `
  query ListRegimenTypes(
    $filter: ModelRegimenTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegimenTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBank = /* GraphQL */ `
  query GetBank($id: ID!) {
    getBank(id: $id) {
      id
      name
      description
      extraData
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      finppiAccounts {
        nextToken
        __typename
      }
      thirdPartyAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listBanks = /* GraphQL */ `
  query ListBanks(
    $filter: ModelBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccountType = /* GraphQL */ `
  query GetAccountType($id: ID!) {
    getAccountType(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccountTypes = /* GraphQL */ `
  query ListAccountTypes(
    $filter: ModelAccountTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductServiceType = /* GraphQL */ `
  query GetProductServiceType($id: ID!) {
    getProductServiceType(id: $id) {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductServiceTypes = /* GraphQL */ `
  query ListProductServiceTypes(
    $filter: ModelProductServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductServiceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEInvoiceProvider = /* GraphQL */ `
  query GetEInvoiceProvider($id: ID!) {
    getEInvoiceProvider(id: $id) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEInvoiceProviders = /* GraphQL */ `
  query ListEInvoiceProviders(
    $filter: ModelEInvoiceProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEInvoiceProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEPayrollProvider = /* GraphQL */ `
  query GetEPayrollProvider($id: ID!) {
    getEPayrollProvider(id: $id) {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEPayrollProviders = /* GraphQL */ `
  query ListEPayrollProviders(
    $filter: ModelEPayrollProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEPayrollProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIndustry = /* GraphQL */ `
  query GetIndustry($id: ID!) {
    getIndustry(id: $id) {
      id
      CIIU
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listIndustrys = /* GraphQL */ `
  query ListIndustrys(
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const industriesbyName = /* GraphQL */ `
  query IndustriesbyName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    industriesbyName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyDocumentType = /* GraphQL */ `
  query GetCompanyDocumentType($id: ID!) {
    getCompanyDocumentType(id: $id) {
      id
      type
      allowedFileTypes
      maxSize
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyDocumentTypes = /* GraphQL */ `
  query ListCompanyDocumentTypes(
    $filter: ModelCompanyDocumentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDocumentTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBadge = /* GraphQL */ `
  query GetBadge($id: ID!) {
    getBadge(id: $id) {
      id
      code
      name
      image {
        bucket
        region
        key
        __typename
      }
      text
      validFor
      requiredPoints
      callback
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listBadges = /* GraphQL */ `
  query ListBadges(
    $filter: ModelBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods(
    $filter: ModelPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const topicsByName = /* GraphQL */ `
  query TopicsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDefaultCompanyRole = /* GraphQL */ `
  query GetDefaultCompanyRole($id: ID!) {
    getDefaultCompanyRole(id: $id) {
      id
      name
      description
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDefaultCompanyRoles = /* GraphQL */ `
  query ListDefaultCompanyRoles(
    $filter: ModelDefaultCompanyRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDefaultCompanyRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      name
      description
      data
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKeyPartnerProductService = /* GraphQL */ `
  query GetKeyPartnerProductService($id: ID!) {
    getKeyPartnerProductService(id: $id) {
      id
      code
      name
      description
      notes
      productServiceTypeID
      contactEmail
      url
      clicks
      clickedBy
      acquired
      rating
      callback
      dataKeys {
        name
        mandatory
        possibleValues
        defaultValue
        description
        comments
        __typename
      }
      requirements {
        type
        value
        condition
        fullText
        __typename
      }
      requiredDocuments {
        type
        name
        mandatory
        comment
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      owner
      keyPartner {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listKeyPartnerProductServices = /* GraphQL */ `
  query ListKeyPartnerProductServices(
    $filter: ModelKeyPartnerProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyPartnerProductServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keyPartnerProductServiceByName = /* GraphQL */ `
  query KeyPartnerProductServiceByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPartnerProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPartnerProductServiceByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keyPartnerProductServiceByType = /* GraphQL */ `
  query KeyPartnerProductServiceByType(
    $productServiceTypeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPartnerProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPartnerProductServiceByType(
      productServiceTypeID: $productServiceTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keyPartnerProductServiceByClicks = /* GraphQL */ `
  query KeyPartnerProductServiceByClicks(
    $clicks: Int
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPartnerProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPartnerProductServiceByClicks(
      clicks: $clicks
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keyPartnerProductServiceByAcquired = /* GraphQL */ `
  query KeyPartnerProductServiceByAcquired(
    $acquired: Int
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPartnerProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPartnerProductServiceByAcquired(
      acquired: $acquired
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keyPartnerProductServiceByRating = /* GraphQL */ `
  query KeyPartnerProductServiceByRating(
    $rating: Float
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPartnerProductServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPartnerProductServiceByRating(
      rating: $rating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNoteConcept = /* GraphQL */ `
  query GetNoteConcept($id: ID!) {
    getNoteConcept(id: $id) {
      id
      code
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNoteConcepts = /* GraphQL */ `
  query ListNoteConcepts(
    $filter: ModelNoteConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNoteConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoiceOperationType = /* GraphQL */ `
  query GetInvoiceOperationType($id: ID!) {
    getInvoiceOperationType(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoiceOperationTypes = /* GraphQL */ `
  query ListInvoiceOperationTypes(
    $filter: ModelInvoiceOperationTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceOperationTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTariffItem = /* GraphQL */ `
  query GetTariffItem($id: ID!) {
    getTariffItem(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTariffItems = /* GraphQL */ `
  query ListTariffItems(
    $filter: ModelTariffItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTariffItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUNSPSC = /* GraphQL */ `
  query GetUNSPSC($id: ID!) {
    getUNSPSC(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUNSPSCs = /* GraphQL */ `
  query ListUNSPSCs(
    $filter: ModelUNSPSCFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUNSPSCs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      planID
      name
      description
      data
      createdAt
      updatedAt
      features {
        nextToken
        __typename
      }
      terms {
        nextToken
        __typename
      }
      price {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlanFeature = /* GraphQL */ `
  query GetPlanFeature($id: ID!) {
    getPlanFeature(id: $id) {
      id
      planID
      featureID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      feature {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listPlanFeatures = /* GraphQL */ `
  query ListPlanFeatures(
    $filter: ModelPlanFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        planID
        featureID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlanTerm = /* GraphQL */ `
  query GetPlanTerm($id: ID!) {
    getPlanTerm(id: $id) {
      id
      planID
      termID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      term {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listPlanTerms = /* GraphQL */ `
  query ListPlanTerms(
    $filter: ModelPlanTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        planID
        termID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTerm = /* GraphQL */ `
  query GetTerm($id: ID!) {
    getTerm(id: $id) {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTerms = /* GraphQL */ `
  query ListTerms(
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlanPrice = /* GraphQL */ `
  query GetPlanPrice($id: ID!) {
    getPlanPrice(id: $id) {
      id
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlanPrices = /* GraphQL */ `
  query ListPlanPrices(
    $filter: ModelPlanPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClassCategory = /* GraphQL */ `
  query GetClassCategory($id: ID!) {
    getClassCategory(id: $id) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listClassCategorys = /* GraphQL */ `
  query ListClassCategorys(
    $filter: ModelClassCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroupCategory = /* GraphQL */ `
  query GetGroupCategory($id: ID!) {
    getGroupCategory(id: $id) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listGroupCategorys = /* GraphQL */ `
  query ListGroupCategorys(
    $filter: ModelGroupCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccountCategory = /* GraphQL */ `
  query GetAccountCategory($id: ID!) {
    getAccountCategory(id: $id) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listAccountCategorys = /* GraphQL */ `
  query ListAccountCategorys(
    $filter: ModelAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubAccountCategory = /* GraphQL */ `
  query GetSubAccountCategory($id: ID!) {
    getSubAccountCategory(id: $id) {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listSubAccountCategorys = /* GraphQL */ `
  query ListSubAccountCategorys(
    $filter: ModelSubAccountCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubAccountCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPatientType = /* GraphQL */ `
  query GetPatientType($id: ID!) {
    getPatientType(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPatientTypes = /* GraphQL */ `
  query ListPatientTypes(
    $filter: ModelPatientTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContractingAndPaymentMethod = /* GraphQL */ `
  query GetContractingAndPaymentMethod($id: ID!) {
    getContractingAndPaymentMethod(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContractingAndPaymentMethods = /* GraphQL */ `
  query ListContractingAndPaymentMethods(
    $filter: ModelContractingAndPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractingAndPaymentMethods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBenefitsPlan = /* GraphQL */ `
  query GetBenefitsPlan($id: ID!) {
    getBenefitsPlan(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBenefitsPlans = /* GraphQL */ `
  query ListBenefitsPlans(
    $filter: ModelBenefitsPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBenefitsPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHealthReferencedDocumentType = /* GraphQL */ `
  query GetHealthReferencedDocumentType($id: ID!) {
    getHealthReferencedDocumentType(id: $id) {
      id
      code
      name
      comment
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHealthReferencedDocumentTypes = /* GraphQL */ `
  query ListHealthReferencedDocumentTypes(
    $filter: ModelHealthReferencedDocumentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHealthReferencedDocumentTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHealthOperationType = /* GraphQL */ `
  query GetHealthOperationType($id: ID!) {
    getHealthOperationType(id: $id) {
      id
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHealthOperationTypes = /* GraphQL */ `
  query ListHealthOperationTypes(
    $filter: ModelHealthOperationTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHealthOperationTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKeyPartner = /* GraphQL */ `
  query GetKeyPartner($id: ID!) {
    getKeyPartner(id: $id) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      description
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      tags {
        name
        value
        __typename
      }
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      rating
      comments {
        author
        text
        __typename
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listKeyPartners = /* GraphQL */ `
  query ListKeyPartners(
    $filter: ModelKeyPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keyPartnersByNationalID = /* GraphQL */ `
  query KeyPartnersByNationalID(
    $nationalID: String
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPartnersByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSuppressionList = /* GraphQL */ `
  query GetSuppressionList($email: String!) {
    getSuppressionList(email: $email) {
      email
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSuppressionLists = /* GraphQL */ `
  query ListSuppressionLists(
    $email: String
    $filter: ModelSuppressionListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSuppressionLists(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        reason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeType = /* GraphQL */ `
  query GetEmployeeType($id: ID!) {
    getEmployeeType(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployeeTypes = /* GraphQL */ `
  query ListEmployeeTypes(
    $filter: ModelEmployeeTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeSubType = /* GraphQL */ `
  query GetEmployeeSubType($id: ID!) {
    getEmployeeSubType(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployeeSubTypes = /* GraphQL */ `
  query ListEmployeeSubTypes(
    $filter: ModelEmployeeSubTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeSubTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContractType = /* GraphQL */ `
  query GetContractType($id: ID!) {
    getContractType(id: $id) {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContractTypes = /* GraphQL */ `
  query ListContractTypes(
    $filter: ModelContractTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
