import React, { useState } from 'react';

import { connect } from 'react-redux';
import { signOutStart } from '../../../redux/user/user.actions';

import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={handleToggleCollapse}
      >
        <p className="topbar__avatar-name" style={{ margin: 'auto 0' }}>
          Accesos rapidos
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Crear productos y servicios"
            icon="store"
            path="/products/edit"
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Crear Factura"
            icon="book"
            path="/income/invoice/edit"
          />
          <TopbarMenuLink
            title="Crear gasto manual"
            icon="exit"
            path="/expense/manual/edit"
          />
        </div>
      </Collapse>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
});

export default connect(null, mapDispatchToProps)(TopbarProfile);
