import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeInvoiceView } from '../../../redux/invoice/actions';
import { changeSidebarVisibility } from '../../../redux/sidebar/sidebar.actions';
import ArrowBackCircleIcon from 'mdi-react/ArrowCollapseLeftIcon';
import ArrowCollapse from 'mdi-react/ArrowCollapseRightIcon';

const optionsAll = (onClick) => {};
const SidebarContent = ({ onClick, sidebarCollapse }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const sidebarCollap = useSelector((state) => state.sidebar.collapse);

  const handleInvoiceView = (view) => {
    dispatch(changeInvoiceView(view));
  };

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const {
    company: { configuration },
  } = currentUser;

  const currentOptions = () => {
    return (
      <>
        <SidebarLink
          title="Dashboard"
          icon="rocket"
          route="/"
          onClick={onClick}
        />
        <SidebarCategory
          title="Productos y servicios"
          icon="cart"
          sidebarCollapse={sidebarCollapse}
        >
          <Link to="/products">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Mis productos</p>
            </button>
          </Link>
          <Link to="/supplier-products">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Mis compras</p>
            </button>
          </Link>
        </SidebarCategory>

        <SidebarCategory
          title="Contactos"
          icon="users"
          sidebarCollapse={sidebarCollapse}
        >
          <Link to="/customers">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Clientes</p>
            </button>
          </Link>
          <Link to="/employees">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Empleados</p>
            </button>
          </Link>
          <Link to="/suppliers">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Proveedores</p>
            </button>
          </Link>
          <Link to="/salesman">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Vendedores</p>
            </button>
          </Link>
          {configuration?.healthProvider && (
            <Link to="/patients">
              <button type="button" className="sidebar__link">
                <p className="sidebar__link-title">Pacientes</p>
              </button>
            </Link>
          )}
        </SidebarCategory>
        <SidebarCategory
          title="Ingresos"
          icon="enter"
          sidebarCollapse={sidebarCollapse}
        >
          <Link to="/income/invoice">
            <button
              type="button"
              className="sidebar__link"
              onClick={() => handleInvoiceView('default')}
            >
              <p className="sidebar__link-title">Facturas de venta</p>
            </button>
          </Link>
          <Link to="/income/invoice">
            <button
              type="button"
              className="sidebar__link"
              onClick={() => handleInvoiceView('creditNote')}
            >
              <p className="sidebar__link-title">Notas crédito</p>
            </button>
          </Link>
          <Link to="/income/invoice">
            <button
              type="button"
              className="sidebar__link"
              onClick={() => handleInvoiceView('debitNote')}
            >
              <p className="sidebar__link-title">Notas débito</p>
            </button>
          </Link>
          <Link to="/pos/sales">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">POS</p>
            </button>
          </Link>
          {configuration?.healthProvider && (
            <Link to="/income/invoice">
              <button
                type="button"
                className="sidebar__link"
                onClick={() => handleInvoiceView('health')}
              >
                <p className="sidebar__link-title">Sector salud</p>
              </button>
            </Link>
          )}
        </SidebarCategory>
        <SidebarCategory
          title="Egresos"
          icon="exit"
          sidebarCollapse={sidebarCollapse}
        >
          <Link to="/expense/support-document">
            <button
              type="button"
              className="sidebar__link"
              onClick={() => handleInvoiceView('default')}
            >
              <p className="sidebar__link-title">Documento soporte</p>
            </button>
          </Link>
          <Link to="/expense/manual">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Gastos manuales</p>
            </button>
          </Link>
          <Link to="/expense/payroll">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Nómina electronica</p>
            </button>
          </Link>
          <Link to="/expense/support-document">
            <button
              type="button"
              className="sidebar__link"
              onClick={() => handleInvoiceView('adjust')}
            >
              <p className="sidebar__link-title">Nota de ajuste</p>
            </button>
          </Link>

          <Link to="/expense/invoice">
            <button type="button" className="sidebar__link">
              <p className="sidebar__link-title">Recepción factura</p>
            </button>
          </Link>
        </SidebarCategory>
        <SidebarLink title="POS" icon="store" route="/pos" onClick={onClick} />
      </>
    );
  };

  const cashierOptions = () => {
    return (
      <>
        <SidebarLink title="POS" icon="rocket" route="/" onClick={onClick} />
        <SidebarLink
          title="Historial ventas"
          icon="list"
          route="/sales"
          onClick={onClick}
        />
        <SidebarLink
          title="Turnos/Sesiones"
          icon="history"
          route="/turns"
          onClick={onClick}
        />
        <SidebarLink
          title="Clientes"
          icon="users"
          route="/customers"
          onClick={onClick}
        />
      </>
    );
  };
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {currentUser.isAdmin && (
          <SidebarLink
            title="Otro usuario"
            icon="list"
            route="/workspaces"
            onClick={onClick}
          />
        )}
        {currentUser.isCashier && cashierOptions()}
        {!currentUser.isCashier && currentOptions()}

        <button
          onClick={() => sidebarVisibility()}
          className="sidebar__button"
          type="button"
        >
          {sidebarCollap ? (
            <ArrowCollapse size={30} className="sidebar__button-icon" />
          ) : (
            <ArrowBackCircleIcon size={30} className="sidebar__button-icon" />
          )}
        </button>
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
