import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import modalReducer from './modal/modal.reducer';
import themeReducer from './theme/theme.reducer';
import sidebarReducer from './sidebar/sidebar.reducer';
import rtlReducer from './rtl/rtl.reducer';
import invoiceReducer from './invoice/reducer';
import wrapperReducer from './wrapper/reducer';
import posReducer from './pos/pos.reducer';
import planReducer from './myPlan/plan.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['theme', 'user', 'invoice', 'pos', 'myPlan'],
};

const rootReducer = combineReducers({
  user: userReducer,
  modal: modalReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  invoice: invoiceReducer,
  wrapper: wrapperReducer,
  pos: posReducer,
  myPlan: planReducer,
});

export default persistReducer(persistConfig, rootReducer);
