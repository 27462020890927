import { useList } from '..';

const removeItem = (items, indexOfItemToRemove) => {
  const newItems = [...items];
  newItems.splice(indexOfItemToRemove, 1);
  return newItems;
};

export const storageActionTypes = {
  ADD_ITEM: 'ADD_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
};

export const storageIds = {
  items: 'items',
  itemsToUpload: 'itemsToUpload',
};

const storageReducer = (state, action) => {
  switch (action.type) {
    case storageActionTypes.ADD_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: [...state.data[action.key], ...action.item],
        },
      };
    }
    case storageActionTypes.REMOVE_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: removeItem(
            state.data[action.key],
            action.indexOfItemToRemove
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export function useStorage({ currItems = [] }) {
  const {
    data: { items, itemsToUpload },
    dispatch,
    loading,
  } = useList(
    {
      data: {
        items: currItems,
        itemsToUpload: [],
      },
      loading: false,
    },
    storageReducer
  );
  const addFile = (file) => {
    dispatch({
      type: storageActionTypes.ADD_ITEM,
      key: storageIds.itemsToUpload,
      item: file,
    });
  };
  const removeFile = (index) => {
    dispatch({
      type: storageActionTypes.REMOVE_ITEM,
      key: storageIds.itemsToUpload,
      indexOfItemToRemove: index,
    });
  };
  return {
    items,
    itemsToUpload,
    dispatch,
    isLoading: loading,
    addFile,
    removeFile,
  };
}
