const queryKeys = {
  products: 'products',
  supplierProducts: 'supplierProducts',
  supplierProductProps: 'supplierProductProps',
  invoiceProducts: 'invoiceProducts',
  invoices: 'invoices',
  invoicesProps: 'invoicesProps',
  units: 'units',
  taxes: 'taxes',
  employees: 'employees',
  idType: 'idType',
  employeeType: 'employeeType',
  employeeSubType: 'employeeSubType',
  employeeContractType: 'employeeContractType',
  employeePaymentMethod: 'employeePaymentMethod',
  customers: 'customers',
  regimenType: 'regimenType',
  fiscalResponsability: 'fiscalResponsability',
  suppliers: 'suppliers',
  patients: 'patients',
  patientType: 'patientType',
  patientContractType: 'patientContractType',
  patientBenefits: 'patientBenefits',
  periodsPayroll: 'periodsPayroll',
  payrollsList: 'payrollsList',
  currentPayroll: 'currentPayroll',
  manualExpense: 'manualExpense',
  equivalentDocuments: 'equivalentDocuments',
  equivalentDocumentsProps: 'equivalentDocumentsProps',
  bills: 'bills',
  sellers: 'sellers',
};

export default queryKeys;
