import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { Route, Routes } from 'react-router-dom';

import { LoggedInRouter, CashierRoutes } from './Router/LoggedInRouter';
import LoggedOutRouter from './Router/LoggedOutRouter';
import Watcher from './Watcher';
import Layout from '../Layout';
import Loading from '../../shared/components/Loading';
import { UserProvider } from './Router/context';

const Global = () => <div>Hello</div>;

const Router = () => {
  const currentUser = useSelector(selectCurrentUser);
  const CurrentRoutes = currentUser?.isCashier ? CashierRoutes : LoggedInRouter;
  return (
    <Watcher>
      <UserProvider>
        {currentUser && <Layout />}
        <div className="container__wrap">
          <Suspense fallback={<Loading />}>
            <Routes>
              {currentUser
                ? CurrentRoutes.map((route) => (
                    <Route
                      key={route.name}
                      path={route.path}
                      element={route.component}
                    />
                  ))
                : LoggedOutRouter.map((route) => (
                    <Route
                      key={route.name}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
              <Route path="/company-app" element={<Global />} />
            </Routes>
          </Suspense>
        </div>
      </UserProvider>
    </Watcher>
  );
};

export default Router;
