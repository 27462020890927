import { Auth, API, graphqlOperation } from 'aws-amplify';
import { takeLatest, put, all, call } from 'redux-saga/effects';

import UserActionTypes from './user.types';
import { listAccountTypes } from '../../graphql/queries';
import { v4 as uuidv4 } from 'uuid';

import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpFailure,
  emailSignInStart,
  userNotVerified,
  createUserCompany,
  signUpSuccess,
  userVerified,
} from './user.actions';
import { toast } from 'react-toastify';
import AmplifyErrors from '../../utils/AmplifyErrors';
import {
  createAccount,
  createCompany,
  createCompanyRole,
  createTax,
  createUser,
} from '../../graphql/mutations';
import { getUserQuery } from './queries';
import { setPlanData } from '../myPlan/plan.actions';

export function* signInWithEmail({ payload }) {
  try {
    const currentUser = yield call(
      [API, 'graphql'],
      graphqlOperation(getUserQuery, { id: payload })
    );
    const user = yield call([Auth, Auth.currentAuthenticatedUser]);
    const isAdminGroup =
      user.signInUserSession.accessToken.payload['cognito:groups']?.includes(
        'admins'
      );
    if (!currentUser.data.getUser) {
      toast.info('Crea tu Compañía en FINPPI');
    } else if (!currentUser.data.getUser.company.plan?.active) {
      yield call([Auth, 'signOut']);
      yield put(signOutSuccess());
      toast.error('El usuario no cuenta con plan');
    } else {
      yield put(
        setPlanData({
          ...currentUser.data.getUser.company.plan,
        })
      );
      yield put(
        signInSuccess({
          ...currentUser.data.getUser,
          isAdmin: isAdminGroup,
          isCashier: !!currentUser.data.getUser?.cashierID,
        })
      );
      toast.info('Bienvenido a FINPPI');
    }
  } catch (error) {
    yield console.error(error);
    AmplifyErrors(error.code);
  }
}
export function* isUserAuthenticated({ payload }) {
  try {
    const user = yield call([Auth, Auth.currentAuthenticatedUser]);
    const isAdminGroup =
      user.signInUserSession.accessToken.payload['cognito:groups']?.includes(
        'admins'
      );
    const currentUser = yield call(
      [API, 'graphql'],
      graphqlOperation(getUserQuery, { id: payload || user.attributes.sub })
    );

    yield put(
      signInSuccess({
        ...currentUser.data.getUser,
        isAdmin: isAdminGroup,
        isCashier: currentUser.data.getUser?.profile?.name === 'Cashier',
      })
    );
  } catch (error) {
    yield put(signInFailure(error));
  }
}
export function* signOut() {
  try {
    yield call([Auth, 'signOut']);
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* resendConfirmation({ payload: { email, code, password } }) {
  try {
    yield call([Auth, 'confirmSignUp'], email, code);
    toast.info('Usuario confirmado!');

    const user = yield call([Auth, 'signIn'], email, password);

    const currentUser = yield call(
      [API, 'graphql'],
      graphqlOperation(getUserQuery, { id: user.attributes.sub })
    );

    if (currentUser.data.getUser) {
      yield put(signInSuccess(currentUser.data.getUser));
      toast.info('Bienvenido a FINPPI');
    } else {
      yield put(userVerified());
    }
  } catch (error) {
    toast.error(error.message);
    yield put(signOutFailure(error));
  }
}

export function* signInAfterSignUp({ payload: { userId, email, password } }) {
  try {
    const currentUser = yield call(
      [API, 'graphql'],
      graphqlOperation(getUserQuery, { id: userId })
    );

    if (currentUser.data.getUser && email && password) {
      yield put(emailSignInStart({ email, password }));
    }
  } catch (error) {
    toast.error(error.message);
    yield put(signOutFailure(error));
  }
}

export function* signUp({ payload: { email, password } }) {
  try {
    const data = yield call([Auth, 'signUp'], email, password);
    /** 
      @param
      data.userSub
      data.userConfirmed
    *
    */
    yield put(signUpSuccess({ userId: data.userSub, email }));
    toast.info('Codigo enviado al email');
  } catch (error) {
    if (error.code === 'UsernameExistsException') {
      yield call([Auth, 'resendSignUp'], email);
      toast.success('Te hemos enviado un código de confirmación');
      yield put(signUpSuccess({ email }));
    } else {
      yield put(signUpFailure(error));
    }
  }
}

export function* createUserVerified({ payload }) {
  try {
    /*
      1. List AccountTypes (Ahorros).
      2. Create Company
      3. Create Account Role
      4. Create Company Notification {
          name: 'Bienvenido',
          message: 'Por favor configura tu empresa',
          date: moment().format('YYYY-MM-DD'),
          url: '/company',
          read: false,
          type: 'settings',
          notificationCompanyId: company.data.createCompany.id,
        }
      5. Create User
      
    */
    const account = yield call(
      [API, 'graphql'],
      graphqlOperation(listAccountTypes, {
        filter: { name: { eq: 'ahorros' } },
      })
    );

    const company = yield call(
      [API, 'graphql'],
      graphqlOperation(createCompany, {
        input: {
          name: payload.company,
          TAXID: payload.TAXID,
          companyMainRole: payload.position,
        },
      })
    );

    yield call(
      [API, 'graphql'],
      graphqlOperation(createTax, {
        input: {
          name: 'Ninguno',
          type: 'vat',
          percentage: 0,
          country: 'Colombia',
          taxCompanyId: company.data.createCompany.id,
        },
      })
    );

    yield call(
      [API, 'graphql'],
      graphqlOperation(createTax, {
        input: {
          name: 'IVA-0',
          type: 'vat',
          percentage: 0,
          country: 'Colombia',
          taxCompanyId: company.data.createCompany.id,
        },
      })
    );

    yield call(
      [API, 'graphql'],
      graphqlOperation(createTax, {
        input: {
          name: 'IVA-5',
          type: 'vat',
          percentage: 5,
          country: 'Colombia',
          taxCompanyId: company.data.createCompany.id,
        },
      })
    );

    yield call(
      [API, 'graphql'],
      graphqlOperation(createTax, {
        input: {
          name: 'IVA-19',
          type: 'vat',
          percentage: 19,
          country: 'Colombia',
          taxCompanyId: company.data.createCompany.id,
        },
      })
    );

    yield call(
      [API, 'graphql'],
      graphqlOperation(createAccount, {
        input: {
          bankID: 'FINPPI001',
          bankName: 'FINPPI',
          accountID: uuidv4(),
          accountTypeId: account.data.listAccountTypes.items[0].id,
          accountCompanyId: company.data.createCompany.id,
          balance: 0,
        },
      })
    );

    yield call(
      [API, 'graphql'],
      graphqlOperation(createCompanyRole, {
        input: {
          name: payload.position,
          companyRoleCompanyId: company.data.createCompany.id,
        },
      })
    );

    // yield call(
    //   [API, 'graphql'],
    //   graphqlOperation(createNotification, {
    //     input: {
    //       name: 'Bienvenido',
    //       message: 'Por favor configura tu empresa',
    //       date: moment().format('YYYY-MM-DD'),
    //       url: '/company',
    //       read: false,
    //       type: 'settings',
    //       notificationCompanyId: company.data.createCompany.id,
    //     },
    //   })
    // );

    const user = yield call(
      [API, 'graphql'],
      graphqlOperation(createUser, {
        input: {
          id: payload.userId,
          name: payload.name,
          lastname: payload.lastname,
          personType: payload.personType,
          nationalID: payload.nationalID,
          email: payload.email,
          userCompanyId: company.data.createCompany.id,
          companyRole: payload.position,
        },
      })
    );

    yield put(signInSuccess(user.data.createUser));
    toast.success('Bienvenido a la familia Finppi! ');
  } catch (error) {
    console.log(error);
    yield put(signUpFailure(error));
  }
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* onUserStartVerification() {
  yield takeLatest(UserActionTypes.USER_VERIFIED_START, resendConfirmation);
}
export function* verifiedAfterSignUp() {
  yield takeLatest(UserActionTypes.USER_VERIFIED_SIGN_UP, createUserVerified);
}
export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}
export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onUserStartVerification),
    call(onSignUpStart),
    call(verifiedAfterSignUp),
  ]);
}
