import WrapperActionTypes from './types';

export const setActiveWrapperView = (props) => ({
  type: WrapperActionTypes.ACTIVE_VIEW,
  payload: props,
});

export const setDesactiveWrapperView = () => ({
  type: WrapperActionTypes.DESACTIVE_VIEW,
});
