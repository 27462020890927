export const PersonTypeOptions = [
  { value: 'natural', label: 'Natural', key: 23 },
  { value: 'juridica', label: 'Juridica', key: 24 },
];

export const RolesOptions = [
  { label: 'Directivo', value: 'Directivo', key: 1 },
  { label: 'Administrativo', value: 'Administrativo', key: 2 },
  { label: 'Profesional', value: 'Profesional', key: 3 },
  { label: 'Operativo', value: 'Operativo', key: 4 },
  { label: 'Auxiliar', value: 'Auxiliar', key: 5 },
  { label: 'Técnico', value: 'Técnico', key: 6 },
  { label: 'Asistencial', value: 'Asistencial', key: 7 },
  { label: 'Otro', value: 'Otro', key: 8 },
];

export const DichotomyValues = [
  { value: 'true', key: 1, label: 'Si' },
  { value: 'false', key: 2, label: 'No' },
];

export const BankAccountTypeOptions = [
  { value: 'Ahorros', key: 1, label: 'Ahorros' },
  { value: 'Corriente', key: 2, label: 'Corriente' },
];

export const BankNameOptions = [
  { value: 'Bancamía S.A.', key: 1, label: 'Bancamía S.A.' },
  { value: 'Bancolombia ', key: 2, label: 'Bancolombia' },
  { value: 'Bancoomeva', key: 3, label: 'Bancoomeva' },
  { value: 'Banco AV Villas', key: 4, label: 'Banco AV Villas' },
  { value: 'Banco Caja Social', key: 5, label: 'Banco Caja Social' },
  {
    value: 'Banco Credifinanciera S.A.',
    key: 6,
    label: 'Banco Credifinanciera S.A.',
  },
  { value: 'Banco de Bogotá', key: 7, label: 'Banco de Bogotá' },
  {
    value: 'Banco de Occidente S.A.',
    key: 8,
    label: 'Banco de Occidente S.A.',
  },
  { value: 'Banco Falabella S.A.', key: 9, label: 'Banco Falabella S.A.' },
  { value: 'Banco Finandina S.A.', key: 10, label: 'Banco Finandina S.A.' },
  {
    value: 'Banco GNB Sudameris S.A.',
    key: 11,
    label: 'Banco GNB Sudameris S.A.',
  },
  { value: 'Banco J.P. MORGAN', key: 12, label: 'Banco J.P. MORGAN' },
  { value: 'Banco Mundo Mujer S.A.', key: 13, label: 'Banco Mundo Mujer S.A.' },
  { value: 'Banco Pichincha S.A.', key: 14, label: 'Banco Pichincha S.A.' },
  {
    value: 'Banco Santander De Negocios Colombia S.A.',
    key: 15,
    label: 'Banco Santander De Negocios Colombia S.A.',
  },
  { value: 'BBVA', key: 16, label: 'BBVA' },
  { value: 'Banco W S.A.', key: 17, label: 'Banco W S.A.' },
  { value: 'Coopcentral', key: 18, label: 'Coopcentral' },
  { value: 'Citibank', key: 19, label: 'Citibank' },
  { value: 'Davivienda', key: 20, label: 'Davivienda' },
  { value: 'Mibanco', key: 21, label: 'Mibanco' },
  { value: 'Itaú', key: 22, label: 'Itaú' },
  {
    value: 'Scotibank Colpatria S.A.',
    key: 23,
    label: 'Scotibank Colpatria S.A.',
  },
  { value: 'Banco Popular S.A.', key: 24, label: 'Banco Popular S.A.' },
  {
    value: 'Banco Agrario de Colombia S.A.',
    key: 25,
    label: 'Banco Agrario de Colombia S.A.',
  },
  { value: 'Banco Serfinanza S.A', key: 26, label: 'Banco Serfinanza S.A' },
];
export const LevelOptions = [
  { value: '0', key: 1, label: 'Sin educación' },
  { value: '1', key: 2, label: 'Primaria' },
  { value: '2', key: 3, label: 'Bachillerato' },
  { value: '3', key: 4, label: 'Profesional' },
  { value: '4', key: 5, label: 'Magister' },
  { value: '5', key: 6, label: 'phD' },
];

export const PeriodPayrollOptions = [
  { value: '1', key: 1, label: 'Semanal' },
  { value: '2', key: 2, label: 'Decenal' },
  { value: '3', key: 3, label: 'Catorcenal' },
  { value: '4', key: 4, label: 'Quincenal' },
  { value: '5', key: 5, label: 'Mensual' },
  { value: '6', key: 6, label: 'Otro' },
];

export const MonthOptions = [
  { value: 'Enero', key: 1, label: 'Enero' },
  { value: 'Febrero', key: 2, label: 'Febrero' },
  { value: 'Marzo', key: 3, label: 'Marzo' },
  { value: 'Abril', key: 4, label: 'Abril' },
  { value: 'Mayo', key: 5, label: 'Mayo' },
  { value: 'Junio', key: 6, label: 'Junio' },
  { value: 'Julio', key: 7, label: 'Julio' },
  { value: 'Agosto', key: 8, label: 'Agosto' },
  { value: 'Septiembre', key: 9, label: 'Septiembre' },
  { value: 'Octubre', key: 10, label: 'Octubre' },
  { value: 'Noviembre', key: 11, label: 'Noviembre' },
  { value: 'Diciembre', key: 12, label: 'Diciembre' },
];
