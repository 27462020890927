import React, { useMemo, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, Col, Row } from 'reactstrap';
import CurrencyUsd from 'mdi-react/CurrencyUsdIcon';
import CreditCard from 'mdi-react/CreditCardIcon';
import Bank from 'mdi-react/BankIcon';
import AccountCredit from 'mdi-react/CreditCardClockIcon';
import ModalPos from './ModalPos';
import Cash from '../Form/payments/Cash';
import Transfer from '../Form/payments/Transfer';
import CardPayment from '../Form/payments/Card';
import Combined from '../Form/payments/Combined';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { getTerminalCashier } from '../index';

const CardData = ({
  products,
  handleCreatePosDocument,
  validateEnableButton,
  formatNumber,
  paymentInfo,
  setPaymentInfo,
  setInvoiceType,
}) => {
  const turnPos = useSelector((state) => state.pos);
  const [modal, setModal] = useState(false);
  const [currentPayment, setCurrentPayment] = useState(null);
  const [isDiscountPercentage, setIsDiscountPercentage] = useState(true);
  const subtotal = _.sumBy(products, 'total');
  const iva = _.sumBy(products, 'totalIVA');
  const inc = _.sumBy(products, 'totalINC');
  const totalOnly = Math.round(subtotal + iva + inc);
  const [totalWithDiscount, setTotalWithDiscount] = useState(totalOnly);

  const showAlertConfirmInvoice = async (paymentShow) => {
    const getTerminalCashierQ = await API.graphql(
      graphqlOperation(getTerminalCashier, {
        id: turnPos.idTurn,
      })
    );

    Swal.fire({
      title: 'Atención',
      text: 'Esta venta supera el valor de $212.060 (5 U.V.T. Ley 2155 de 2021). ¿Deseas que esta factura sea electrónica?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (
          getTerminalCashierQ?.data?.getTerminalCashier?.terminal
            ?.invoiceResolution
        ) {
          setInvoiceType(2);
          setModal(true);
          setCurrentPayment(paymentShow);
        } else {
          toast.error(
            'No tienes resolucion configurada para emitir facturas electrónicas'
          );
        }
      } else {
        setInvoiceType(1);
        setModal(true);
        setCurrentPayment(paymentShow);
      }
    });
  };

  const calculateSum = (id) => {
    return Number(_.sumBy(products, id.toString())).toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSubmitPayment = (currentPayment) => {
    if (!validateEnableButton) {
      toast.warning('Selecciona un cliente y por lo menos un producto');
    } else {
      if (totalOnly > 212060) {
        showAlertConfirmInvoice(currentPayment?.toString());
      } else {
        setModal(true);
        setCurrentPayment(currentPayment?.toString());
      }
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    setCurrentPayment(null);
  };

  const handleChangePayment = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setPaymentInfo((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };
  const handleChangeDiscount = () => {
    setIsDiscountPercentage((prev) => !prev);
    handleChangePayment({ target: { value: 0, name: 'discount' } });
  };
  const renderModalPayment = (currentPayment) => {
    switch (currentPayment) {
      case 'cash':
        return (
          <Cash
            total={totalOnly}
            {...{
              createPosDocument,
              handleChangePayment,
              paymentInfo,
              setTotalWithDiscount,
              formatNumber,
              isDiscountPercentage,
              handleChangeDiscount,
            }}
          />
        );
      case 'transfer':
        return (
          <Transfer
            total={totalOnly}
            {...{
              createPosDocument,
              handleChangePayment,
              paymentInfo,
              setTotalWithDiscount,
              formatNumber,
              isDiscountPercentage,
              handleChangeDiscount,
            }}
          />
        );
      case 'cardPayment':
        return (
          <CardPayment
            total={totalOnly}
            {...{
              createPosDocument,
              handleChangePayment,
              paymentInfo,
              setTotalWithDiscount,
              formatNumber,
              isDiscountPercentage,
              handleChangeDiscount,
            }}
          />
        );
      case 'combined':
        return (
          <Combined
            total={totalOnly}
            {...{
              createPosDocument,
              handleChangePayment,
              paymentInfo,
              setTotalWithDiscount,
              formatNumber,
              isDiscountPercentage,
              handleChangeDiscount,
            }}
          />
        );
      default:
        return <div />;
    }
  };

  const createPosDocument = (isDraft = false) => {
    const data = {
      total: totalWithDiscount,
      totalWithoutDiscount: totalOnly,
      payment: {
        ...paymentInfo,
        type: currentPayment,
        isDiscountPercentage,
      },
    };

    handleCreatePosDocument(data, isDraft);
    handleCloseModal();
  };
  return (
    <div className="card-data-container">
      <Col>
        <Row xl={12}>
          <Col>
            <p>Subtotal</p>
          </Col>
          <Col>
            <p className="text-align-right mr-5 bold-text">
              {calculateSum('total')}
            </p>
          </Col>
        </Row>
        <Row xl={12}>
          <Col>
            <p>IVA (19%)</p>
          </Col>
          <Col>
            <p className="text-align-right mr-5 bold-text">
              {calculateSum('totalIVA')}
            </p>
          </Col>
        </Row>
        <Row xl={12}>
          <Col>
            <p>INC (8%)</p>
          </Col>
          <Col>
            <p className="text-align-right mr-5 bold-text">
              {calculateSum('totalINC')}
            </p>
          </Col>
        </Row>
        <hr />
        <Row xl={12}>
          <Col>
            <p>Total</p>
          </Col>
          <Col>
            <p className="text-align-right mr-5 bold-text">
              {formatNumber(totalOnly)}
            </p>
          </Col>
        </Row>

        <p>Metodo de pago</p>
        <Col xl={12} className="mb-3">
          <Row>
            <Button
              outline
              size="sm"
              onClick={() => handleSubmitPayment('cash')}
              active={currentPayment === 'cash'}
            >
              <p>
                <CurrencyUsd />
                Efectivo
              </p>
            </Button>
            <Button
              outline
              size="sm"
              onClick={() => handleSubmitPayment('cardPayment')}
              active={currentPayment === 'cardPayment'}
            >
              <p>
                <CreditCard />
                Tarjeta
              </p>
            </Button>
            <Button
              outline
              size="sm"
              onClick={() => handleSubmitPayment('transfer')}
              active={currentPayment === 'transfer'}
            >
              <p>
                <Bank />
                Transfer
              </p>
            </Button>
            <Button
              outline
              size="sm"
              onClick={() => handleSubmitPayment('combined')}
              active={currentPayment === 'combined'}
            >
              <p>
                <AccountCredit />
                Combinado
              </p>
            </Button>
            <Col>
              <Button
                outline
                size="sm"
                className="text-align-right"
                onClick={() => createPosDocument(true)}
              >
                <p>Guardar</p>
              </Button>
            </Col>
          </Row>
        </Col>

        <ModalPos
          open={modal}
          setOpen={setModal}
          toggle={handleCloseModal}
          title="Pagar factura"
        >
          {renderModalPayment(currentPayment)}
        </ModalPos>
      </Col>
    </div>
  );
};

export default CardData;
