import { useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { graphqlOperation, API } from 'aws-amplify';

import * as Yup from 'yup';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createCustomer, updateCustomer } from '../../../../graphql/mutations';
import _ from 'lodash';
import {
  useGetCustomer,
  useIdType,
  useRegimenType,
  useFiscalResponsability,
} from '../../../../shared/hooks/Customers';
import { listCustomers } from '../../../../graphql/queries';
import { selectWrapperStateView } from '../../../../redux/wrapper/selectors';
import { useMutation, useQueryClient } from 'react-query';
import queryKeys from '../../../../shared/hooks/queryKeys';
import { TrackerEvent } from '../../../../utils/logging';
import moment from 'moment';
import { getCustomerByCompany } from '../../../../shared/hooks/Customers/queries';

export const schema = Yup.object({
  nationalID: Yup.string().required('Documento de identificación requerido'),
  verificationDigit: Yup.number().min(-1),
  name: Yup.string(),
  lastname: Yup.string(),
  businessName: Yup.string(),
  active: Yup.boolean().oneOf([true, false]),
  locations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Nombre requerido'),
        address: Yup.object().shape({
          country: Yup.string().required('Pais requerido'),
          city: Yup.string(),
          cityCode: Yup.string(),
          address_line1: Yup.string().required('Direccion requerido'),
          address_line2: Yup.string(),
          address_zip_code: Yup.string().required(),
          tags: Yup.array().of(
            Yup.object().shape({
              name: Yup.string(), //.required('Nombre es requerido'),
              value: Yup.string(),
            })
          ),
        }),
        headquarters: Yup.boolean().oneOf([true, false]),
        branchCode: Yup.string(),
        phoneNumbers: Yup.array().of(
          Yup.object().shape({
            countryCode: Yup.string().required('Codigo requerido'),
            number: Yup.number().required('Nombre requerido'),
            extension: Yup.string(),
          })
        ),
      })
    )
    .min(1),
  phoneNumber: Yup.array().of(
    Yup.object().shape({
      countryCode: Yup.string().required('Codigo del pais'),
      number: Yup.number().required('Numero requerido'),
      extension: Yup.string(),
    })
  ),
  contacts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      lastname: Yup.string(),
      title: Yup.string(),
      email: Yup.string().required('Email requerido'),

      phoneNumber: Yup.object().shape({
        countryCode: Yup.string().required('Codigo del pais'),
        number: Yup.number().required('Numero requerido'),
        extension: Yup.string(),
        tags: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('Nombre es requerido'),
            value: Yup.string(),
          })
        ),
      }),
      main: Yup.boolean().oneOf([true, false]),
    })
  ),
  tags: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Campo es requerido'),
      value: Yup.string(),
    })
  ),
  customerIdTypeId: Yup.string().required('Tipo de documento'),
  customerRegimenTypeId: Yup.string().required('Regimen requerido'),
  customerFiscalResponsabilityId: Yup.string().required(
    'Responsabilidad requerida'
  ),
});

const initialValues = {
  nationalID: '',
  verificationDigit: 0,
  name: '',
  personType: 'natural',
  lastname: '',
  businessName: '',
  defaultLocation: 0,
  active: true,
  locations: [
    {
      name: 'Oficina principal',
      phoneNumbers: [
        {
          countryCode: '+57',
          number: '',
          extension: '',
        },
      ],
      address: {
        country: 'Colombia',
        city: '',
        address_line1: '',
        address_line2: '',
        address_zip_code: '35',
      },
      headquarters: false,
      branchCode: '',
    },
  ],
  phoneNumber: [],
  contacts: [
    {
      name: '',
      lastname: '',
      title: '',
      email: '',
      phoneNumber: {
        countryCode: '+57',
        number: '',
        extension: '',
      },
      main: false,
    },
  ],
  tags: [],
  customerIdTypeId: '',
  customerRegimenTypeId: '',
  customerFiscalResponsabilityId: '',
};

export function useDefaultValues() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const isOpen = useSelector(selectWrapperStateView);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { idTypeOptions, isLoading: isLoadingIdTypeOptions } = useIdType();
  const { regimenTypeOptions, isLoading: isLoadingRegimenTypeOptions } =
    useRegimenType();
  const { fiscalOptions, isLoading: isLoadingFiscalOptions } =
    useFiscalResponsability();

  const { customer, isLoading: isLoadingCustomer } = useGetCustomer();

  const params = useParams();

  const updateCustomerForm = useCallback(
    async (values) => {
      try {
        if (!values.name && !values.businessName)
          return toast.error('Escribe un nombre o nombre comercial.');
        setLoading(true);
        const mappedValues = { ...values };

        if (values.locations)
          for (let loc of mappedValues.locations) {
            if (!loc.phoneNumbers) loc.phoneNumbers = [];
            if (!loc.tags) loc.tags = [];
          }
        if (values.contacts)
          for (let cont of mappedValues.contacts) {
            if (cont.location) cont.location.phoneNumbers = [];
            if (!cont.location) delete cont['location'];
            if (!cont.tags) cont.tags = [];
          }

        await API.graphql(
          graphqlOperation(updateCustomer, {
            input: {
              id: params.id,
              ...mappedValues,
            },
          })
        );

        TrackerEvent({
          logCompanyId: currentUser.company.id,
          description: 'Actualizaci\u00F3n cliente',
          timestamp: moment().toISOString(),
          operationType: 'ADMINISTRATIVE',
          subOperationType: 'CUSTOMER_UPDATED',
          url: `/customers/edit/${params.id}`,
          user: currentUser.id,
        });

        toast.info('Cliente actualizado!');
        if (!isOpen) navigate('/customers');
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [currentUser.company.id, currentUser.id, isOpen, navigate, params.id]
  );

  const createCustomerForm = useCallback(
    async (values) => {
      try {
        setActive(true);
        if (!values.name && !values.businessName)
          return toast.error('Escribe un nombre o nombre comercial.');
        setLoading(true);
        let next = null;
        let third = [];
        do {
          const thirds = await API.graphql(
            graphqlOperation(getCustomerByCompany, {
              id: currentUser.company.id,
              filter: {
                nationalID: { eq: values.nationalID },
              },
              nextToken: next,
            })
          );
          console.log(thirds);
          third.push(...thirds.data.getCompany.customers.items);
          next = thirds.data.getCompany.customers.nextToken;
        } while (next);

        if (third.length > 0) {
          return toast.error('El cliente ya existe');
        }

        const mappedValues = { ...values };

        if (values.locations)
          for (let loc of mappedValues.locations)
            if (!loc.phoneNumbers) loc.phoneNumbers = [];
        if (values.contacts)
          for (let cont of mappedValues.contacts)
            if (cont.location) cont.location.phoneNumbers = [];

        const customerC = await API.graphql(
          graphqlOperation(createCustomer, {
            input: {
              ...mappedValues,
              customerCompanyId: currentUser.company.id,
            },
          })
        );
        /* if (supplier) {
          delete Object.assign(mappedValues, {
            supplierIdTypeId: mappedValues['customerIdTypeId'],
          })['customerIdTypeId'];
          delete Object.assign(mappedValues, {
            supplierRegimenTypeId: mappedValues['customerRegimenTypeId'],
          })['customerRegimenTypeId'];
          delete Object.assign(mappedValues, {
            supplierFiscalResponsabilityId:
              mappedValues['customerFiscalResponsabilityId'],
          })['customerFiscalResponsabilityId'];
          await API.graphql(
            graphqlOperation(createSupplier, {
              input: {
                ...mappedValues,
                supplierCompanyId: currentUser.company.id,
              },
            })
          );
        } */

        TrackerEvent({
          logCompanyId: currentUser.company.id,
          description: 'Creaci\u00F3n cliente',
          timestamp: moment().toISOString(),
          operationType: 'ADMINISTRATIVE',
          subOperationType: 'CUSTOMER_CREATED',
          url: `/customers/edit/${customerC.data.createCustomer.id}`,
          user: currentUser.id,
        });

        toast.info('Cliente creado');
        if (!isOpen) navigate('/customers');
      } catch (error) {
        console.error(error);
      } finally {
        setActive(false);
        setLoading(false);
      }
    },
    [currentUser.company.id, currentUser.id, isOpen, navigate]
  );
  const { mutate } = useMutation(
    params.id ? updateCustomerForm : createCustomerForm,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.customers]);
      },
    }
  );
  return {
    isLoading:
      loading ||
      isLoadingCustomer ||
      isLoadingIdTypeOptions ||
      isLoadingRegimenTypeOptions ||
      isLoadingFiscalOptions,
    formValues: {
      defaultValues: _.isEmpty(customer) ? initialValues : customer,
      idTypeOptions,
      regimenTypeOptions,
      fiscalOptions,
      active,
    },
    submit: mutate,
    status: params.id ? 'Actualizar' : 'Crear',
  };
}
