import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import Spinner from './rocket.json';

const Loading = () => {
  return (
    <div className="loading-rocket">
      <Lottie
        style={{
          display: 'inline-block',
          width: '15rem',
          height: '15rem',
        }}
        animationData={Spinner}
        loop
        autoPlay
      />
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
};

export default Loading;
