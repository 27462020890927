import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProps, RTLProps } from '../../shared/prop-types/ReducerProps';
import { useLocation } from 'react-router';

const MainWrapper = ({ theme, children, rtl }) => {
  const location = useLocation();
  const direction = location.pathname === '/' ? 'ltr' : rtl.direction;

  return (
    <div className={`${theme.className} ${direction}-support`} dir={direction}>
      <div className="wrapper">{children}</div>
    </div>
  );
};

MainWrapper.propTypes = {
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect((state) => ({
  theme: state.theme,
  rtl: state.rtl,
}))(MainWrapper);
