import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import AccountPlus from 'mdi-react/AccountPlusIcon';
import { useSelector } from 'react-redux';
import CardProductForm from '../components/CardProductForm';
import CardData from '../components/CardData';
import { setActiveWrapperView } from '../../../../redux/wrapper/actions';
import CustomerForm from '../../../Thridparty/Customers/components/Form';
import Dropdown from '../../../../shared/components/form/Dropdown';
import { useCustomers } from '../../../../shared/hooks/Customers';
import Loading from '../../../../shared/components/Loading';

const getSalesmenByCompany = `
query GetCompany($id: ID!, $nextToken: String ) {
  getCompany(id: $id) {
      salesmen (limit: 1000, nextToken: $nextToken) {
      items {
        name
        id
        lastname
      }
      nextToken
    }
  }
}
`;

const Form = ({
  products,
  removeProduct,
  addQuantity,
  removeQuantity,
  dispatch,
  handleCreatePosDocument,
  setCustomerID,
  customerID,
  validateEnableButton,
  loading,
  formatNumber,
  setSalesman,
  currentPOS,
  paymentInfo,
  setPaymentInfo,
  invoiceType,
  setInvoiceType,
  customers,
}) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [salesmenOptions, setSalesmenOptions] = useState([]);
  const optionsCustomer = useMemo(() => {
    return customers?.map((item) => ({
      value: item.id,
      key: item.id,
      label: _.capitalize(item.fullName),
    }));
  }, [customers]);

  const fetchAllSalesman = async () => {
    try {
      let salesman = [];
      let nextToken = null;

      do {
        const resultSalesman = await API.graphql(
          graphqlOperation(getSalesmenByCompany, {
            id: currentUser.company.id,
            nextToken,
          })
        );
        salesman.push(...resultSalesman.data.getCompany.salesmen.items);
        nextToken = resultSalesman.data.getCompany.salesmen.nextToken;
      } while (nextToken);

      return setSalesmenOptions(
        salesman?.map((item) => ({
          value: item.id,
          key: item.id,
          label: _.capitalize(`${item.name} ${item.lastname}`),
        }))
      );
    } catch (error) {
      console.error('fetchAllSalesman', error);
    }
  };

  useEffect(() => {
    fetchAllSalesman();
  }, []);

  return (
    <div className="container-form">
      <Col>
        <Row className="mb-2">
          <Col md={6} xl={6} lg={6} xs={12}>
            <h4 className="bold-text">Factura de venta {currentPOS?.code}</h4>
            <Dropdown
              placeholder="Seleccione"
              options={[
                { label: 'POS', value: 1 },
                { label: 'Factura electronica', value: 2 },
              ]}
              onChange={(value) => setInvoiceType(value)}
              value={invoiceType}
            />
          </Col>
          <Col md={6} xl={6} lg={4} xs={12}>
            <p className="bold-text mb-1">Vendedor</p>
            <Dropdown
              isClearable
              placeholder="Seleccione"
              options={salesmenOptions}
              onChange={(value) => setSalesman(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} xl={6} lg={6} xs={12} className="mb-2">
            <p className="bold-text">Cliente*</p>
            <Dropdown
              placeholder="Seleccione"
              options={optionsCustomer}
              onChange={(value) => setCustomerID(value)}
              value={customerID}
            />
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Button
              outline
              className="text-align-right mt-4"
              size="sm"
              onClick={() =>
                dispatch(
                  setActiveWrapperView({
                    View: CustomerForm,
                    props: {
                      title: 'Crear Cliente',
                    },
                  })
                )
              }
            >
              <p>
                <AccountPlus /> Agregar
              </p>
            </Button>
          </Col>
        </Row>
      </Col>
      {loading && <Loading />}
      {!loading && (
        <>
          <Col>
            {products?.length < 1 && (
              <div className="container-card-product-form p-4" />
            )}
            <div className="wrapper-cards">
              {products?.length >= 1 &&
                products.map((item) => (
                  <CardProductForm
                    dataProduct={item}
                    {...{ removeProduct, addQuantity, removeQuantity }}
                  />
                ))}
            </div>
          </Col>
          <Col>
            <CardData
              {...{
                products,
                handleCreatePosDocument,
                validateEnableButton,
                formatNumber,
                paymentInfo,
                setPaymentInfo,
                setInvoiceType,
              }}
            />
          </Col>
        </>
      )}
    </div>
  );
};

export default Form;
