/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCompany(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      TAXID
      verificationDigit
      personType
      constitutionDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      isKeyPartner
      tags {
        name
        value
        __typename
      }
      companyMainRole
      signatures {
        fullName
        prefix
        title
        __typename
      }
      defaultSignature
      bankingEnabled
      restrictiveLists
      PEPS
      PEPSCategory
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      referrals {
        name
        email
        date
        __typename
      }
      lastDateOfReferral
      readers
      editors
      createdAt
      updatedAt
      owner
      configuration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      plan {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      documents {
        nextToken
        __typename
      }
      industries {
        nextToken
        __typename
      }
      forms {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      badges {
        nextToken
        __typename
      }
      supportTickets {
        nextToken
        __typename
      }
      groupCategories {
        nextToken
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      taxes {
        nextToken
        __typename
      }
      logging {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      terminals {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      cashiers {
        nextToken
        __typename
      }
      salesmen {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      epayroll {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      bags {
        nextToken
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      productServiceTypes {
        nextToken
        __typename
      }
      supplierProductsServices {
        nextToken
        __typename
      }
      keyPartnersProductsServices {
        nextToken
        __typename
      }
      customers {
        nextToken
        __typename
      }
      suppliers {
        nextToken
        __typename
      }
      patients {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      commissionsAndTips {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCompany(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      TAXID
      verificationDigit
      personType
      constitutionDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      isKeyPartner
      tags {
        name
        value
        __typename
      }
      companyMainRole
      signatures {
        fullName
        prefix
        title
        __typename
      }
      defaultSignature
      bankingEnabled
      restrictiveLists
      PEPS
      PEPSCategory
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      referrals {
        name
        email
        date
        __typename
      }
      lastDateOfReferral
      readers
      editors
      createdAt
      updatedAt
      owner
      configuration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      plan {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      documents {
        nextToken
        __typename
      }
      industries {
        nextToken
        __typename
      }
      forms {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      badges {
        nextToken
        __typename
      }
      supportTickets {
        nextToken
        __typename
      }
      groupCategories {
        nextToken
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      taxes {
        nextToken
        __typename
      }
      logging {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      terminals {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      cashiers {
        nextToken
        __typename
      }
      salesmen {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      epayroll {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      bags {
        nextToken
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      productServiceTypes {
        nextToken
        __typename
      }
      supplierProductsServices {
        nextToken
        __typename
      }
      keyPartnersProductsServices {
        nextToken
        __typename
      }
      customers {
        nextToken
        __typename
      }
      suppliers {
        nextToken
        __typename
      }
      patients {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      commissionsAndTips {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCompany(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      TAXID
      verificationDigit
      personType
      constitutionDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      isKeyPartner
      tags {
        name
        value
        __typename
      }
      companyMainRole
      signatures {
        fullName
        prefix
        title
        __typename
      }
      defaultSignature
      bankingEnabled
      restrictiveLists
      PEPS
      PEPSCategory
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      referrals {
        name
        email
        date
        __typename
      }
      lastDateOfReferral
      readers
      editors
      createdAt
      updatedAt
      owner
      configuration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      plan {
        id
        initDate
        endDate
        active
        data
        log
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      documents {
        nextToken
        __typename
      }
      industries {
        nextToken
        __typename
      }
      forms {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      badges {
        nextToken
        __typename
      }
      supportTickets {
        nextToken
        __typename
      }
      groupCategories {
        nextToken
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      taxes {
        nextToken
        __typename
      }
      logging {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      terminals {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      cashiers {
        nextToken
        __typename
      }
      salesmen {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      epayroll {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      bags {
        nextToken
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      productServiceTypes {
        nextToken
        __typename
      }
      supplierProductsServices {
        nextToken
        __typename
      }
      keyPartnersProductsServices {
        nextToken
        __typename
      }
      customers {
        nextToken
        __typename
      }
      suppliers {
        nextToken
        __typename
      }
      patients {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      commissionsAndTips {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePublicCompany = /* GraphQL */ `
  subscription OnCreatePublicCompany {
    onCreatePublicCompany {
      id
      name
      url
      description
      lemma
      industries
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      defaultContact
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      bannerPhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      productServices
      readers
      editors
      createdAt
      updatedAt
      owner
      posts {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePublicCompany = /* GraphQL */ `
  subscription OnUpdatePublicCompany {
    onUpdatePublicCompany {
      id
      name
      url
      description
      lemma
      industries
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      defaultContact
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      bannerPhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      productServices
      readers
      editors
      createdAt
      updatedAt
      owner
      posts {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePublicCompany = /* GraphQL */ `
  subscription OnDeletePublicCompany {
    onDeletePublicCompany {
      id
      name
      url
      description
      lemma
      industries
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      defaultContact
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      bannerPhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      productServices
      readers
      editors
      createdAt
      updatedAt
      owner
      posts {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyConfiguration = /* GraphQL */ `
  subscription OnCreateCompanyConfiguration(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCompanyConfiguration(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        __typename
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        __typename
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
        __typename
      }
      branches {
        name
        code
        description
        currentBillNumber
        __typename
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      adjustmentNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      decimals
      decimalsQuantity
      emailForInvoicing
      eInvoiceHeaderExtraFields {
        name
        value
        __typename
      }
      eInvoiceFooterExtraFields {
        name
        value
        __typename
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      currentEventsNumber
      billsReceiverData
      sendSuppliersEmail
      quotationsPrefixAndNumbering
      cashReceiptsPrefixAndNumbering
      dischargeReceiptsPrefixAndNumbering
      signInvoices
      signSupportDocuments
      signCashReceipts
      signDischargeReceipt
      adjustTaxBase
      healthProvider
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      productServicesConfiguration
      showBatchAndExpirationDate
      warehousesConfiguration
      pointOfSalesConfiguration
      terminalsConfiguration
      POSEnabled
      POSResolutions
      cashiersConfiguration
      salesmenConfiguration
      POSDocumentConfiguration
      defaultToInvoiceIfHigherValue
      storagePath
      loggingVerbosityLevel
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsabilities {
        nextToken
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompanyConfiguration = /* GraphQL */ `
  subscription OnUpdateCompanyConfiguration(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCompanyConfiguration(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        __typename
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        __typename
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
        __typename
      }
      branches {
        name
        code
        description
        currentBillNumber
        __typename
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      adjustmentNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      decimals
      decimalsQuantity
      emailForInvoicing
      eInvoiceHeaderExtraFields {
        name
        value
        __typename
      }
      eInvoiceFooterExtraFields {
        name
        value
        __typename
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      currentEventsNumber
      billsReceiverData
      sendSuppliersEmail
      quotationsPrefixAndNumbering
      cashReceiptsPrefixAndNumbering
      dischargeReceiptsPrefixAndNumbering
      signInvoices
      signSupportDocuments
      signCashReceipts
      signDischargeReceipt
      adjustTaxBase
      healthProvider
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      productServicesConfiguration
      showBatchAndExpirationDate
      warehousesConfiguration
      pointOfSalesConfiguration
      terminalsConfiguration
      POSEnabled
      POSResolutions
      cashiersConfiguration
      salesmenConfiguration
      POSDocumentConfiguration
      defaultToInvoiceIfHigherValue
      storagePath
      loggingVerbosityLevel
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsabilities {
        nextToken
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompanyConfiguration = /* GraphQL */ `
  subscription OnDeleteCompanyConfiguration(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCompanyConfiguration(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      resolutions {
        name
        branchCode
        invoicePrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentInvoiceNumber
        invoicesResolution
        __typename
      }
      equivalentDocumentResolutions {
        name
        branchCode
        equivalentDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentEquivalentDocumentNumber
        equivalentDocumentResolution
        __typename
      }
      payrolls {
        prefix
        name
        currentPayrollNumber
        __typename
      }
      branches {
        name
        code
        description
        currentBillNumber
        __typename
      }
      creditNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      debitNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      adjustmentNotesConfiguration {
        branchCode
        notePrefix
        currentNoteNumber
        __typename
      }
      decimals
      decimalsQuantity
      emailForInvoicing
      eInvoiceHeaderExtraFields {
        name
        value
        __typename
      }
      eInvoiceFooterExtraFields {
        name
        value
        __typename
      }
      defaultTermsAndConditionsForInvoice
      ePayrollExtras
      ePayrollDeductions
      currentEventsNumber
      billsReceiverData
      sendSuppliersEmail
      quotationsPrefixAndNumbering
      cashReceiptsPrefixAndNumbering
      dischargeReceiptsPrefixAndNumbering
      signInvoices
      signSupportDocuments
      signCashReceipts
      signDischargeReceipt
      adjustTaxBase
      healthProvider
      discountRetentions
      healthServicesLenderCode
      eInvoicingDIANEnabled
      ePayrollDIANEnabled
      productServicesConfiguration
      showBatchAndExpirationDate
      warehousesConfiguration
      pointOfSalesConfiguration
      terminalsConfiguration
      POSEnabled
      POSResolutions
      cashiersConfiguration
      salesmenConfiguration
      POSDocumentConfiguration
      defaultToInvoiceIfHigherValue
      storagePath
      loggingVerbosityLevel
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsabilities {
        nextToken
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      eInvoiceProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      ePayrollProvider {
        id
        name
        apiKeyName
        apiKeyValue
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyConfigurationFiscalResponsability = /* GraphQL */ `
  subscription OnCreateCompanyConfigurationFiscalResponsability(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCompanyConfigurationFiscalResponsability(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyConfigurationID
      fiscalResponsabilityID
      readers
      editors
      createdAt
      updatedAt
      companyConfiguration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompanyConfigurationFiscalResponsability = /* GraphQL */ `
  subscription OnUpdateCompanyConfigurationFiscalResponsability(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCompanyConfigurationFiscalResponsability(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyConfigurationID
      fiscalResponsabilityID
      readers
      editors
      createdAt
      updatedAt
      companyConfiguration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompanyConfigurationFiscalResponsability = /* GraphQL */ `
  subscription OnDeleteCompanyConfigurationFiscalResponsability(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCompanyConfigurationFiscalResponsability(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyConfigurationID
      fiscalResponsabilityID
      readers
      editors
      createdAt
      updatedAt
      companyConfiguration {
        id
        decimals
        decimalsQuantity
        emailForInvoicing
        defaultTermsAndConditionsForInvoice
        ePayrollExtras
        ePayrollDeductions
        currentEventsNumber
        billsReceiverData
        sendSuppliersEmail
        quotationsPrefixAndNumbering
        cashReceiptsPrefixAndNumbering
        dischargeReceiptsPrefixAndNumbering
        signInvoices
        signSupportDocuments
        signCashReceipts
        signDischargeReceipt
        adjustTaxBase
        healthProvider
        discountRetentions
        healthServicesLenderCode
        eInvoicingDIANEnabled
        ePayrollDIANEnabled
        productServicesConfiguration
        showBatchAndExpirationDate
        warehousesConfiguration
        pointOfSalesConfiguration
        terminalsConfiguration
        POSEnabled
        POSResolutions
        cashiersConfiguration
        salesmenConfiguration
        POSDocumentConfiguration
        defaultToInvoiceIfHigherValue
        storagePath
        loggingVerbosityLevel
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMyPlan = /* GraphQL */ `
  subscription OnCreateMyPlan(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateMyPlan(owner: $owner, readers: $readers, editors: $editors) {
      id
      initDate
      endDate
      active
      data
      log
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMyPlan = /* GraphQL */ `
  subscription OnUpdateMyPlan(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateMyPlan(owner: $owner, readers: $readers, editors: $editors) {
      id
      initDate
      endDate
      active
      data
      log
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMyPlan = /* GraphQL */ `
  subscription OnDeleteMyPlan(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteMyPlan(owner: $owner, readers: $readers, editors: $editors) {
      id
      initDate
      endDate
      active
      data
      log
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyDocument = /* GraphQL */ `
  subscription OnCreateCompanyDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCompanyDocument(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      location {
        bucket
        region
        key
        __typename
      }
      date
      expires
      validUntil
      public
      metadata
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompanyDocument = /* GraphQL */ `
  subscription OnUpdateCompanyDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCompanyDocument(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      location {
        bucket
        region
        key
        __typename
      }
      date
      expires
      validUntil
      public
      metadata
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompanyDocument = /* GraphQL */ `
  subscription OnDeleteCompanyDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCompanyDocument(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      location {
        bucket
        region
        key
        __typename
      }
      date
      expires
      validUntil
      public
      metadata
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      type {
        id
        type
        allowedFileTypes
        maxSize
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyIndustry = /* GraphQL */ `
  subscription OnCreateCompanyIndustry(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCompanyIndustry(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyID
      industryID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      industry {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompanyIndustry = /* GraphQL */ `
  subscription OnUpdateCompanyIndustry(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCompanyIndustry(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyID
      industryID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      industry {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompanyIndustry = /* GraphQL */ `
  subscription OnDeleteCompanyIndustry(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCompanyIndustry(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyID
      industryID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      industry {
        id
        CIIU
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyForm = /* GraphQL */ `
  subscription OnCreateCompanyForm($owner: String) {
    onCreateCompanyForm(owner: $owner) {
      id
      companyID
      formID
      answers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      form {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompanyForm = /* GraphQL */ `
  subscription OnUpdateCompanyForm($owner: String) {
    onUpdateCompanyForm(owner: $owner) {
      id
      companyID
      formID
      answers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      form {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompanyForm = /* GraphQL */ `
  subscription OnDeleteCompanyForm($owner: String) {
    onDeleteCompanyForm(owner: $owner) {
      id
      companyID
      formID
      answers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      form {
        id
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateNotification(owner: $owner, readers: $readers, editors: $editors) {
      id
      timestamp
      owner
      type
      title
      message
      data
      url
      callback
      callbackParams
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateNotification(owner: $owner, readers: $readers, editors: $editors) {
      id
      timestamp
      owner
      type
      title
      message
      data
      url
      callback
      callbackParams
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteNotification(owner: $owner, readers: $readers, editors: $editors) {
      id
      timestamp
      owner
      type
      title
      message
      data
      url
      callback
      callbackParams
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyBadge = /* GraphQL */ `
  subscription OnCreateCompanyBadge {
    onCreateCompanyBadge {
      id
      companyID
      badgeID
      assigned
      active
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      badge {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompanyBadge = /* GraphQL */ `
  subscription OnUpdateCompanyBadge {
    onUpdateCompanyBadge {
      id
      companyID
      badgeID
      assigned
      active
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      badge {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompanyBadge = /* GraphQL */ `
  subscription OnDeleteCompanyBadge {
    onDeleteCompanyBadge {
      id
      companyID
      badgeID
      assigned
      active
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      badge {
        id
        code
        name
        text
        validFor
        requiredPoints
        callback
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSupportTicket = /* GraphQL */ `
  subscription OnCreateSupportTicket(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateSupportTicket(owner: $owner, readers: $readers, editors: $editors) {
      id
      subject
      description
      assignedTo
      messages {
        timestamp
        authorName
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateSupportTicket = /* GraphQL */ `
  subscription OnUpdateSupportTicket(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateSupportTicket(owner: $owner, readers: $readers, editors: $editors) {
      id
      subject
      description
      assignedTo
      messages {
        timestamp
        authorName
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteSupportTicket = /* GraphQL */ `
  subscription OnDeleteSupportTicket(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteSupportTicket(owner: $owner, readers: $readers, editors: $editors) {
      id
      subject
      description
      assignedTo
      messages {
        timestamp
        authorName
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateMyGroupCategory = /* GraphQL */ `
  subscription OnCreateMyGroupCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateMyGroupCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      accountCategories {
        nextToken
        __typename
      }
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMyGroupCategory = /* GraphQL */ `
  subscription OnUpdateMyGroupCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateMyGroupCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      accountCategories {
        nextToken
        __typename
      }
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMyGroupCategory = /* GraphQL */ `
  subscription OnDeleteMyGroupCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteMyGroupCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      accountCategories {
        nextToken
        __typename
      }
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMyAccountCategory = /* GraphQL */ `
  subscription OnCreateMyAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateMyAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      groupCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMyAccountCategory = /* GraphQL */ `
  subscription OnUpdateMyAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateMyAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      groupCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMyAccountCategory = /* GraphQL */ `
  subscription OnDeleteMyAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteMyAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      groupCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMySubAccountCategory = /* GraphQL */ `
  subscription OnCreateMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      auxiliarCategories {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMySubAccountCategory = /* GraphQL */ `
  subscription OnUpdateMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      auxiliarCategories {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMySubAccountCategory = /* GraphQL */ `
  subscription OnDeleteMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      auxiliarCategories {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAuxiliarCategory = /* GraphQL */ `
  subscription OnCreateAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateAuxiliarCategory = /* GraphQL */ `
  subscription OnUpdateAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAuxiliarCategory = /* GraphQL */ `
  subscription OnDeleteAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      name
      description
      nature
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTax = /* GraphQL */ `
  subscription OnCreateTax($owner: String, $readers: String, $editors: String) {
    onCreateTax(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      type
      percentage
      municipality
      value
      country
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateTax = /* GraphQL */ `
  subscription OnUpdateTax($owner: String, $readers: String, $editors: String) {
    onUpdateTax(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      type
      percentage
      municipality
      value
      country
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteTax = /* GraphQL */ `
  subscription OnDeleteTax($owner: String, $readers: String, $editors: String) {
    onDeleteTax(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      type
      percentage
      municipality
      value
      country
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateLog = /* GraphQL */ `
  subscription OnCreateLog($owner: String, $readers: String, $editors: String) {
    onCreateLog(owner: $owner, readers: $readers, editors: $editors) {
      id
      timestamp
      operationType
      subOperationType
      description
      total
      url
      extraData
      user
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      readers
      editors
      __typename
    }
  }
`;
export const onUpdateLog = /* GraphQL */ `
  subscription OnUpdateLog($owner: String, $readers: String, $editors: String) {
    onUpdateLog(owner: $owner, readers: $readers, editors: $editors) {
      id
      timestamp
      operationType
      subOperationType
      description
      total
      url
      extraData
      user
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      readers
      editors
      __typename
    }
  }
`;
export const onDeleteLog = /* GraphQL */ `
  subscription OnDeleteLog($owner: String, $readers: String, $editors: String) {
    onDeleteLog(owner: $owner, readers: $readers, editors: $editors) {
      id
      timestamp
      operationType
      subOperationType
      description
      total
      url
      extraData
      user
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      readers
      editors
      __typename
    }
  }
`;
export const onCreateWarehouse = /* GraphQL */ `
  subscription OnCreateWarehouse(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateWarehouse(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      description
      location {
        name
        headquarters
        branchCode
        __typename
      }
      remarks
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateWarehouse = /* GraphQL */ `
  subscription OnUpdateWarehouse(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateWarehouse(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      description
      location {
        name
        headquarters
        branchCode
        __typename
      }
      remarks
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteWarehouse = /* GraphQL */ `
  subscription OnDeleteWarehouse(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteWarehouse(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      description
      location {
        name
        headquarters
        branchCode
        __typename
      }
      remarks
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePOS = /* GraphQL */ `
  subscription OnCreatePOS($owner: String, $readers: String, $editors: String) {
    onCreatePOS(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      location {
        name
        headquarters
        branchCode
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePOS = /* GraphQL */ `
  subscription OnUpdatePOS($owner: String, $readers: String, $editors: String) {
    onUpdatePOS(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      location {
        name
        headquarters
        branchCode
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePOS = /* GraphQL */ `
  subscription OnDeletePOS($owner: String, $readers: String, $editors: String) {
    onDeletePOS(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      location {
        name
        headquarters
        branchCode
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTerminal = /* GraphQL */ `
  subscription OnCreateTerminal(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateTerminal(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      nameType
      location
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      cashiers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTerminal = /* GraphQL */ `
  subscription OnUpdateTerminal(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateTerminal(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      nameType
      location
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      cashiers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTerminal = /* GraphQL */ `
  subscription OnDeleteTerminal(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteTerminal(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
        __typename
      }
      nameType
      location
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      cashiers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateUser(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      companyRole
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      status
      employeeID
      supplierID
      cashierID
      salesmanID
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateUser(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      companyRole
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      status
      employeeID
      supplierID
      cashierID
      salesmanID
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteUser(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      name
      lastname
      email
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      companyRole
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      status
      employeeID
      supplierID
      cashierID
      salesmanID
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        name
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyRole = /* GraphQL */ `
  subscription OnCreateCompanyRole(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCompanyRole(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      users {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateCompanyRole = /* GraphQL */ `
  subscription OnUpdateCompanyRole(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCompanyRole(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      users {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteCompanyRole = /* GraphQL */ `
  subscription OnDeleteCompanyRole(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCompanyRole(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      users {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateCashier = /* GraphQL */ `
  subscription OnCreateCashier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCashier(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCashier = /* GraphQL */ `
  subscription OnUpdateCashier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCashier(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCashier = /* GraphQL */ `
  subscription OnDeleteCashier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCashier(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      terminals {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSalesman = /* GraphQL */ `
  subscription OnCreateSalesman(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateSalesman(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comtips {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSalesman = /* GraphQL */ `
  subscription OnUpdateSalesman(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateSalesman(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comtips {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSalesman = /* GraphQL */ `
  subscription OnDeleteSalesman(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteSalesman(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      nationalID
      name
      lastname
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comtips {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateEmployee(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      highPensionRisk
      nationalID
      name
      lastname
      position
      positionType
      defaultBankName
      defaultBankAccountType
      defaultBankAccountNumber
      workCountryCode
      workStateCode
      workCityCode
      address
      integralSalary
      baseSalary
      startDate
      endDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      phoneNumbers {
        countryCode
        number
        extension
        __typename
      }
      tags {
        name
        value
        __typename
      }
      active
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      defaultPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeSubType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateEmployee(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      highPensionRisk
      nationalID
      name
      lastname
      position
      positionType
      defaultBankName
      defaultBankAccountType
      defaultBankAccountNumber
      workCountryCode
      workStateCode
      workCityCode
      address
      integralSalary
      baseSalary
      startDate
      endDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      phoneNumbers {
        countryCode
        number
        extension
        __typename
      }
      tags {
        name
        value
        __typename
      }
      active
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      defaultPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeSubType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteEmployee(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      highPensionRisk
      nationalID
      name
      lastname
      position
      positionType
      defaultBankName
      defaultBankAccountType
      defaultBankAccountNumber
      workCountryCode
      workStateCode
      workCityCode
      address
      integralSalary
      baseSalary
      startDate
      endDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      phoneNumbers {
        countryCode
        number
        extension
        __typename
      }
      tags {
        name
        value
        __typename
      }
      active
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      profile {
        id
        studiesLevel
        experienceYears
        rating
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      defaultPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      employeeSubType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEmployeeProfile = /* GraphQL */ `
  subscription OnCreateEmployeeProfile(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateEmployeeProfile(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      studiesLevel
      experienceYears
      rating
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateEmployeeProfile = /* GraphQL */ `
  subscription OnUpdateEmployeeProfile(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateEmployeeProfile(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      studiesLevel
      experienceYears
      rating
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteEmployeeProfile = /* GraphQL */ `
  subscription OnDeleteEmployeeProfile(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteEmployeeProfile(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      studiesLevel
      experienceYears
      rating
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateEmployeePayment = /* GraphQL */ `
  subscription OnCreateEmployeePayment(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateEmployeePayment(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      paymentType
      month
      year
      paymentDates
      period {
        startDate
        endDate
        workingTime
        generationDate
        paymentIntervalCode
        __typename
      }
      extras
      deductions
      total
      paymentForm
      bankName
      bankAccountType
      bankAccountNumber
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      ePayroll {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateEmployeePayment = /* GraphQL */ `
  subscription OnUpdateEmployeePayment(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateEmployeePayment(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      paymentType
      month
      year
      paymentDates
      period {
        startDate
        endDate
        workingTime
        generationDate
        paymentIntervalCode
        __typename
      }
      extras
      deductions
      total
      paymentForm
      bankName
      bankAccountType
      bankAccountNumber
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      ePayroll {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteEmployeePayment = /* GraphQL */ `
  subscription OnDeleteEmployeePayment(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteEmployeePayment(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      paymentType
      month
      year
      paymentDates
      period {
        startDate
        endDate
        workingTime
        generationDate
        paymentIntervalCode
        __typename
      }
      extras
      deductions
      total
      paymentForm
      bankName
      bankAccountType
      bankAccountNumber
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      ePayroll {
        id
        number
        month
        year
        CUNE
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEPayroll = /* GraphQL */ `
  subscription OnCreateEPayroll(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateEPayroll(owner: $owner, readers: $readers, editors: $editors) {
      id
      number
      month
      year
      CUNE
      status
      jsonFile {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateEPayroll = /* GraphQL */ `
  subscription OnUpdateEPayroll(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateEPayroll(owner: $owner, readers: $readers, editors: $editors) {
      id
      number
      month
      year
      CUNE
      status
      jsonFile {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteEPayroll = /* GraphQL */ `
  subscription OnDeleteEPayroll(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteEPayroll(owner: $owner, readers: $readers, editors: $editors) {
      id
      number
      month
      year
      CUNE
      status
      jsonFile {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      payments {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateAccount(owner: $owner, readers: $readers, editors: $editors) {
      id
      accountID
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      branchCode
      balance
      userData {
        username
        password
        __typename
      }
      active
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      debitCards {
        nextToken
        __typename
      }
      eCards {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateAccount(owner: $owner, readers: $readers, editors: $editors) {
      id
      accountID
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      branchCode
      balance
      userData {
        username
        password
        __typename
      }
      active
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      debitCards {
        nextToken
        __typename
      }
      eCards {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteAccount(owner: $owner, readers: $readers, editors: $editors) {
      id
      accountID
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      branchCode
      balance
      userData {
        username
        password
        __typename
      }
      active
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      debitCards {
        nextToken
        __typename
      }
      eCards {
        nextToken
        __typename
      }
      incomes {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateThirdPartyAccount = /* GraphQL */ `
  subscription OnCreateThirdPartyAccount(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateThirdPartyAccount(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      accountID
      thirdPartyType
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateThirdPartyAccount = /* GraphQL */ `
  subscription OnUpdateThirdPartyAccount(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateThirdPartyAccount(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      accountID
      thirdPartyType
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteThirdPartyAccount = /* GraphQL */ `
  subscription OnDeleteThirdPartyAccount(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteThirdPartyAccount(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      accountID
      thirdPartyType
      internationalData {
        IBAN
        SWIFT
        __typename
      }
      currency
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      employee {
        id
        code
        highPensionRisk
        nationalID
        name
        lastname
        position
        positionType
        defaultBankName
        defaultBankAccountType
        defaultBankAccountNumber
        workCountryCode
        workStateCode
        workCityCode
        address
        integralSalary
        baseSalary
        startDate
        endDate
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      transactions {
        nextToken
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      bank {
        id
        name
        description
        extraData
        createdAt
        updatedAt
        __typename
      }
      type {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateTransaction(owner: $owner, readers: $readers, editors: $editors) {
      id
      bankTransactionID
      reference
      timestamp
      type
      data {
        total
        description
        extra
        __typename
      }
      user
      fee {
        total
        description
        extra
        __typename
      }
      xml {
        __typename
      }
      status
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      debitCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      eCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateTransaction(owner: $owner, readers: $readers, editors: $editors) {
      id
      bankTransactionID
      reference
      timestamp
      type
      data {
        total
        description
        extra
        __typename
      }
      user
      fee {
        total
        description
        extra
        __typename
      }
      xml {
        __typename
      }
      status
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      debitCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      eCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteTransaction(owner: $owner, readers: $readers, editors: $editors) {
      id
      bankTransactionID
      reference
      timestamp
      type
      data {
        total
        description
        extra
        __typename
      }
      user
      fee {
        total
        description
        extra
        __typename
      }
      xml {
        __typename
      }
      status
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      debitCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      eCard {
        id
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDebitCard = /* GraphQL */ `
  subscription OnCreateDebitCard(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateDebitCard(owner: $owner, readers: $readers, editors: $editors) {
      id
      data {
        number
        expirationDate
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateDebitCard = /* GraphQL */ `
  subscription OnUpdateDebitCard(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateDebitCard(owner: $owner, readers: $readers, editors: $editors) {
      id
      data {
        number
        expirationDate
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteDebitCard = /* GraphQL */ `
  subscription OnDeleteDebitCard(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteDebitCard(owner: $owner, readers: $readers, editors: $editors) {
      id
      data {
        number
        expirationDate
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateECard = /* GraphQL */ `
  subscription OnCreateECard(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateECard(owner: $owner, readers: $readers, editors: $editors) {
      id
      data {
        number
        expirationDate
        cvv
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateECard = /* GraphQL */ `
  subscription OnUpdateECard(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateECard(owner: $owner, readers: $readers, editors: $editors) {
      id
      data {
        number
        expirationDate
        cvv
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteECard = /* GraphQL */ `
  subscription OnDeleteECard(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteECard(owner: $owner, readers: $readers, editors: $editors) {
      id
      data {
        number
        expirationDate
        cvv
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateBag = /* GraphQL */ `
  subscription OnCreateBag($owner: String, $readers: String, $editors: String) {
    onCreateBag(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      balance
      blocked
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateBag = /* GraphQL */ `
  subscription OnUpdateBag($owner: String, $readers: String, $editors: String) {
    onUpdateBag(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      balance
      blocked
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteBag = /* GraphQL */ `
  subscription OnDeleteBag($owner: String, $readers: String, $editors: String) {
    onDeleteBag(owner: $owner, readers: $readers, editors: $editors) {
      id
      name
      balance
      blocked
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateProductService = /* GraphQL */ `
  subscription OnCreateProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      type
      productServiceTypeID
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      limitless
      quantity
      averagePrice
      acceptableLimit
      dontSaleOnScarcity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      configuration
      favorite
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      active
      public
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      psTypes {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateProductService = /* GraphQL */ `
  subscription OnUpdateProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      type
      productServiceTypeID
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      limitless
      quantity
      averagePrice
      acceptableLimit
      dontSaleOnScarcity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      configuration
      favorite
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      active
      public
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      psTypes {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteProductService = /* GraphQL */ `
  subscription OnDeleteProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      type
      productServiceTypeID
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      limitless
      quantity
      averagePrice
      acceptableLimit
      dontSaleOnScarcity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      configuration
      favorite
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      active
      public
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      psTypes {
        nextToken
        __typename
      }
      warehouses {
        nextToken
        __typename
      }
      transfers {
        nextToken
        __typename
      }
      pos {
        nextToken
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      posDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateProductServicePSType = /* GraphQL */ `
  subscription OnCreateProductServicePSType(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateProductServicePSType(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      productServiceID
      psTypeID
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      pstype {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateProductServicePSType = /* GraphQL */ `
  subscription OnUpdateProductServicePSType(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateProductServicePSType(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      productServiceID
      psTypeID
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      pstype {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteProductServicePSType = /* GraphQL */ `
  subscription OnDeleteProductServicePSType(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteProductServicePSType(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      productServiceID
      psTypeID
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      pstype {
        id
        code
        name
        description
        status
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePSType = /* GraphQL */ `
  subscription OnCreatePSType(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreatePSType(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      name
      description
      image {
        bucket
        region
        key
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePSType = /* GraphQL */ `
  subscription OnUpdatePSType(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdatePSType(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      name
      description
      image {
        bucket
        region
        key
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePSType = /* GraphQL */ `
  subscription OnDeletePSType(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeletePSType(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      name
      description
      image {
        bucket
        region
        key
        __typename
      }
      status
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateWPTransfer = /* GraphQL */ `
  subscription OnCreateWPTransfer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateWPTransfer(owner: $owner, readers: $readers, editors: $editors) {
      id
      warehouseID
      posID
      transferType
      date
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateWPTransfer = /* GraphQL */ `
  subscription OnUpdateWPTransfer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateWPTransfer(owner: $owner, readers: $readers, editors: $editors) {
      id
      warehouseID
      posID
      transferType
      date
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteWPTransfer = /* GraphQL */ `
  subscription OnDeleteWPTransfer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteWPTransfer(owner: $owner, readers: $readers, editors: $editors) {
      id
      warehouseID
      posID
      transferType
      date
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateWarehouseProductService = /* GraphQL */ `
  subscription OnCreateWarehouseProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateWarehouseProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      warehouseID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateWarehouseProductService = /* GraphQL */ `
  subscription OnUpdateWarehouseProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateWarehouseProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      warehouseID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteWarehouseProductService = /* GraphQL */ `
  subscription OnDeleteWarehouseProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteWarehouseProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      warehouseID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      warehouse {
        id
        code
        description
        remarks
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateWPTransferProductService = /* GraphQL */ `
  subscription OnCreateWPTransferProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateWPTransferProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      wpTransferID
      productServiceID
      quantity
      price
      reason
      variations
      remarks
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      wpTransfer {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateWPTransferProductService = /* GraphQL */ `
  subscription OnUpdateWPTransferProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateWPTransferProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      wpTransferID
      productServiceID
      quantity
      price
      reason
      variations
      remarks
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      wpTransfer {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteWPTransferProductService = /* GraphQL */ `
  subscription OnDeleteWPTransferProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteWPTransferProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      wpTransferID
      productServiceID
      quantity
      price
      reason
      variations
      remarks
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      wpTransfer {
        id
        warehouseID
        posID
        transferType
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreatePOSProductService = /* GraphQL */ `
  subscription OnCreatePOSProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreatePOSProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      posID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePOSProductService = /* GraphQL */ `
  subscription OnUpdatePOSProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdatePOSProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      posID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePOSProductService = /* GraphQL */ `
  subscription OnDeletePOSProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeletePOSProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      posID
      productServiceID
      quantity
      variations
      readers
      editors
      createdAt
      updatedAt
      pos {
        id
        code
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreatePublicProductService = /* GraphQL */ `
  subscription OnCreatePublicProductService {
    onCreatePublicProductService {
      id
      name
      productServiceTypeID
      url
      description
      lemma
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      prices {
        id
        name
        description
        value
        conditions
        __typename
      }
      defaultPrice
      taxed
      vatPercentage
      vat
      active
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePublicProductService = /* GraphQL */ `
  subscription OnUpdatePublicProductService {
    onUpdatePublicProductService {
      id
      name
      productServiceTypeID
      url
      description
      lemma
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      prices {
        id
        name
        description
        value
        conditions
        __typename
      }
      defaultPrice
      taxed
      vatPercentage
      vat
      active
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePublicProductService = /* GraphQL */ `
  subscription OnDeletePublicProductService {
    onDeletePublicProductService {
      id
      name
      productServiceTypeID
      url
      description
      lemma
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      prices {
        id
        name
        description
        value
        conditions
        __typename
      }
      defaultPrice
      taxed
      vatPercentage
      vat
      active
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateSupplierProductService = /* GraphQL */ `
  subscription OnCreateSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      equivalentDocumentScheme {
        name
        id
        agencyID
        code
        __typename
      }
      type
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      quantity
      acceptableLimit
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      brandName
      modelName
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSupplierProductService = /* GraphQL */ `
  subscription OnUpdateSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      equivalentDocumentScheme {
        name
        id
        agencyID
        code
        __typename
      }
      type
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      quantity
      acceptableLimit
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      brandName
      modelName
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSupplierProductService = /* GraphQL */ `
  subscription OnDeleteSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      code
      equivalentDocumentScheme {
        name
        id
        agencyID
        code
        __typename
      }
      type
      name
      prices {
        id
        quantity
        cost
        value
        name
        administration
        unexpected
        utility
        __typename
      }
      defaultPrice
      quantity
      acceptableLimit
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      description
      brandName
      modelName
      photos {
        bucket
        region
        key
        __typename
      }
      videos {
        bucket
        region
        key
        __typename
      }
      videoURLs
      websites {
        url
        type
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      bills {
        nextToken
        __typename
      }
      equivalentDocuments {
        nextToken
        __typename
      }
      unit {
        id
        code
        name
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyKeyPartnerProductService = /* GraphQL */ `
  subscription OnCreateCompanyKeyPartnerProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCompanyKeyPartnerProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyID
      keyPartnerProductServiceID
      data
      documents
      acquired
      rating
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      keyPartnerproductService {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCompanyKeyPartnerProductService = /* GraphQL */ `
  subscription OnUpdateCompanyKeyPartnerProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCompanyKeyPartnerProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyID
      keyPartnerProductServiceID
      data
      documents
      acquired
      rating
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      keyPartnerproductService {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCompanyKeyPartnerProductService = /* GraphQL */ `
  subscription OnDeleteCompanyKeyPartnerProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCompanyKeyPartnerProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      companyID
      keyPartnerProductServiceID
      data
      documents
      acquired
      rating
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      keyPartnerproductService {
        id
        code
        name
        description
        notes
        productServiceTypeID
        contactEmail
        url
        clicks
        clickedBy
        acquired
        rating
        callback
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      timestamp
      topicID
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      timestamp
      topicID
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      timestamp
      topicID
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePostComment = /* GraphQL */ `
  subscription OnCreatePostComment {
    onCreatePostComment {
      id
      timestamp
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      post {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePostComment = /* GraphQL */ `
  subscription OnUpdatePostComment {
    onUpdatePostComment {
      id
      timestamp
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      post {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePostComment = /* GraphQL */ `
  subscription OnDeletePostComment {
    onDeletePostComment {
      id
      timestamp
      authorFullName
      companyID
      content {
        text
        urls
        __typename
      }
      reactions {
        likes
        loves
        laughs
        __typename
      }
      totalPositives
      censored
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        url
        description
        lemma
        industries
        defaultLocation
        defaultContact
        videoURLs
        productServices
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      post {
        id
        timestamp
        topicID
        authorFullName
        companyID
        totalPositives
        censored
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateCustomer(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      cashReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateCustomer(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      cashReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteCustomer(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      cashReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSupplier = /* GraphQL */ `
  subscription OnCreateSupplier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateSupplier(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      dischargeReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSupplier = /* GraphQL */ `
  subscription OnUpdateSupplier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateSupplier(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      dischargeReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSupplier = /* GraphQL */ `
  subscription OnDeleteSupplier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteSupplier(owner: $owner, readers: $readers, editors: $editors) {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      userID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      dischargeReceipts {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePatient = /* GraphQL */ `
  subscription OnCreatePatient(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreatePatient(owner: $owner, readers: $readers, editors: $editors) {
      id
      nationalID
      name
      otherNames
      lastname
      otherLastnames
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      invoices {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      patientType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractingAndPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      benefitsPlan {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePatient = /* GraphQL */ `
  subscription OnUpdatePatient(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdatePatient(owner: $owner, readers: $readers, editors: $editors) {
      id
      nationalID
      name
      otherNames
      lastname
      otherLastnames
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      invoices {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      patientType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractingAndPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      benefitsPlan {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePatient = /* GraphQL */ `
  subscription OnDeletePatient(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeletePatient(owner: $owner, readers: $readers, editors: $editors) {
      id
      nationalID
      name
      otherNames
      lastname
      otherLastnames
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      active
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      owner
      invoices {
        nextToken
        __typename
      }
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      fiscalResponsability {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      regimenType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      patientType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      contractingAndPaymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      benefitsPlan {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateIncome = /* GraphQL */ `
  subscription OnCreateIncome(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateIncome(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      invoiceID
      posDocumentID
      transactionID
      senderType
      senderID
      senderName
      manual
      date
      time
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateIncome = /* GraphQL */ `
  subscription OnUpdateIncome(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateIncome(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      invoiceID
      posDocumentID
      transactionID
      senderType
      senderID
      senderName
      manual
      date
      time
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteIncome = /* GraphQL */ `
  subscription OnDeleteIncome(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteIncome(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      invoiceID
      posDocumentID
      transactionID
      senderType
      senderID
      senderName
      manual
      date
      time
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      cashReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateIncomeSubAccountCategory = /* GraphQL */ `
  subscription OnCreateIncomeSubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateIncomeSubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateIncomeSubAccountCategory = /* GraphQL */ `
  subscription OnUpdateIncomeSubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateIncomeSubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteIncomeSubAccountCategory = /* GraphQL */ `
  subscription OnDeleteIncomeSubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteIncomeSubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateIncomeMySubAccountCategory = /* GraphQL */ `
  subscription OnCreateIncomeMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateIncomeMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateIncomeMySubAccountCategory = /* GraphQL */ `
  subscription OnUpdateIncomeMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateIncomeMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteIncomeMySubAccountCategory = /* GraphQL */ `
  subscription OnDeleteIncomeMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteIncomeMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateIncomeAuxiliarCategory = /* GraphQL */ `
  subscription OnCreateIncomeAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateIncomeAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateIncomeAuxiliarCategory = /* GraphQL */ `
  subscription OnUpdateIncomeAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateIncomeAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteIncomeAuxiliarCategory = /* GraphQL */ `
  subscription OnDeleteIncomeAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteIncomeAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      incomeID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateQuotation = /* GraphQL */ `
  subscription OnCreateQuotation($owner: String, $readers: String) {
    onCreateQuotation(owner: $owner, readers: $readers) {
      id
      quotationID
      client
      clientID
      productsServices {
        id
        name
        quantity
        taxes
        total
        __typename
      }
      subtotal
      taxes
      total
      date
      expirationDays
      status
      files {
        bucket
        region
        key
        __typename
      }
      videos {
        url
        type
        __typename
      }
      url
      password
      readers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateQuotation = /* GraphQL */ `
  subscription OnUpdateQuotation($owner: String, $readers: String) {
    onUpdateQuotation(owner: $owner, readers: $readers) {
      id
      quotationID
      client
      clientID
      productsServices {
        id
        name
        quantity
        taxes
        total
        __typename
      }
      subtotal
      taxes
      total
      date
      expirationDays
      status
      files {
        bucket
        region
        key
        __typename
      }
      videos {
        url
        type
        __typename
      }
      url
      password
      readers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteQuotation = /* GraphQL */ `
  subscription OnDeleteQuotation($owner: String, $readers: String) {
    onDeleteQuotation(owner: $owner, readers: $readers) {
      id
      quotationID
      client
      clientID
      productsServices {
        id
        name
        quantity
        taxes
        total
        __typename
      }
      subtotal
      taxes
      total
      date
      expirationDays
      status
      files {
        bucket
        region
        key
        __typename
      }
      videos {
        url
        type
        __typename
      }
      url
      password
      readers
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateInvoice(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      originalData
      terminalCashierID
      resolutionPosition
      invoiceNumber
      seller
      salesman
      salesmanType
      comtipID
      date
      time
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      totalDiscount
      discountPercentage
      includeConditionalDiscount
      total
      paymentLink
      cufe
      xml
      splitPayments
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      copay
      moderatingFee
      recoveryFee
      voluntaryHealthPlansSharedPayments
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      extraData
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      selectedQuotation
      scheduled
      schedule {
        sendOn
        sendOnMultipleDates
        sendUntil
        programmedTimes
        executedTimes
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      healthReferencedDocumentType {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      healthOperationType {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateInvoice(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      originalData
      terminalCashierID
      resolutionPosition
      invoiceNumber
      seller
      salesman
      salesmanType
      comtipID
      date
      time
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      totalDiscount
      discountPercentage
      includeConditionalDiscount
      total
      paymentLink
      cufe
      xml
      splitPayments
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      copay
      moderatingFee
      recoveryFee
      voluntaryHealthPlansSharedPayments
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      extraData
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      selectedQuotation
      scheduled
      schedule {
        sendOn
        sendOnMultipleDates
        sendUntil
        programmedTimes
        executedTimes
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      healthReferencedDocumentType {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      healthOperationType {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteInvoice(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      originalData
      terminalCashierID
      resolutionPosition
      invoiceNumber
      seller
      salesman
      salesmanType
      comtipID
      date
      time
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      totalDiscount
      discountPercentage
      includeConditionalDiscount
      total
      paymentLink
      cufe
      xml
      splitPayments
      authorizationNumber
      MIPRESprescriptionNumber
      MIPRESprescriptionDeliveryNumber
      contractNumber
      policyNumber
      copay
      moderatingFee
      recoveryFee
      voluntaryHealthPlansSharedPayments
      invoicingPeriodInitDate
      invoicingPeriodEndDate
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      extraData
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      selectedQuotation
      scheduled
      schedule {
        sendOn
        sendOnMultipleDates
        sendUntil
        programmedTimes
        executedTimes
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      patient {
        id
        nationalID
        name
        otherNames
        lastname
        otherLastnames
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      quotations {
        nextToken
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      healthReferencedDocumentType {
        id
        code
        name
        comment
        country
        createdAt
        updatedAt
        __typename
      }
      healthOperationType {
        id
        code
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateInvoiceProductService = /* GraphQL */ `
  subscription OnCreateInvoiceProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateInvoiceProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      invoiceID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      expirationDate
      batch
      configuration
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateInvoiceProductService = /* GraphQL */ `
  subscription OnUpdateInvoiceProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateInvoiceProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      invoiceID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      expirationDate
      batch
      configuration
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteInvoiceProductService = /* GraphQL */ `
  subscription OnDeleteInvoiceProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteInvoiceProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      invoiceID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      expirationDate
      batch
      configuration
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoice {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        originalData
        terminalCashierID
        resolutionPosition
        invoiceNumber
        seller
        salesman
        salesmanType
        comtipID
        date
        time
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        totalDiscount
        discountPercentage
        includeConditionalDiscount
        total
        paymentLink
        cufe
        xml
        splitPayments
        authorizationNumber
        MIPRESprescriptionNumber
        MIPRESprescriptionDeliveryNumber
        contractNumber
        policyNumber
        copay
        moderatingFee
        recoveryFee
        voluntaryHealthPlansSharedPayments
        invoicingPeriodInitDate
        invoicingPeriodEndDate
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        extraData
        selectedQuotation
        scheduled
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreatePurchaseOrder = /* GraphQL */ `
  subscription OnCreatePurchaseOrder(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreatePurchaseOrder(owner: $owner, readers: $readers, editors: $editors) {
      id
      orderID
      date
      documents {
        bucket
        region
        key
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePurchaseOrder = /* GraphQL */ `
  subscription OnUpdatePurchaseOrder(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdatePurchaseOrder(owner: $owner, readers: $readers, editors: $editors) {
      id
      orderID
      date
      documents {
        bucket
        region
        key
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePurchaseOrder = /* GraphQL */ `
  subscription OnDeletePurchaseOrder(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeletePurchaseOrder(owner: $owner, readers: $readers, editors: $editors) {
      id
      orderID
      date
      documents {
        bucket
        region
        key
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateCashReceipt = /* GraphQL */ `
  subscription OnCreateCashReceipt($owner: String) {
    onCreateCashReceipt(owner: $owner) {
      id
      cashReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCashReceipt = /* GraphQL */ `
  subscription OnUpdateCashReceipt($owner: String) {
    onUpdateCashReceipt(owner: $owner) {
      id
      cashReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCashReceipt = /* GraphQL */ `
  subscription OnDeleteCashReceipt($owner: String) {
    onDeleteCashReceipt(owner: $owner) {
      id
      cashReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      customer {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      incomes {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCashReceiptIncome = /* GraphQL */ `
  subscription OnCreateCashReceiptIncome($owner: String) {
    onCreateCashReceiptIncome(owner: $owner) {
      id
      cashReceiptID
      incomeID
      value
      date
      data
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipt {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateCashReceiptIncome = /* GraphQL */ `
  subscription OnUpdateCashReceiptIncome($owner: String) {
    onUpdateCashReceiptIncome(owner: $owner) {
      id
      cashReceiptID
      incomeID
      value
      date
      data
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipt {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteCashReceiptIncome = /* GraphQL */ `
  subscription OnDeleteCashReceiptIncome($owner: String) {
    onDeleteCashReceiptIncome(owner: $owner) {
      id
      cashReceiptID
      incomeID
      value
      date
      data
      createdAt
      updatedAt
      income {
        id
        concept
        invoiceID
        posDocumentID
        transactionID
        senderType
        senderID
        senderName
        manual
        date
        time
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashReceipt {
        id
        cashReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateTerminalCashier = /* GraphQL */ `
  subscription OnCreateTerminalCashier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateTerminalCashier(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashBeforeDetail
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      cashOutputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      observations
      readers
      editors
      createdAt
      updatedAt
      terminal {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashier {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      owner
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTerminalCashier = /* GraphQL */ `
  subscription OnUpdateTerminalCashier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateTerminalCashier(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashBeforeDetail
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      cashOutputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      observations
      readers
      editors
      createdAt
      updatedAt
      terminal {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashier {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      owner
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTerminalCashier = /* GraphQL */ `
  subscription OnDeleteTerminalCashier(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteTerminalCashier(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashBeforeDetail
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      cashOutputs {
        dateTime
        value
        reason
        remarks
        __typename
      }
      observations
      readers
      editors
      createdAt
      updatedAt
      terminal {
        id
        code
        extraData
        nameType
        location
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      cashier {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      owner
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePOSDocument = /* GraphQL */ `
  subscription OnCreatePOSDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreatePOSDocument(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      POSDocumentID
      code
      date
      total
      taxes
      data
      status
      salesman
      salesmanType
      comtipID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePOSDocument = /* GraphQL */ `
  subscription OnUpdatePOSDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdatePOSDocument(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      POSDocumentID
      code
      date
      total
      taxes
      data
      status
      salesman
      salesmanType
      comtipID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePOSDocument = /* GraphQL */ `
  subscription OnDeletePOSDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeletePOSDocument(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      POSDocumentID
      code
      date
      total
      taxes
      data
      status
      salesman
      salesmanType
      comtipID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      client {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      terminalCashier {
        id
        terminalID
        cashierID
        initDateTime
        endDateTime
        cashBefore
        cashBeforeDetail
        cashAfter
        cashAfterDetail
        observations
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productServices {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePOSDocumentProductService = /* GraphQL */ `
  subscription OnCreatePOSDocumentProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreatePOSDocumentProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      posDocumentID
      productServiceID
      quantity
      price
      total
      taxes
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      posDocument {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePOSDocumentProductService = /* GraphQL */ `
  subscription OnUpdatePOSDocumentProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdatePOSDocumentProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      posDocumentID
      productServiceID
      quantity
      price
      total
      taxes
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      posDocument {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePOSDocumentProductService = /* GraphQL */ `
  subscription OnDeletePOSDocumentProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeletePOSDocumentProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      posDocumentID
      productServiceID
      quantity
      price
      total
      taxes
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        productServiceTypeID
        name
        defaultPrice
        limitless
        quantity
        averagePrice
        acceptableLimit
        dontSaleOnScarcity
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        configuration
        favorite
        videoURLs
        active
        public
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      posDocument {
        id
        type
        POSDocumentID
        code
        date
        total
        taxes
        data
        status
        salesman
        salesmanType
        comtipID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateExpense = /* GraphQL */ `
  subscription OnCreateExpense(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateExpense(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      billID
      transactionID
      equivalentDocumentID
      comtipID
      receiverType
      receiverID
      receiverName
      manual
      date
      time
      transferID
      paid
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateExpense = /* GraphQL */ `
  subscription OnUpdateExpense(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateExpense(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      billID
      transactionID
      equivalentDocumentID
      comtipID
      receiverType
      receiverID
      receiverName
      manual
      date
      time
      transferID
      paid
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteExpense = /* GraphQL */ `
  subscription OnDeleteExpense(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteExpense(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      billID
      transactionID
      equivalentDocumentID
      comtipID
      receiverType
      receiverID
      receiverName
      manual
      date
      time
      transferID
      paid
      value
      currency
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategories {
        nextToken
        __typename
      }
      mySubAccountCategories {
        nextToken
        __typename
      }
      auxiliarCategories {
        nextToken
        __typename
      }
      dischargeReceipts {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateExpenseSubAccountCategory = /* GraphQL */ `
  subscription OnCreateExpenseSubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateExpenseSubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateExpenseSubAccountCategory = /* GraphQL */ `
  subscription OnUpdateExpenseSubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateExpenseSubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteExpenseSubAccountCategory = /* GraphQL */ `
  subscription OnDeleteExpenseSubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteExpenseSubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      subAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      subAccountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateExpenseMySubAccountCategory = /* GraphQL */ `
  subscription OnCreateExpenseMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateExpenseMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateExpenseMySubAccountCategory = /* GraphQL */ `
  subscription OnUpdateExpenseMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateExpenseMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteExpenseMySubAccountCategory = /* GraphQL */ `
  subscription OnDeleteExpenseMySubAccountCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteExpenseMySubAccountCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      mySubAccountCategoryID
      readers
      editors
      createdAt
      updatedAt
      mySubAccountCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateExpenseAuxiliarCategory = /* GraphQL */ `
  subscription OnCreateExpenseAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateExpenseAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateExpenseAuxiliarCategory = /* GraphQL */ `
  subscription OnUpdateExpenseAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateExpenseAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteExpenseAuxiliarCategory = /* GraphQL */ `
  subscription OnDeleteExpenseAuxiliarCategory(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteExpenseAuxiliarCategory(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      expenseID
      auxiliarCategoryID
      readers
      editors
      createdAt
      updatedAt
      auxiliarCategory {
        id
        code
        name
        description
        nature
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateTransfer = /* GraphQL */ `
  subscription OnCreateTransfer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateTransfer(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      expenseID
      receiverType
      receiverID
      receiverName
      date
      value
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateTransfer = /* GraphQL */ `
  subscription OnUpdateTransfer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateTransfer(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      expenseID
      receiverType
      receiverID
      receiverName
      date
      value
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteTransfer = /* GraphQL */ `
  subscription OnDeleteTransfer(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteTransfer(owner: $owner, readers: $readers, editors: $editors) {
      id
      concept
      expenseID
      receiverType
      receiverID
      receiverName
      date
      value
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateBill = /* GraphQL */ `
  subscription OnCreateBill(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateBill(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      billNumberDIAN
      supplierID
      supplierName
      supplierData
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cufe
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateBill = /* GraphQL */ `
  subscription OnUpdateBill(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateBill(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      billNumberDIAN
      supplierID
      supplierName
      supplierData
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cufe
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteBill = /* GraphQL */ `
  subscription OnDeleteBill(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteBill(owner: $owner, readers: $readers, editors: $editors) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      billNumberDIAN
      supplierID
      supplierName
      supplierData
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      bags
      totalBagTax
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cufe
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      payment {
        totalGross
        totalReteIVA
        totalReteICA
        totalSourceRetention
        totalOtherRetentions
        totalNet
        __typename
      }
      documents {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateBillSupplierProductService = /* GraphQL */ `
  subscription OnCreateBillSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateBillSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      billID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      bill {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateBillSupplierProductService = /* GraphQL */ `
  subscription OnUpdateBillSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateBillSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      billID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      bill {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteBillSupplierProductService = /* GraphQL */ `
  subscription OnDeleteBillSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteBillSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      billID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      bill {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        billNumberDIAN
        supplierID
        supplierName
        supplierData
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        bags
        totalBagTax
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cufe
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateEquivalentDocument = /* GraphQL */ `
  subscription OnCreateEquivalentDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateEquivalentDocument(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      resolutionPosition
      equivalentDocumentNumber
      equivalentDocumentNumberDIAN
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cuds
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      documents {
        bucket
        region
        key
        __typename
      }
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateEquivalentDocument = /* GraphQL */ `
  subscription OnUpdateEquivalentDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateEquivalentDocument(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      resolutionPosition
      equivalentDocumentNumber
      equivalentDocumentNumberDIAN
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cuds
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      documents {
        bucket
        region
        key
        __typename
      }
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteEquivalentDocument = /* GraphQL */ `
  subscription OnDeleteEquivalentDocument(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteEquivalentDocument(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      type
      national
      currency
      exchangeRate
      noteNumber
      resolutionPosition
      equivalentDocumentNumber
      equivalentDocumentNumberDIAN
      date
      hour
      expirationDays
      expiration
      paymentWay
      subtotal
      totalVat
      totalINC
      totalRetention
      reteIVA
      reteICA
      total
      paymentLink
      cuds
      termsAndConditions
      notes
      comments
      status
      statusMetadata
      documents {
        bucket
        region
        key
        __typename
      }
      extraFieldsHeader {
        name
        value
        __typename
      }
      extraFieldsFooter {
        name
        value
        __typename
      }
      tags {
        name
        value
        __typename
      }
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      purchaseOrder {
        id
        orderID
        date
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      productsServices {
        nextToken
        __typename
      }
      paymentMethod {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      noteConcept {
        id
        code
        type
        name
        createdAt
        updatedAt
        __typename
      }
      operationType {
        id
        code
        name
        country
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEquivalentDocumentSupplierProductService = /* GraphQL */ `
  subscription OnCreateEquivalentDocumentSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateEquivalentDocumentSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      equivalentDocumentID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      startDate
      descriptionCode
      descriptionXML
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      equivalentDocument {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateEquivalentDocumentSupplierProductService = /* GraphQL */ `
  subscription OnUpdateEquivalentDocumentSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateEquivalentDocumentSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      equivalentDocumentID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      startDate
      descriptionCode
      descriptionXML
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      equivalentDocument {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteEquivalentDocumentSupplierProductService = /* GraphQL */ `
  subscription OnDeleteEquivalentDocumentSupplierProductService(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteEquivalentDocumentSupplierProductService(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      equivalentDocumentID
      productServiceID
      unitPrice
      retentionPercentage
      retention
      discounted
      discountPercentage
      discount
      quantity
      taxed
      vatPercentage
      vat
      inced
      incPercentage
      INC
      subtotal
      total
      comment
      startDate
      descriptionCode
      descriptionXML
      extra
      readers
      editors
      createdAt
      updatedAt
      productService {
        id
        code
        type
        name
        defaultPrice
        quantity
        acceptableLimit
        taxed
        vatPercentage
        vat
        inced
        incPercentage
        INC
        description
        brandName
        modelName
        videoURLs
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      equivalentDocument {
        id
        type
        national
        currency
        exchangeRate
        noteNumber
        resolutionPosition
        equivalentDocumentNumber
        equivalentDocumentNumberDIAN
        date
        hour
        expirationDays
        expiration
        paymentWay
        subtotal
        totalVat
        totalINC
        totalRetention
        reteIVA
        reteICA
        total
        paymentLink
        cuds
        termsAndConditions
        notes
        comments
        status
        statusMetadata
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateDischargeReceipt = /* GraphQL */ `
  subscription OnCreateDischargeReceipt($owner: String) {
    onCreateDischargeReceipt(owner: $owner) {
      id
      dischargeReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDischargeReceipt = /* GraphQL */ `
  subscription OnUpdateDischargeReceipt($owner: String) {
    onUpdateDischargeReceipt(owner: $owner) {
      id
      dischargeReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDischargeReceipt = /* GraphQL */ `
  subscription OnDeleteDischargeReceipt($owner: String) {
    onDeleteDischargeReceipt(owner: $owner) {
      id
      dischargeReceiptID
      date
      transactionID
      currency
      total
      notes
      paymentMethod
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      account {
        id
        accountID
        branchCode
        balance
        active
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      thirdPartyAccount {
        id
        accountID
        thirdPartyType
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      supplier {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        defaultLocation
        active
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      expenses {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDischargeReceiptExpense = /* GraphQL */ `
  subscription OnCreateDischargeReceiptExpense($owner: String) {
    onCreateDischargeReceiptExpense(owner: $owner) {
      id
      dischargeReceiptID
      expenseID
      value
      date
      data
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      dischargeReceipt {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateDischargeReceiptExpense = /* GraphQL */ `
  subscription OnUpdateDischargeReceiptExpense($owner: String) {
    onUpdateDischargeReceiptExpense(owner: $owner) {
      id
      dischargeReceiptID
      expenseID
      value
      date
      data
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      dischargeReceipt {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteDischargeReceiptExpense = /* GraphQL */ `
  subscription OnDeleteDischargeReceiptExpense($owner: String) {
    onDeleteDischargeReceiptExpense(owner: $owner) {
      id
      dischargeReceiptID
      expenseID
      value
      date
      data
      createdAt
      updatedAt
      expense {
        id
        concept
        billID
        transactionID
        equivalentDocumentID
        comtipID
        receiverType
        receiverID
        receiverName
        manual
        date
        time
        transferID
        paid
        value
        currency
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      dischargeReceipt {
        id
        dischargeReceiptID
        date
        transactionID
        currency
        total
        notes
        paymentMethod
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateComTip = /* GraphQL */ `
  subscription OnCreateComTip(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateComTip(owner: $owner, readers: $readers, editors: $editors) {
      id
      percentage
      value
      data
      posDocumentID
      invoiceID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      salesmen {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateComTip = /* GraphQL */ `
  subscription OnUpdateComTip(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateComTip(owner: $owner, readers: $readers, editors: $editors) {
      id
      percentage
      value
      data
      posDocumentID
      invoiceID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      salesmen {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteComTip = /* GraphQL */ `
  subscription OnDeleteComTip(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteComTip(owner: $owner, readers: $readers, editors: $editors) {
      id
      percentage
      value
      data
      posDocumentID
      invoiceID
      readers
      editors
      createdAt
      updatedAt
      company {
        id
        name
        TAXID
        verificationDigit
        personType
        constitutionDate
        defaultLocation
        isKeyPartner
        companyMainRole
        defaultSignature
        bankingEnabled
        restrictiveLists
        PEPS
        PEPSCategory
        videoURLs
        lastDateOfReferral
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      salesmen {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSalesmanComTip = /* GraphQL */ `
  subscription OnCreateSalesmanComTip(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateSalesmanComTip(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      salesmanID
      comtipID
      percentage
      value
      comtipType
      data
      readers
      editors
      createdAt
      updatedAt
      salesman {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      comtip {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateSalesmanComTip = /* GraphQL */ `
  subscription OnUpdateSalesmanComTip(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateSalesmanComTip(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      salesmanID
      comtipID
      percentage
      value
      comtipType
      data
      readers
      editors
      createdAt
      updatedAt
      salesman {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      comtip {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteSalesmanComTip = /* GraphQL */ `
  subscription OnDeleteSalesmanComTip(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onDeleteSalesmanComTip(
      owner: $owner
      readers: $readers
      editors: $editors
    ) {
      id
      salesmanID
      comtipID
      percentage
      value
      comtipType
      data
      readers
      editors
      createdAt
      updatedAt
      salesman {
        id
        code
        nationalID
        name
        lastname
        userID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      comtip {
        id
        percentage
        value
        data
        posDocumentID
        invoiceID
        readers
        editors
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateIDType = /* GraphQL */ `
  subscription OnCreateIDType {
    onCreateIDType {
      id
      code
      name
      abbreviation
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateIDType = /* GraphQL */ `
  subscription OnUpdateIDType {
    onUpdateIDType {
      id
      code
      name
      abbreviation
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteIDType = /* GraphQL */ `
  subscription OnDeleteIDType {
    onDeleteIDType {
      id
      code
      name
      abbreviation
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFiscalResponsability = /* GraphQL */ `
  subscription OnCreateFiscalResponsability {
    onCreateFiscalResponsability {
      id
      code
      name
      country
      createdAt
      updatedAt
      companiesConfigurations {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateFiscalResponsability = /* GraphQL */ `
  subscription OnUpdateFiscalResponsability {
    onUpdateFiscalResponsability {
      id
      code
      name
      country
      createdAt
      updatedAt
      companiesConfigurations {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteFiscalResponsability = /* GraphQL */ `
  subscription OnDeleteFiscalResponsability {
    onDeleteFiscalResponsability {
      id
      code
      name
      country
      createdAt
      updatedAt
      companiesConfigurations {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRegimenType = /* GraphQL */ `
  subscription OnCreateRegimenType {
    onCreateRegimenType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRegimenType = /* GraphQL */ `
  subscription OnUpdateRegimenType {
    onUpdateRegimenType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRegimenType = /* GraphQL */ `
  subscription OnDeleteRegimenType {
    onDeleteRegimenType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBank = /* GraphQL */ `
  subscription OnCreateBank {
    onCreateBank {
      id
      name
      description
      extraData
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      finppiAccounts {
        nextToken
        __typename
      }
      thirdPartyAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateBank = /* GraphQL */ `
  subscription OnUpdateBank {
    onUpdateBank {
      id
      name
      description
      extraData
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      finppiAccounts {
        nextToken
        __typename
      }
      thirdPartyAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteBank = /* GraphQL */ `
  subscription OnDeleteBank {
    onDeleteBank {
      id
      name
      description
      extraData
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      finppiAccounts {
        nextToken
        __typename
      }
      thirdPartyAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAccountType = /* GraphQL */ `
  subscription OnCreateAccountType {
    onCreateAccountType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAccountType = /* GraphQL */ `
  subscription OnUpdateAccountType {
    onUpdateAccountType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAccountType = /* GraphQL */ `
  subscription OnDeleteAccountType {
    onDeleteAccountType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUnit = /* GraphQL */ `
  subscription OnCreateUnit {
    onCreateUnit {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUnit = /* GraphQL */ `
  subscription OnUpdateUnit {
    onUpdateUnit {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUnit = /* GraphQL */ `
  subscription OnDeleteUnit {
    onDeleteUnit {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProductServiceType = /* GraphQL */ `
  subscription OnCreateProductServiceType {
    onCreateProductServiceType {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProductServiceType = /* GraphQL */ `
  subscription OnUpdateProductServiceType {
    onUpdateProductServiceType {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProductServiceType = /* GraphQL */ `
  subscription OnDeleteProductServiceType {
    onDeleteProductServiceType {
      id
      code
      name
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEInvoiceProvider = /* GraphQL */ `
  subscription OnCreateEInvoiceProvider {
    onCreateEInvoiceProvider {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEInvoiceProvider = /* GraphQL */ `
  subscription OnUpdateEInvoiceProvider {
    onUpdateEInvoiceProvider {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEInvoiceProvider = /* GraphQL */ `
  subscription OnDeleteEInvoiceProvider {
    onDeleteEInvoiceProvider {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEPayrollProvider = /* GraphQL */ `
  subscription OnCreateEPayrollProvider {
    onCreateEPayrollProvider {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEPayrollProvider = /* GraphQL */ `
  subscription OnUpdateEPayrollProvider {
    onUpdateEPayrollProvider {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEPayrollProvider = /* GraphQL */ `
  subscription OnDeleteEPayrollProvider {
    onDeleteEPayrollProvider {
      id
      name
      apiKeyName
      apiKeyValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateIndustry = /* GraphQL */ `
  subscription OnCreateIndustry {
    onCreateIndustry {
      id
      CIIU
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateIndustry = /* GraphQL */ `
  subscription OnUpdateIndustry {
    onUpdateIndustry {
      id
      CIIU
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteIndustry = /* GraphQL */ `
  subscription OnDeleteIndustry {
    onDeleteIndustry {
      id
      CIIU
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCompanyDocumentType = /* GraphQL */ `
  subscription OnCreateCompanyDocumentType {
    onCreateCompanyDocumentType {
      id
      type
      allowedFileTypes
      maxSize
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompanyDocumentType = /* GraphQL */ `
  subscription OnUpdateCompanyDocumentType {
    onUpdateCompanyDocumentType {
      id
      type
      allowedFileTypes
      maxSize
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompanyDocumentType = /* GraphQL */ `
  subscription OnDeleteCompanyDocumentType {
    onDeleteCompanyDocumentType {
      id
      type
      allowedFileTypes
      maxSize
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBadge = /* GraphQL */ `
  subscription OnCreateBadge {
    onCreateBadge {
      id
      code
      name
      image {
        bucket
        region
        key
        __typename
      }
      text
      validFor
      requiredPoints
      callback
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateBadge = /* GraphQL */ `
  subscription OnUpdateBadge {
    onUpdateBadge {
      id
      code
      name
      image {
        bucket
        region
        key
        __typename
      }
      text
      validFor
      requiredPoints
      callback
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteBadge = /* GraphQL */ `
  subscription OnDeleteBadge {
    onDeleteBadge {
      id
      code
      name
      image {
        bucket
        region
        key
        __typename
      }
      text
      validFor
      requiredPoints
      callback
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePaymentMethod = /* GraphQL */ `
  subscription OnCreatePaymentMethod {
    onCreatePaymentMethod {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePaymentMethod = /* GraphQL */ `
  subscription OnUpdatePaymentMethod {
    onUpdatePaymentMethod {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePaymentMethod = /* GraphQL */ `
  subscription OnDeletePaymentMethod {
    onDeletePaymentMethod {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTopic = /* GraphQL */ `
  subscription OnCreateTopic {
    onCreateTopic {
      id
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTopic = /* GraphQL */ `
  subscription OnUpdateTopic {
    onUpdateTopic {
      id
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTopic = /* GraphQL */ `
  subscription OnDeleteTopic {
    onDeleteTopic {
      id
      name
      description
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDefaultCompanyRole = /* GraphQL */ `
  subscription OnCreateDefaultCompanyRole {
    onCreateDefaultCompanyRole {
      id
      name
      description
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDefaultCompanyRole = /* GraphQL */ `
  subscription OnUpdateDefaultCompanyRole {
    onUpdateDefaultCompanyRole {
      id
      name
      description
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDefaultCompanyRole = /* GraphQL */ `
  subscription OnDeleteDefaultCompanyRole {
    onDeleteDefaultCompanyRole {
      id
      name
      description
      capacities {
        table
        read
        write
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateForm = /* GraphQL */ `
  subscription OnCreateForm {
    onCreateForm {
      id
      name
      description
      data
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateForm = /* GraphQL */ `
  subscription OnUpdateForm {
    onUpdateForm {
      id
      name
      description
      data
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteForm = /* GraphQL */ `
  subscription OnDeleteForm {
    onDeleteForm {
      id
      name
      description
      data
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateKeyPartnerProductService = /* GraphQL */ `
  subscription OnCreateKeyPartnerProductService {
    onCreateKeyPartnerProductService {
      id
      code
      name
      description
      notes
      productServiceTypeID
      contactEmail
      url
      clicks
      clickedBy
      acquired
      rating
      callback
      dataKeys {
        name
        mandatory
        possibleValues
        defaultValue
        description
        comments
        __typename
      }
      requirements {
        type
        value
        condition
        fullText
        __typename
      }
      requiredDocuments {
        type
        name
        mandatory
        comment
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      owner
      keyPartner {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateKeyPartnerProductService = /* GraphQL */ `
  subscription OnUpdateKeyPartnerProductService {
    onUpdateKeyPartnerProductService {
      id
      code
      name
      description
      notes
      productServiceTypeID
      contactEmail
      url
      clicks
      clickedBy
      acquired
      rating
      callback
      dataKeys {
        name
        mandatory
        possibleValues
        defaultValue
        description
        comments
        __typename
      }
      requirements {
        type
        value
        condition
        fullText
        __typename
      }
      requiredDocuments {
        type
        name
        mandatory
        comment
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      owner
      keyPartner {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteKeyPartnerProductService = /* GraphQL */ `
  subscription OnDeleteKeyPartnerProductService {
    onDeleteKeyPartnerProductService {
      id
      code
      name
      description
      notes
      productServiceTypeID
      contactEmail
      url
      clicks
      clickedBy
      acquired
      rating
      callback
      dataKeys {
        name
        mandatory
        possibleValues
        defaultValue
        description
        comments
        __typename
      }
      requirements {
        type
        value
        condition
        fullText
        __typename
      }
      requiredDocuments {
        type
        name
        mandatory
        comment
        __typename
      }
      photos {
        bucket
        region
        key
        __typename
      }
      tags {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      owner
      keyPartner {
        id
        personType
        nationalID
        verificationDigit
        name
        lastname
        businessName
        description
        defaultLocation
        rating
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onCreateNoteConcept = /* GraphQL */ `
  subscription OnCreateNoteConcept {
    onCreateNoteConcept {
      id
      code
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNoteConcept = /* GraphQL */ `
  subscription OnUpdateNoteConcept {
    onUpdateNoteConcept {
      id
      code
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNoteConcept = /* GraphQL */ `
  subscription OnDeleteNoteConcept {
    onDeleteNoteConcept {
      id
      code
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInvoiceOperationType = /* GraphQL */ `
  subscription OnCreateInvoiceOperationType {
    onCreateInvoiceOperationType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInvoiceOperationType = /* GraphQL */ `
  subscription OnUpdateInvoiceOperationType {
    onUpdateInvoiceOperationType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInvoiceOperationType = /* GraphQL */ `
  subscription OnDeleteInvoiceOperationType {
    onDeleteInvoiceOperationType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTariffItem = /* GraphQL */ `
  subscription OnCreateTariffItem {
    onCreateTariffItem {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTariffItem = /* GraphQL */ `
  subscription OnUpdateTariffItem {
    onUpdateTariffItem {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTariffItem = /* GraphQL */ `
  subscription OnDeleteTariffItem {
    onDeleteTariffItem {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUNSPSC = /* GraphQL */ `
  subscription OnCreateUNSPSC {
    onCreateUNSPSC {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUNSPSC = /* GraphQL */ `
  subscription OnUpdateUNSPSC {
    onUpdateUNSPSC {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUNSPSC = /* GraphQL */ `
  subscription OnDeleteUNSPSC {
    onDeleteUNSPSC {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePlan = /* GraphQL */ `
  subscription OnCreatePlan {
    onCreatePlan {
      id
      planID
      name
      description
      data
      createdAt
      updatedAt
      features {
        nextToken
        __typename
      }
      terms {
        nextToken
        __typename
      }
      price {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePlan = /* GraphQL */ `
  subscription OnUpdatePlan {
    onUpdatePlan {
      id
      planID
      name
      description
      data
      createdAt
      updatedAt
      features {
        nextToken
        __typename
      }
      terms {
        nextToken
        __typename
      }
      price {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePlan = /* GraphQL */ `
  subscription OnDeletePlan {
    onDeletePlan {
      id
      planID
      name
      description
      data
      createdAt
      updatedAt
      features {
        nextToken
        __typename
      }
      terms {
        nextToken
        __typename
      }
      price {
        id
        value
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePlanFeature = /* GraphQL */ `
  subscription OnCreatePlanFeature($owner: String) {
    onCreatePlanFeature(owner: $owner) {
      id
      planID
      featureID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      feature {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePlanFeature = /* GraphQL */ `
  subscription OnUpdatePlanFeature($owner: String) {
    onUpdatePlanFeature(owner: $owner) {
      id
      planID
      featureID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      feature {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePlanFeature = /* GraphQL */ `
  subscription OnDeletePlanFeature($owner: String) {
    onDeletePlanFeature(owner: $owner) {
      id
      planID
      featureID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      feature {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateFeature = /* GraphQL */ `
  subscription OnCreateFeature {
    onCreateFeature {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateFeature = /* GraphQL */ `
  subscription OnUpdateFeature {
    onUpdateFeature {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteFeature = /* GraphQL */ `
  subscription OnDeleteFeature {
    onDeleteFeature {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePlanTerm = /* GraphQL */ `
  subscription OnCreatePlanTerm($owner: String) {
    onCreatePlanTerm(owner: $owner) {
      id
      planID
      termID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      term {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePlanTerm = /* GraphQL */ `
  subscription OnUpdatePlanTerm($owner: String) {
    onUpdatePlanTerm(owner: $owner) {
      id
      planID
      termID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      term {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePlanTerm = /* GraphQL */ `
  subscription OnDeletePlanTerm($owner: String) {
    onDeletePlanTerm(owner: $owner) {
      id
      planID
      termID
      createdAt
      updatedAt
      plan {
        id
        planID
        name
        description
        data
        createdAt
        updatedAt
        __typename
      }
      owner
      term {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTerm = /* GraphQL */ `
  subscription OnCreateTerm {
    onCreateTerm {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTerm = /* GraphQL */ `
  subscription OnUpdateTerm {
    onUpdateTerm {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTerm = /* GraphQL */ `
  subscription OnDeleteTerm {
    onDeleteTerm {
      id
      name
      description
      documents {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      plans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePlanPrice = /* GraphQL */ `
  subscription OnCreatePlanPrice {
    onCreatePlanPrice {
      id
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePlanPrice = /* GraphQL */ `
  subscription OnUpdatePlanPrice {
    onUpdatePlanPrice {
      id
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePlanPrice = /* GraphQL */ `
  subscription OnDeletePlanPrice {
    onDeletePlanPrice {
      id
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateClassCategory = /* GraphQL */ `
  subscription OnCreateClassCategory {
    onCreateClassCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateClassCategory = /* GraphQL */ `
  subscription OnUpdateClassCategory {
    onUpdateClassCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteClassCategory = /* GraphQL */ `
  subscription OnDeleteClassCategory {
    onDeleteClassCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateGroupCategory = /* GraphQL */ `
  subscription OnCreateGroupCategory {
    onCreateGroupCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateGroupCategory = /* GraphQL */ `
  subscription OnUpdateGroupCategory {
    onUpdateGroupCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteGroupCategory = /* GraphQL */ `
  subscription OnDeleteGroupCategory {
    onDeleteGroupCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      classCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      accountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAccountCategory = /* GraphQL */ `
  subscription OnCreateAccountCategory {
    onCreateAccountCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateAccountCategory = /* GraphQL */ `
  subscription OnUpdateAccountCategory {
    onUpdateAccountCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAccountCategory = /* GraphQL */ `
  subscription OnDeleteAccountCategory {
    onDeleteAccountCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      groupCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      subAccountCategories {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSubAccountCategory = /* GraphQL */ `
  subscription OnCreateSubAccountCategory {
    onCreateSubAccountCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSubAccountCategory = /* GraphQL */ `
  subscription OnUpdateSubAccountCategory {
    onUpdateSubAccountCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSubAccountCategory = /* GraphQL */ `
  subscription OnDeleteSubAccountCategory {
    onDeleteSubAccountCategory {
      id
      code
      name
      description
      nature
      createdAt
      updatedAt
      incomes {
        nextToken
        __typename
      }
      expenses {
        nextToken
        __typename
      }
      accountCategory {
        id
        code
        name
        description
        nature
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePatientType = /* GraphQL */ `
  subscription OnCreatePatientType {
    onCreatePatientType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePatientType = /* GraphQL */ `
  subscription OnUpdatePatientType {
    onUpdatePatientType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePatientType = /* GraphQL */ `
  subscription OnDeletePatientType {
    onDeletePatientType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateContractingAndPaymentMethod = /* GraphQL */ `
  subscription OnCreateContractingAndPaymentMethod {
    onCreateContractingAndPaymentMethod {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContractingAndPaymentMethod = /* GraphQL */ `
  subscription OnUpdateContractingAndPaymentMethod {
    onUpdateContractingAndPaymentMethod {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContractingAndPaymentMethod = /* GraphQL */ `
  subscription OnDeleteContractingAndPaymentMethod {
    onDeleteContractingAndPaymentMethod {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBenefitsPlan = /* GraphQL */ `
  subscription OnCreateBenefitsPlan {
    onCreateBenefitsPlan {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBenefitsPlan = /* GraphQL */ `
  subscription OnUpdateBenefitsPlan {
    onUpdateBenefitsPlan {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBenefitsPlan = /* GraphQL */ `
  subscription OnDeleteBenefitsPlan {
    onDeleteBenefitsPlan {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateHealthReferencedDocumentType = /* GraphQL */ `
  subscription OnCreateHealthReferencedDocumentType {
    onCreateHealthReferencedDocumentType {
      id
      code
      name
      comment
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateHealthReferencedDocumentType = /* GraphQL */ `
  subscription OnUpdateHealthReferencedDocumentType {
    onUpdateHealthReferencedDocumentType {
      id
      code
      name
      comment
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteHealthReferencedDocumentType = /* GraphQL */ `
  subscription OnDeleteHealthReferencedDocumentType {
    onDeleteHealthReferencedDocumentType {
      id
      code
      name
      comment
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateHealthOperationType = /* GraphQL */ `
  subscription OnCreateHealthOperationType {
    onCreateHealthOperationType {
      id
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateHealthOperationType = /* GraphQL */ `
  subscription OnUpdateHealthOperationType {
    onUpdateHealthOperationType {
      id
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteHealthOperationType = /* GraphQL */ `
  subscription OnDeleteHealthOperationType {
    onDeleteHealthOperationType {
      id
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateKeyPartner = /* GraphQL */ `
  subscription OnCreateKeyPartner {
    onCreateKeyPartner {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      description
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      tags {
        name
        value
        __typename
      }
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      rating
      comments {
        author
        text
        __typename
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateKeyPartner = /* GraphQL */ `
  subscription OnUpdateKeyPartner {
    onUpdateKeyPartner {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      description
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      tags {
        name
        value
        __typename
      }
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      rating
      comments {
        author
        text
        __typename
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteKeyPartner = /* GraphQL */ `
  subscription OnDeleteKeyPartner {
    onDeleteKeyPartner {
      id
      personType
      nationalID
      verificationDigit
      name
      lastname
      businessName
      description
      locations {
        name
        headquarters
        branchCode
        __typename
      }
      defaultLocation
      phoneNumber {
        countryCode
        number
        extension
        __typename
      }
      contacts {
        name
        lastname
        title
        email
        main
        __typename
      }
      tags {
        name
        value
        __typename
      }
      profilePhoto {
        bucket
        region
        key
        __typename
      }
      rating
      comments {
        author
        text
        __typename
      }
      createdAt
      updatedAt
      idType {
        id
        code
        name
        abbreviation
        country
        createdAt
        updatedAt
        __typename
      }
      productsServices {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateSuppressionList = /* GraphQL */ `
  subscription OnCreateSuppressionList {
    onCreateSuppressionList {
      email
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSuppressionList = /* GraphQL */ `
  subscription OnUpdateSuppressionList {
    onUpdateSuppressionList {
      email
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSuppressionList = /* GraphQL */ `
  subscription OnDeleteSuppressionList {
    onDeleteSuppressionList {
      email
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmployeeType = /* GraphQL */ `
  subscription OnCreateEmployeeType {
    onCreateEmployeeType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeType = /* GraphQL */ `
  subscription OnUpdateEmployeeType {
    onUpdateEmployeeType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeType = /* GraphQL */ `
  subscription OnDeleteEmployeeType {
    onDeleteEmployeeType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmployeeSubType = /* GraphQL */ `
  subscription OnCreateEmployeeSubType {
    onCreateEmployeeSubType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeSubType = /* GraphQL */ `
  subscription OnUpdateEmployeeSubType {
    onUpdateEmployeeSubType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeSubType = /* GraphQL */ `
  subscription OnDeleteEmployeeSubType {
    onDeleteEmployeeSubType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateContractType = /* GraphQL */ `
  subscription OnCreateContractType {
    onCreateContractType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContractType = /* GraphQL */ `
  subscription OnUpdateContractType {
    onUpdateContractType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContractType = /* GraphQL */ `
  subscription OnDeleteContractType {
    onDeleteContractType {
      id
      code
      name
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
