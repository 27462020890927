import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';

import { BrowserRouter } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports';

import App from './containers/App/App';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import './scss/app.scss';
import QueryProvider from './containers/App/react-query';
import ErrorBoundary from './shared/components/ErrorBoundary';
Amplify.configure(aws_exports);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <QueryProvider>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </QueryProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
