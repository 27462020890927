export const getCompanyPOS = `
query GetCompany($id: ID!) {
  getCompany(id: $id) {
    pos {
      items {
        code
        id
        location {
          phoneNumbers {
            number
          }
          address {
            address_line1
            address_line2
            city
            state
          }
        }
      }
    }
  }
}`;
