import { API, graphqlOperation } from 'aws-amplify';
import { updateMyPlan } from '../graphql/mutations';
import { limitPlans } from './plans';

export const addDocument = async ({ typeDocument, currentUser, myPlan }) => {
  try {
    const currentIDPlan =
      currentUser?.company?.plan.type.planID ?? myPlan.type.planID;

    if (
      limitPlans.includes(currentIDPlan) &&
      (currentUser?.company.plan.data || myPlan.data)
    ) {
      let dataPlan = JSON.parse(currentUser?.company.plan.data ?? myPlan.data);

      dataPlan[typeDocument] = dataPlan[typeDocument]
        ? dataPlan[typeDocument] + 1
        : 1;

      if (typeDocument !== 'documentPOS') {
        dataPlan['currentElectronicDocuments'] =
          dataPlan['currentElectronicDocuments'] + 1;
      }

      const input = {
        id: currentUser?.company.plan.id ?? myPlan.id,
        data: JSON.stringify(dataPlan),
      };

      const result = await API.graphql(
        graphqlOperation(updateMyPlan, {
          input,
        })
      );
      return result.data.updateMyPlan;
    }
  } catch (error) {
    console.log(error);
  }
};

export const validationTotalDocuments = ({ currentUser }) => {
  const currentIDPlan = currentUser.company.plan.type.planID;
  if (limitPlans.includes(currentIDPlan) && currentUser.company.plan.data) {
    const dataPlan = JSON.parse(currentUser.company.plan.data);
    if (
      dataPlan['currentElectronicDocuments'] >=
      dataPlan['maxElectronicDocuments']
    ) {
      return true;
    }
  }
  return false;
};
