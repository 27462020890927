import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createStructuredSelector } from 'reselect';

import {
  changeSidebarVisibility,
  changeMobileSidebarVisibility,
} from '../../redux/sidebar/sidebar.actions';
import {
  changeThemeToDark,
  changeThemeToLight,
} from '../../redux/theme/theme.actions';
import Swal from 'sweetalert2';

import { SidebarProps } from '../../shared/prop-types/ReducerProps';
import Wrapper from '../../shared/components/wrapper';
import { useEnabler } from '../../shared/hooks/Enabler';
import { useEffect } from 'react';
import {
  onUpdateCompany,
  onUpdateMyPlan,
  onUpdateUser,
} from '../../graphql/subscriptions';
import { onUpdateCompanyConfigurationSubscription } from '../App/Router/queries';
import { setCurrentUser } from '../../redux/user/user.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { getUserQuery } from '../../redux/user/queries';
import { createCashier, updateMyPlan } from '../../graphql/mutations';
import { limitPlans } from '../../utils/plans';
import { cashiersByNationalID } from '../../graphql/queries';
const Layout = ({ sidebar, setCurrentUser }) => {
  const dispatch = useDispatch();
  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  const currentUser = useSelector((state) => state.user.currentUser);
  const currentPlan = useSelector((state) => state.myPlan);
  const maxDocumentPos = JSON.parse(
    currentPlan.type.data
  )?.maxElectronicDocuments;
  const currentDocumentPos =
    currentPlan.data && JSON.parse(currentPlan.data).documentPOS;
  const documentsWarnings = maxDocumentPos * 0.9;

  const { isNearExpiration, expirationDate } = useEnabler();

  const setDataPlans = async () => {
    const currentIDPlan = currentUser.company?.plan?.type?.planID;
    if (
      limitPlans.includes(currentIDPlan) &&
      !currentUser.company?.plan?.data
    ) {
      const dataPlan = {
        maxElectronicDocuments: Number(currentIDPlan.replace(/[^0-9]+/g, '')),
        currentElectronicDocuments: 0,
        invoices: 0,
        creditNotes: 0,
        debitNotes: 0,
        supportDocuments: 0,
        adjustmentNotesSupportDocuments: 0,
        payrolls: 0,
        adjustmentNotesPayrolls: 0,
        bills: 0,
      };

      const input = {
        id: currentUser.company.plan.id,
        data: JSON.stringify(dataPlan),
      };

      await API.graphql(
        graphqlOperation(updateMyPlan, {
          input,
        })
      );

      const newUserQ = await API.graphql(
        graphqlOperation(getUserQuery, {
          id: currentUser?.id,
        })
      );
      let newUser = newUserQ.data.getUser;

      if (newUser.company.profilePhoto?.key) {
        const ImgUrl = await Storage.get(newUser.company.profilePhoto?.key);

        newUser = { ...newUser, company: { ...newUser.company, ImgUrl } };
      }

      setCurrentUser(newUser);
    }
  };

  useEffect(() => {
    setDataPlans();
  }, [currentUser.id]);

  useEffect(() => {
    let didCancel = true;
    async function setCrispData() {
      if (currentUser.company?.profilePhoto?.key) {
        const ImgUrl = await Storage.get(
          currentUser.company?.profilePhoto?.key
        );
        setCurrentUser({
          ...currentUser,
          company: {
            ...currentUser.company,
            ImgUrl,
          },
        });
      }
    }
    if (didCancel) setCrispData();
    return () => {
      didCancel = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentUser, currentUser.id]);

  useEffect(() => {
    if (isNearExpiration && expirationDate > 0) {
      Swal.fire({
        title: 'Suscripción a punto de expirar',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Renuévala aquí',
        confirmButtonColor: '#272443',
        cancelButtonText: 'Recordar más tarde',
        html: `
          <div>
          <p>Tu suscripción va a expirar en ${expirationDate} días. Recuerda renovarla con tiempo para seguir usando la app.</p>
          <p><strong>Nota:</strong> Una vez realizada la compra por favor ingresa al chat de soporte para habilitar tu cuenta.</p>
          </div>
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open('https://finppi.com/planes/', 'blank');
        }
      });
    }

    if (expirationDate < 0) {
      Swal.fire({
        title: 'Tu suscripción ha expirado',
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: '#272443',
        confirmButtonText: 'Renuévala aquí',
        html: `
          <div>
          <p> Para seguir emitiendo documentos electrónicos con tu cuenta es necesario que renueves tu suscripción. Puedes hacerlo a través del siguiente enlace:</p>
          
          </div>
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open('https://finppi.com/planes/', 'blank');
        }
      });
    }

    const subscription = API.graphql(
      graphqlOperation(onUpdateUser, {
        owner: currentUser?.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: async (eventData) => {
        const newUserQ = await API.graphql(
          graphqlOperation(getUserQuery, {
            id: currentUser?.id,
          })
        );
        let newUser = newUserQ.data.getUser;

        if (newUser.company.profilePhoto?.key) {
          const ImgUrl = await Storage.get(newUser.company.profilePhoto?.key);

          newUser = { ...newUser, company: { ...newUser.company, ImgUrl } };
        }

        setCurrentUser(newUser);
      },
    });
    const subscription2 = API.graphql(
      graphqlOperation(onUpdateCompany, {
        owner: currentUser?.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: async (eventData) => {
        const newUserQ = await API.graphql(
          graphqlOperation(getUserQuery, {
            id: currentUser?.id,
          })
        );
        let newUser = newUserQ.data.getUser;

        if (newUser.company.profilePhoto?.key) {
          const ImgUrl = await Storage.get(newUser.company.profilePhoto?.key);

          newUser = { ...newUser, company: { ...newUser.company, ImgUrl } };
        }

        setCurrentUser(newUser);
      },
    });
    const subscription3 = API.graphql(
      graphqlOperation(onUpdateCompanyConfigurationSubscription, {
        owner: currentUser?.id,
        readers: currentUser?.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: async (eventData) => {
        const newUserQ = await API.graphql(
          graphqlOperation(getUserQuery, {
            id: currentUser?.id,
          })
        );
        let newUser = newUserQ.data.getUser;

        if (newUser.company.profilePhoto?.key) {
          const ImgUrl = await Storage.get(newUser.company.profilePhoto?.key);

          newUser = { ...newUser, company: { ...newUser.company, ImgUrl } };
        }

        setCurrentUser(newUser);
      },
    });
    const subscriptionOnUpdatePlan = API.graphql(
      graphqlOperation(onUpdateMyPlan, {
        owner: currentUser?.id,
        readers: currentUser?.id,
        editors: currentUser?.id,
      })
    ).subscribe({
      next: async (eventData) => {
        const newUserQ = await API.graphql(
          graphqlOperation(getUserQuery, {
            id: currentUser?.id,
          })
        );
        let newUser = newUserQ.data.getUser;

        if (newUser.company.profilePhoto?.key) {
          const ImgUrl = await Storage.get(newUser.company.profilePhoto?.key);

          newUser = { ...newUser, company: { ...newUser.company, ImgUrl } };
        }

        setCurrentUser(newUser);
      },
    });
    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
      subscription3.unsubscribe();
      subscriptionOnUpdatePlan.unsubscribe();
    };
  }, [currentUser, setCurrentUser]);

  useEffect(() => {
    if (currentPlan.active && currentDocumentPos > documentsWarnings) {
      Swal.fire({
        title: 'Has alcanzado mas del 90% de tus documentos POS',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Renuévala aquí',
        confirmButtonColor: '#272443',
        cancelButtonText: 'Recordar más tarde',
        html: `
          <div>
          <p>Se estan agotando los documentos POS de tu plan. Recuerda renovar tu plan con tiempo para seguir emitiendo documentos POS.</p>
          <p><strong>Nota:</strong> Una vez realizada la compra por favor ingresa al chat de soporte.</p>
          </div>
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open('https://finppi.com/planes/', 'blank');
        }
      });
    }
  }, [currentUser.id]);

  useEffect(() => {
    (async () => {
      if (!currentUser?.cashierID) {
        const cashierUser = await API.graphql(
          graphqlOperation(cashiersByNationalID, {
            nationalID: currentUser.nationalID,
          })
        );

        if (!cashierUser.data.cashiersByNationalID.items.length) {
          await API.graphql(
            graphqlOperation(createCashier, {
              input: {
                cashierCompanyId: currentUser.company.id,
                cashierIdTypeId: '51f071d8-58d8-4e0f-b6a5-766c0c158683',
                lastname: currentUser.lastname,
                name: currentUser.name,
                userID: currentUser.id,
                nationalID: currentUser.nationalID,
              },
            })
          );
        }
      }
    })();
  }, [currentUser.id]);

  return (
    <div className={layoutClass}>
      <Wrapper />
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
      />
      <Sidebar
        sidebar={sidebar}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
      />
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  sidebar: (state) => state.sidebar,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
