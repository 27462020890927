import React from 'react';
import { Card, CardBody, CardImg, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import AccountCashOutline from 'mdi-react/AccountCashOutlineIcon';
import Cash from 'mdi-react/CashIcon';
import CreditCardPlusOutline from 'mdi-react/CreditCardPlusOutlineIcon';
import { useSelector } from 'react-redux';
import ClipboardListOutline from 'mdi-react/ClipboardListOutlineIcon';
import UserCardDetailsIcon from 'mdi-react/UserCardDetailsIcon';
import UserBadgeOutlineIcon from 'mdi-react/UserBadgeOutlineIcon';

const CardProduct = ({ product, onClick }) => {
  const theme = useSelector((state) => state.theme);
  const styleFill = () => {
    return theme.className === 'theme-dark' ? '#4ce1b6' : '#272443';
  };
  const getIcon = () => {
    if (product.name.toLowerCase() === 'factura') {
      return (
        <ClipboardListOutline
          style={{
            fill: styleFill(),
          }}
        />
      );
    } else if (product.name.toLowerCase() === 'nómina electrónica') {
      return (
        <AccountCashOutline
          style={{
            fill: styleFill(),
          }}
        />
      );
    } else if (product.name.toLowerCase() === 'empleados') {
      return (
        <UserBadgeOutlineIcon
          style={{
            fill: styleFill(),
          }}
        />
      );
    } else if (product.name.toLowerCase() === 'Recepción') {
      return (
        <Cash
          style={{
            fill: styleFill(),
          }}
        />
      );
    } else if (product.name.toLowerCase() === 'clientes') {
      return (
        <UserCardDetailsIcon
          style={{
            fill: styleFill(),
          }}
        />
      );
    } else if (product.name.toLowerCase() === 'prod y servicios') {
      return (
        <CreditCardPlusOutline
          style={{
            fill: styleFill(),
          }}
        />
      );
    } else {
      return (
        <Cash
          style={{
            fill: styleFill(),
          }}
        />
      );
    }
  };
  return (
    <Col md={6} xl={4} lg={4} xs={6}>
      <Card className="cursor-pointer" onClick={onClick}>
        <CardBody className="pos__card-widget ">
          <div className="mobile-app-widget d-flex flex-column justify-content-center align-items-center">
            {/* {getIcon()} */}
            <div className="pos-app-widget__title mb-0 mt-2 text-center">
              <p className="bold-text text-center">{product.code}</p>
              <h5>{product.name}</h5>
              {/* <h5>
                {Number(product.price).toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h5> */}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardProduct;
