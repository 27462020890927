import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, Col, Input, Row } from 'reactstrap';
import ModalPos from './ModalPos';
import CalculatorIcon from 'mdi-react/CalculatorIcon';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import InitialCash from './InitialCash';

export const getTerminalCashier = /* GraphQL */ `
  query GetTerminalCashier($id: ID!) {
    getTerminalCashier(id: $id) {
      id
      terminalID
      cashierID
      initDateTime
      endDateTime
      cashBefore
      cashAfter
      cashAfterDetail
      cashInputs {
        dateTime
        value
        reason
      }
      cashOutputs {
        dateTime
        value
        reason
      }
      observations
      documents {
        items {
          id
          code
          date
          total
          taxes
          data
        }
        nextToken
      }
    }
  }
`;

const TurnOffModal = ({
  modal,
  onCancel,
  handleCloseTurn,
  statePOS,
  formatNumber,
  totalCashBefore,
  setObservationTurn,
  handleChangeInput,
}) => {
  const [defaultValues, setDefaultValues] = useState({});
  const [step, setStep] = useState(1);
  const [expectedMoney, setExpectedMoney] = useState(0);
  const [groupPayments, setGroupPayments] = useState({});

  useEffect(() => {
    if (modal) {
      getValues();
    }
  }, [modal]);

  const getValues = async () => {
    try {
      const id = statePOS.idTurn;

      const getDataTerminal = await API.graphql(
        graphqlOperation(getTerminalCashier, {
          id,
        })
      );

      const currentTerminalCash = getDataTerminal.data.getTerminalCashier;

      const documentsPos = currentTerminalCash.documents.items?.map((item) => ({
        total: item.total,
        data: JSON.parse(item.data),
      }));

      const groupBy = _.groupBy(documentsPos, 'data.payment.type');
      const dataTotal = {
        cash: _.sumBy(groupBy?.cash, 'total'),
        cardPayment: _.sumBy(groupBy?.cardPayment, 'total'),
        combined: _.sumBy(groupBy?.combined, 'total'),
        transfer: _.sumBy(groupBy?.transfer, 'total'),
      };
      setGroupPayments(dataTotal);

      const expectMoneyData =
        currentTerminalCash.cashBefore +
        _.sumBy(currentTerminalCash?.cashInputs, 'value') +
        dataTotal.cash +
        dataTotal.cardPayment +
        dataTotal.transfer +
        dataTotal.combined -
        _.sumBy(currentTerminalCash?.cashOutputs, 'value');

      setExpectedMoney(expectMoneyData);

      setDefaultValues(currentTerminalCash);
    } catch (error) {
      console.log('error', error);
      toast.error('Ha ocurrido un error, vuelva a intentar');
    }
  };

  return (
    <ModalPos open={modal} title="Cerrar turno" toggle={onCancel}>
      {step == 1 && (
        <>
          <InitialCash
            title="Dinero en caja"
            {...{ totalCashBefore, setObservationTurn, handleChangeInput }}
          />
          <Button className="mt-2" outline onClick={() => setStep(2)}>
            Siguiente
          </Button>
        </>
      )}
      {step === 2 && (
        <>
          <Col>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 bold-text text-align-left">
                  Fecha inicio
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {moment(defaultValues?.initDateTime).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 bold-text  text-align-left">
                  Fecha cierre
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {moment().format('YYYY-MM-DD HH:mm:ss')}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 bold-text text-align-left">
                  Efectivo inicial (+)
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(defaultValues?.cashBefore)}
                </h5>
              </Col>
            </Row>
            <Row>
              <h5 className="mb-3 bold-text">Metodos de pago</h5>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 text-align-left">
                  Ventas en efectivo (+)
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(groupPayments?.cash)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 text-align-left">
                  Ventas tarjeta debito/credito (+)
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(groupPayments?.cardPayment)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5  text-align-left">
                  Ventas transferencia (+)
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(groupPayments?.transfer)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5  text-align-left">
                  Ventas combinadas (+)
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(groupPayments?.combined)}
                </h5>
              </Col>
            </Row>
            <Row>
              <h5 className="mb-3 bold-text">Movimientos de efectivo</h5>
            </Row>

            <Row>
              <Col>
                <h5 className="mb-3 mr-5  text-align-left">Ingresos (+)</h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(_.sumBy(defaultValues?.cashInputs, 'value'))}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5  text-align-left">Retiros (-)</h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(_.sumBy(defaultValues?.cashOutputs, 'value'))}
                </h5>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 bold-text text-align-left">
                  Dinero esperado
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(expectedMoney)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 bold-text  text-align-left">
                  Dinero en caja
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(totalCashBefore)}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3 mr-5 bold-text text-align-left">
                  Diferencia
                </h5>
              </Col>
              <Col>
                <h5 className="text-align-left">
                  {formatNumber(expectedMoney - totalCashBefore)}
                </h5>
              </Col>
            </Row>
          </Col>

          <Button className="mt-2 mr-3" outline onClick={() => setStep(1)}>
            Anterior
          </Button>
          <Button className="mt-2" outline onClick={handleCloseTurn}>
            Cerrar turno
          </Button>
        </>
      )}
    </ModalPos>
  );
};

export default TurnOffModal;
