import React, { useEffect } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import Dropdown from '../../../../../shared/components/form/Dropdown';
import { BankNameOptions } from '../../../../../utils/OthersData';
import HeaderPayment from '../../components/HeaderPayment';
import CurrencyFormat from 'react-currency-format';
import PercentIcon from 'mdi-react/PercentIcon';

const CardPayment = ({
  total,
  createPosDocument,
  handleChangePayment,
  paymentInfo,
  setTotalWithDiscount,
  formatNumber,
  isDiscountPercentage,
  handleChangeDiscount,
}) => {
  const discount = paymentInfo?.discount ?? 0;
  const calculeDiscount = isDiscountPercentage
    ? (total * discount) / 100
    : discount;
  useEffect(() => {
    setTotalWithDiscount(total - calculeDiscount);
  }, [total, calculeDiscount]);
  return (
    <div>
      <HeaderPayment total={formatNumber(total - calculeDiscount)} />

      <Row className="mt-3">
        <Col md={6} xl={6} lg={6} xs={6}>
          <Row>
            <Col>
              <p>Banco</p>
              <Dropdown
                placeholder="Seleccione"
                options={BankNameOptions}
                onChange={(value) =>
                  handleChangePayment({ target: { value, name: 'bankName' } })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <p>Tipo de tarjeta</p>
              <Row>
                <Col md={6} xl={6} lg={6} xs={12}>
                  <Button
                    outline
                    size="sm"
                    onClick={(e) =>
                      handleChangePayment({
                        target: { name: 'cardType', value: 'debit' },
                      })
                    }
                    active={paymentInfo.cardType === 'debit'}
                  >
                    <p>DÉBITO</p>
                  </Button>
                </Col>
                <Col md={6} xl={6} lg={6} xs={12}>
                  <Button
                    outline
                    size="sm"
                    onClick={(e) =>
                      handleChangePayment({
                        target: { name: 'cardType', value: 'credit' },
                      })
                    }
                    active={paymentInfo.cardType === 'credit'}
                  >
                    <p>CRÉDITO</p>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{`Descuento en ${isDiscountPercentage ? '%' : 'pesos'}`}</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix={isDiscountPercentage ? '%' : '$'}
                name="discount"
                value={discount}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'discount',
                    },
                  });
                }}
              />
            </Col>
            <Col xl={3}>
              <Button
                className="mt-4"
                outline
                size="sm"
                onClick={() => handleChangeDiscount()}
                active={isDiscountPercentage}
              >
                <p>
                  <PercentIcon />
                </p>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={6} xl={6} lg={6} xs={6}>
          <p>Observaciones</p>
          <textarea
            rows={10}
            className="textarea-description"
            name="observation"
            onChange={(e) => handleChangePayment(e)}
            value={paymentInfo.observation}
          />
        </Col>
      </Row>

      <Button
        className="mt-3"
        size="sm"
        outline
        onClick={() => createPosDocument()}
      >
        <p>Pagar</p>
      </Button>
    </div>
  );
};

export default CardPayment;
