import React from 'react';
import { connect } from 'react-redux';

import { useIdleTimer } from 'react-idle-timer';

import { useNavigate } from 'react-router-dom';
import { signOutStart } from '../../redux/user/user.actions';

const SESSION_IDEL_MINUTES = 60;

const Watcher = ({ children, signOutStart }) => {
  const navigate = useNavigate();

  const handleOnIdle = (event) => {
    signOutStart();
    navigate('/');
  };

  useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <React.Fragment>{children}</React.Fragment>;
};
const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
});
export default connect(null, mapDispatchToProps)(Watcher);
