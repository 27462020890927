import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

const AlertComponent = ({
  color,
  className,
  icon,
  children,
  onShow,
  buttonAction,
}) => {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => {
    setVisible(false);
  };

  let Icon;

  switch (color) {
    case 'info':
      Icon = <InformationOutlineIcon />;
      break;
    case 'success':
      Icon = <ThumbUpOutlineIcon />;
      break;
    case 'warning':
      Icon = <CommentAlertOutlineIcon />;
      break;
    case 'danger':
      Icon = <CloseCircleOutlineIcon />;
      break;
    default:
      console.log('Wrong color!');
      break;
  }

  if (visible) {
    return (
      <Alert color={color} className={className} isOpen={visible}>
        {icon && <div className="alert__icon">{Icon}</div>}

        <div className="alert__content">{children}</div>
        {buttonAction && (
          <a
            rel="noopener noreferrer"
            href="https://finppi.com/listaespera/"
            target="_blank"
          >
            <Button size="sm" className="close">
              <p>Aplicar</p>
            </Button>
          </a>
        )}
      </Alert>
    );
  }

  return <Button onClick={onShow}>Reestablecer</Button>;
};

export default AlertComponent;

AlertComponent.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onShow: PropTypes.func.isRequired,
};

AlertComponent.defaultProps = {
  color: '',
  icon: false,
  className: '',
  onShow: () => {},
};
