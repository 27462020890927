import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { renderComponentField } from '../../../shared/components/form/FormField';

export const Dropdown = React.forwardRef(
  (
    {
      onChange,
      value,
      name,
      placeholder,
      options,
      isClearable = false,
      ...props
    },
    ref
  ) => {
    const handleChange = (selectedOption) => {
      if (!selectedOption) {
        return onChange(null);
      }

      if (selectedOption && typeof selectedOption === 'object') {
        return onChange(selectedOption.value);
      }
      onChange(selectedOption.value);
    };
    const getLabel = (option) => {
      if (typeof option !== 'object') {
        return options.find((o) => o.value === option);
      }

      return options.find((o) => o.value === option?.value);
    };
    return (
      <Select
        name={name}
        value={getLabel(value)}
        onChange={handleChange}
        options={options}
        isClearable={isClearable}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        ref={ref}
        isDisabled={props.disabled}
        style={{
          border: 'none',
        }}
        {...props}
      />
    );
  }
);

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
};

Dropdown.defaultProps = {
  placeholder: '',
  options: [],
};

export default renderComponentField(Dropdown);
