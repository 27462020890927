import React from 'react';
import { Modal } from 'reactstrap';

import CloseIcon from 'mdi-react/CloseIcon';

const ModalPos = ({ open, setOpen, children, title, toggle }) => {
  const handleClose = () => {
    if (toggle) {
      toggle();
    } else {
      setOpen(false);
    }
  };

  return (
    <Modal
      size="lg"
      className="modal-pos"
      isOpen={open}
      toggle={handleClose}
      backdrop="static"
    >
      <div className="header">
        <h4>
          {title}

          <CloseIcon
            onClick={handleClose}
            className="cursor-pointer"
            style={{
              fill: '#ffffff',
              float: 'right',
            }}
          />
        </h4>
      </div>
      <div className="container">{children}</div>
    </Modal>
  );
};

export default ModalPos;
