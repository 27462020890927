import ThemeActionTypes from './theme.types';

const INITIAL_STATE = {
  className: 'theme-light',
};

const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThemeActionTypes.CHANGE_THEME_TO_DARK:
      return { className: 'theme-dark' };
    case ThemeActionTypes.CHANGE_THEME_TO_LIGHT:
      return { className: 'theme-light' };
    default:
      return state;
  }
};
export default themeReducer;
