import InvoiceActionTypes from './types';

const INITIAL_STATE = {
  view: 'default',
};

const invoiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InvoiceActionTypes.SELECT_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    default:
      return state;
  }
};
export default invoiceReducer;
