export const verificationDigit = (myNit) => {
  let vpri, x, y, z;

  vpri = new Array(16);
  z = myNit.length;

  vpri[1] = 3;
  vpri[2] = 7;
  vpri[3] = 13;
  vpri[4] = 17;
  vpri[5] = 19;
  vpri[6] = 23;
  vpri[7] = 29;
  vpri[8] = 37;
  vpri[9] = 41;
  vpri[10] = 43;
  vpri[11] = 47;
  vpri[12] = 53;
  vpri[13] = 59;
  vpri[14] = 67;
  vpri[15] = 71;

  x = 0;
  y = 0;
  for (let i = 0; i < z; i++) {
    y = myNit.substr(i, 1);

    x += y * vpri[z - i];
  }

  y = x % 11;

  return y > 1 ? 11 - y : y;
};
export const cleanObject = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') cleanObject(v);
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v.length === 0
    ) {
      // if (Array.isArray(object)) object.splice(k, 1);
      /*else*/ if (!(v instanceof Date)) delete object[k];
    }
  });
  return object;
};
export const setEmptyObject = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') setEmptyObject(v);
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v.length === 0
    ) {
      if (!(v instanceof Date)) object[k] = '';
    }
  });
  return object;
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.2)'
    );
  }
  throw new Error('Bad Hex');
};
export const transformToXML = (text) => {
  return String(text).replace(/(['"<>&'])(\w+;)?/g, (match, char, escaped) => {
    if (escaped) return match;

    switch (char) {
      case "'":
        return '&quot;';
      case '"':
        return '&apos;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '&':
        return '&amp;';
      default:
        return char;
    }
  });
};
export function truncate(source, size) {
  return source.length > size ? (
    <span title={source}>{source.slice(0, size - 1) + '…'}</span>
  ) : (
    source
  );
}

export const formatNumber = (number) => {
  return Number(number).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
