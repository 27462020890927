import React, { useEffect } from 'react';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';

const CardProductForm = ({
  dataProduct,
  removeProduct,
  addQuantity,
  removeQuantity,
}) => {
  return (
    <div className="container-card-product-form">
      <Row>
        <Col md={4} xl={4} lg={4} xs={4}>
          <p className="bold-text"> {dataProduct.name} </p>
          <h6>
            {Number(dataProduct.price).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>
        </Col>
        <Col md={3} xl={3} lg={3} xs={3}>
          <Row>
            <Col md={2} xl={4} lg={4} xs={2}>
              <ButtonGroup className="btn-group--icons">
                <Button
                  outline
                  color="danger"
                  onClick={() => removeQuantity(dataProduct)}
                >
                  <span className="lnr lnr-circle-minus" />
                </Button>
              </ButtonGroup>
            </Col>
            <Col md={3} xl={4} lg={4} xs={3}>
              <h5 className="mt-2 ml-2">{dataProduct.quantity}</h5>
            </Col>
            <Col md={3} xl={4} lg={4} xs={3}>
              <ButtonGroup className="btn-group--icons">
                <Button
                  outline
                  color="info"
                  onClick={() => addQuantity(dataProduct)}
                >
                  <span className="lnr lnr-plus-circle" />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
        <Col md={4} xl={3} lg={3} xs={4}>
          <p className="mt-2 text-align-right">
            {Number(dataProduct.price * dataProduct.quantity).toLocaleString(
              'de-DE',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </p>
        </Col>
        <Col md={1} xl={1} lg={1} xs={1}>
          <ButtonGroup className="btn-group--icons">
            <Button
              outline
              color="danger"
              onClick={() => removeProduct(dataProduct)}
            >
              <span className="lnr lnr-trash" />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

export default CardProductForm;
