import React, { useEffect } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import HeaderPayment from '../../components/HeaderPayment';
import Dropdown from '../../../../../shared/components/form/Dropdown';
import { BankNameOptions } from '../../../../../utils/OthersData';
import CurrencyFormat from 'react-currency-format';
import PercentIcon from 'mdi-react/PercentIcon';

const Transfer = ({
  total,
  createPosDocument,
  handleChangePayment,
  paymentInfo,
  setTotalWithDiscount,
  formatNumber,
  isDiscountPercentage,
  handleChangeDiscount,
}) => {
  const discount = paymentInfo?.discount ?? 0;
  const calculeDiscount = isDiscountPercentage
    ? (total * discount) / 100
    : discount;

  useEffect(() => {
    setTotalWithDiscount(total - calculeDiscount);
  }, [total, calculeDiscount]);

  return (
    <div>
      <HeaderPayment total={formatNumber(total - calculeDiscount)} />

      <Row className="mt-3">
        <Col md={6} xl={6} lg={6} xs={6}>
          <Row>
            <Col>
              <p>Número de referencia</p>
              <Input
                name="referenceNumber"
                placeholder="Número de referencia"
                value={paymentInfo.referenceNumber}
                onChange={(e) => handleChangePayment(e)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p>Banco</p>
              <Dropdown
                placeholder="Seleccione"
                options={BankNameOptions}
                value={paymentInfo.bankName}
                onChange={(value) =>
                  handleChangePayment({ target: { value, name: 'bankName' } })
                }
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p>{`Descuento en ${isDiscountPercentage ? '%' : 'pesos'}`}</p>
              <CurrencyFormat
                customInput={Input}
                placeholder="$"
                thousandSeparator
                prefix={isDiscountPercentage ? '%' : '$'}
                name="discount"
                value={discount}
                onValueChange={({ floatValue }) => {
                  handleChangePayment({
                    target: {
                      value: isNaN(floatValue) ? 0 : floatValue,
                      name: 'discount',
                    },
                  });
                }}
              />
            </Col>
            <Col xl={3}>
              <Button
                className="mt-4"
                outline
                size="sm"
                onClick={() => handleChangeDiscount()}
                active={isDiscountPercentage}
              >
                <p>
                  <PercentIcon />
                </p>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={6} xl={6} lg={6} xs={6}>
          <p>Observaciones</p>
          <textarea
            rows={10}
            className="textarea-description"
            name="observation"
            onChange={(e) => handleChangePayment(e)}
            value={paymentInfo.observation}
          />
        </Col>
      </Row>

      <Button
        className="mt-3"
        size="sm"
        outline
        onClick={() => createPosDocument()}
      >
        <p>Pagar</p>
      </Button>
    </div>
  );
};

export default Transfer;
