import ActionPosTypes from './pos.types';

export const openTurn = (idTurn) => ({
  type: ActionPosTypes.OPEN_TURN,
  payload: idTurn,
});
export const closeTurn = () => ({
  type: ActionPosTypes.CLOSE_TURN,
});

export const changeModal = (modal) => ({
  type:
    modal === 'openTurn'
      ? ActionPosTypes.CHANGE_MODAL_OPEN
      : ActionPosTypes.CHANGE_MODAL_CLOSE,
  payload: modal,
});
