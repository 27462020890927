import actionPlanTypes from './plan.types';

const INITIAL_STATE = {
  plan: null,
};

const planReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionPlanTypes.SET_PLAN_DATA:
      return action.payload;
    default:
      return state;
  }
};
export default planReducer;
