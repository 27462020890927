import React, { lazy } from 'react';
import SelecteCompanyModal from '../../Account/SelectCurrentUserModal';
import FormDashboard from '../../POS/dashboard';

const Calendar = lazy(() => import('../../Account/Profile'));
const AppDashboard = lazy(() => import('../../Dashboard'));
const ItemRouter = lazy(() => import('../../Items'));
const SupplierItemRouter = lazy(() => import('../../SupplierProduct'));
const TaxRouter = lazy(() => import('../../Configurations/Taxes'));
const ResolutionRouter = lazy(() => import('../../Configurations/Resolutions'));
const ConfigurationsRouter = lazy(() => import('../../Configurations/index'));
const EmployeeRouter = lazy(() => import('../../Thridparty/Employee/index'));
const CustomersRouter = lazy(() => import('../../Thridparty/Customers/index'));
const SalesmanRouter = lazy(() => import('../../Thridparty/salesman/index'));
const SuppliersRouter = lazy(() => import('../../Thridparty/Suppliers/index'));
const PatientRouter = lazy(() => import('../../Thridparty/Patients/index'));
const EnablerRouter = lazy(() => import('../../Configurations/Enabler'));
const IncomeRouter = lazy(() => import('../../Income'));
const ExpenseRouter = lazy(() => import('../../Expense'));
const BankRouter = lazy(() => import('../../Bank'));
const MarketPLaceRouter = lazy(() => import('../../MarketPlace'));
const PricingRouter = lazy(() => import('../../Pricing'));
const LogsRouter = lazy(() => import('../../Log/index'));
//Community
const CommunityRouter = lazy(() => import('../../Community'));
//POS
const PosRouter = lazy(() => import('../../POS/index'));
const TurnList = lazy(() => import('../../POS/turn/List'));
const TerminalesRouter = lazy(() => import('../../POS/terminals/index'));
const SalesList = lazy(() => import('../../POS/sales/List'));
export const LoggedInRouter = [
  {
    name: 'AppDashboard',
    path: '/',
    component: <AppDashboard />,
  },
  {
    name: 'Sales',
    path: '/sales',
    component: <SalesList />,
  },
  {
    name: 'SelectWorkspace',
    path: '/workspaces',
    component: <SelecteCompanyModal />,
  },
  {
    name: 'Calendar',
    path: '/account',
    component: <Calendar />,
  },
  {
    name: 'ItemRouter',
    path: '/products/*',
    component: <ItemRouter />,
  },
  {
    name: 'SupplierItemRouter',
    path: '/supplier-products/*',
    component: <SupplierItemRouter />,
  },
  {
    name: 'TaxRouter',
    path: '/taxs/*',
    component: <TaxRouter />,
  },
  {
    name: 'ResolutionRouter',
    path: '/resolutions/*',
    component: <ResolutionRouter />,
  },
  {
    name: 'ConfigurationsRouter',
    path: '/configurations',
    component: <ConfigurationsRouter />,
  },
  {
    name: 'EmployeeRouter',
    path: '/employees/*',
    component: <EmployeeRouter />,
  },
  {
    name: 'CustomersRouter',
    path: '/customers/*',
    component: <CustomersRouter />,
  },
  {
    name: 'SalesmanRouter',
    path: '/salesman/*',
    component: <SalesmanRouter />,
  },
  {
    name: 'SuppliersRouter',
    path: '/suppliers/*',
    component: <SuppliersRouter />,
  },
  {
    name: 'PatientRouter',
    path: '/patients/*',
    component: <PatientRouter />,
  },
  {
    name: 'EnablerRouter',
    path: '/enabler/*',
    component: <EnablerRouter />,
  },
  {
    name: 'IncomeRouter',
    path: '/income/*',
    component: <IncomeRouter />,
  },
  {
    name: 'ExpenseRouter',
    path: '/expense/*',
    component: <ExpenseRouter />,
  },
  {
    name: 'BankRouter',
    path: '/bank/*',
    component: <BankRouter />,
  },
  {
    name: 'MarketPLaceRouter',
    path: '/marketPlace/*',
    component: <MarketPLaceRouter />,
  },
  {
    name: 'CommunityRouter',
    path: '/community/*',
    component: <CommunityRouter />,
  },
  {
    name: 'PricingRouter',
    path: '/plans/*',
    component: <PricingRouter />,
  },
  {
    name: 'LogsRouter',
    path: '/logs/*',
    component: <LogsRouter />,
  },
  {
    name: 'posTerminales',
    path: '/pos/terminales/*',
    component: <TerminalesRouter />,
  },
  {
    name: 'Sales',
    path: '/pos/sales',
    component: <SalesList isAdmin />,
  },
  {
    name: 'POS',
    path: '/pos',
    component: <PosRouter />,
  },
];

export const CashierRoutes = [
  {
    name: 'POS',
    path: '/',
    component: <PosRouter />,
  },
  {
    name: 'POSEDIT',
    path: '/edit/:id',
    component: <FormDashboard />,
  },
  {
    name: 'Sales',
    path: '/sales',
    component: <SalesList />,
  },
  {
    name: 'CustomersRouter',
    path: '/customers/*',
    component: <CustomersRouter />,
  },
  {
    name: 'Turns',
    path: '/turns',
    component: <TurnList />,
  },
  {
    name: 'ProfileAccount',
    path: '/account',
    component: <Calendar />,
  },
  {
    name: 'ItemRouter',
    path: '/products/*',
    component: <ItemRouter />,
  },
];
