import UserActionTypes from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  error: undefined,
  isFetching: false,
  userVerified: true,
  userCredentials: null,
  userCompany: true,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_SUCCESS:
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        ...INITIAL_STATE,
        currentUser: action.payload,
      };
    case UserActionTypes.EMAIL_SIGN_IN_START:
    case UserActionTypes.SIGN_UP_START:
    case UserActionTypes.USER_VERIFIED_SIGN_UP:
      return {
        ...state,
        isFetching: true,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        isFetching: false,
        error: null,
      };
    case UserActionTypes.CHECK_USER_SESSION:
      return {
        ...state,
        isFetching: true,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UserActionTypes.USER_NOT_VERIFIED:
      return {
        ...state,
        userVerified: false,
        isFetching: false,
      };
    case UserActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userCredentials: action.payload,
      };
    case UserActionTypes.USER_VERIFIED_START:
      return {
        ...state,
        isFetching: true,
      };
    case UserActionTypes.USER_VERIFIED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userVerified: true,
        userCompany: false,
      };
    case UserActionTypes.CREATE_COMPANY:
      return {
        isFetching: false,
        userCompany: false,
        userCredentials: action.payload,
      };
    case UserActionTypes.SIGN_UP_SUCCESS_VERIFY:
      return {
        ...state,
        isFetching: false,
        userId: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
