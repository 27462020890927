export const getProductsByCompany = `
query GetCompany($id: ID!,$filter: ModelProductServiceFilterInput,$nextToken: String) {
  getCompany(id: $id) {
    productsServices(limit: 1000, filter: $filter, nextToken: $nextToken) {
      items {
        id
        taxed
        type
        name
        code
        description
        vatPercentage
        INC
        inced
        incPercentage
        description
        photos{
          bucket
          region
          key
        }
        videos{
          bucket
          region
          key
        }
        unit{
          code
          name
        }
        active
        prices{
          id
          value
          name
          administration
          unexpected
          utility
        }
        defaultPrice
      }
      nextToken
    }
  }
}
`;
