import React from 'react';
import { Col, Row } from 'reactstrap';

const HeaderPayment = ({ total }) => {
  return (
    <Row>
      <Col>
        <h4>Total a pagar</h4>
        <h4 className="bold-text m-3">{total}</h4>
      </Col>
    </Row>
  );
};

export default HeaderPayment;
