import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Container,
  Row,
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import { companiesByTAXID } from './queries';
import { useDispatch } from 'react-redux';
import { emailSignInStart } from '../../../redux/user/user.actions';
import Loading from '../../../shared/components/Loading';
import { useNavigate } from 'react-router-dom';
import AlertComponent from '../../../shared/components/Alert';

function SelecteCompanyModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyTAXID, setCompanyTAXID] = useState([]);
  const [currentTAXID, setCurrentTAXID] = useState([]);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const toggle = () => setOpen(!open);

  const getCompanyQ = async (id) => {
    try {
      setLoading(true);
      setCurrentTAXID(id);
      if (id !== '') {
        const companies = await API.graphql(
          graphqlOperation(companiesByTAXID, {
            TAXID: id,
          })
        );
        const companiesSelected = companies.data.companiesByTAXID.items;
        setCompanyTAXID(companiesSelected);
      } else {
        setCompanyTAXID([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setNewCompany = async (id) => {
    try {
      dispatch(emailSignInStart(id));
      navigate('/');
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };
  {
  }
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Ingresar a nuevo usuario</h3>
          <h3 className="page-subhead subhead">
            Aquí puedes entrar a revisar la informacion de otro usuario solo con
            el TAXID de si empresa.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <p>Escribe TAXID de la empresa:</p>
              <Input
                autoFocus
                onChange={(e) => getCompanyQ(e.target.value)}
                value={currentTAXID}
                placeholder="ej: 123456"
                className="mb-5"
              />
              {loading && <Loading />}

              {!loading && (
                <Row md={12} lg={12}>
                  {companyTAXID.length > 0 && (
                    <Col>
                      {companyTAXID.map((company) => (
                        <div
                          onClick={() =>
                            setNewCompany(company.users.items[0].id)
                          }
                        >
                          <AlertComponent
                            color="info"
                            className="alert--bordered cursor-pointer mb-3"
                            icon
                          >
                            <p>
                              <b>Nombre de la empresa:</b>
                              <br /> {company.name}
                            </p>
                            <p>
                              <b>Email: </b>
                              <br />
                              {company.users.items[0].email}
                            </p>
                          </AlertComponent>
                        </div>
                      ))}
                    </Col>
                  )}
                  {companyTAXID.length === 0 && (
                    <Col>
                      <AlertComponent
                        color="warning"
                        className="alert--bordered"
                        icon
                      >
                        <h5 className="bold-text">
                          No se encontraron coincidencias
                        </h5>
                      </AlertComponent>
                    </Col>
                  )}
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SelecteCompanyModal;
