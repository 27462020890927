import SidebarActionTypes from './sidebar.ypes';

const INITIAL_STATE = {
  show: false,
  collapse: true,
};

const sidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SidebarActionTypes.CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case SidebarActionTypes.CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    default:
      return state;
  }
};
export default sidebarReducer;
