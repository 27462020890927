export const getPOS = /* GraphQL */ `
  query GetPOS($id: ID!) {
    getPOS(id: $id) {
      id
      code
      owner
      terminals {
        items {
          id
          code
          extraData
          nameType
          location
          resolution {
            name
            POSDocumentPrefix
            POSDocumentResolution
            branchCode
            currentPOSDocumentNumber
            endDate
            initDate
            rangeEnd
            rangeInit
          }
          notesResolution {
            name
            POSDocumentPrefix
            POSDocumentResolution
            branchCode
            currentPOSDocumentNumber
            endDate
            initDate
            rangeEnd
            rangeInit
          }
          invoiceResolution {
            name
            POSDocumentPrefix
            POSDocumentResolution
            branchCode
            currentPOSDocumentNumber
            endDate
            initDate
            rangeEnd
            rangeInit
            technicalKey
          }
        }
        nextToken
      }
    }
  }
`;

export const getTerminal = /* GraphQL */ `
  query GetTerminal($id: ID!) {
    getTerminal(id: $id) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
      }
      invoiceResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        technicalKey
        currentPOSDocumentNumber
        POSDocumentResolution
      }
      nameType
      location
    }
  }
`;

export const onUpdateTerminal = /* GraphQL */ `
  subscription OnUpdateTerminal(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onUpdateTerminal(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        currentPOSDocumentNumber
        POSDocumentResolution
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        currentPOSDocumentNumber
        POSDocumentResolution
      }
      nameType
      location
    }
  }
`;
export const onCreateTerminal = /* GraphQL */ `
  subscription onCreateTerminal(
    $owner: String
    $readers: String
    $editors: String
  ) {
    onCreateTerminal(owner: $owner, readers: $readers, editors: $editors) {
      id
      code
      extraData
      resolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        currentPOSDocumentNumber
        POSDocumentResolution
      }
      notesResolution {
        name
        branchCode
        POSDocumentPrefix
        initDate
        endDate
        rangeInit
        rangeEnd
        currentPOSDocumentNumber
        POSDocumentResolution
      }
      nameType
      location
    }
  }
`;
