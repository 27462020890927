import { lazy } from 'react';

const LoggedOutRouter = [
  {
    name: 'LogIn',
    path: '/',
    component: lazy(() => import('../../Account/LogIn')),
  },
  {
    name: 'ChangePassword',
    path: '/change-password',
    component: lazy(() => import('../../Account/ChangePassword')),
  },
  {
    name: 'Verification',
    path: '/confirm-email',
    component: lazy(() => import('../../Account/Verification')),
  },
  {
    name: 'ForgotPassword',
    path: '/reset-password',
    component: lazy(() => import('../../Account/ForgotPassword')),
  },
];

export default LoggedOutRouter;
