const availableAlls = ['STA', 'STM', 'TL', 'REP'];

export const limitPlans = [
  '400P',
  '150P',
  '10P',
  '1000P',
  '2500P',
  '5000P',
  '10000P',
];

export const AvailableInvoicePlans = [...availableAlls.concat(limitPlans)];
export const AvailablePayrollPlans = [
  ...availableAlls.concat(limitPlans),
  'SNE',
];
