import { useEffect } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { getCustomerByCompany, getCustomer } from './queries';
import { useQuery, useQueryClient } from 'react-query';
import {
  onCreateCustomer,
  onDeleteCustomer,
  onUpdateCustomer,
} from '../../../graphql/subscriptions';
import queryKeys from '../queryKeys';
import { useParams } from 'react-router-dom';
import {
  listFiscalResponsabilitys,
  listIDTypes,
  listRegimenTypes,
} from '../../../graphql/queries';
import _ from 'lodash';
import { cleanObject } from '../../../utils/formulas';

const fetchCustomers = async (id, filterOption) => {
  const customers = await fetchAllCustomers(id, filterOption);
  const allCustomers = customers.map((customers, idx) => ({
    idx: idx + 1,
    nationalID: customers.nationalID,
    fullName:
      customers.businessName === '' || customers.businessName === null
        ? `${customers.name} ${customers.lastname}`
        : customers.businessName,
    contacts: customers.contacts[0],
    id: customers.id,
    idType: customers.idType,
    location: customers.locations,
    regimenType: customers.regimenType,
    fiscalResponsability: customers.fiscalResponsability,
    options: {
      id: customers.id,
      status: customers.active === null ? true : customers.active,
    },
  }));
  return _.orderBy(allCustomers, ['options.status'], ['desc']);
};
export const fetchAllCustomers = async (id, filterOption) => {
  try {
    let customers = [];
    let nextToken = null;
    const options = {};
    if (filterOption !== 'ALL') {
      options.filter = {
        or: [{ active: { eq: true } }, { active: { attributeExists: false } }],
      };
    }
    do {
      const custo = await API.graphql(
        graphqlOperation(getCustomerByCompany, {
          id: id,
          nextToken,
          ...options,
        })
      );
      customers.push(...custo.data.getCompany.customers.items);
      nextToken = custo.data.getCompany.customers.nextToken;
    } while (nextToken);

    return customers;
  } catch (error) {
    console.error('fetchAllCustomers', error);
  }
};
export const useCustomers = (filter = 'ALL') => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const queryClient = useQueryClient();

  const { data: customers = [], isLoading } = useQuery(
    [queryKeys.customers, filter],
    () => fetchCustomers(currentUser.company.id, filter)
  );

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateCustomer, {
        owner: currentUser.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: (eventData) => {
        const custo = eventData.value.data.onCreateCustomer;
        queryClient.setQueryData(queryKeys.customers, (prevData) => [
          ...prevData,
          custo,
        ]);
      },
    });

    const subscription2 = API.graphql(
      graphqlOperation(onUpdateCustomer, {
        owner: currentUser.id,
        readers: currentUser.id,
        editors: currentUser.id,
      })
    ).subscribe({
      next: async (eventData) => {
        let customers = [];
        let nextToken = null;
        do {
          const custo = await API.graphql(
            graphqlOperation(getCustomerByCompany, {
              id: currentUser.company.id,
              nextToken,
            })
          );
          customers.push(...custo.data.getCompany.customers.items);
          nextToken = custo.data.getCompany.customers.nextToken;
        } while (nextToken);

        const allCustomers = customers.map((customers, idx) => ({
          idx: idx + 1,
          nationalID: customers.nationalID,
          fullName:
            customers.businessName === ''
              ? `${customers.name} ${customers.lastname}`
              : customers.businessName,
          contacts: customers.contacts[0],
          id: customers.id,
          options: {
            id: customers.id,
            status: customers.active === null ? true : customers.active,
          },
        }));

        queryClient.setQueryData(queryKeys.customers, () =>
          _.orderBy(allCustomers, ['options.status'], ['desc'])
        );
      },
    });
    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
    };
  }, [currentUser.company.id, currentUser.id, queryClient]);

  return {
    customers,
    isLoading,
  };
};

const fetchCustomer = async (id) => {
  try {
    const { data } = await API.graphql(graphqlOperation(getCustomer, { id }));
    const third = cleanObject({
      ...data.getCustomer,
      active: data.getCustomer.active ?? true,
    });
    const {
      idType,
      nationalID,
      verificationDigit,
      name,
      lastname,
      personType,
      businessName,
      locations,
      phoneNumber,
      contacts,
      tags,
      regimenType,
      defaultLocation,
      fiscalResponsability,
      active,
    } = third;

    if (locations)
      for (let loc of locations) if (!loc.phoneNumbers) loc.phoneNumbers = [];
    // if (contacts)
    //   for (let cont of contacts)
    //     if (!cont.location) cont.location.phoneNumbers = [];

    return {
      nationalID,
      verificationDigit: verificationDigit ? verificationDigit : 0,
      name,
      lastname,
      personType,
      businessName,
      active: Boolean(active),
      defaultLocation: Number(defaultLocation),
      locations: locations ? locations : [],
      phoneNumber: phoneNumber ? phoneNumber : [],
      contacts: contacts ? contacts : [],
      tags: tags ? tags : [],
      customerIdTypeId: idType ? idType.id : '',
      customerRegimenTypeId: regimenType ? regimenType.id : '',
      customerFiscalResponsabilityId: fiscalResponsability
        ? fiscalResponsability.id
        : '',
    };
  } catch (error) {
    console.error('fetchCustomer', error);
  }
};

export function useGetCustomer() {
  const params = useParams();
  const queryClient = useQueryClient();

  const { data: customer = {}, isLoading } = useQuery(
    [queryKeys.customers, params.id],
    () => fetchCustomer(params.id),
    {
      initialData: async () => {
        const data = queryClient
          .getQueryData(queryKeys.customers)
          ?.find((d) => d.id === params.id);
        if (data) {
          return data;
        }
      },
      enabled: !!params.id,
    }
  );

  return {
    customer,
    isLoading,
  };
}

const fetchIdType = async () => {
  const { data } = await API.graphql(
    graphqlOperation(listIDTypes, { limit: 1100 })
  );

  return data.listIDTypes.items.map((item) => ({
    value: item.id,
    key: item.id,
    label: _.capitalize(item.name),
  }));
};

export function useIdType() {
  const { data: idTypeOptions = [], isLoading } = useQuery(
    queryKeys.idType,
    () => fetchIdType()
  );
  return {
    idTypeOptions,
    isLoading,
  };
}
const fetchRegimenType = async () => {
  const { data } = await API.graphql(
    graphqlOperation(listRegimenTypes, { limit: 1100 })
  );

  return data.listRegimenTypes.items.map((item) => ({
    value: item.id,
    key: item.id,
    label: _.capitalize(item.name),
  }));
};

export function useRegimenType() {
  const { data: regimenTypeOptions = [], isLoading } = useQuery(
    queryKeys.regimenType,
    () => fetchRegimenType()
  );
  return {
    regimenTypeOptions,
    isLoading,
  };
}
const fetchFiscalResponsability = async () => {
  const { data } = await API.graphql(
    graphqlOperation(listFiscalResponsabilitys, { limit: 1100 })
  );

  return data.listFiscalResponsabilitys.items.map((item) => ({
    value: item.id,
    key: item.id,
    label: _.capitalize(item.name),
  }));
};

export function useFiscalResponsability() {
  const { data: fiscalOptions = [], isLoading } = useQuery(
    queryKeys.fiscalResponsability,
    () => fetchFiscalResponsability()
  );
  return {
    fiscalOptions,
    isLoading,
  };
}
