import React from 'react';

import { useSelector } from 'react-redux';
import moment from 'moment';

export function useEnabler(availablePlans = []) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const configuration = currentUser?.company?.configuration;

  const currentDate = moment();

  const dateInvalid = moment(currentUser.company.plan.endDate).add(5, 'days');

  const checker = React.useCallback(() => {
    if (currentDate.diff(dateInvalid, 'days') > 0) {
      return true;
    }
    if (availablePlans.length > 0) {
      return !availablePlans.includes(currentUser.company.plan.type.planID);
    }

    return false;
  }, [
    availablePlans,
    currentDate,
    currentUser.company.plan.type.planID,
    dateInvalid,
  ]);

  return {
    isDisabled: checker(),
    isNearExpiration: currentDate.diff(dateInvalid, 'days') >= -5,
    expirationDate: dateInvalid.diff(currentDate, 'days'),
    resolutionExpirationDate: dateInvalid.diff(currentDate, 'days'),
    isEnabledInvoice: configuration?.eInvoicingDIANEnabled ?? false,
    isEnabledPayrrol: configuration?.ePayrollDIANEnabled ?? false
  };
}
